import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaUtensils } from 'react-icons/fa';
import RegisterButton from '~/components/Buttons/RegisterButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import api from '~/services/api';
import ChooseKitchen from './ChooseKitchen';

import { Container, Card, Header, Content } from './styles';
import { Button } from 'takeat-design-system-ui-kit';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '~/context/AuthContext';

function Kds() {
  const [kds, setKds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [createKds, setCreateKds] = useState(false);
  const history = useHistory()
  const { user } = useAuth()

  const getKds = useCallback(async () => {
    const response = await api.get('/restaurants/kds');
    setIsLoading(false);
    setKds(response.data);
  }, []);

  useEffect(() => {
    try {
      getKds();
    } catch (error) {
      console.log(error.message);
    }
  }, [getKds]);

  const [permission, setPermission] = useState();


  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'OperationKDS'}`
      );

      const { can_read } = response.data.OperationKDS;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);


  const handleOpenInNewTab = () => {
    const url = history.createHref({ pathname: '/kds-password' });
    window.open(url, '_blank');
  };


  return !permission ? (
    <Container>
      <PermissionErrorContainer />
    </Container>
  ) : isLoading ? (
    <Container>
      <Header>
        <h2>Tela KDS</h2>
        {kds && kds.length > 0 && user.id === 2 && (
          <Button onClick={handleOpenInNewTab}>
            Tela de senhas
          </Button>
        )}
      </Header>
      <Card>
        <Content>
          <p>Carregando ...</p>
        </Content>
      </Card>
    </Container>
  ) : (
    <Container>
      <Header>
        <h2>Tela KDS</h2>
        {kds && kds.length > 0 && user.id === 2 && (
          <Button onClick={handleOpenInNewTab}>
            Tela de senhas
          </Button>
        )}
      </Header>
      {(kds && kds.length > 0) || createKds ? (
        <ChooseKitchen kds={kds} getKds={getKds} />
      ) : (
        <Card>
          <Content>
            <FaUtensils size={44} />
            <p style={{ fontWeight: 'bold', marginTop: 20 }}>
              Você não possui KDS configurado ainda...
            </p>
            <p style={{ marginBottom: 20 }}>
              Clique no botão “Adicionar KDS” para começar.
            </p>
            <RegisterButton
              text="Adicionar KDS"
              onClick={() => setCreateKds(true)}
            />
          </Content>
        </Card>
      )}
    </Container>
  );
}

export default Kds;
