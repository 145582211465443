import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';
import api2 from '~/services/api2';

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [menu, setMenu] = useState([]);
  const [productsFiltered, setProductsFiltered] = useState();

  const [categoryState, setCategoryState] = useState();

  const { user } = useAuth();
  const userId = user?.restaurant_id || user?.id;

  // async function handleSearchInputProduct(data, inputProductRef) {
  //   try {
  //     if (inputProductRef?.current) {
  //       inputProductRef.current.setErrors({});
  //     }
  //     const schema = Yup.object().shape({
  //       product: Yup.string().required('Produto obrigatório'),
  //     });

  //     await schema.validate(data, {
  //       abortEarly: false,
  //     });

  //     const product = data.product.toLowerCase();
  //     const response = await api.get(
  //       `/restaurants/products-available?delivery=${data.isTableDelivery}`
  //     );

  //     const productFind = response.data.filter((prod) => {
  //       if (prod.name.toLowerCase().includes(product)) {
  //         return prod;
  //       }
  //       return '';
  //     });

  //     setProductsFiltered({ products: productFind });
  //   } catch (error) {
  //     if (error instanceof Yup.ValidationError) {
  //       const errors = getValidationErrors(error);
  //       if (inputProductRef?.current) {
  //         inputProductRef.current.setErrors(errors);
  //       }
  //     } else {

  //       toast.error(`Produto não encontrado!`);
  //     }
  //   }
  // }

  async function handleSearchInputProduct(data, isTableDelivery) {
    if (isTableDelivery) {
      if (data.length >= 3) {
        try {
          // const products = [];

          // menu.map((cat) => cat.map(cat => cat.products).map((prod) => products.push(prod)));

          // setMenuProducts(products);

          const productFind = menu.map((cat) =>
            cat.products.map((prod) => {
              if (
                prod.name
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .includes(
                    data
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  ) &&
                prod.available_in_delivery
              ) {
                return { prod, cat };
              }
              return '';
            })
          );

          const arrayReady = productFind
            .map((item) => item.filter((cat) => cat !== ''))
            .filter((arr) => arr.length > 0);
          setProductsFiltered(arrayReady);
        } catch (error) {
          console.log(`Produto não encontrado!`);
        }
      }

      if (data.length === 0) {
        setProductsFiltered({ products: [] });
      }
    } else {
      if (data.length >= 3) {
        try {
          // const products = [];

          // menu.map((cat) => cat.map(cat => cat.products).map((prod) => products.push(prod)));

          // setMenuProducts(products);

          const productFind = menu.map((cat) =>
            cat.products.map((prod) => {
              if (
                prod.name
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .includes(
                    data
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  ) &&
                prod.available
              ) {
                return { prod, cat };
              }
              return '';
            })
          );

          const arrayReady = productFind
            .map((item) => item.filter((cat) => cat !== ''))
            .filter((arr) => arr.length > 0);
          setProductsFiltered(arrayReady);
        } catch (error) {
          console.log(`Produto não encontrado!`);
        }
      }

      if (data.length === 0) {
        setProductsFiltered({ products: [] });
      }
    }


  }

  const handleChangeCategory = useCallback(
    async (categoryName) => {
      // const response = await api.get(`/public/restaurants/menu/${userId}`);
      const categoryFiltered = menu.filter((cat) => cat.name === categoryName);

      setProductsFiltered('');

      setCategoryState(categoryFiltered[0]);
    },
    [menu]
  );

  const updateMenuCategory = useCallback(
    (id, data) => {
      const category = menu.find((c) => c.id === id);

      if (category) {
        Object.keys(data).forEach((k) => {
          category[k] = data[k];
        });
      }
    },
    [menu]
  );

  const getMenu = useCallback(
    async (userScreenTop) => {
      const response = await api.get(`/public/restaurants/menu/${userId}`);
      setMenu(response.data);

      const categoryNotEmptyPdv = response.data.filter((cat) => cat.available);

      const categoryNotEmptyDelivery = response.data.filter(
        (cat) => cat.available_in_delivery
      );

      if (user.only_delivery) {
        setCategoryState(categoryNotEmptyDelivery[0]);
      } else {
        setCategoryState(categoryNotEmptyPdv[0]);
      }

      if (userScreenTop) {
        window.scroll({
          top: Number(userScreenTop),
          behavior: 'smooth',
        });
      }
    },
    [userId, user]
  );

  useEffect(() => {
    if (user?.id) {
      getMenu();
    }
  }, [getMenu, user]);

  const value = useMemo(
    () => ({
      handleChangeCategory,
      handleSearchInputProduct,
      productsFiltered,
      categoryState,
      menu,
      setProductsFiltered,
      getMenu,
      setCategoryState,
      setMenu,
      updateMenuCategory,
    }),
    [
      categoryState,
      productsFiltered,
      handleChangeCategory,
      setProductsFiltered,
      menu,
      getMenu,
      setMenu,
      setCategoryState,
      updateMenuCategory,
      handleSearchInputProduct,
    ]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

const useMenu = () => {
  const context = useContext(MenuContext);

  if (!context) {
    console.log('useCart must be within a provider');
  }

  return context;
};

export { MenuProvider, useMenu };
