import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Tabs } from 'ui-kit-takeat';
import { Modal } from 'reactstrap';
import { Container, CategoriesTable, Header, FilterArea } from './styles';
import api2 from '~/services/api2';
import NewTicket from './Store';

import Coupom from './Coupom';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Button, Checkbox, IconPlus } from 'takeat-design-system-ui-kit';
import { IoFilterSharp } from 'react-icons/io5';

import { PageTitle } from '~/components/PageTitle';

function Tickets() {
  const [tickets, setTickets] = useState([]);
  const [allTickets, setAllTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isModalAddTicketsOpen, setIsModalAddTicketsOpen] = useState(false);
  const [filter, setFilter] = useState('Ativos');
  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
  function toggleAddTicketModal() {
    setIsModalAddTicketsOpen(!isModalAddTicketsOpen);
  }
  const [filterType, setFilterType] = useState(['delivery']);

  function toggleFilterType(option) {
    setFilterType((prev) => {
      if (prev.includes(option)) {
        if (prev.length === 1) {
          return prev;
        }
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  }

  const getTickets = useCallback(async () => {
    try {
      const response = await api2.get('/restaurants/discount-coupons');
      setAllTickets(response.data);
      setFilter(filter);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api2.get(
        `/restaurants/users/role-permission/${'DeliveryCoupons'}`
      );

      const { can_read } = response.data.DeliveryCoupons;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  function toggleFilterModal() {
    setIsFilterModalOpened(!isFilterModalOpened);
  }

  const areaRef = useRef(null);

  const handleClickOutside = (event) => {
    if (areaRef.current && !areaRef.current.contains(event.target)) {
      setIsFilterModalOpened(false);
    }
  };

  function changeFilter(tab) {
    switch (tab) {
      case 0:
        setFilter('Ativos');
        break;
      case 1:
        setFilter('Inativos');
        break;
      case 2:
        setFilter('Expirados');
        break;
    }
  }

  useEffect(() => {
    const filteredTickets = allTickets.filter((ticket) => {
      if (
        filterType.includes('delivery') &&
        ticket.accepted_channels.includes('delivery') &&
        !ticket.is_campaign
      ) {
        return true;
      }

      if (filterType.includes('crm') && ticket.is_campaign) {
        return true;
      }

      if (
        filterType.includes('presencial') &&
        ticket.accepted_channels.includes('presencial') &&
        !ticket.is_campaign
      ) {
        return true;
      }
    });
    setTickets(filteredTickets);
    console.log(filteredTickets, 'filtertype', filterType);
  }, [filterType, allTickets]);

  useEffect(() => {
    if (isFilterModalOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFilterModalOpened]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    const cuponsFiltrados = tickets.filter((ticket) => {
      if (
        filter === 'Inativos' &&
        !ticket.is_active &&
        (new Date(ticket.limit_date) > new Date() || ticket.limit_date === null)
      )
        return true;
      if (
        filter === 'Expirados' &&
        ticket.limit_date !== null &&
        new Date(ticket.limit_date) < new Date()
      )
        return true;
      if (filter === 'Ativos' && ticket.is_active) {
        if (ticket.limit_date === null) {
          return true;
        } else if (new Date(ticket.limit_date) > new Date()) {
          return true;
        }
      }
    });
    setFilteredTickets(cuponsFiltrados);
  }, [tickets, filter]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PageTitle>Cupons</PageTitle>
            <VideoPlayer id={71} />
          </div>
        </div>
        <div style={{ display: 'flex', gap: 20 }}>
          <Button
            children="Filtrar"
            icon={<IoFilterSharp color="#2EC9B7" />}
            style={{ padding: '10px 24px', fontSize: 16, fontWeight: 600 }}
            variant="outlined"
            customColor="#2EC9B7"
            onClick={toggleFilterModal}
          />
          {isFilterModalOpened && (
            <FilterArea ref={areaRef}>
              <p style={{ paddingTop: 8 }}>
                <Checkbox
                  label="Delivery"
                  checked={filterType.includes('delivery')}
                  onClick={() => toggleFilterType('delivery')}
                />
              </p>
              <p>
                <Checkbox
                  label="Presencial"
                  checked={filterType.includes('presencial')}
                  onClick={() => toggleFilterType('presencial')}
                />
              </p>
              <p>
                <Checkbox
                  label="CRM"
                  checked={filterType.includes('crm')}
                  onClick={() => toggleFilterType('crm')}
                />
              </p>
            </FilterArea>
          )}
          <Button
            children="Cadastrar"
            customColor="#ff2c3a"
            icon={<IconPlus color="white" />}
            onClick={toggleAddTicketModal}
            style={{ padding: '10px 24px', fontSize: 16, fontWeight: 600 }}
          />
        </div>
      </Header>
      <Tabs
        tabs={['Ativos', 'Inativos', 'Expirados']}
        onChangeTab={(e) => {
          setActiveTab(e);
          changeFilter(e);
        }}
        selected={activeTab}
      />

      <Modal
        isOpen={isModalAddTicketsOpen}
        toggle={toggleAddTicketModal}
        size="lg"
      >
        <NewTicket
          getTickets={getTickets}
          toggleAddTicketModal={toggleAddTicketModal}
        />
      </Modal>

      <CategoriesTable borderless firstTab={activeTab === 0}>
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <th>Operação</th>
            <th>Público</th>
            <th>Desconto</th>
            <th>$ MÍNIMO</th>
            <th>Desconto Máx.</th>
            <th>Limite</th>
            <th>Usado</th>
            <th>Expiração</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tickets && tickets.length > 0 ? (
            filteredTickets.map((ticket) => (
              <Coupom coupom={ticket} getTickets={getTickets} />
            ))
          ) : (
            <h1>Vazio</h1>
          )}
        </tbody>
      </CategoriesTable>
    </Container>
  );
}

export default Tickets;
