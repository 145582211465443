import React, { useEffect, useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { Warning } from 'ui-kit-takeat';
import { useAuth } from '~/context/AuthContext';
import { SidebarContainer, FillBar, FillBarTop } from './styles';
import { NewSidebarItem } from './NewSidebarItem';

export const NewNewSidebar = ({ menuItems, user, color }) => {
  const [openItem, setOpenItem] = useState('');
  const [searchArray, setSearchArray] = useState([]);
  const { combinedGroup } = useAuth();
  useEffect(() => {
    const flattenItems = (items, depth = 1, parentPath = '') => {
      return items.reduce((result, item) => {
        const { text, path, subitems, condition } = item;
        let itemCondition = condition === undefined ? true : condition;
        let fullPath = parentPath ? `${parentPath}` : path;
        if (path) {
          fullPath = `${fullPath}${path}`;
        }
        if (path && subitems?.length > 0) {
          itemCondition = false;
        }
        if (!path && subitems?.length > 0) {
          fullPath = `${fullPath}${subitems[0].path}`;
        }
        result.push({
          text,
          path: fullPath,
          external: item.external,
          externalRef: item.externalRef,
          condition: itemCondition,
        });

        if (depth > 1 && subitems) {
          result.push(
            ...flattenItems(
              subitems,
              depth - 1,
              item.path ? item.path : parentPath
            )
          );
        }

        return result;
      }, []);
    };

    const flattenedItems = flattenItems(menuItems(user), 3);

    setSearchArray(flattenedItems);
  }, [menuItems, user]);

  // warning state
  const warningSidebarMarkupStorage = JSON.parse(
    localStorage.getItem('@gddashboard:warningSidebarMarkupStorage')
  );
  const [warningSidebarMarkup, setWarningSidebarMarkup] = useState(
    warningSidebarMarkupStorage === null ? true : warningSidebarMarkupStorage
  );

  function toggleWarning() {
    setWarningSidebarMarkup(false);
    localStorage.setItem('@gddashboard:warningSidebarMarkupStorage', false);
  }

  return (
    <SidebarContainer>
      {combinedGroup.includes(user.id) && (
        <Warning
          containerStyles={{
            maxWidth: 600,
            position: 'absolute',
            width: 410,
            top: 410,
            left: 40,
          }}
          content="A nova funcionalidade de precificação se encontra no menu  Cadastros > Precificação"
          onClick={toggleWarning}
          show={warningSidebarMarkup}
          title="Nova funcionalidade!"
          type="success"
        />
      )}
      <FillBarTop color={color} />
      <FillBarTop color={color} />
      <NewSidebarItem
        key={0}
        item={menuItems(user)[0]}
        user={user}
        isOpen={openItem === menuItems(user)[0].text}
        setIsOpen={setOpenItem}
        isProfile
        color={color}
      />
      <NewSidebarItem
        key={1}
        item={{
          id: '1',
          text: 'Buscar',
          icon: <FaMagnifyingGlass />,
          path: '',
        }}
        isOpen={openItem === 'Buscar'}
        setIsOpen={setOpenItem}
        isSearch
        color={color}
        searchArray={searchArray}
      />
      <FillBarTop color={color} />
      {menuItems(user)
        .slice(1)
        .map((item, idx) => {
          if (item.condition === false) return null;

          return (
            <NewSidebarItem
              key={idx + 1}
              item={item}
              isOpen={openItem === item.text}
              setIsOpen={setOpenItem}
              color={color}
            />
          );
        })}
      <FillBar color={color} />
    </SidebarContainer>
  );
};
