import React, { useCallback, useEffect, useState } from 'react';
import { PageTitle } from '~/components/PageTitle';
import {
  Button,
  Divider,
  IconHelpFilled,
  IconInfoFilled,
  IconLikeFilled,
  Input,
  NotificationBanner,
  Select,
} from 'takeat-design-system-ui-kit';
import { IconInput } from 'ui-kit-takeat';
import { ModalBody, ModalFooter } from 'reactstrap';
import api from '~/services/api';
import toast from 'react-hot-toast';
import { useAuth } from '~/context/AuthContext';
import { IoIosArrowDown } from 'react-icons/io';
import Product from './Product';
import {
  ActionArea,
  ButtonsArea,
  Container,
  Header,
  SearchArea,
  Card,
  StyledModal,
  InputsArea,
  CustomTooltip,
  CategoryArea,
  CategoryHeader,
  CategoryBody,
  HeaderItem,
  HeaderTitle,
  HeaderItemTitle,
  HeaderItemOptmize,
  NotifyModal,
} from './styles';

function Markup() {
  const { user, combinedGroups } = useAuth();
  const [, setIsLoading] = useState(false);
  const [isModalChangeMarkupOpened, setIsModalChangeMarkupOpened] =
    useState(false);
  const [isModalChangeCmvOpened, setIsModalChangeCmvOpened] = useState(false);
  const [products, setProducts] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [categories, setCategories] = useState([]);

  function toggleModalMarkup() {
    setIsModalChangeMarkupOpened(!isModalChangeMarkupOpened);
  }

  function toggleModalCmv() {
    setIsModalChangeCmvOpened(!isModalChangeCmvOpened);
  }

  const getProducts = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get('/restaurants/markup/products');
      if (response.data.products) {
        setProducts(response.data.products);
      }

      if (response.data.categories) {
        setCategories(response.data.categories);

        const categoriesFormatted = response.data.categories.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });

        setCategoriesOptions([
          { label: 'Todas as categorias', value: 'all' },
          ...categoriesFormatted,
        ]);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error('Erro ao baixar produtos.');
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const [searchInputProd, setSearchInputProd] = useState(null);
  const [categoryFiltered, setCategoryFiltered] = useState(null);

  const handleSearchInputProduct = useCallback(
    async (name) => {
      if (name && name.length >= 3) {
        try {
          // Normalizar o texto para busca
          const normalizedSearch = name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

          // Filtrar produtos que correspondem à busca
          const matchingProducts = products.filter((product) =>
            product.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(normalizedSearch)
          );

          console.log('Matching Products:', matchingProducts);
          console.log('Categories:', categories);

          // Agrupar produtos por categoria e criar o array com produtos correspondentes
          const categorizedProducts = categories
            .map((category) => {
              // Filtrar produtos que pertencem a esta categoria
              const productsInCategory = matchingProducts.filter(
                (product) => product.category === category.name
              );

              // Se a categoria tiver produtos correspondentes, inclui no array
              if (productsInCategory.length > 0) {
                return { ...category, products: productsInCategory };
              }

              return null; // Ignora categorias sem produtos correspondentes
            })
            .filter(Boolean); // Remove itens nulos do array final

          setCategoryFiltered(categorizedProducts);
        } catch (error) {
          toast.error('Erro ao filtrar');
        }
      } else if (name?.length === 0) {
        setCategoryFiltered(null);
      }
    },
    [products, categories]
  );

  useEffect(() => {
    handleSearchInputProduct(searchInputProd);
  }, [searchInputProd, handleSearchInputProduct]);

  function handleSetCategoryFilter(e) {
    const filtered = categories.find((item) => item.id === e);
    if (filtered) {
      const productsFind = products.filter((p) => p.category === filtered.name);
      const newCategory = {
        ...filtered,
        products: productsFind,
      };
      setCategoryFiltered([newCategory]);
    } else {
      setCategoryFiltered(null);
    }
  }

  const [categoriesToMarkup, setCategoriesToMarkup] = useState(null);
  const [categoriesToCmv, setCategoriesToCmv] = useState(null);

  function handleSelectCategoriesToChangeMarkup(e) {
    setCategoriesToMarkup(e);
  }

  function handleSelectCategoriesToChangeCmv(e) {
    setCategoriesToCmv(e);
  }

  const [markupSetter, setMarkupSetter] = useState(null);
  const [markupDeliverySetter, setMarkupDeliverySetter] = useState(null);

  const [markupDefault, setMarkupDefault] = useState(null);
  const [markupDeliveryDefault, setMarkupDeliveryDefault] = useState(null);

  const handleChangeMarkup = useCallback(async () => {
    try {
      const data = {};

      if (!categoriesToMarkup) {
        toast.error('Selecione ao menos uma categoria');
        return;
      }

      if (markupSetter) data.markup = markupSetter;
      if (markupDeliverySetter) data.markup_delivery = markupDeliverySetter;

      data.product_category_ids = categoriesToMarkup.map((item) => item.value);

      await api.put('/restaurants/markup/replicate', data);

      setMarkupDeliveryDefault({
        markup_delivery: markupDeliverySetter,
        categories: categoriesToMarkup.map((item) => item.label),
      });
      setMarkupDefault({
        markup: markupSetter,
        categories: categoriesToMarkup.map((item) => item.label),
      });

      toggleModalMarkup();
      toast.success('Markup definido com sucesso');
      setCategoriesToMarkup(null);
    } catch (error) {
      toast.error('Erro ao definir markup');
    }
  }, [markupSetter, markupDeliverySetter, categoriesToMarkup]);

  const [cmvPercentualSetter, setCmvPercentualSetter] = useState(null);
  const [cmvPercentualDeliverySetter, setCmvPercentualDeliverySetter] =
    useState(null);

  const handleChangeCmvPercentual = useCallback(async () => {
    try {
      const data = {};

      if (!categoriesToCmv) {
        toast.error('Selecione ao menos uma categoria');
        return;
      }

      if (cmvPercentualSetter > 0) {
        data.markup = 100 / cmvPercentualSetter;
      }

      if (cmvPercentualDeliverySetter > 0) {
        data.markup_delivery = 100 / cmvPercentualDeliverySetter;
      }

      data.product_category_ids = categoriesToCmv.map((item) => item.value);
      await api.put('/restaurants/markup/replicate', data);

      if (cmvPercentualSetter > 0) {
        setMarkupDefault({
          markup: (data.markup = 100 / cmvPercentualSetter),
          categories: categoriesToCmv.map((item) => item.label),
        });
      }

      if (cmvPercentualDeliverySetter > 0) {
        setMarkupDeliveryDefault({
          markup_delivery: (data.markup_delivery =
            100 / cmvPercentualDeliverySetter),
          categories: categoriesToCmv.map((item) => item.label),
        });
      }
      toggleModalCmv();
      setCategoriesToCmv(null);
      toast.success('%CMV definido com sucesso');
    } catch (error) {
      toast.error('Erro ao definir %CMV');
    }
  }, [cmvPercentualSetter, cmvPercentualDeliverySetter, categoriesToCmv]);

  // tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  // tooltips
  const [tooltipStates, setTooltipStates] = useState({});

  const toggleTooltip = (id) => {
    setTooltipStates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // hide category
  const [hideCategory, setHideCategory] = useState([]);

  function handleHideCategory(category) {
    if (hideCategory.includes(category)) {
      setHideCategory((old) => {
        const filtered = old.filter((old) => old !== category);

        return filtered;
      });
    } else {
      setHideCategory((old) => {
        return [...old, category];
      });
    }
  }

  const [browserZoomLevel, setBrowserZoomLevel] = useState(
    Math.round(window.devicePixelRatio * 100)
  );

  useEffect(() => {
    const handleZoomChange = () => {
      setBrowserZoomLevel(Math.round(window.devicePixelRatio * 100));
    };

    window.addEventListener('resize', handleZoomChange);

    return () => {
      window.removeEventListener('resize', handleZoomChange);
    };
  }, []);

  const [hasDelivery] = useState(
    user?.delivery_info?.is_delivery_allowed ||
    user?.delivery_info?.is_withdrawal_allowed
  );

  const groupA = [
    32478, 62145, 17661, 64224, 37725, 57228, 52443, 79, 321, 45282, 65247,
    10269, 44325, 31983, 56701, 61584, 19773, 36240, 19377, 30399, 37098, 44523,
    32247, 2283, 314, 36439, 32577, 18816, 322, 201, 161, 338, 39012, 22446,
    19379, 35646, 15285, 28518, 28419, 41652,
  ];

  const groupB = [
    2, 36801, 52707, 54093, 59802, 51948, 28559, 64818, 57261, 224, 25053,
    44952, 50265, 47296, 29640, 56898, 296, 32841, 1690, 57690, 61089, 10830,
    441, 32016, 56733, 52410, 19378, 50826, 15879, 31257, 59, 20829, 22710, 266,
    13701, 14295, 21555, 30201, 1887, 40200, 29641, 13, 43764, 41652, 58482,
  ];

  const [isNotifyNpsOpened, setIsNotifyNpsOpened] = useState(() => {
    const storage = JSON.parse(localStorage.getItem('@gddashboard:notify-nps'));

    return storage === null ? true : storage;
  });

  function toggleNotifyNps() {
    setIsNotifyNpsOpened(!isNotifyNpsOpened);
  }

  async function handleAnswerNps(status) {
    try {
      await api.post('/restaurants/research-answer', {
        research_id: 233,
        value: status,
        comment: null,
      });
      toast.success('Resposta enviada.');
      toggleNotifyNps();

      localStorage.setItem('@gddashboard:notify-nps', false);
      setIsNotifyNpsOpened(false);
    } catch (error) {
      toast.error('Erro ao enviar resposta.');
      console.log(error);
    }
  }

  return (
    <Container>
      <StyledModal
        isOpen={isModalChangeMarkupOpened}
        toggle={toggleModalMarkup}
      >
        <div style={{ padding: 16, height: 56 }}>
          <h3 style={{ fontSize: 18, fontWeight: 600 }}>Alterar Markup</h3>
        </div>
        <ModalBody style={{ padding: 16 }}>
          <Select
            isMulti
            options={categoriesOptions.filter((item) => item.value !== 'all')}
            onChange={(e) => {
              handleSelectCategoriesToChangeMarkup(e);
            }}
            label="Categoria(s)"
          />
          {user.only_delivery ? (
            <InputsArea>
              <Input
                value={markupDeliverySetter}
                onChange={(e) => setMarkupDeliverySetter(e.target.value)}
                placeholder="0"
                isCurrency
              />
            </InputsArea>
          ) : (
            <InputsArea>
              <Input
                value={markupSetter}
                onChange={(e) => setMarkupSetter(e.target.value)}
                label={
                  (user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed) &&
                  'Presencial'
                }
                placeholder="0"
                isCurrency
                style={{
                  width: 250,
                }}
              />
              {(user?.delivery_info?.is_delivery_allowed ||
                user?.delivery_info?.is_withdrawal_allowed) && (
                  <Input
                    value={markupDeliverySetter}
                    onChange={(e) => setMarkupDeliverySetter(e.target.value)}
                    style={{
                      width: 250,
                    }}
                    label="Delivery"
                    placeholder="0"
                    isCurrency
                  />
                )}
            </InputsArea>
          )}
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: 'flex-end',
            padding: 16,
          }}
        >
          <Divider
            style={{
              marginBottom: 16,
            }}
          />
          <Button customColor="#C8131B" onClick={toggleModalMarkup}>
            Cancelar
          </Button>
          <Button
            customColor="#2EC9B7"
            style={{
              marginLeft: 8,
            }}
            onClick={handleChangeMarkup}
          >
            Alterar
          </Button>
        </ModalFooter>
      </StyledModal>

      {user.user_role === 'Administrador' && (
        <NotifyModal isOpen={isNotifyNpsOpened} toggle={toggleNotifyNps}>
          <ModalBody style={{ padding: 16 }}>
            <div
              style={{
                width: 307,
                height: 64,
                background: '#F6F6F6',
                borderRadius: 12,
                padding: '8px 12px',
              }}
            >
              <h3
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: '#222',
                  textAlign: 'center',
                }}
              >
                {' '}
                Está gostando da experiência com a nova Precificação?
              </h3>
            </div>
          </ModalBody>
          <ModalFooter style={{ padding: '0 16px 16px 16px' }}>
            <Button
              customColor="#2EC9B7"
              onClick={() => handleAnswerNps(1)}
              icon={<IconLikeFilled />}
              style={{ width: 148, height: 48 }}
            >
              Sim
            </Button>
            <Button
              customColor="#C8131B"
              onClick={() => handleAnswerNps(0)}
              icon={<IconLikeFilled style={{ transform: 'rotate(180deg)' }} />}
              style={{ width: 148, height: 48 }}
            >
              Não
            </Button>
          </ModalFooter>
        </NotifyModal>
      )}

      <StyledModal isOpen={isModalChangeCmvOpened} toggle={toggleModalCmv}>
        <div style={{ padding: 16, height: 56 }}>
          <h3 style={{ fontSize: 18, fontWeight: 600 }}>Alterar % CMV</h3>
        </div>
        <ModalBody style={{ padding: 16 }}>
          <Select
            isMulti
            options={categoriesOptions.filter((item) => item.value !== 'all')}
            onChange={(e) => {
              handleSelectCategoriesToChangeCmv(e);
            }}
          />
          {user?.only_delivery ? (
            <InputsArea>
              <Input
                value={cmvPercentualDeliverySetter}
                onChange={(e) => setCmvPercentualDeliverySetter(e.target.value)}
                placeholder="%"
                isCurrency
              />
            </InputsArea>
          ) : (
            <InputsArea>
              <Input
                value={cmvPercentualSetter}
                onChange={(e) => setCmvPercentualSetter(e.target.value)}
                label={
                  (user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed) &&
                  'Presencial'
                }
                placeholder="%"
                style={{
                  width: 250,
                }}
                isCurrency
              />
              {(user?.delivery_info?.is_delivery_allowed ||
                user?.delivery_info?.is_withdrawal_allowed) && (
                  <Input
                    value={cmvPercentualDeliverySetter}
                    onChange={(e) =>
                      setCmvPercentualDeliverySetter(e.target.value)
                    }
                    style={{
                      width: 250,
                    }}
                    label="Delivery"
                    placeholder="%"
                    isCurrency
                  />
                )}
            </InputsArea>
          )}
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: 'flex-end',
            padding: 16,
          }}
        >
          <Divider
            style={{
              marginBottom: 16,
            }}
          />
          <Button customColor="#C8131B" onClick={toggleModalCmv}>
            Cancelar
          </Button>
          <Button
            onClick={handleChangeCmvPercentual}
            customColor="#2EC9B7"
            style={{
              marginLeft: 8,
            }}
          >
            Alterar
          </Button>
        </ModalFooter>
      </StyledModal>

      <Header>
        <PageTitle>Precificação</PageTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            Agora você pode editar a precificação do seu produto, seja pelo seu
            markup ou pelo CMV.
          </span>
          <span
            style={{
              alignItems: 'left',
              display: 'flex',
              gap: 8,
              marginTop: 8,
            }}
          >
            <span style={{ fontSize: 12, fontWeight: 500 }}>
              Qual o significado das cores do resultado?
            </span>{' '}
            <IconHelpFilled fill="#545454" id="title-info" fontSize={18} />
            <CustomTooltip
              isOpen={tooltipOpen}
              toggle={toggle}
              target="title-info"
              placement="bottom"
            >
              <p
                style={{ color: '#fff', textAlign: 'left', fontWeight: 'bold' }}
              >
                Resultados das cores tabeladas em RESULTADO
              </p>
              <p style={{ color: '#2EC9B7', textAlign: 'left' }}>
                Verde e seta para cima = Resultado Positivo
              </p>
              <p style={{ color: '#E5A1A4', textAlign: 'left' }}>
                Vermelho e seta para baixo = Resultado Negativo
              </p>
            </CustomTooltip>
          </span>
        </div>
      </Header>
      <ActionArea>
        <SearchArea>
          <IconInput
            placeholder="Buscar produtos"
            value={searchInputProd || ''}
            onChange={(e) => {
              setSearchInputProd(e.target.value);
            }}
            containerStyles={{
              height: 38,
              background: '#fff',
              border: '1px solid #7a7a7a',
              borderRadius: 8,
              marginRight: 8,
            }}
            inputStyles={{
              fontSize: 14,
            }}
            isClearable
            deleteButton={() => {
              setSearchInputProd('');
            }}
          />
          <Select
            placeholder="Selecionar Categoria"
            options={categoriesOptions}
            onChange={(e) => {
              handleSetCategoryFilter(e.value);
            }}
          />
        </SearchArea>

        <ButtonsArea>
          <Button
            variant="primary"
            customColor="#2EC9B7"
            style={{
              marginRight: 8,
            }}
            onClick={toggleModalMarkup}
          >
            Alterar Markup
          </Button>
          <Button
            variant="primary"
            customColor="#01AFFF"
            onClick={toggleModalCmv}
          >
            Alterar %CMV
          </Button>
        </ButtonsArea>
      </ActionArea>
      <Card>
        {categoryFiltered && categoryFiltered.length > 0
          ? categoryFiltered.map((item) => (
            <CategoryArea>
              {groupB.includes(user.id) ? (
                <CategoryHeader
                  onClick={() => {
                    handleHideCategory(item.name);
                  }}
                  style={{ height: 54 }}
                >
                  <div
                    style={{ marginLeft: 12, fontSize: 16, fontWeight: 700 }}
                  >
                    <IoIosArrowDown
                      size={24}
                      onClick={() => {
                        handleHideCategory(item.name);
                      }}
                      style={{
                        transform: `rotate(${hideCategory.includes(item.name)
                            ? '-180deg'
                            : '0deg'
                          })`,
                        transition: 'transform 0.3s ease',
                      }}
                      cursor="pointer"
                    />{' '}
                    {item.name}
                  </div>
                  <HeaderTitle>
                    <HeaderItemOptmize
                      hasDelivery={hasDelivery}
                      browserZoomLevel={browserZoomLevel}
                    >
                      <HeaderItemTitle>
                        <span>CMV</span>

                        <span>
                          <IconInfoFilled
                            fill="#7a7a7a"
                            id={`title-cmv-${item.id}`}
                            style={{ marginLeft: 4 }}
                          />
                          <CustomTooltip
                            isOpen={tooltipStates[`cmv1-${item.id}`] || false}
                            toggle={() => toggleTooltip(`cmv1-${item.id}`)}
                            target={`title-cmv-${item.id}`}
                            placement="bottom"
                          >
                            Valor referente ao custo do produto.
                          </CustomTooltip>
                        </span>
                      </HeaderItemTitle>
                    </HeaderItemOptmize>
                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>Markup</span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-markup-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`markup-${item.id}`] || false
                              }
                              toggle={() =>
                                toggleTooltip(`markup-${item.id}`)
                              }
                              target={`title-markup-${item.id}`}
                              placement="bottom"
                            >
                              O valor será o multiplicador do CMV.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Markup Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-markup-delivery-${item.id}`}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`markup-delivery-${item.id}`] ||
                                false
                              }
                              toggle={() =>
                                toggleTooltip(`markup-delivery-${item.id}`)
                              }
                              target={`title-markup-delivery-${item.id}`}
                              placement="bottom"
                            >
                              O valor será o multiplicador do CMV.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <span style={{ textAlign: 'center' }}>%CMV</span>
                        <IconInfoFilled
                          fill="#7a7a7a"
                          id={`title-cmv-percent-${item.id}`}
                          style={{ marginLeft: 4 }}
                        />
                        <CustomTooltip
                          isOpen={
                            tooltipStates[`cmv-percent-${item.id}`] || false
                          }
                          toggle={() =>
                            toggleTooltip(`cmv-percent-${item.id}`)
                          }
                          target={`title-cmv-percent-${item.id}`}
                          placement="bottom"
                        >
                          Valor em porcentagem do CMV / PREÇO ATUAL.
                        </CustomTooltip>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            %CMV Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-cmv-percent-delivery-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[
                                `cmv-percent-delivery-${item.id}`
                                ] || false
                              }
                              toggle={() =>
                                toggleTooltip(
                                  `cmv-percent-delivery-${item.id}`
                                )
                              }
                              target={`title-cmv-percent-delivery-${item.id}`}
                              placement="bottom"
                            >
                              Valor em porcentagem do CMV DELIVERY / PREÇO
                              ATUAL DELIVERY.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}
                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        browserZoomLevel={browserZoomLevel}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Atual Presencial
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-actual-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`actual-${item.id}`] || false
                              }
                              toggle={() =>
                                toggleTooltip(`actual-${item.id}`)
                              }
                              target={`title-actual-${item.id}`}
                              placement="bottom"
                            >
                              Valor de venda atual do seu produto em seu
                              cardápio.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Atual Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-actual-delivery-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`actual-delivery-${item.id}`] ||
                                false
                              }
                              toggle={() =>
                                toggleTooltip(`actual-delivery-${item.id}`)
                              }
                              target={`title-actual-delivery-${item.id}`}
                              placement="bottom"
                            >
                              Valor de venda atual do seu produto em seu
                              cardápio.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Ideal Presencial
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-ideal-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`ideal-${item.id}`] || false
                              }
                              toggle={() => toggleTooltip(`ideal-${item.id}`)}
                              target={`title-ideal-${item.id}`}
                              placement="bottom"
                            >
                              Valor de venda ideal de acordo com o markup e
                              %CMV definido.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Ideal Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-ideal-delivery-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`ideal-delivery-${item.id}`] ||
                                false
                              }
                              toggle={() =>
                                toggleTooltip(`ideal-delivery-${item.id}`)
                              }
                              target={`title-ideal-delivery-${item.id}`}
                              placement="bottom"
                            >
                              Valor de venda ideal de acordo com o markup
                              delivery e %CMV delivery definido.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}
                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Resultado Presencial
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-result-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`result-${item.id}`] || false
                              }
                              toggle={() =>
                                toggleTooltip(`result-${item.id}`)
                              }
                              target={`title-result-${item.id}`}
                              placement="bottom"
                            >
                              Diferença entre o valor de venda atual menos o
                              valor ideal.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Resultado Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-result-delivery-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`result-delivery-${item.id}`] ||
                                false
                              }
                              toggle={() =>
                                toggleTooltip(`result-delivery-${item.id}`)
                              }
                              target={`title-result-delivery-${item.id}`}
                              placement="bottom"
                            >
                              Diferença entre o valor de venda atual menos o
                              valor ideal delivery.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}
                  </HeaderTitle>
                </CategoryHeader>
              ) : (
                <CategoryHeader
                  onClick={() => {
                    handleHideCategory(item.name);
                  }}
                >
                  <div
                    style={{ marginLeft: 12, fontSize: 16, fontWeight: 700 }}
                  >
                    <IoIosArrowDown
                      size={24}
                      onClick={() => {
                        handleHideCategory(item.name);
                      }}
                      cursor="pointer"
                      style={{
                        transform: `rotate(${hideCategory.includes(item.name)
                            ? '-180deg'
                            : '0deg'
                          })`,
                        transition: 'transform 0.3s ease',
                      }}
                    />{' '}
                    {item.name}
                  </div>
                  <HeaderTitle>
                    <HeaderItem>
                      Markup
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-markup-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`markup-${item.id}`] || false}
                        toggle={() => toggleTooltip(`markup-${item.id}`)}
                        target={`title-markup-${item.id}`}
                        placement="bottom"
                      >
                        O valor será o multiplicador do CMV.
                      </CustomTooltip>
                    </HeaderItem>
                    <HeaderItem>
                      %CMV
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-cmv-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`cmv-${item.id}`] || false}
                        toggle={() => toggleTooltip(`cmv-${item.id}`)}
                        target={`title-cmv-${item.id}`}
                        placement="bottom"
                      >
                        Valor em porcentagem do CMV / PREÇO ATUAL.
                      </CustomTooltip>
                    </HeaderItem>
                    <HeaderItem>
                      Valor Atual
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-actual-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`actual-${item.id}`] || false}
                        toggle={() => toggleTooltip(`actual-${item.id}`)}
                        target={`title-actual-${item.id}`}
                        placement="bottom"
                      >
                        Valor de venda atual do seu produto em seu cardápio.
                      </CustomTooltip>
                    </HeaderItem>
                    <HeaderItem>
                      Valor Ideal
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-ideal-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`ideal-${item.id}`] || false}
                        toggle={() => toggleTooltip(`ideal-${item.id}`)}
                        target={`title-ideal-${item.id}`}
                        placement="bottom"
                      >
                        Valor de venda ideal de acordo com o markup e %CMV
                        definido.
                      </CustomTooltip>
                    </HeaderItem>
                    <HeaderItem>
                      Resultado
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-result-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`result-${item.id}`] || false}
                        toggle={() => toggleTooltip(`result-${item.id}`)}
                        target={`title-result-${item.id}`}
                        placement="bottom"
                      >
                        Diferença entre o valor de venda atual menos o valor
                        ideal.
                      </CustomTooltip>
                    </HeaderItem>
                  </HeaderTitle>
                </CategoryHeader>
              )}

              <CategoryBody>
                {item.products &&
                  item.products.length > 0 &&
                  item.products.map((product, index) => (
                    <Product
                      key={index}
                      product={product}
                      markupDefault={markupDefault}
                      markupDeliveryDefault={markupDeliveryDefault}
                      visible={!hideCategory.includes(product.category)}
                      setProducts={setProducts}
                    />
                  ))}
              </CategoryBody>
            </CategoryArea>
          ))
          : categories &&
          categories.length > 0 &&
          categories.map((item) => (
            <CategoryArea>
              {groupB.includes(user.id) ? (
                <CategoryHeader
                  onClick={() => {
                    handleHideCategory(item.name);
                  }}
                  style={{ height: 54 }}
                >
                  <div
                    style={{ marginLeft: 12, fontSize: 16, fontWeight: 700 }}
                  >
                    <IoIosArrowDown
                      size={24}
                      onClick={() => {
                        handleHideCategory(item.name);
                      }}
                      style={{
                        transform: `rotate(${hideCategory.includes(item.name)
                            ? '-180deg'
                            : '0deg'
                          })`,
                        transition: 'transform 0.3s ease',
                      }}
                      cursor="pointer"
                    />{' '}
                    {item.name}
                  </div>
                  <HeaderTitle>
                    <HeaderItemOptmize
                      hasDelivery={hasDelivery}
                      browserZoomLevel={browserZoomLevel}
                    >
                      <HeaderItemTitle>
                        <span style={{ textAlign: 'center' }}>CMV</span>
                        <span>
                          <IconInfoFilled
                            fill="#7a7a7a"
                            id={`title-cmv-${item.id}`}
                            style={{ marginLeft: 4 }}
                          />
                          <CustomTooltip
                            isOpen={tooltipStates[`cmv-${item.id}`] || false}
                            toggle={() => toggleTooltip(`cmv-${item.id}`)}
                            target={`title-cmv-${item.id}`}
                            placement="bottom"
                          >
                            Custo de preparação do item.
                          </CustomTooltip>
                        </span>
                      </HeaderItemTitle>
                    </HeaderItemOptmize>
                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          Markup
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-markup-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`markup-${item.id}`] || false
                              }
                              toggle={() =>
                                toggleTooltip(`markup-${item.id}`)
                              }
                              target={`title-markup-${item.id}`}
                              placement="bottom"
                            >
                              O valor será o multiplicador do CMV.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Markup Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-markup-delivery-${item.id}`}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`markup-delivery-${item.id}`] ||
                                false
                              }
                              toggle={() =>
                                toggleTooltip(`markup-delivery-${item.id}`)
                              }
                              target={`title-markup-delivery-${item.id}`}
                              placement="bottom"
                            >
                              O valor será o multiplicador do CMV.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        %CMV
                        <span>
                          <IconInfoFilled
                            fill="#7a7a7a"
                            id={`title-cmv-percent-${item.id}`}
                            style={{ marginLeft: 4 }}
                          />
                          <CustomTooltip
                            isOpen={
                              tooltipStates[`cmv-percent-${item.id}`] || false
                            }
                            toggle={() =>
                              toggleTooltip(`cmv-percent-${item.id}`)
                            }
                            target={`title-cmv-percent-${item.id}`}
                            placement="bottom"
                          >
                            Valor em porcentagem do CMV / PREÇO ATUAL.
                          </CustomTooltip>
                        </span>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            %CMV Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-cmv-percent-delivery-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[
                                `cmv-percent-delivery-${item.id}`
                                ] || false
                              }
                              toggle={() =>
                                toggleTooltip(
                                  `cmv-percent-delivery-${item.id}`
                                )
                              }
                              target={`title-cmv-percent-delivery-${item.id}`}
                              placement="bottom"
                            >
                              Valor em porcentagem do CMV DELIVERY/ PREÇO
                              ATUAL DELIVERY.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}
                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Atual Presencial
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-actual-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`actual-${item.id}`] || false
                              }
                              toggle={() =>
                                toggleTooltip(`actual-${item.id}`)
                              }
                              target={`title-actual-${item.id}`}
                              placement="bottom"
                            >
                              Valor de venda atual do seu produto em seu
                              cardápio.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Atual Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-actual-delivery-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`actual-delivery-${item.id}`] ||
                                false
                              }
                              toggle={() =>
                                toggleTooltip(`actual-delivery-${item.id}`)
                              }
                              target={`title-actual-delivery-${item.id}`}
                              placement="bottom"
                            >
                              Valor de venda atual delivery do seu produto em
                              seu cardápio.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Ideal Presencial
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-ideal-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`ideal-${item.id}`] || false
                              }
                              toggle={() => toggleTooltip(`ideal-${item.id}`)}
                              target={`title-ideal-${item.id}`}
                              placement="bottom"
                            >
                              Valor de venda ideal de acordo com o markup e
                              %CMV definido.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Ideal Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-ideal-delivery-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`ideal-delivery-${item.id}`] ||
                                false
                              }
                              toggle={() =>
                                toggleTooltip(`ideal-delivery-${item.id}`)
                              }
                              target={`title-ideal-delivery-${item.id}`}
                              placement="bottom"
                            >
                              Valor de venda ideal de acordo com o markup
                              delivery e %CMV delivery definido.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}
                    {!user.only_delivery && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Resultado Presencial
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-result-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`result-${item.id}`] || false
                              }
                              toggle={() =>
                                toggleTooltip(`result-${item.id}`)
                              }
                              target={`title-result-${item.id}`}
                              placement="bottom"
                            >
                              Diferença entre o valor de venda atual menos o
                              valor ideal.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}

                    {(hasDelivery || user.only_delivery) && (
                      <HeaderItemOptmize
                        hasDelivery={hasDelivery}
                        onlyDelivery={user.only_delivery}
                        browserZoomLevel={browserZoomLevel}
                      >
                        <HeaderItemTitle>
                          <span style={{ textAlign: 'center' }}>
                            Resultado Delivery
                          </span>
                          <span>
                            <IconInfoFilled
                              fill="#7a7a7a"
                              id={`title-result-delivery-${item.id}`}
                              style={{ marginLeft: 4 }}
                            />
                            <CustomTooltip
                              isOpen={
                                tooltipStates[`result-delivery-${item.id}`] ||
                                false
                              }
                              toggle={() =>
                                toggleTooltip(`result-delivery-${item.id}`)
                              }
                              target={`title-result-delivery-${item.id}`}
                              placement="bottom"
                            >
                              Diferença entre o valor de venda atual menos o
                              valor ideal.
                            </CustomTooltip>
                          </span>
                        </HeaderItemTitle>
                      </HeaderItemOptmize>
                    )}
                  </HeaderTitle>
                </CategoryHeader>
              ) : (
                <CategoryHeader
                  onClick={() => {
                    handleHideCategory(item.name);
                  }}
                >
                  <div
                    style={{ marginLeft: 12, fontSize: 16, fontWeight: 700 }}
                  >
                    <IoIosArrowDown
                      size={24}
                      onClick={() => {
                        handleHideCategory(item.name);
                      }}
                      style={{
                        transform: `rotate(${hideCategory.includes(item.name)
                            ? '-180deg'
                            : '0deg'
                          })`,
                        transition: 'transform 0.3s ease',
                      }}
                      cursor="pointer"
                    />{' '}
                    {item.name}
                  </div>
                  <HeaderTitle>
                    <HeaderItem>
                      Markup
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-markup-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`markup-${item.id}`] || false}
                        toggle={() => toggleTooltip(`markup-${item.id}`)}
                        target={`title-markup-${item.id}`}
                        placement="bottom"
                      >
                        O valor será o multiplicador do CMV.
                      </CustomTooltip>
                    </HeaderItem>
                    <HeaderItem>
                      %CMV
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-cmv-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`cmv-${item.id}`] || false}
                        toggle={() => toggleTooltip(`cmv-${item.id}`)}
                        target={`title-cmv-${item.id}`}
                        placement="bottom"
                      >
                        Valor em porcentagem do CMV / PREÇO ATUAL.
                      </CustomTooltip>
                    </HeaderItem>
                    <HeaderItem>
                      Valor Atual
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-actual-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`actual-${item.id}`] || false}
                        toggle={() => toggleTooltip(`actual-${item.id}`)}
                        target={`title-actual-${item.id}`}
                        placement="bottom"
                      >
                        Valor de venda atual do seu produto em seu cardápio.
                      </CustomTooltip>
                    </HeaderItem>
                    <HeaderItem>
                      Valor Ideal
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-ideal-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`ideal-${item.id}`] || false}
                        toggle={() => toggleTooltip(`ideal-${item.id}`)}
                        target={`title-ideal-${item.id}`}
                        placement="bottom"
                      >
                        Valor de venda ideal de acordo com o markup e %CMV
                        definido.
                      </CustomTooltip>
                    </HeaderItem>
                    <HeaderItem>
                      Resultado
                      <IconInfoFilled
                        fill="#7a7a7a"
                        id={`title-result-${item.id}`}
                        style={{ marginLeft: 4 }}
                      />
                      <CustomTooltip
                        isOpen={tooltipStates[`result-${item.id}`] || false}
                        toggle={() => toggleTooltip(`result-${item.id}`)}
                        target={`title-result-${item.id}`}
                        placement="bottom"
                      >
                        Diferença entre o valor de venda atual menos o valor
                        ideal.
                      </CustomTooltip>
                    </HeaderItem>
                  </HeaderTitle>
                </CategoryHeader>
              )}

              <CategoryBody>
                {products &&
                  products.length > 0 &&
                  products
                    .filter((p) => p.category === item.name)
                    .map((product, index) => (
                      <Product
                        key={index}
                        product={product}
                        markupDefault={markupDefault}
                        markupDeliveryDefault={markupDeliveryDefault}
                        visible={!hideCategory.includes(product.category)}
                        setProducts={setProducts}
                      />
                    ))}
              </CategoryBody>
            </CategoryArea>
          ))}
      </Card>
    </Container>
  );
}

export default Markup;
