import axios from 'axios';
import history from './history';

const api2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_2 || process.env.REACT_APP_API_URL,
});

api2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if(error.response.data) {
        if (error.response.data.errorType === 'invalid_token') {
          history.push('/');
        }
        if (error.response.data.errorType === 'token_not_sent') {
          history.push('/');
        }
      }
      console.error('Error', error.message);
    }
    return Promise.reject(error);
  }
);

export default api2;
