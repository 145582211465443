import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';
import api2 from '~/services/api2';

const MotoboysContext = createContext();

const MotoboysProvider = ({ children }) => {

  const [motoboys, setMotoboys] = useState([]);
  const [detailedMotoboy, setDetailedMotoboy] = useState(null);
  const getMotoboys = useCallback(async () => {
    try {
      const response = await api2.get('/restaurants/motoboys');

      setMotoboys(response.data);
    } catch (err) {
      console.log('getMotoboys Error: ', err);
      toast.error('Não foi possível carregar os motoboys');
    }
  }, []);

  const getDetailedMotoboy = useCallback(async (start, end) => {
    try {
      const response = await api2.get(
        `/restaurants/motoboys/sessions?start=${start}&end=${end}`
      );
      setDetailedMotoboy(response.data);
    } catch (err) {
      console.log('getDetailedMotoboy Error: ', err);
      toast.error('Não foi possível carregar as informações');
    }
  }, []);

  const value = useMemo(
    () => ({
      getMotoboys,
      motoboys,
      setMotoboys,
      getDetailedMotoboy,
      detailedMotoboy,
      setDetailedMotoboy,
    }),
    [
      getMotoboys,
      motoboys,
      setMotoboys,
      getDetailedMotoboy,
      detailedMotoboy,
      setDetailedMotoboy,
    ]
  );
  return (
    <MotoboysContext.Provider value={value}>
      {children}
    </MotoboysContext.Provider>
  );
};

function useMotoboys() {
  const context = useContext(MotoboysContext);

  if (!context) {
    throw new Error('useMotoboys must be used within an MotoboysProvider');
  }

  return context;
}

export { useMotoboys, MotoboysProvider };
