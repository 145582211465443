import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';

import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FaCheck, FaEye, FaPlus, FaPrint, FaTimes } from 'react-icons/fa';
import api2 from '~/services/api2';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  DeleteAndEditActions,
  CardColumnItem,
  CardRow,
} from '../styles';
import {
  Print,
  DisplayNoneImprimir,
  PrintOutline,
  Footer,
  ContainerOrders,
  ButtonCancel,
  ModalContainer,
  ModalContainerHeader,
} from './styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import { useAuth } from '~/context/AuthContext';
import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';
import { useMotoboys } from '~/context/MotoboysContext';
import TableEditDelivery from '~/components/Operation/TablesOperation/TableEditDelivery';
import {
  OrderStatusTag,
  Button,
  ActionButton,
  DefaultSelect,
  OrderButton,
} from 'ui-kit-takeat';
import CreateMotoboy from '~/pages/Motoboys/Create';

export default function OrderAndamento({
  item,
  handleItemFinished,
  handleCancelItem,
  handleItemReady,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [btnDisable, serBtnDisable] = useState(false);
  const [session, setSession] = useState(null);
  const { motoboys } = useMotoboys();
  const { user } = useAuth();
  const initialData = {
    motoboy: {
      value: motoboys[0]?.id,
      label: motoboys[0]?.name,
    },
  };

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const [creatingMotoboy, setCreatingMotoboy] = useState(false);
  const [modalMotoboy, setModalMotoboy] = useState(false);
  const [motoboySelected, setMotoboySelected] = useState(null);
  const toggleModalMotoboy = () => {
    setModalMotoboy(!modalMotoboy);
  };

  const [orders, setOrders] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api2.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const getSession = async () => {
    const response = await api2.get(
      `/restaurants/delivery/session-orders/${item.session_id}`
    );

    setSession(response.data?.orders[0]);
  };

  useEffect(() => {
    if (modalDelete) {
      getSession();
    }
  }, [modalDelete, getSession]);

  useEffect(() => {
    if (modalDelete) {
      getOrders();
    }
  }, [modalDelete, getOrders]);

  const componentRef = useRef(null);

  const [loadingPrinter, setLoadingPrinter] = useState(false);

  async function printClick() {
    try {
      await api2.post('restaurants/printers/printer-queue', {
        order_basket_id: item.basket.id,
        order_type: 'new_order',
      });
      toast.success('Pedido impresso.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
  }

  async function printDeliverySession() {
    setLoadingPrinter(true);
    try {
      await api2.post('restaurants/printers/printer-queue', {
        table_session_id: item.session_id,
        is_delivery: true,
      });

      toast.success('Pedido enviado para impressão.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
    setLoadingPrinter(false);
  }

  const userName = item?.buyer
    ? formatPhone(
        item.buyer.phone
          ? item.buyer.phone
          : item.buyer.ifood_phone
            ? item.buyer.ifood_phone
            : item.buyer.neemo_phone || ''
      )
    : item?.waiter?.name;

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_type === 'self-service') {
      return 'Totem';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    if (table.table_type === 'prepaid') {
      return `Mesa  ${table.table_number} - Pagamento Antecipado`;
    }

    return `Mesa  ${table.table_number}`;
  }

  const handleAddMotoboy = async (data) => {
    try {
      const response = await api2.post('/restaurants/motoboys/relate', {
        motoboy_id: motoboySelected.value,
        session_id: item.session_id,
      });

      item.motoboy = response.data;

      toast.success('Motoboy adicionado à entrega');
      toggleModalMotoboy();
      handleItemReady(
        item.basket.id,
        item.basket.ifood_id,
        item.with_withdrawal
      );
      if (!item.basket.ifood_id) {
        handleNotifyWhatsapp(item, orders, user, 'ready');
      }
    } catch (err) {
      console.log('handleAddMotoboy Error: ', err);
      toast.error('Não foi possível adicionar motoboy à essa entrega');
    }
  };

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  return (
    <Pedido>
      <ContentBody>
        <CardRow grow onEnd>
          <CardColumnItem onStart style={{ marginTop: 5, marginLeft: 2 }}>
            <TableNumber>
              {item?.table.table_type === 'command' &&
                item?.table.table_type !== 'delivery' ? (
                `Comanda  ${item?.table.table_number} / Mesa ${item?.basket?.command_table_number || 'não informada'
                } `
              ) : (
                <>
                  {item?.basket?.ifood_table
                    ? item?.basket?.ifood_table
                    : searchTableName(item?.table)}
                </>
              )}
            </TableNumber>
          </CardColumnItem>

          <CardColumnItem>
            <OrderNumber>#{item?.basket.basket_id}</OrderNumber>
          </CardColumnItem>
        </CardRow>
        <CardRow>
          <CardColumnItem onStart style={{ marginLeft: 2 }}>
            <PhoneNumber>{userName}</PhoneNumber>
          </CardColumnItem>
          {item?.buyer?.name && (
            <CardColumnItem onStart style={{ marginLeft: 2 }}>
              <PhoneNumber>
                {item?.buyer?.name?.length < 20
                  ? item.buyer.name
                  : `${item.buyer.name.slice(0, 17)}...`}
              </PhoneNumber>
            </CardColumnItem>
          )}
          <CardColumnItem>
            <Time>{formatTime(item?.basket.start_time)}</Time>
          </CardColumnItem>
        </CardRow>
        <CardRow>
          <div>
            <OrderStatusTag text="Em andamento" color="#01AFFF" width="150px" />
          </div>
          <DeleteAndEditActions>
            <OrderButton
              onClick={() => {
                handleItemFinished(item.basket.id);
              }}
              title="Pronto"
              icon={<FaCheck />}
              color="#2EC9B7"
              inverted
            />

            <OrderButton icon={<FaEye />} color="#FFA814" onClick={toggle} />

            {item?.table?.table_type === 'delivery' && (
              <TableEditDelivery
                session={session}
                item={item}
                orders={orders}
                setOrders={setOrders}
                toggleDeleteDelivery={toggleDeleteDelivery}
                disabled={!!item.basket.ifood_id}
              />
            )}
          </DeleteAndEditActions>
        </CardRow>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <ContainerOrders browserZoomLevel={browserZoomLevel}>
            <EyeModalOrders item={item} />
          </ContainerOrders>
        </ModalBody>
        <Footer
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          {user.is_printed_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getOrders();
              }}
              trigger={() => (
                <Print>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
              content={() => componentRef.current}
            />
          ) : (
            <>
              {item?.table.table_type === 'delivery' ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <PrintOutline onClick={printClick}>
                    <FaPrint color="#ff2c3a" size={20} />
                    Imprimir em ambiente de produção
                  </PrintOutline>
                  {user.print_bills_delivery_on_web ? (
                    <ReactToPrint
                      onBeforeGetContent={async () => {
                        await getOrders();
                      }}
                      trigger={() => (
                        <Print>
                          <FaPrint color="#fff" size={20} />
                          Imprimir via de entrega
                        </Print>
                      )}
                      content={() => componentRef.current}
                    />
                  ) : (
                    <Print
                      onClick={printDeliverySession}
                      disabled={loadingPrinter}
                    >
                      <FaPrint color="#fff" size={20} />
                      Imprimir via de entrega
                    </Print>
                  )}
                </div>
              ) : (
                <Print onClick={printClick}>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
            </>
          )}
        </Footer>
      </Modal>
      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar o pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => toggleDeleteDelivery()}
            title="Desistir"
            inverted
          />
          <Button
            title="Recusar o pedido"
            onClick={() =>
              handleCancelItem({
                from: 'accepted',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
                ifood_id: item.basket.ifood_id,
              })
            }
          />
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalMotoboy} toggle={toggleModalMotoboy}>
        {creatingMotoboy ? (
          <CreateMotoboy
            toggleModalRegisterMotoboy={() => setCreatingMotoboy(false)}
            motoboyCreated={(data) => {
              setMotoboySelected(data);
              setCreatingMotoboy(false);
            }}
          />
        ) : (
          <Form onSubmit={handleAddMotoboy} initialData={initialData}>
            <ModalContainer>
              <ModalContainerHeader>
                <h3>Adicionar motoboy a essa entrega?</h3>
                <FaTimes
                  size={20}
                  color="red"
                  onClick={() => toggleModalMotoboy()}
                />
              </ModalContainerHeader>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <DefaultSelect
                  name="motoboy"
                  width="-webkit-fill-available"
                  options={motoboys.map((m) => {
                    return { value: m.id, label: m.name };
                  })}
                  value={motoboySelected}
                  onChange={(e) => setMotoboySelected(e)}
                />

                <ActionButton
                  icon={<FaPlus />}
                  buttonColor="#2EC9B7"
                  minWidth={40}
                  onClick={() => setCreatingMotoboy(true)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModalMotoboy();
                    handleItemReady(
                      item.basket.id,
                      item.basket.ifood_id,
                      item.with_withdrawal
                    );
                    if (!item.basket.ifood_id) {
                      handleNotifyWhatsapp(item, orders, user, 'ready');
                    }
                  }}
                  title="Não adicionar motoboy"
                />
                <Button onClick={() => { }} title="Adicionar" />
              </div>
            </ModalContainer>
          </Form>
        )}
      </Modal>
      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <DeleteItemOrders
            item={item}
            modalDelete={modalDelete}
            from="accepted"
            orders={orders}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonCancel
            color="danger"
            onClick={() =>
              handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'accepted',
              })
            }
          >
            Cancelar todos de uma vez
          </ButtonCancel>
        </ModalFooter>
      </Modal>
      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          orders={orders}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
