import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactToPrint from 'react-to-print';

import PrintClosingBoxAuditById from '~/components/Prints/PrintClosingBoxAuditById';
import { Modal, ModalFooter } from 'reactstrap';
import { useAuth } from '~/context/AuthContext';
import { Button, Input } from 'takeat-design-system-ui-kit';
import toast from 'react-hot-toast';
import api from '~/services/api';
import { DisplayImprimir, PrintDiv, ButtonPrint } from './styles';

function PrintOpeningBoxAudit({
  totalManualDeposits,
  totalAutomaticsDeposit,
  boxTotalValue,
  boxInitialValue,
  totalWithdrawalDeposits,
  boxFinalValue,
  handleDeleteBoxStates,
  paymentsAutomatics,
  paymentsManual,
  manualDeposits,
  manualWithdrawals,
  boxClosingTime,
  boxOpeningTime,
  userClose,
  userOpen,
  arrayMethods,
  auditItems,
  modalPrintAudit,
  boxId,
  isClosing,
  closedBoxId,
}) {
  const { user } = useAuth();
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [printType, setPrintType] = useState('');
  const [serviceTax, setServiceTax] = useState(0);

  const [shouldTriggerPrint, setShouldTriggerPrint] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const componentRef = useRef(null);

  const hasKey = !!user?.has_order_cancel_password;

  const validatePassword = (e) => {
    return e === user?.order_cancel_password;
  };

  const triggerPrint = () => {
    const printButton = document.querySelector('.print-trigger');
    printButton.click();
  };

  const handlePrint = () => {
    if (hasKey) {
      setShowOptionsModal(true);
    } else {
      triggerPrint();
    }
  };

  function toggleModalOptions() {
    setShowOptionsModal(!showOptionsModal);
  }

  function togglePasswordModal() {
    setShowPasswordModal(!showPasswordModal);
  }

  const getIndividualsBoxValuesWithTax = useCallback(async () => {
    try {
      const response = await api.post(
        `/restaurants/cashier-opening-event/summary/${boxId || closedBoxId}`,
        {
          password: user.order_cancel_password,
        }
      );

      const summary = response.data;
      setServiceTax(summary.total_service_tax);
    } catch (err) {
      if (err?.response?.data?.errorType === 'cashier_opening_not_found') {
        toast.error('Erro ao carregar valores');
      } else {
        toast.error('Erro ao carregar valores');
      }
    }
  }, [boxId, closedBoxId]);

  const handleOptionClick = (type) => {
    setPrintType(type);
    if (type === 'completo' && hasKey) {
      setShowPasswordModal(true);
    } else {
      setShowTax(false);

      setShouldTriggerPrint(true);
    }
    setShowOptionsModal(false);
  };

  useEffect(() => {
    if (shouldTriggerPrint) {
      triggerPrint();
      setShouldTriggerPrint(false);
    }
  }, [shouldTriggerPrint]);

  const handlePasswordSubmit = (e) => {
    if (validatePassword(e)) {
      setShowPasswordModal(false);
      if (printType === 'completo') {
        setShowTax(true);
      } else {
        setShowTax(false);
      }
      setShouldTriggerPrint(true);
    } else {
      toast.error('Senha incorreta');
    }
  };

  const handleChangePassword = useCallback(
    (e) => {
      if (e.length === user?.order_cancel_password.length) {
        setPassword(e);
        handlePasswordSubmit(e);
      } else {
        setPassword(e);
      }
    },
    [user]
  );

  return (
    <>
      <DisplayImprimir>
        <PrintClosingBoxAuditById
          ref={componentRef}
          totalAutomaticsDeposit={totalAutomaticsDeposit}
          totalManualDeposits={totalManualDeposits}
          boxTotalValue={boxTotalValue}
          boxInitialValue={boxInitialValue}
          totalWithdrawalDeposits={totalWithdrawalDeposits}
          boxFinalValue={boxFinalValue}
          paymentsAutomatics={paymentsAutomatics}
          paymentsManual={paymentsManual}
          manualDeposits={manualDeposits}
          manualWithdrawals={manualWithdrawals}
          boxClosingTime={boxClosingTime}
          boxOpeningTime={boxOpeningTime}
          userClose={userClose}
          userOpen={userOpen}
          arrayMethods={arrayMethods}
          auditItems={auditItems}
          modalPrintAudit={modalPrintAudit}
          boxId={boxId}
          printType={printType}
          showTax={showTax}
          serviceTax={serviceTax}
        />
      </DisplayImprimir>

      <PrintDiv>
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              className="print-trigger"
              style={{ display: 'none' }}
            >
              Hidden Print Button
            </button>
          )}
          content={() => componentRef.current}
          onBeforeGetContent={async () => {
            if (printType === 'completo' && getIndividualsBoxValuesWithTax) {
              setShowTax(true);
              await getIndividualsBoxValuesWithTax();
            }
          }}
          onAfterPrint={() => {
            if (isClosing) {
              handleDeleteBoxStates();
            }
            setPrintType('simples');
            setShowTax(false);
            setPassword('');
          }}
        />
        <Button
          onClick={handlePrint}
          style={{
            width: '100%',
          }}
        >
          Imprimir
        </Button>
      </PrintDiv>

      <Modal isOpen={showOptionsModal} toggle={toggleModalOptions}>
        <div style={{ padding: '20px', paddingBottom: 0 }}>
          <h2
            style={{
              fontWeight: 600,
              fontSize: 18,
              color: '#222',
            }}
          >
            Qual o tipo de relatório?
          </h2>
        </div>
        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 0,
          }}
        >
          <Button
            onClick={() => handleOptionClick('simplificado')}
            variant="outlined"
          >
            Relatório simples
          </Button>
          <Button onClick={() => handleOptionClick('completo')}>
            Relatório com taxa de serviço
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showPasswordModal} toggle={togglePasswordModal}>
        <div style={{ padding: '20px' }}>
          <h2
            style={{
              fontWeight: 600,
              fontSize: 18,
              color: '#222',
            }}
          >
            Insira a senha administrativa
          </h2>
          <Input
            value={password}
            onChange={(e) => handleChangePassword(e.target.value)}
            autocomplete="new-password"
            type="password"
          />
        </div>
        <ModalFooter style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button onClick={() => setShowPasswordModal(false)}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PrintOpeningBoxAudit;
