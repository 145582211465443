import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-hot-toast';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import EyeModalOrders from '~/components/EyeModalOrders';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import api2 from '~/services/api2';

import { useAuth } from '~/context/AuthContext';

import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';
import { FaEye, FaPrint, FaRegClock } from 'react-icons/fa';
import TableEditDelivery from '~/components/Operation/TablesOperation/TableEditDelivery';
import formatDateYYYY from '~/services/formatDateYYYY';
import formatTime from '~/services/formatTime';
import { Button } from 'ui-kit-takeat';
import logoNeemo from '../../../../assets/img/neemo-logo.png';
import logoIfood from '../../../../assets/img/ifood-logo.png';
import logoTakeat from '../../../../assets/img/garfinho_red.png';
import {
  Actions,
  ButtonCancel,
  CardContainer,
  DisplayNoneImprimir,
  CardHeader,
  CardBody,
  DateArea,
  BillInfo,
} from './styles';
import {
  OrderNumber,
  PhoneNumber,
  UserDeliveryInfo,
  UserDeliveryInfoArea,
} from '../styles';

export default function OrderNewDelivery({
  item,
  handlePrintItem,
  handleCancelItem,
  setItemIfoodId,
  handleUpdate,
}) {
  const { user } = useAuth();
  const componentRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [btnDisable, serBtnDisable] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ordersNotGrouped, setOrdersNotGrouped] = useState([]);
  const [printLoading, setPrintLoading] = useState(false);
  const [loadingCancelItem, setLoadingCancelItem] = useState(false);

  const getOrders = useCallback(async () => {
    const group = false;
    try {
      setPrintLoading(true);
      const response = await api2.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );
      console.log('eu aqui');
      setOrders(response.data);
      setPrintLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const getOrdersNotGrouped = useCallback(async () => {
    try {
      setPrintLoading(true);
      const response = await api2.get(
        `/restaurants/basket-orders/${item.basket.id}?group=false`
      );

      setOrdersNotGrouped(response.data);
      setPrintLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const toggle = () => setModal(!modal);

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => {
    setModalDelete(!modalDelete);
    serBtnDisable(!btnDisable);
  };

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const userNameDelivery = item?.buyer
    ? item.buyer.phone
      ? item.buyer.phone
      : item.buyer.ifood_phone
        ? item.buyer.ifood_phone
        : item.buyer.neemo_phone || ''
    : item?.waiter?.name;

  async function closeClick() {
    try {
      if (user.print_bills_delivery_on_web) {
        await api2.post('restaurants/printers/printer-queue', {
          order_basket_id: item.basket.id,
          order_type: 'new_order',
        });
      }

      handlePrintItem(
        item.basket.id,
        item.basket.ifood_id,
        item?.table?.table_type === 'delivery'
      );

      if (!item.basket.ifood_id) {
        handleNotifyWhatsapp(item, orders, user, 'accepted');
      }
    } catch (error) {
      toast.error('Erro ao aceitar pedido.');
    }
  }

  function searchTableName(table) {
    if (item?.with_withdrawal && item?.scheduled_to) {
      return 'Retirada Agendada';
    }
    if (item?.with_withdrawal) {
      return 'Retirada';
    }

    if (!item?.with_withdrawal && item?.scheduled_to) {
      return 'Delivery Agendado';
    }

    return 'Delivery';
  }

  useEffect(() => {
    if (modalDelete) {
      getOrdersNotGrouped();
    }
  }, [getOrdersNotGrouped, modalDelete, modal]);

  useEffect(() => {
    if (!user?.is_printed_on_web) {
      getOrders();
    }
  }, [getOrders, user.is_printed_on_web]);

  function Logo({ basket }) {
    if (basket.ifood_id) {
      return <img src={logoIfood} style={{ width: 38, height: 25 }} />;
    }
    if (basket.neemo_id) {
      return (
        <img src={logoNeemo} title="Neemo" style={{ width: 25, height: 25 }} />
      );
    }

    return <img src={logoTakeat} style={{ width: 20, height: 25 }} />;
  }

  return (
    <CardContainer>
      <CardHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <BillInfo>
            <Logo basket={item.basket} />

            <OrderNumber>#{item?.attendance_password}</OrderNumber>
            <OrderNumber>
              {item?.table.table_type === 'command' &&
                item?.table.table_type !== 'delivery' ? (
                `Comanda  ${item.table.table_number} / Mesa ${item?.basket?.command_table_number || 'não informada'
                } `
              ) : (
                <>
                  {item?.basket?.ifood_table
                    ? item?.basket?.ifood_table
                    : searchTableName(item?.table)}
                </>
              )}
            </OrderNumber>
          </BillInfo>
          {item?.ifood_restaurant?.name && (
            <p style={{ textAlign: 'left' }}>{item.ifood_restaurant.name}</p>
          )}
        </div>
        <span>
          {item?.scheduled_to ? (
            <DateArea>
              <span style={{ fontSize: 12 }}>Agendado para:</span>
              <strong>{formatDateYYYY(item?.scheduled_to)}</strong>
              <span>
                <FaRegClock /> {formatTime(item?.scheduled_to)}
              </span>
            </DateArea>
          ) : (
            <DateArea>
              <strong>{formatDateYYYY(item?.basket.start_time)}</strong>
              <span>
                <FaRegClock /> {formatTime(item?.basket.start_time)}
              </span>
            </DateArea>
          )}
        </span>
      </CardHeader>
      <CardBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {item?.buyer?.name && (
            <PhoneNumber style={{ fontWeight: 600 }}>
              {item.buyer.name}
            </PhoneNumber>
          )}
          {item?.basket?.schedule && (
            <PhoneNumber>
              <strong>{item?.basket?.schedule}</strong>
            </PhoneNumber>
          )}
          <PhoneNumber>{userNameDelivery}</PhoneNumber>
          {!item?.with_withdrawal && (
            <UserDeliveryInfoArea>
              {item?.buyer?.delivery_address?.neighborhood && (
                <UserDeliveryInfo>
                  Bairro: {item?.buyer?.delivery_address?.neighborhood}-{' '}
                  {item?.buyer?.delivery_address?.city}
                </UserDeliveryInfo>
              )}

              {item?.buyer?.delivery_address?.zip_code && (
                <UserDeliveryInfo>
                  CEP: {item?.buyer?.delivery_address?.zip_code}
                </UserDeliveryInfo>
              )}
            </UserDeliveryInfoArea>
          )}
        </div>
        <Actions>
          {user.is_printed_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getOrders();
              }}
              trigger={() => <FaPrint color="#3BD2C1" size={16} />}
              content={() => componentRef.current}
              onAfterPrint={() => {
                handlePrintItem(
                  item.basket.id,
                  item.basket.ifood_id,
                  item?.table?.table_type === 'delivery'
                );
                if (!item.basket.ifood_id) {
                  handleNotifyWhatsapp(item, orders, user, 'accepted');
                }
              }}
            />
          ) : (
            <>
              {item?.table?.table_type === 'delivery' &&
                user.print_bills_delivery_on_web ? (
                <ReactToPrint
                  onBeforeGetContent={async () => {
                    await getOrders();
                  }}
                  trigger={() => <FaPrint color="#3BD2C1" size={16} />}
                  content={() => componentRef.current}
                  onAfterPrint={() => {
                    handlePrintItem(
                      item.basket.id,
                      item.basket.ifood_id,
                      item?.table?.table_type === 'delivery'
                    );
                    if (!item.basket.ifood_id) {
                      handleNotifyWhatsapp(item, orders, user, 'accepted');
                    }
                  }}
                />
              ) : (
                <FaPrint color="#3BD2C1" size={16} onClick={closeClick} />
              )}
            </>
          )}

          <FaEye color="#FFA814" onClick={toggle} size={20} />

          <TableEditDelivery
            item={item}
            toggleDeleteDelivery={toggleDeleteDelivery}
            handleUpdate={handleUpdate}
          />
        </Actions>
      </CardBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <EyeModalOrders item={item} />
        </ModalBody>
      </Modal>

      <DisplayNoneImprimir>
        {/* console.log("aqqqq")} {console.log(item) */}
        <PrintNewOrderComponent
          ref={componentRef}
          restaurant={user}
          item={item}
          handlePrintItem={handlePrintItem}
          orders={orders}
        />
      </DisplayNoneImprimir>

      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <h3>Tem certeza que deseja recusar o pedido?</h3>
        </ModalBody>
        <ModalFooter>
          <Button
            inverted
            onClick={() => toggleDeleteDelivery()}
            title="Desistir"
          />

          <Button
            onClick={() =>
              handleCancelItem({
                from: 'pending',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
                ifood_id: item.basket.ifood_id,
              })
            }
            title="Recusar o pedido"
          />
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrders
              item={item}
              modalDelete={modalDelete}
              from="pending"
              orders={ordersNotGrouped}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonCancel
            color="danger"
            onClick={async () => {
              setLoadingCancelItem(true);
              await handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'pending',
                ifood_id: item.basket.ifood_id,
              });
              setLoadingCancelItem(false);
            }}
            disabled={loadingCancelItem}
            type="button"
          >
            Cancelar todos de uma vez
          </ButtonCancel>
        </ModalFooter>
      </Modal>
    </CardContainer>
  );
}
