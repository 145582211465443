import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  IconArrowDown,
  IconArrowUp,
  IconHelpFilled,
  IconInfoFilled,
  IconPencilFilled,
  IconQuestion,
  IconTrashFilled,
  Input,
} from 'takeat-design-system-ui-kit';
import { IoIosArrowDown } from 'react-icons/io';
import { ReactComponent as Check } from '~/assets/img/check.svg';
import formatValue from '~/services/formatValue';
import api from '~/services/api';
import toast from 'react-hot-toast';
import { useAuth } from '~/context/AuthContext';
import { ModalFooter } from 'reactstrap';
import EditStock from './EditStock';
import {
  CategoryName,
  Container,
  CustomTooltip,
  Header,
  InputOptimized,
  InputTableStyled,
  Price,
  ProductInfo,
  ProductInfoArea,
  ProductName,
  ProductNameEllipsis,
  ResultButton,
  ResultButtonOptimized,
  StockArea,
  StyledModal,
  TabelArea,
  TableStyled,
} from './styles';

export default function Product({
  product,
  markupDeliveryDefault,
  markupDefault,
  visible,
  setProducts,
}) {
  const { user } = useAuth();
  const [inputsAndIntermediaries, setInputsAndIntermediaries] = useState([]);
  const [showStock, setShowStock] = useState(false);
  const [isModalEditStockOpened, setIsModalEditStockOpened] = useState(false);
  const [markup, setMarkup] = useState(product.markup);
  const [markupDelivery, setMarkupDelivery] = useState(product.markup_delivery);
  const [price, setPrice] = useState(product.price || 0);
  const [deliveryPrice, setDeliveryPrice] = useState(
    product.delivery_price || product.price || 0
  );
  const [cmvPercentual, setCmvPercentual] = useState(100 / product.markup);
  const [cmvPercentualDelivery, setCmvPercentualDelivery] = useState(
    100 / product.markup_delivery
  );
  const [ideal, setIdeal] = useState(0);
  const [result, setResult] = useState(0);
  const [idealDelivery, setIdealDelivery] = useState(0);
  const [resultDelivery, setResultDelivery] = useState(0);
  const [cmv, setCmv] = useState(0);

  useEffect(() => {
    setPrice(product.price);
    setDeliveryPrice(product.delivery_price || product.price || 0);
    setMarkup(product.markup);
    setMarkupDelivery(product.markup_delivery);
  }, [product]);

  useEffect(() => {
    const newCmv = product.current_cmv
      ? product.current_cmv
      : inputsAndIntermediaries.reduce((acc, item) => {
        const quantidade = parseFloat(item.quantidade) || 0;
        const unitaryPrice = parseFloat(item.unitary_price) || 0;
        return acc + quantidade * unitaryPrice;
      }, 0);

    setCmv(newCmv);
  }, [inputsAndIntermediaries]);

  useEffect(() => {
    const categoryFind = markupDefault?.categories?.includes(product.category);
    const categoryDeliveryFind = markupDeliveryDefault?.categories?.includes(
      product.category
    );
    if (markupDefault && categoryFind) {
      setMarkup(markupDefault.markup);
      setCmvPercentual(100 / markupDefault.markup);
    }
    if (markupDeliveryDefault && categoryDeliveryFind) {
      setMarkupDelivery(markupDeliveryDefault.markup_delivery);
      setCmvPercentualDelivery(100 / markupDeliveryDefault.markup_delivery);
    }
  }, [markupDefault, markupDeliveryDefault]);

  useEffect(() => {
    const newPrice = price - ideal;
    const newPriceDelivery = deliveryPrice - idealDelivery;

    setResult(isNaN(newPrice) ? 0 : newPrice);
    setResultDelivery(isNaN(newPriceDelivery) ? 0 : newPriceDelivery);
  }, [price, deliveryPrice]);

  useEffect(() => {
    const updatedIdeal = markup * cmv;
    const updatedResult = price - updatedIdeal;

    const updatedIdealDelivery = markupDelivery * cmv;
    const updatedResultDelivery = deliveryPrice - updatedIdealDelivery;

    setIdeal(isNaN(updatedIdeal) ? 0 : updatedIdeal);
    setResult(isNaN(updatedResult) ? 0 : updatedResult);
    setIdealDelivery(updatedIdealDelivery || 0);
    setResultDelivery(updatedResultDelivery || 0);
  }, [markup, markupDelivery, cmv]);

  useEffect(() => {
    const newMarkup = 100 / cmvPercentual;
    if (markup !== newMarkup) setMarkup(newMarkup);
  }, [cmvPercentual]);

  useEffect(() => {
    const newMarkupDelivery = 100 / cmvPercentualDelivery;
    if (markupDelivery !== newMarkupDelivery)
      setMarkupDelivery(newMarkupDelivery);
  }, [cmvPercentualDelivery]);

  useEffect(() => {
    const newCmvPercentual = 100 / markup;
    if (cmvPercentual !== newCmvPercentual) setCmvPercentual(newCmvPercentual);
  }, [markup]);

  useEffect(() => {
    const newCmvPercentualDelivery = 100 / markupDelivery;
    if (cmvPercentualDelivery !== newCmvPercentualDelivery)
      setCmvPercentualDelivery(newCmvPercentualDelivery);
  }, [markupDelivery]);

  useEffect(() => {
    setInputsAndIntermediaries([...product.inputs, ...product.intermediaries]);
  }, [product]);

  function handleShowStockArea() {
    setShowStock(!showStock);
  }

  function toggleModalEditStock() {
    setIsModalEditStockOpened(!isModalEditStockOpened);
  }

  const [isModalDeleteInputOnpened, setIsModalDeleteInputOpened] =
    useState(false);
  const [inputToDelete, setItemToDelete] = useState(null);

  function toggleModalDeleteInput() {
    setIsModalDeleteInputOpened(!isModalDeleteInputOnpened);
  }

  function handleDelete(item) {
    setItemToDelete(item);
    setIsModalDeleteInputOpened(true);

    console.log(item);
  }

  const handleConfirmDelete = useCallback(
    async (item) => {
      try {
        const payload = {
          product_id: item.product_id,
          ...(item.input_id
            ? { input_id: item.input_id }
            : { intermediary_id: item.intermediary_id }),
        };

        await api.put('/restaurants/markup/delete-input-or-inter', payload);

        setInputsAndIntermediaries((old) =>
          old.filter((it) =>
            item.input_id
              ? it.input_id !== item.input_id
              : it.intermediary_id !== item.intermediary_id
          )
        );

        setProducts((old) =>
          old.map((product) => {
            if (product.id === item.product_id) {
              const keyToFilter = item.input_id ? 'inputs' : 'intermediaries';
              return {
                ...product,
                [keyToFilter]: product[keyToFilter].filter((entry) =>
                  item.input_id
                    ? entry.input_id !== item.input_id
                    : entry.intermediary_id !== item.intermediary_id
                ),
              };
            }
            return product;
          })
        );

        toast.success(
          item.input_id
            ? 'Insumo deletado com sucesso'
            : 'Intermediário deletado com sucesso'
        );
        setIsModalDeleteInputOpened(false);
        setItemToDelete(null);
      } catch (error) {
        toast.error('Erro ao deletar do estoque');
        console.error(error);
      }
    },
    [setProducts]
  );

  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);

  const handleSubmit = useCallback(async () => {
    try {
      setButtonSubmitDisabled(true);

      const data = {
        markup,
        markup_delivery: markupDelivery,
        // price,
        // delivery_price: deliveryPrice,
      };

      if (product.is_cmv_manual) {
        data.current_cmv = cmv;
        data.is_cmv_manual = true;
      }

      await api.put(`/restaurants/products/${product.id}`, data);
      toast.success('Produto atualizado com sucesso');
    } catch (error) {
      toast.error('Erro ao atualizar produto.');
    }
  }, [markup, markupDelivery, cmv]);

  const getButtonColor = useCallback(
    (type) => {
      // const percentage = type === "delivery" ? (Number(resultDelivery) / Number(idealDelivery)) * 100 : (Number(result) / Number(ideal)) * 100;
      const percentage = type === 'delivery' ? resultDelivery : result;

      if (percentage < 0) {
        return '#E5A1A4';
      }
      return '#BCE3DE';
    },
    [resultDelivery, result]
  );

  const getIcon = useCallback(
    (type) => {
      // const percentage = type === "delivery" ? (Number(resultDelivery) / Number(idealDelivery)) * 100 : (Number(result) / Number(ideal)) * 100;
      const percentage = type === 'delivery' ? resultDelivery : result;

      if (percentage < 0) {
        return (
          <IconArrowDown
            style={{ width: '0.95rem', color: '#C8131B', fill: '#C8131B' }}
          />
        );
      }
      return <IconArrowUp style={{ width: '0.95rem', fill: '#2EC9B7' }} />;
    },
    [result, resultDelivery]
  );

  function handleChangeCmv(e) {
    if (product.is_cmv_manual) {
      setCmv(e);
      setProducts((old) =>
        old.map((item) =>
          item.id === product.id ? { ...item, current_cmv: e } : item
        )
      );
    }
  }

  // tooltips
  const [tooltipStates, setTooltipStates] = useState({});

  const toggleTooltip = (id) => {
    setTooltipStates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const groupB = [
    2, 36801, 52707, 54093, 59802, 51948, 28559, 64818, 57261, 224, 25053,
    44952, 50265, 47296, 29640, 56898, 296, 32841, 1690, 57690, 61089, 10830,
    441, 32016, 56733, 52410, 19378, 50826, 15879, 31257, 59, 20829, 22710, 266,
    13701, 14295, 21555, 30201, 1887, 40200, 29641, 13, 43764, 41652, 58482,
  ];

  return !groupB.includes(user.id) ? (
    <Container visible={visible}>
      <StyledModal
        isOpen={isModalEditStockOpened}
        toggle={toggleModalEditStock}
        size="lg"
      >
        <EditStock
          toggleModalEditStock={toggleModalEditStock}
          product={product}
          inputsAndIntermediaries={inputsAndIntermediaries}
          setInputsAndIntermediaries={setInputsAndIntermediaries}
        />
      </StyledModal>

      <StyledModal
        isOpen={isModalDeleteInputOnpened}
        toggle={toggleModalDeleteInput}
        size="md"
      >
        <p
          style={{ padding: 20, color: '#222', fontSize: 16, fontWeight: 600 }}
        >
          Deseja realmente deletar este item?
        </p>
        <ModalFooter
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button customColor="#2ec9b7" onClick={toggleModalDeleteInput}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleConfirmDelete(inputToDelete);
            }}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </StyledModal>

      {user.only_delivery ? (
        <Header onClick={handleShowStockArea}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IoIosArrowDown
                color="#222"
                size={21}
                style={{
                  marginRight: 16,
                  transform: `rotate(${showStock ? '-180deg' : '0deg'})`,
                  transition: 'transform 0.3s ease',
                }}
                cursor="pointer"
              />
            </div>
            <ProductInfoArea>
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
              </ProductInfo>
            </ProductInfoArea>
          </div>
          <TabelArea
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <TableStyled>
              <thead>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {product.is_cmv_manual ? (
                        <Input
                          value={cmv}
                          onChange={(e) => {
                            handleChangeCmv(e.target.value);
                          }}
                          disabled={!product.is_cmv_manual}
                          style={{
                            width: 104,
                            height: 32,
                            textAlign: 'center',
                            marginTop: 16,
                          }}
                          onClick={() => {
                            setButtonSubmitDisabled(false);
                          }}
                          hasPrefix
                          isCurrency
                        />
                      ) : (
                        <ResultButton
                          style={{
                            maxWidth: 140,
                            height: 32,
                            borderRadius: 8,
                            color: '#000',
                            cursor: 'auto',
                            background: '#F6F6F6',
                            fontWeight: 400,
                          }}
                        >
                          {cmv > 0 ? formatValue(cmv) : 'R$ 0,00'}
                        </ResultButton>
                      )}
                      {product.is_cmv_manual && (
                        <span style={{ fontSize: 12 }}>*CMV manual</span>
                      )}
                    </div>
                  </td>
                  <td>
                    <Input
                      disabled
                      value={0}
                      onChange={(e) => setMarkupDelivery(e.target.value)}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                      isCurrency
                    />
                  </td>
                  <td>
                    <Input
                      value={cmvPercentualDelivery}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      onChange={(e) => setCmvPercentualDelivery(e.target.value)}
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                      isCurrency
                    />
                  </td>
                  <td>
                    <Input
                      value={deliveryPrice}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      onChange={(e) => setDeliveryPrice(e.target.value)}
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                    />
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 118,
                        height: 32,
                        borderRadius: 8,
                        color: '#000',
                        cursor: 'auto',
                      }}
                      customColor="#F6F6F6"
                    >
                      {formatValue(idealDelivery)}
                    </ResultButton>
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 118,
                        height: 32,
                        borderRadius: 8,
                        cursor: 'auto',
                        background:
                          idealDelivery > 0
                            ? getButtonColor('delivery')
                            : '#f6f6f6',
                        color: '#000',
                      }}
                      icon={idealDelivery > 0 && getIcon('delivery')}
                    >
                      {idealDelivery > 0
                        ? formatValue(Math.abs(resultDelivery))
                        : '-'}
                    </ResultButton>
                  </td>
                  <td>
                    <Button
                      icon={<Check />}
                      customColor="#2EC9B7"
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: 8,
                      }}
                      disabled={buttonSubmitDisabled}
                      onClick={handleSubmit}
                    />
                  </td>
                </tr>
              </tbody>
            </TableStyled>
          </TabelArea>
        </Header>
      ) : (
        <Header onClick={handleShowStockArea}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IoIosArrowDown
                color="#222"
                size={21}
                style={{
                  marginRight: 16,
                  transform: `rotate(${showStock ? '-180deg' : '0deg'})`,
                  transition: 'transform 0.3s ease',
                }}
                onClick={handleShowStockArea}
                cursor="pointer"
              />
            </div>
            <ProductInfoArea>
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
              </ProductInfo>
              {(user?.delivery_info?.is_delivery_allowed ||
                user?.delivery_info?.is_withdrawal_allowed) && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 8,
                    }}
                  >
                    <span style={{ fontSize: 12, fontWeight: 600 }}>
                      Custo do Item (CMV):
                    </span>
                    {product.is_cmv_manual ? (
                      <Input
                        value={cmv}
                        onChange={(e) => {
                          handleChangeCmv(e.target.value);
                        }}
                        disabled={!product.is_cmv_manual}
                        style={{
                          width: 140,
                          height: 32,
                          textAlign: 'center',
                        }}
                        onClick={() => {
                          setButtonSubmitDisabled(false);
                        }}
                        hasPrefix
                        isCurrency
                      />
                    ) : (
                      <ResultButton
                        style={{
                          width: 140,
                          height: 32,
                          borderRadius: 8,
                          color: '#000',
                          cursor: 'auto',
                          background: '#f6f6f6',
                          fontWeight: 400,
                        }}
                      >
                        {cmv > 0 ? formatValue(cmv) : 'R$ 0,00'}
                      </ResultButton>
                    )}

                    {product.is_cmv_manual && (
                      <span style={{ fontSize: 12 }}>*CMV manual</span>
                    )}
                  </div>
                )}
            </ProductInfoArea>
          </div>
          <TabelArea
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <TableStyled>
              <thead>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  {user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed ? (
                    <td>Presencial</td>
                  ) : (
                    <td>
                      <Input
                        value={`CMV: ${formatValue(
                          inputsAndIntermediaries.reduce((acc, item) => {
                            const quantidade = parseFloat(item.quantidade) || 0;
                            const unitaryPrice =
                              parseFloat(item.unitary_price) || 0;
                            return acc + quantidade * unitaryPrice;
                          }, 0)
                        )}`}
                        style={{
                          width: 131,
                          height: 32,
                        }}
                      />
                    </td>
                  )}
                  <td>
                    <Input
                      value={0}
                      disabled
                      onChange={(e) => setMarkup(e.target.value)}
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      isCurrency
                    />
                  </td>
                  <td>
                    <Input
                      value={cmvPercentual}
                      onChange={(e) => {
                        setCmvPercentual(e.target.value);
                      }}
                      isCurrency
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={(e) => setPrice(e.target.value)}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      value={price}
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                      isCurrency
                      hasPrefix
                    />
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 118,
                        height: 32,
                        borderRadius: 8,
                        color: '#000',
                        cursor: 'auto',
                        background: '#F6F6F6',
                      }}
                    >
                      {formatValue(ideal)}
                    </ResultButton>
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 118,
                        height: 32,
                        borderRadius: 8,
                        cursor: 'auto',
                        color: '#000',
                        background: ideal > 0 ? getButtonColor() : '#f6f6f6',
                      }}
                      icon={ideal > 0 && getIcon()}
                    >
                      {ideal > 0 ? formatValue(Math.abs(result)) : '-'}
                    </ResultButton>
                  </td>
                  {user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed ? (
                    <td rowSpan="2">
                      <Button
                        icon={<Check />}
                        customColor="#2EC9B7"
                        style={{ height: '5rem', borderRadius: 8 }}
                        disabled={buttonSubmitDisabled}
                        onClick={handleSubmit}
                      />
                    </td>
                  ) : (
                    <td>
                      <Button
                        icon={<Check />}
                        customColor="#2EC9B7"
                        style={{ width: 32, borderRadius: 8 }}
                        disabled={buttonSubmitDisabled}
                        onClick={handleSubmit}
                      />
                    </td>
                  )}
                </tr>
                {(user?.delivery_info?.is_delivery_allowed ||
                  user?.delivery_info?.is_withdrawal_allowed) && (
                    <tr>
                      <td>Delivery</td>
                      <td>
                        <Input
                          value={0}
                          disabled
                          onChange={(e) => setMarkupDelivery(e.target.value)}
                          onClick={() => {
                            setButtonSubmitDisabled(false);
                          }}
                          style={{
                            maxWidth: 118,
                            height: 32,
                            textAlign: 'center',
                          }}
                          isCurrency
                        />
                      </td>
                      <td>
                        <Input
                          value={cmvPercentualDelivery}
                          onChange={(e) =>
                            setCmvPercentualDelivery(e.target.value)
                          }
                          onClick={() => {
                            setButtonSubmitDisabled(false);
                          }}
                          style={{
                            maxWidth: 118,
                            height: 32,
                            textAlign: 'center',
                          }}
                          isCurrency
                        />
                      </td>
                      <td>
                        <Input
                          value={deliveryPrice}
                          onChange={(e) => setDeliveryPrice(e.target.value)}
                          onClick={() => {
                            setButtonSubmitDisabled(false);
                          }}
                          style={{
                            maxWidth: 118,
                            height: 32,
                            textAlign: 'center',
                          }}
                          isCurrency
                          hasPrefix
                        />
                      </td>
                      <td>
                        <ResultButton
                          style={{
                            maxWidth: 118,
                            height: 32,
                            borderRadius: 8,
                            color: '#000',
                            cursor: 'auto',
                            background: '#F6F6F6',
                          }}
                        >
                          {formatValue(idealDelivery)}
                        </ResultButton>
                      </td>
                      <td>
                        <ResultButton
                          style={{
                            maxWidth: 118,
                            height: 32,
                            borderRadius: 8,
                            cursor: 'auto',
                            background:
                              idealDelivery > 0
                                ? getButtonColor('delivery')
                                : '#f6f6f6',
                            color: '#000',
                          }}
                          variant="tonal"
                          icon={idealDelivery > 0 && getIcon('delivery')}
                        >
                          {idealDelivery > 0
                            ? formatValue(Math.abs(resultDelivery))
                            : '-'}
                        </ResultButton>
                      </td>
                    </tr>
                  )}
              </tbody>
            </TableStyled>
          </TabelArea>
        </Header>
      )}

      <StockArea show={showStock} style={{ marginTop: showStock ? 10 : 0 }}>
        <InputTableStyled>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Nome</th>
              <th>
                Consumo
                <IconInfoFilled
                  fill="#7a7a7a"
                  id={`title-qtd-${product.id}`}
                  style={{ marginLeft: 4 }}
                />
                <CustomTooltip
                  isOpen={tooltipStates[`qtd-${product.id}`] || false}
                  toggle={() => toggleTooltip(`qtd-${product.id}`)}
                  target={`title-qtd-${product.id}`}
                  placement="bottom"
                >
                  Quantidade e unidade de medida usada na ficha técnica.
                </CustomTooltip>
              </th>
              <th>
                Valor
                <IconInfoFilled
                  fill="#7a7a7a"
                  id={`title-value-${product.id}`}
                  style={{ marginLeft: 4 }}
                />
                <CustomTooltip
                  isOpen={tooltipStates[`value-${product.id}`] || false}
                  toggle={() => toggleTooltip(`value-${product.id}`)}
                  target={`title-value-${product.id}`}
                  placement="bottom"
                >
                  Valor de custo daquele insumo/produto intermediário na ficha
                  técnica.
                </CustomTooltip>
              </th>
              <th>
                Estoque
                <IconInfoFilled
                  fill="#7a7a7a"
                  id={`title-amount-${product.id}`}
                  style={{ marginLeft: 4 }}
                />
                <CustomTooltip
                  isOpen={tooltipStates[`amount-${product.id}`] || false}
                  toggle={() => toggleTooltip(`amount-${product.id}`)}
                  target={`title-amount-${product.id}`}
                  placement="bottom"
                >
                  Quantidade daquele produto no estoque.
                </CustomTooltip>
              </th>
              <th>
                <Button
                  customColor="#01AFFF"
                  icon={<IconPencilFilled style={{ width: 14 }} />}
                  style={{
                    borderRadius: 8,
                    width: 84,
                    height: 32,
                    fontSize: 12,
                  }}
                  onClick={toggleModalEditStock}
                >
                  Editar
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {inputsAndIntermediaries &&
              inputsAndIntermediaries.length > 0 &&
              inputsAndIntermediaries.map((item) => (
                <tr key={item.id}>
                  <td>{item.input_id ? 'Insumos' : 'Intermediários'}</td>
                  <td>{item.name}</td>
                  <td>{`${item.quantidade} (${item.unidade})`} </td>
                  <td>{formatValue(item.quantidade * item.unitary_price)} </td>
                  <td>{`${item.available_amount || '-'} ${item.unidade}`} </td>
                  <td>
                    <IconTrashFilled
                      fill="#c8131b"
                      fontSize={24}
                      style={{
                        marginRight: 26,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleDelete(item);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </InputTableStyled>
      </StockArea>
    </Container>
  ) : (
    <Container visible={visible}>
      <StyledModal
        isOpen={isModalEditStockOpened}
        toggle={toggleModalEditStock}
        size="lg"
      >
        <EditStock
          toggleModalEditStock={toggleModalEditStock}
          product={product}
          inputsAndIntermediaries={inputsAndIntermediaries}
          setInputsAndIntermediaries={setInputsAndIntermediaries}
        />
      </StyledModal>

      <StyledModal
        isOpen={isModalDeleteInputOnpened}
        toggle={toggleModalDeleteInput}
        size="md"
      >
        <p
          style={{ padding: 20, color: '#222', fontSize: 16, fontWeight: 600 }}
        >
          Deseja realmente deletar este item?
        </p>
        <ModalFooter
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button customColor="#2ec9b7" onClick={toggleModalDeleteInput}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleConfirmDelete(inputToDelete);
            }}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </StyledModal>
      {user.only_delivery ? (
        <Header onClick={handleShowStockArea}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IoIosArrowDown
                color="#222"
                size={21}
                style={{
                  marginRight: 16,
                  transform: `rotate(${showStock ? '-180deg' : '0deg'})`,
                  transition: 'transform 0.3s ease',
                }}
                cursor="pointer"
              />
            </div>
            <ProductInfoArea>
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
              </ProductInfo>
            </ProductInfoArea>
          </div>
          <TabelArea
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <TableStyled>
              <thead>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {product.is_cmv_manual ? (
                        <Input
                          value={cmv}
                          onChange={(e) => {
                            handleChangeCmv(e.target.value);
                          }}
                          disabled={!product.is_cmv_manual}
                          style={{
                            width: 104,
                            height: 32,
                            textAlign: 'center',
                            marginTop: 16,
                          }}
                          onClick={() => {
                            setButtonSubmitDisabled(false);
                          }}
                          hasPrefix
                          isCurrency
                        />
                      ) : (
                        <ResultButton
                          style={{
                            maxWidth: 140,
                            height: 32,
                            borderRadius: 8,
                            color: '#000',
                            cursor: 'auto',
                            background: '#F6F6F6',
                            fontWeight: 400,
                          }}
                        >
                          {cmv > 0 ? formatValue(cmv) : 'R$ 0,00'}
                        </ResultButton>
                      )}
                      {/* {product.is_cmv_manual && (
                        <span style={{ fontSize: 12 }}>*CMV manual</span>
                      )} */}
                    </div>
                  </td>
                  <td>
                    <Input
                      value={markupDelivery}
                      onChange={(e) => setMarkupDelivery(e.target.value)}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                      isCurrency
                    />
                  </td>
                  <td>
                    <Input
                      value={0}
                      disabled
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      onChange={(e) => setCmvPercentualDelivery(e.target.value)}
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                      isCurrency
                    />
                  </td>
                  <td>
                    <Input
                      value={deliveryPrice}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      onChange={(e) => setDeliveryPrice(e.target.value)}
                      style={{
                        maxWidth: 118,
                        height: 32,
                        textAlign: 'center',
                      }}
                    />
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 118,
                        height: 32,
                        borderRadius: 8,
                        color: '#000',
                        cursor: 'auto',
                      }}
                      customColor="#F6F6F6"
                    >
                      {formatValue(idealDelivery)}
                    </ResultButton>
                  </td>
                  <td>
                    <ResultButton
                      style={{
                        maxWidth: 118,
                        height: 32,
                        borderRadius: 8,
                        cursor: 'auto',
                        background:
                          idealDelivery > 0
                            ? getButtonColor('delivery')
                            : '#f6f6f6',
                        color: '#000',
                      }}
                      icon={idealDelivery > 0 && getIcon('delivery')}
                    >
                      {idealDelivery > 0
                        ? formatValue(Math.abs(resultDelivery))
                        : '-'}
                    </ResultButton>
                  </td>
                  <td>
                    <Button
                      icon={<Check />}
                      customColor="#2EC9B7"
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: 8,
                      }}
                      disabled={buttonSubmitDisabled}
                      onClick={handleSubmit}
                    />
                  </td>
                </tr>
              </tbody>
            </TableStyled>
          </TabelArea>
        </Header>
      ) : (
        <Header onClick={handleShowStockArea}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IoIosArrowDown
                color="#222"
                size={21}
                style={{
                  marginRight: 16,
                  transform: `rotate(${showStock ? '-180deg' : '0deg'})`,
                  transition: 'transform 0.3s ease',
                }}
                onClick={handleShowStockArea}
                cursor="pointer"
              />
            </div>
            <ProductInfoArea>
              <ProductInfo>
                <ProductNameEllipsis>{product.name}</ProductNameEllipsis>
              </ProductInfo>
              {/* {(user?.delivery_info?.is_delivery_allowed ||
                user?.delivery_info?.is_withdrawal_allowed) && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 8,
                    }}
                  >
                    <span style={{ fontSize: 12, fontWeight: 600 }}>
                      Custo do Item (CMV):
                    </span>
                    {product.is_cmv_manual ? (
                      <Input
                        value={cmv}
                        onChange={(e) => {
                          handleChangeCmv(e.target.value);
                        }}
                        disabled={!product.is_cmv_manual}
                        style={{
                          width: 140,
                          height: 32,
                          textAlign: 'center',
                        }}
                        onClick={() => {
                          setButtonSubmitDisabled(false);
                        }}
                        hasPrefix
                        isCurrency
                      />
                    ) : (
                      <ResultButton
                        style={{
                          width: 140,
                          height: 32,
                          borderRadius: 8,
                          color: '#000',
                          cursor: 'auto',
                          background: '#f6f6f6',
                          fontWeight: 400,
                        }}
                      >
                        {cmv > 0 ? formatValue(cmv) : 'R$ 0,00'}
                      </ResultButton>
                    )}

                    {product.is_cmv_manual && (
                      <span style={{ fontSize: 12 }}>*CMV manual</span>
                    )}
                  </div>
                )} */}
            </ProductInfoArea>
          </div>
          <TabelArea
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <TableStyled>
              <thead>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {product?.is_cmv_manual ? (
                      <InputOptimized
                        value={cmv}
                        onChange={(e) => {
                          handleChangeCmv(e.target.value);
                        }}
                        onClick={() => {
                          setButtonSubmitDisabled(false);
                        }}
                        hasPrefix
                        isCurrency
                      />
                    ) : (
                      <ResultButtonOptimized
                        style={{
                          borderRadius: 8,
                          color: '#222',
                          cursor: 'auto',
                          background: '#F6F6F6',
                        }}
                      >
                        {cmv > 0 ? formatValue(cmv) : formatValue(0.0)}
                      </ResultButtonOptimized>
                    )}
                  </td>

                  <td>
                    <InputOptimized
                      value={markup}
                      onChange={(e) => setMarkup(e.target.value)}
                      style={{
                        textAlign: 'center',
                      }}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      isCurrency
                    />
                  </td>

                  {(user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed) && (
                      <td>
                        <InputOptimized
                          value={markupDelivery}
                          onChange={(e) => setMarkupDelivery(e.target.value)}
                          onClick={() => {
                            setButtonSubmitDisabled(false);
                          }}
                          style={{
                            textAlign: 'center',
                          }}
                          isCurrency
                        />
                      </td>
                    )}

                  <td>
                    <InputOptimized
                      value={0}
                      disabled
                      onChange={(e) => {
                        setCmvPercentual(e.target.value);
                      }}
                      isCurrency
                      style={{
                        textAlign: 'center',
                      }}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                    />
                  </td>

                  {(user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed) && (
                      <td>
                        <InputOptimized
                          value={0}
                          disabled
                          onChange={(e) =>
                            setCmvPercentualDelivery(e.target.value)
                          }
                          onClick={() => {
                            setButtonSubmitDisabled(false);
                          }}
                          style={{
                            textAlign: 'center',
                          }}
                          isCurrency
                        />
                      </td>
                    )}
                  <td>
                    <InputOptimized
                      onChange={(e) => setPrice(e.target.value)}
                      onClick={() => {
                        setButtonSubmitDisabled(false);
                      }}
                      value={price}
                      style={{
                        textAlign: 'center',
                      }}
                      isCurrency
                      hasPrefix
                    />
                  </td>

                  {(user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed) && (
                      <td>
                        <InputOptimized
                          value={deliveryPrice}
                          onChange={(e) => setDeliveryPrice(e.target.value)}
                          onClick={() => {
                            setButtonSubmitDisabled(false);
                          }}
                          style={{
                            textAlign: 'center',
                          }}
                          isCurrency
                          hasPrefix
                        />
                      </td>
                    )}
                  <td>
                    <ResultButtonOptimized
                      style={{
                        color: '#000',
                        cursor: 'auto',
                        background: '#F6F6F6',
                      }}
                    >
                      {formatValue(ideal)}
                    </ResultButtonOptimized>
                  </td>
                  {(user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed) && (
                      <td>
                        <ResultButtonOptimized
                          style={{
                            color: '#000',
                            cursor: 'auto',
                            background: '#F6F6F6',
                          }}
                        >
                          {formatValue(idealDelivery)}
                        </ResultButtonOptimized>
                      </td>
                    )}

                  <td>
                    <ResultButtonOptimized
                      style={{
                        cursor: 'auto',
                        color: '#000',
                        background: ideal > 0 ? getButtonColor() : '#f6f6f6',
                      }}
                      icon={ideal > 0 && getIcon()}
                    >
                      <Price>
                        {ideal > 0 ? formatValue(Math.abs(result)) : '-'}
                      </Price>
                    </ResultButtonOptimized>
                  </td>
                  {(user?.delivery_info?.is_delivery_allowed ||
                    user?.delivery_info?.is_withdrawal_allowed) && (
                      <ResultButtonOptimized
                        style={{
                          cursor: 'auto',
                          background:
                            idealDelivery > 0
                              ? getButtonColor('delivery')
                              : '#f6f6f6',
                          color: '#000',
                        }}
                        variant="tonal"
                        icon={idealDelivery > 0 && getIcon('delivery')}
                      >
                        <Price>
                          {idealDelivery > 0
                            ? formatValue(Math.abs(resultDelivery))
                            : '-'}
                        </Price>
                      </ResultButtonOptimized>
                    )}

                  <td>
                    <Button
                      icon={<Check />}
                      customColor="#2EC9B7"
                      style={{ width: 32, height: 32, borderRadius: 8 }}
                      disabled={buttonSubmitDisabled}
                      onClick={handleSubmit}
                    />
                  </td>
                </tr>
              </tbody>
            </TableStyled>
          </TabelArea>
        </Header>
      )}

      <StockArea show={showStock} style={{ marginTop: showStock ? 10 : 0 }}>
        <InputTableStyled>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Nome</th>
              <th>
                Consumo
                <IconInfoFilled
                  fill="#7a7a7a"
                  id={`title-qtd-${product.id}`}
                  style={{ marginLeft: 4 }}
                />
                <CustomTooltip
                  isOpen={tooltipStates[`qtd-${product.id}`] || false}
                  toggle={() => toggleTooltip(`qtd-${product.id}`)}
                  target={`title-qtd-${product.id}`}
                  placement="bottom"
                >
                  Quantidade e unidade de medida usada na ficha técnica.
                </CustomTooltip>
              </th>
              <th>
                Valor
                <IconInfoFilled
                  fill="#7a7a7a"
                  id={`title-value-${product.id}`}
                  style={{ marginLeft: 4 }}
                />
                <CustomTooltip
                  isOpen={tooltipStates[`value-${product.id}`] || false}
                  toggle={() => toggleTooltip(`value-${product.id}`)}
                  target={`title-value-${product.id}`}
                  placement="bottom"
                >
                  Valor de custo daquele insumo/produto intermediário na ficha
                  técnica.
                </CustomTooltip>
              </th>
              <th>
                Estoque
                <IconInfoFilled
                  fill="#7a7a7a"
                  id={`title-amount-${product.id}`}
                  style={{ marginLeft: 4 }}
                />
                <CustomTooltip
                  isOpen={tooltipStates[`amount-${product.id}`] || false}
                  toggle={() => toggleTooltip(`amount-${product.id}`)}
                  target={`title-amount-${product.id}`}
                  placement="bottom"
                >
                  Quantidade daquele produto no estoque.
                </CustomTooltip>
              </th>
              <th>
                <Button
                  customColor="#01AFFF"
                  icon={<IconPencilFilled style={{ width: 14 }} />}
                  style={{
                    borderRadius: 8,
                    width: 84,
                    height: 32,
                    fontSize: 12,
                  }}
                  onClick={toggleModalEditStock}
                >
                  Editar
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {inputsAndIntermediaries &&
              inputsAndIntermediaries.length > 0 &&
              inputsAndIntermediaries.map((item) => (
                <tr key={item.id}>
                  <td>{item.input_id ? 'Insumos' : 'Intermediários'}</td>
                  <td>{item.name}</td>
                  <td>{`${item.quantidade} (${item.unidade})`} </td>
                  <td>{formatValue(item.quantidade * item.unitary_price)} </td>
                  <td>{`${item.available_amount || '-'} ${item.unidade}`} </td>
                  <td>
                    <IconTrashFilled
                      fill="#c8131b"
                      fontSize={24}
                      style={{
                        marginRight: 26,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleDelete(item);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </InputTableStyled>
      </StockArea>
    </Container>
  );
}
