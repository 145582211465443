import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 460px;
  height: 108px;
  border-left: 4px solid #FFB32F;
  background-color: #fff;
  z-index: 9999; 
  position: fixed;
  right: 10px;
  bottom: 80px;
  border-radius: 12px;
  padding: 20px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));

  transform: translateX(${(props) => (props.isOpen ? "0" : "110%")});
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  will-change: transform, opacity;
`;


