import React, { useRef, useState, useMemo, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Row, Col, ModalBody } from 'reactstrap';
import { format, startOfDay, subHours } from 'date-fns';
import { FiX } from 'react-icons/fi';
import { Header, Container, StyledForm, Title, InputArea } from './styles';
import {
  Button,
  Switch,
  Checkbox,
  Input,
  Select,
  SingleCalendar,
  RadioGroup,
} from 'takeat-design-system-ui-kit';
import getValidationErrors from '~/services/getValidationErrors';
import { useAuth } from '~/context/AuthContext';
import api2 from '~/services/api2';

function NewTicket({ getTickets, toggleAddTicketModal }) {
  const { user } = useAuth();
  const formRef = useRef(null);
  const [discountType, setDiscountType] = useState('percentage');
  const [type, setType] = useState('Desconto (%)');
  const today = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), 'yyyy-MM-dd');
  }, []);
  const [expirationDate, setExpirationDate] = useState(null);
  const [acceptedChannels, setAcceptedChannels] = useState(['delivery']);

  const [ACCEPTED_CHANNELS] = useState(
    user.has_stone_pos
      ? [
          {
            value: ['delivery'],
            label: 'Delivery',
          },
          {
            value: ['totem'],
            label: 'Totem',
          },
          {
            value: ['delivery', 'totem'],
            label: 'Delivery e Totem',
          },
        ]
      : [
          {
            value: ['delivery'],
            label: 'Delivery',
          },
        ]
  );

  function changeType(new_discount_type) {
    switch (new_discount_type) {
      case 'percentage':
        setType('Desconto (%)');
        break;
      case 'absolute':
        setType('Desconto (R$)');
        break;
      default:
        break;
    }

    setDiscountType(new_discount_type);
  }

  const [active, setActive] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [expires, setExpires] = useState(false);
  const [limitClientCouponUse, setLimitClientCouponUse] = useState(true);
  const [limitCoupon, setLimitCoupon] = useState(true);
  const [data, setData] = useState({
    code: '',
    minimum_price: '',
    coupon_code: '',
    maximum_discount: '',
    expires_at: new Date(),
    userLimit: '',
    discount: '',
    distance_limit: '',
    limit_amount: '',
    acceptedChannels: [''],
  });
  const [ticketLimit, setTicketLimit] = useState(null);

  const couponType = [
    { label: 'Reais (R$)', value: 'absolute' },
    { label: 'Porcentagem (%)', value: 'percentage' },
    { label: 'Frete Grátis', value: 'free-shipping' },
  ];
  function changeActive() {
    setActive(!active);
  }

  function changeIsPublic() {
    setIsPublic(!isPublic);
  }

  function changeLimitCoupon() {
    setLimitCoupon(!limitCoupon);
  }

  function changeClienteCouponUse() {
    setLimitClientCouponUse(!limitClientCouponUse);
  }

  function changeExpires() {
    setExpires(!expires);
    !expires ? setExpirationDate(`${today}T03:00:00`) : setExpirationDate(null);
    setData((prevData) => ({
      ...prevData,
      expires_at: null,
    }));
  }

  async function handleSubmit() {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        code: Yup.string().required('O nome é obrigatório'),
        coupon_code: Yup.string().required('O código é obrigatório'),
        discount:
          discountType !== 'free-shipping'
            ? Yup.string().required('O desconto é obrigatório')
            : Yup.string(),
        maximum_discount:
          discountType !== 'free-shipping'
            ? Yup.string()
                .transform((value, originalValue) =>
                  originalValue === '' ? null : value
                )
                .nullable()
                .min(1, 'O desconto deve ser maior que 1 Real')
            : Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      let formattedPrice = 0;
      let distance_limit = null;
      if (discountType === 'free-shipping') {
        data.distance_limit = data.distance_limit.trim();
        if (data.distance_limit) {
          distance_limit = parseFloat(data.distance_limit.replace(',', '.'));
        }
      } else if (discountType === 'percentage') {
        formattedPrice = data.discount.includes(',')
          ? parseFloat(data.discount.replace(',', '.')) / 100
          : parseFloat(data.discount) / 100;
      } else {
        formattedPrice = data.discount.includes(',')
          ? parseFloat(data.discount.replace(',', '.')) / 100
          : parseFloat(data.discount) / 100;
      }

      const dataToSend = {
        currency: 'BRL',
        name: data.code,
        code: data.coupon_code,
        discount_type: discountType,
        discount: formattedPrice,
        limit_date: data.expires_at,
        is_active: active,
        is_public: isPublic,
        description: '',
        title: '',
        distance_limit: data.distance_limit === '' ? 0 : data.distance_limit,
        accepted_channels: data.acceptedChannels,
      };
      if (data.minimum_price !== '') {
        dataToSend.minimum_price = parseFloat(
          data.minimum_price.replace(',', '.')
        ).toFixed(2);
      }
      if (data.maximum_discount !== '') {
        dataToSend.maximum_discount = parseFloat(
          data.maximum_discount.includes(',')
            ? data.maximum_discount.replace(',', '.')
            : data.maximum_discount
        ).toFixed(2);
      }
      if (data.limit_amount !== '') {
        dataToSend.limit_amount = data.limit_amount;
      }
      if (data.userLimit !== '') {
        dataToSend.buyer_limit_buy = data.userLimit;
      }

      await api2.post('/restaurants/discount-coupons', dataToSend);

      toast.success('Cupom cadastrado com sucesso!');
      getTickets();
      toggleAddTicketModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (error.response?.data?.errorType === 'coupon_already_exists') {
        toast.error('Já existe um cupom cadastrado com esse código.');
      } else {
        toast.error('Erro ao cadastrar cupom!');
      }
    }
  }

  useEffect(() => {
    if (acceptedChannels.includes('totem')) {
      changeType('percentage');
    }
  }, [acceptedChannels]);

  return (
    <ModalBody>
      <Header>
        <Title>Cadastrar cupons</Title>

        <FiX
          size={22}
          color="#222222"
          cursor="pointer"
          strokeWidth={3}
          onClick={() => toggleAddTicketModal()}
        />
      </Header>
      <Container>
        <Row>
          <Col md="12 ml-auto mr-auto">
            <StyledForm ref={formRef} onSubmit={handleSubmit}>
              <Row style={{ paddingBottom: 10 }}>
                <RadioGroup
                  options={couponType}
                  name="discount_type"
                  onChange={(e) => changeType(e.target.value)}
                  value={discountType}
                />
              </Row>

              <InputArea>
                {discountType !== 'free-shipping' ? (
                  <Input
                    label={type}
                    name="discount"
                    type="text"
                    containerStyles={{ gridArea: 'discount' }}
                    onChange={(e) => {
                      e.persist();
                      setData((prevData) => ({
                        ...prevData,
                        discount: e.target.value,
                      }));
                    }}
                  />
                ) : (
                  <Input
                    label="Limite de Distância (km)"
                    name="distance_limit"
                    type="text"
                    containerStyles={{ gridArea: 'discount' }}
                    onChange={(e) => {
                      e.persist();
                      setData((prevData) => ({
                        ...prevData,
                        distance_limit: e.target.value,
                      }));
                    }}
                  />
                )}

                <Input
                  label="Valor Mínimo (R$)"
                  name="minimum_price"
                  type="number"
                  placeholder=""
                  containerStyles={{ gridArea: 'minimum_price' }}
                  value={data.minimum_price}
                  onChange={(e) => {
                    e.persist();
                    setData((newdata) => ({
                      ...newdata,
                      minimum_price: e.target?.value,
                    }));
                  }}
                />

                <Input
                  label="Nome do Cupom"
                  name="name"
                  type="text"
                  placeholder="Ex.: 60% OFF"
                  containerStyles={{ gridArea: 'coupon_name' }}
                  value={data.code}
                  onChange={(e) => {
                    e.persist();
                    setData((newdata) => ({
                      ...newdata,
                      code: e.target?.value,
                    }));
                  }}
                />

                <Input
                  label="Código do cupom"
                  name="code"
                  type="text"
                  placeholder="Ex.: 60OFF"
                  containerStyles={{ gridArea: 'coupon_code' }}
                  value={data.coupon_code}
                  onChange={(e) => {
                    e.persist();
                    setData((newdata) => ({
                      ...newdata,
                      coupon_code: e.target?.value,
                    }));
                  }}
                />

                <Input
                  label="Desconto Máximo (R$)"
                  name="maximum_discount"
                  type="text"
                  disabled={
                    discountType === 'free-shipping' ||
                    discountType === 'absolute'
                  }
                  placeholder=""
                  containerStyles={{ gridArea: 'maximum_discount' }}
                  value={data.maximum_discount}
                  onChange={(e) => {
                    e.persist();
                    setData((newdata) => ({
                      ...newdata,
                      maximum_discount: e.target?.value,
                    }));
                  }}
                />

                <div
                  style={{
                    gridArea: 'date',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      color: '#545454',
                      height: 28,
                      fontSize: 16,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Validade
                  </span>
                  <SingleCalendar
                    containerStyles={{ width: '100%', fontWeight: 700 }}
                    disabled={expires}
                    onSelectDate={(e) => {
                      setData((prevData) => ({
                        ...prevData,
                        expires_at: e,
                      }));
                    }}
                    onlyDate={true}
                    style={{ color: 'yellow !important' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingBottom: 16,
                      paddingTop: 6,
                    }}
                  >
                    <Checkbox
                      label="Sem data de expiração"
                      onChange={changeExpires}
                    />
                  </div>
                </div>

                <Select
                  label="Operação"
                  isMulti={true}
                  options={[
                    { value: 'delivery', label: 'Delivery' },
                    { value: 'presencial', label: 'Presencial' },
                  ]}
                  containerStyles={{ gridArea: 'operation' }}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );

                    setData((prevData) => ({
                      ...prevData,
                      acceptedChannels: selectedValues,
                    }));
                  }}
                />

                <div style={{ gridArea: 'limitCoupon' }}>
                  <Checkbox
                    label="Limitar uso do cupom por cliente"
                    onChange={changeLimitCoupon}
                  />
                  <Input
                    placeholder=""
                    disabled={limitCoupon}
                    onChange={(e) => {
                      e.persist();
                      setData((prevData) => ({
                        ...prevData,
                        userLimit: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div style={{ gridArea: 'limitClientCouponUse' }}>
                  <Checkbox
                    label="Limitar utilização do cupom"
                    onChange={changeClienteCouponUse}
                  />
                  <Input
                    placeholder="Digite o limite máx"
                    disabled={limitClientCouponUse}
                    onChange={(e) => {
                      e.persist();
                      setData((prevData) => ({
                        ...prevData,
                        limit_amount: e.target.value,
                      }));
                    }}
                  />
                </div>
              </InputArea>

              <Row style={{ marginTop: 10 }}>
                <Col style={{ paddingLeft: 10 }}>
                  <Switch
                    label="Cupom publico"
                    name="is_public"
                    checked={isPublic}
                    onChange={changeIsPublic}
                  />
                </Col>
              </Row>

              <Row>
                <Col style={{ paddingLeft: 10 }}>
                  <Switch
                    label="Ativo"
                    name="active"
                    checked={active}
                    onChange={changeActive}
                  />
                </Col>
              </Row>
              <div style={{ float: 'right', display: 'flex', gap: 8 }}>
                <Button
                  customColor="#C8131B"
                  children="Cancelar"
                  onClick={toggleAddTicketModal}
                />
                <Button customColor="#2EC9B7" children="Salvar" type="submit" />
              </div>
            </StyledForm>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  );
}

export default NewTicket;
