import React, { useCallback, useEffect, useState } from 'react';
import {
  CarouselControl,
  CarouselItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  TabPane,
} from 'reactstrap';
import { FiX } from 'react-icons/fi';
import popupifood from '~/assets/img/popupifood.png';

import YouTube from 'react-youtube';
import { useOrder } from '~/context/orderContext';
import CardOrdersAndamento from '~/components/Orders/Cards/CardOrdersAndamento';
import { ReactComponent as LiveOn } from '~/assets/img/live_on.svg';
import { ReactComponent as LiveOff } from '~/assets/img/live_off.svg';
import CardOrdersNew from '~/components/Orders/Cards/CardOrdersNew';
import CardOrdersCanceled from '~/components/Orders/Cards/CardOrdersCanceled';
import CardOrdersReady from '~/components/Orders/Cards/CardOrdersReady';
import CardOrdersFinishedDelivery from '~/components/Orders/Cards/CardOrderFinishedDelivery';
import CardOrdersDelivery from '~/components/Orders/Cards/CardOrdersDelivery';
import { useMotoboys } from '~/context/MotoboysContext';
import { useAuth } from '~/context/AuthContext';
import CardOrdersSchedulingNew from '~/components/Orders/Cards/CardOrdersSchedulingNew';
import CardOrdersSchedulingReady from '~/components/Orders/Cards/CardOrdersSchedulingReady';
import CardOrdersSchedulingAndamento from '~/components/Orders/Cards/CardOrdersSchedulingAndamento';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Button, Hyperlink, Tabs } from 'ui-kit-takeat';
import { Button as ButtonKit } from 'takeat-design-system-ui-kit';
import { FaCircle, FaCopy } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdUpdate } from 'react-icons/md';
import slide2 from '~/assets/img/bannerLive1.png';
import slide1 from '~/assets/img/bannerLive2.png';
import { FaMagnifyingGlass, FaX, FaGear } from 'react-icons/fa6';
import { getDay, getHours } from 'date-fns';
import { ReactComponent as Star } from '~/assets/img/star.svg';
import bannerDecret from '~/assets/img/decreto.png';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
import api2 from '~/services/api2';
import BannerRede from '~/assets/img/banner_rede.png';

import api from '~/services/api';
import {
  Wrapper,
  Header,
  LugarPedidos,
  DeliveryLink,
  LinkAndVideoDiv,
  LiveTextContent,
  LiveButton,
  VideoContainer,
  SquareButton,
  SearchInput,
  Overlay,
  ModalIfood,
  CarouselStyled,
  CarouselControlStyled,
  CarouselIndicatorsStyled,
} from './styles';

function OrdersPage({
  handleItemFinished,
  handleCancelItem,
  handleItemReady,
  handlePrintItem,
  setItemIfoodId,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const {
    pendingBaskets,
    acceptedBaskets,
    finishedBaskets,
    canceledBaskets,
    readyBaskets,
    ongoingBaskets,
    deliveredBaskets,
    loading,
    getAllOrders,
  } = useOrder();
  const {
    user,
    modalLiveBannerNew,
    setModalLiveBannerNew,
    modalBannerDecret,
    setModalBannerDecret,
    paymentMethodsState,
    popupIfood,
    setPopupIfood,
    modalBannerMarkup,
    setModalBannerMarkup,
    modalBannerPosRede,
    setModalBannerPosRede,
    combinedGroup,
  } = useAuth();
  const history = useHistory();
  const secondPath = history.location.pathname.split('/')[2];
  const [isModalLiveBannerOpened, setIsModalLiveBannerOpened] = useState(
    modalLiveBannerNew.toString() !== 'false'
  );
  const [player, setPlayer] = useState(null);
  const [clickHere, setClickHere] = useState(false);
  const [hasOrder, setHasOrder] = useState(true);
  const [filter, setFilter] = useState('');
  const [visiblefilter, setVisibleFilter] = useState('');
  const [pendingBasketsCount, setPendingBasketsCount] = useState(0);
  const [acceptedBasketsCount, setAcceptedBasketsCount] = useState(0);
  const [readyBasketsCount, setReadyBasketsCount] = useState(0);
  const [pendingBasketSchedulingCount, setPendingBasketSchedulingCount] =
    useState(0);
  const [acceptedBasketSchedulingCount, setAcceptedBasketSchedulingCount] =
    useState(0);
  const [readyBasketSchedulingCount, setReadyBasketSchedulingCount] =
    useState(0);
  const [canceledBasketsCount, setCanceledBasketsCount] = useState(0);
  const [isModalPopupIfoodOpened, setIsModalPopupIfoodOpened] = useState(
    popupIfood.toString() !== 'false'
  );

  useEffect(() => {
    const count_orders_new = pendingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    ).length; // cards new order e esse

    const count_orders_accepted = acceptedBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    ).length; // accepted

    const count_orders_ready = readyBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    ).length;

    const count_orders_scheduling_new = pendingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    ).length;

    const count_orders_scheduling_accepted = acceptedBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    ).length;

    const count_orders_scheduling_ready = readyBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    ).length;

    const count_orders_canceled = canceledBaskets.filter(
      (order) => order.table.table_type === 'delivery'
    ).length;

    setPendingBasketsCount(count_orders_new);
    setAcceptedBasketsCount(count_orders_accepted);
    setReadyBasketsCount(count_orders_ready);

    setPendingBasketSchedulingCount(count_orders_scheduling_new);
    setAcceptedBasketSchedulingCount(count_orders_scheduling_accepted);
    setReadyBasketSchedulingCount(count_orders_scheduling_ready);

    setCanceledBasketsCount(count_orders_canceled);

    if (
      count_orders_new === 0 &&
      count_orders_accepted === 0 &&
      count_orders_ready === 0
    ) {
      setHasOrder(false);
    } else {
      setHasOrder(true);
    }
  }, [acceptedBaskets, readyBaskets, pendingBaskets]);

  function toggleModalLiveBanner() {
    setIsModalLiveBannerOpened(!isModalLiveBannerOpened);
  }
  const { getMotoboys } = useMotoboys();

  async function handleUpdate() {
    getAllOrders();
  }

  useEffect(() => {
    if (user.delivery_info.is_delivery_allowed) {
      getMotoboys();
    }
  }, [getMotoboys]);

  // live conditional
  const [isLiveOn, setIsLiveOn] = useState(() => {
    const now = new Date();
    const day = getDay(now);
    const hour = getHours(now);

    // Verifica se é dia útil (segunda a sexta)
    const isWeekday = day >= 1 && day <= 5;

    // Verifica se está no horário comercial
    const isMorning = hour >= 9 && hour < 12;
    const isAfternoon = hour >= 14 && hour < 18;

    return isWeekday && (isMorning || isAfternoon);
  });

  const [isLiveTextContextOpened, setIsLiveTextContextOpened] = useState(false);

  function toggleLiveTextContext() {
    setIsLiveTextContextOpened(!isLiveTextContextOpened);
  }

  function handleGoToLive() {
    window.open('https://meet.google.com/wqr-yurp-jed');
  }

  // banner decret
  function acceptsDdds(phone) {
    if (phone) {
      const ddds = ['65', '66'];

      const ddd = phone.slice(1, 3);

      if (ddds.includes(ddd)) {
        return true;
      }
      return false;
    }
  }

  const [isModalBannerDecretOpened, setIsModalBannerDecretOpened] = useState(
    modalBannerDecret.toString() !== 'false' &&
    (user.adress?.state?.toLowerCase() === 'rs' || acceptsDdds(user?.phone))
  );

  function toggleModalBannerDecret() {
    setIsModalBannerDecretOpened(!isModalBannerDecretOpened);
  }

  const handleSearchCommand = (arrayToFilter, filterString) => {
    const filteredArray = arrayToFilter.filter((basket) => {
      const searchString = filterString.toLowerCase();
      for (const key in basket) {
        if (basket.hasOwnProperty(key)) {
          if (
            key === 'attendance_password' &&
            basket[key] !== null &&
            basket[key].toString().startsWith(searchString)
          ) {
            return true;
          }
          if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('name') &&
            basket[key].name?.toLowerCase().startsWith(searchString)
          ) {
            return true;
          }
          if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('phone') &&
            basket[key].phone?.includes(' ') &&
            basket[key].phone?.split(' ')[1].startsWith(searchString)
          ) {
            return true;
          }
          if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('phone') &&
            basket[key].phone?.includes('-') &&
            basket[key].phone?.split('-')[1].startsWith(searchString)
          ) {
            return true;
          }
          if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('phone') &&
            basket[key].phone
              ?.replace(/[\s\-\(\)]/g, '')
              .startsWith(searchString)
          ) {
            return true;
          }
          if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('ifood_phone') &&
            basket[key].ifood_phone?.replace(/\s/g, '').startsWith(searchString)
          ) {
            return true;
          }
          if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('ifood_phone') &&
            basket[key].ifood_phone?.startsWith(searchString)
          ) {
            return true;
          }
        }
      }
      return false;
    });
    return filteredArray;
  };

  const [pendingBasketsFiltered, setPendingBasketsFiltered] = useState([]);
  const [acceptedBasketsFiltered, setAcceptedBasketsFiltered] = useState([]);
  const [readyBasketsFiltered, setReadyBasketsFiltered] = useState([]);
  const [
    pendingBasketsSchedulingFiltered,
    setPendingBasketsSchedulingFiltered,
  ] = useState([]);
  const [
    acceptedBasketsSchedulingFiltered,
    setAcceptedBasketsSchedulingFiltered,
  ] = useState([]);
  const [readyBasketsSchedulingFiltered, setReadyBasketsSchedulingFiltered] =
    useState([]);
  const [canceledBasketsFiltered, setCanceledBasketsFiltered] = useState([]);

  const [ongoingBasketsFiltered, setOngoingBasketsFiltered] = useState([]);
  const [deliveredBasketsFiltered, setDeliveredBasketsFiltered] = useState([]);

  const [finishedBasketsFiltered, setFinishedBasketsFiltered] = useState([]);
  const [finishedBasketsSession, setFinishedBasketsSession] = useState([]);
  const [finishedBasketsSessionCounter, setFinishedBasketsSessionCounter] =
    useState(0);

  useEffect(() => {
    const finished_baskets_counter = finishedBasketsSession.length;

    setFinishedBasketsSessionCounter(finished_baskets_counter);
  }, [finishedBasketsSession]);

  useEffect(() => {
    const deliveryReadyBaskets = readyBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    );
    const deliveryPendingBaskets = pendingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    );
    const deliveryAcceptedBaskets = acceptedBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    );
    const deliveryOngoingBaskets = ongoingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    );
    const deliveryDeliveredBaskets = deliveredBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    );

    const deliveryReadySchedulingBaskets = readyBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    );
    const deliveryPendingSchedulingBaskets = pendingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    );
    const deliveryAcceptedSchedulingBaskets = acceptedBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    );

    const deliveryCanceledBaskets = canceledBaskets.filter(
      (order) => order.table.table_type === 'delivery'
    );

    const deliveryReadyBasketFiltered = handleSearchCommand(
      deliveryReadyBaskets,
      filter
    );
    const deliveryPendingBasketFiltered = handleSearchCommand(
      deliveryPendingBaskets,
      filter
    );
    const deliveryAcceptedBasketFiltered = handleSearchCommand(
      deliveryAcceptedBaskets,
      filter
    );
    const deliveryOngoingBasketFiltered = handleSearchCommand(
      deliveryOngoingBaskets,
      filter
    );
    const deliveryDeliveredBasketFiltered = handleSearchCommand(
      deliveryDeliveredBaskets,
      filter
    );

    const deliveryReadySchedulingBasketFiltered = handleSearchCommand(
      deliveryReadySchedulingBaskets,
      filter
    );
    const deliveryPendingSchedulingBasketFiltered = handleSearchCommand(
      deliveryPendingSchedulingBaskets,
      filter
    );
    const deliveryAcceptedSchedulingBasketFiltered = handleSearchCommand(
      deliveryAcceptedSchedulingBaskets,
      filter
    );

    const finishedBasketsFiltered = handleSearchCommand(
      finishedBasketsSession,
      filter
    );

    const deliveryCanceledBasketsFiltered = handleSearchCommand(
      deliveryCanceledBaskets,
      filter
    );

    setPendingBasketsFiltered(deliveryPendingBasketFiltered);
    setAcceptedBasketsFiltered(deliveryAcceptedBasketFiltered);
    setReadyBasketsFiltered(deliveryReadyBasketFiltered);
    setOngoingBasketsFiltered(deliveryOngoingBasketFiltered);
    setDeliveredBasketsFiltered(deliveryDeliveredBasketFiltered);

    setPendingBasketsSchedulingFiltered(
      deliveryPendingSchedulingBasketFiltered
    );
    setAcceptedBasketsSchedulingFiltered(
      deliveryAcceptedSchedulingBasketFiltered
    );
    setReadyBasketsSchedulingFiltered(deliveryReadySchedulingBasketFiltered);

    setFinishedBasketsFiltered(finishedBasketsFiltered);

    setCanceledBasketsFiltered(deliveryCanceledBasketsFiltered);
  }, [
    readyBaskets,
    pendingBaskets,
    acceptedBaskets,
    finishedBasketsSession,
    filter,
    activeTab,
    ongoingBaskets,
    deliveredBaskets,
    canceledBaskets,
  ]);

  useEffect(() => {
    setFilter('');
  }, [activeTab]);

  function formatFilter(filtro) {
    const formatedFilter = filtro.replace(/^0+/, '');
    setFilter(formatedFilter);
    setVisibleFilter(filtro);
  }

  function togglePopupIfood() {
    setIsModalPopupIfoodOpened(!isModalPopupIfoodOpened);
    setPopupIfood(!popupIfood);
  }

  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [favoritePaymentMethods, setFavoritePaymentMethods] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const getPaymentMethods = useCallback(async () => {
    const methods = paymentMethodsState;

    methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

    setAllPaymentMethods([...methods]);

    const favoriteMethods = methods.slice(0, 8);

    setFavoritePaymentMethods(favoriteMethods);

    setPaymentMethods(methods.slice(8));
  }, [paymentMethodsState]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  // modal carousel

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const items = [
    {
      src: slide1,
    },
    {
      src: slide2,
    },
  ];

  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // Carousel Item Data
  const carouselItemData = items.map((item, index) => {
    return (
      <CarouselItem
        key={index}
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
        active={index === activeIndex}
      >
        <img src={item.src} alt={item.altText} />
      </CarouselItem>
    );
  });

  // modal markup

  const [isModalMarkupOpened, setIsModalMarkupOpened] = useState(
    modalBannerMarkup.toString() !== 'false' && combinedGroup.includes(user.id)
  );

  function toggleModalMarkup() {
    setIsModalMarkupOpened(!isModalMarkupOpened);
  }

  async function handleSubmitNpsMarkup(status) {
    try {
      await api.post('/restaurants/research-answer', {
        research_id: 200,
        value: status,
        comment: null,
      });
      toast.success('Resposta enviada.');
      if (status === 1) {
        history.push('/register/markup');
      }
      toggleModalMarkup();
      setModalBannerMarkup('false');
    } catch (error) {
      toast.error('Erro ao enviar resposta.');
      console.log(error);
    }
  }

  // modal maquininha rede
  const [isModalBannerPosRedeOpened, setIsModalBannerPosRedeOpened] = useState(
    modalBannerPosRede.toString() !== 'false'
  );

  function toggleModalBannerPosRede() {
    setIsModalBannerPosRedeOpened(!isModalBannerPosRedeOpened);
  }

  function handleNotifyWhatsapp() {
    const text = 'Desejo saber sobre integração com maquininhas de cartão';
    const userPhone = '27997420879';

    setIsModalBannerPosRedeOpened(false);
    setModalBannerPosRede('false');

    window.open(
      `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`,
      'wppTakeat'
    );
  }

  return (
    <Wrapper isOrders={secondPath === 'orders'}>
      {user.only_delivery && (
        <Modal
          isOpen={isModalBannerPosRedeOpened}
          toggle={toggleModalBannerPosRede}
          style={{ padding: 0, position: 'relative' }}
        >
          <img
            src={BannerRede}
            alt="Banner mostrando as maquininhas integradas"
          />
          <ModalFooter
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: 10,
            }}
          >
            <ButtonKit
              variant="outlined"
              onClick={() => {
                setIsModalBannerPosRedeOpened(false);
                setModalBannerPosRede('false');
              }}
            >
              Não, obrigado
            </ButtonKit>

            <ButtonKit
              onClick={() => {
                handleNotifyWhatsapp();
              }}
            >
              Desejo saber mais
            </ButtonKit>
          </ModalFooter>
        </Modal>
      )}
      {user.only_delivery && (
        <>
          <Modal
            isOpen={isModalBannerDecretOpened}
            toggle={toggleModalBannerDecret}
            style={{ padding: 0 }}
          >
            <VideoContainer>
              <img
                style={{ width: '100%' }}
                src={bannerDecret}
                alt="Banner mostrando ao usuário que a Takeat está de acordo com o decreto do Rio grrande do Sul"
              />
              <div style={{ position: 'absolute', left: 63, bottom: 85 }}>
                <YouTube
                  videoId="QfjiN2Tp-Rg"
                  onReady={(e) => setPlayer(e.target)}
                  onPlay={() => {
                    // toggleModalYoutube();
                    // setIsHidden(true);
                    setClickHere(false);
                  }}
                  opts={{
                    width: 380,
                    height: 200,
                    playerVars: { enablejsapi: 1, autoplay: 1 },
                  }}
                />
              </div>
            </VideoContainer>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                onClick={() => {
                  setModalBannerDecret(false);
                  setIsModalBannerDecretOpened(false);
                }}
                title="Não desejo ver novamente"
                inverted
              />

              <a
                href="https://api.whatsapp.com/send?phone=5527998431975"
                target="blank"
              >
                <Button type="button" buttonColor="#2ec9b7" title="Eu quero!" />
              </a>
            </div>
          </Modal>
        </>
      )}
      {user.only_delivery && (
        <Modal
          isOpen={isModalMarkupOpened}
          toggle={toggleModalMarkup}
          style={{ padding: 0, position: 'relative' }}
        >
          <ModalBody>
            <button
              style={{
                position: 'absolute',
                height: 0,
                background: 'none',
                border: 'none',
                padding: 0,
                right: 12,
                top: 12,
              }}
            >
              <IoMdClose
                size={20}
                color="black"
                onClick={() => toggleModalMarkup()}
                style={{ cursor: 'pointer' }}
              />
            </button>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Star />
            </div>

            <p
              style={{
                fontSize: 18,
                color: '#222',
                fontWeight: 500,
                marginTop: 10,
                textAlign: 'center',
              }}
            >
              Olá Parceiro, você foi selecionado para testar a nova ferramenta
              de
              <strong> precificação Takeat!</strong>
            </p>
          </ModalBody>
          <ModalFooter
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ButtonKit
              customColor="#FFB32F"
              style={{ width: '100%' }}
              onClick={() => {
                handleSubmitNpsMarkup(1);
              }}
            >
              Quero Participar
            </ButtonKit>

            <ButtonKit
              style={{ width: '100%', background: 'none', color: '#000' }}
              onClick={() => {
                handleSubmitNpsMarkup(0);
              }}
            >
              Não, obrigado
            </ButtonKit>
          </ModalFooter>
        </Modal>
      )}
      {isModalPopupIfoodOpened && user.has_ifood && (
        <Overlay
          onClick={() => {
            togglePopupIfood();
          }}
        >
          <ModalIfood>
            <button className="close-button">
              <IoMdClose
                size={20}
                color="black"
                onClick={() => togglePopupIfood()}
                style={{ cursor: 'pointer' }}
              />
            </button>
            <body>
              <div>
                <img src={popupifood} />
                <h1>Comunicado Importante!</h1>
                <p>
                  As vendas com pagamento na entrega, realizadas por meio da{' '}
                  <span style={{ color: '#C8131B' }}>Entrega Parceira</span>,
                  passam a seguir um novo fluxo de gerenciamento de caixa,
                  denominado <strong>Pagamentos</strong>{' '}
                  <strong>na Entrega iFood.</strong>
                </p>
              </div>

              <a
                href="https://doc.clickup.com/9007042153/d/h/8cdt3k9-4573/3d52a317ca52f28"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button onClick={() => togglePopupIfood()}>Saber mais</button>
              </a>
            </body>
          </ModalIfood>
        </Overlay>
      )}

      {user.only_delivery && (
        <Modal
          isOpen={isModalLiveBannerOpened}
          toggle={toggleModalLiveBanner}
          style={{ padding: 0 }}
        >
          <ModalHeader style={{ display: 'flex', justifyContent: 'end' }}>
            <FiX
              onClick={toggleModalLiveBanner}
              color="red"
              cursor="pointer"
              size={17}
              title="Fechar Modal"
            />
          </ModalHeader>
          <CarouselStyled
            activeIndex={activeIndex}
            next={nextButton}
            previous={previousButton}
            interval={20000}
          >
            {carouselItemData}

            <CarouselControlStyled
              direction="prev"
              directionText="Previous"
              onClickHandler={previousButton}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={nextButton}
            />
          </CarouselStyled>
          <CarouselIndicatorsStyled
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />

          <ModalFooter>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                onClick={() => {
                  setIsModalLiveBannerOpened(false);
                  setModalLiveBannerNew(false);
                }}
                title="Não desejo ver novamente"
                inverted
              />
              <a href="https://meet.google.com/wqr-yurp-jed" target="blank">
                <Button type="button" title="Acessar o plantão tira dúvidas!" />
              </a>
            </div>
          </ModalFooter>
        </Modal>
      )}

      <Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxHeight: 65,
          }}
        >
          <LinkAndVideoDiv>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 426,
              }}
            >
              <DeliveryLink>
                {`Link do seu delivery: `}
                <Hyperlink
                  fontSize={14}
                  onClick={() =>
                    window.open(
                      `https://pedido.takeat.app/${user?.nickname ? user?.nickname : user?.name
                      }`
                    )
                  }
                >
                  {`https://pedido.takeat.app/${user?.nickname ? user?.nickname : user?.name
                    }`}
                </Hyperlink>
                <Hyperlink
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `https://pedido.takeat.app/${user?.nickname ? user?.nickname : user?.name
                        }`
                      )
                      .then(() => toast.success('Link copiado com sucesso!'))
                  }
                >
                  <FaCopy />
                </Hyperlink>
              </DeliveryLink>
              {isLiveOn ? (
                <LiveOn
                  onClick={toggleLiveTextContext}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <LiveOff
                  onClick={toggleLiveTextContext}
                  style={{ cursor: 'pointer' }}
                />
              )}

              <div style={{ position: 'relative' }}>
                {isLiveOn && isLiveTextContextOpened ? (
                  <LiveTextContent>
                    <FaX
                      onClick={toggleLiveTextContext}
                      color="#f10d27"
                      cursor="pointer"
                      style={{ float: 'right' }}
                    />
                    <p>
                      Esclarecemos as suas dúvidas em tempo real. Junte-se ao
                      nosso plantão tira dúvidas.
                    </p>

                    <p style={{ fontSize: 14, marginTop: 10 }}>
                      {' '}
                      Programação: 9h às 12h e 14h às 18h de segunda à sexta.
                    </p>

                    <Button
                      title="Ir para o Plantão Tira Dúvidas"
                      containerStyles={{ width: '100%' }}
                      onClick={handleGoToLive}
                    />
                  </LiveTextContent>
                ) : (
                  isLiveTextContextOpened && (
                    <LiveTextContent>
                      <FaX
                        onClick={toggleLiveTextContext}
                        color="#f10d27"
                        cursor="pointer"
                        style={{ float: 'right' }}
                      />
                      <p>
                        O plantão tira dúvidas é um atendimento coletivo, onde
                        um de nossos especialistas fica de prontidão via Google
                        Meet, com o intuito de sanar suas dúvidas quanto ao
                        sistema.
                      </p>
                      <p>
                        Estamos disponíveis de 9h às 12h e 14h às 18h de segunda
                        à sexta. Traga suas dúvidas!
                      </p>
                    </LiveTextContent>
                  )
                )}
              </div>
            </div>
          </LinkAndVideoDiv>
          <VideoPlayer
            id={67}
            containerStyles={{ marginLeft: 0, maxHeight: 45 }}
          />
        </div>

        {activeTab === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <SquareButton color="#2EC9B7">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={handleUpdate}
              >
                <MdUpdate size={22} />
                <span>Atualizar</span>
              </div>
            </SquareButton>
            <SquareButton color="#6C6C6C">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={() => history.push('/delivery/config')}
              >
                <FaGear size={20} />
                <span>Config.</span>
              </div>
            </SquareButton>
            <SearchInput>
              <FaMagnifyingGlass color="#666" size={16} />
              <input
                type="text"
                placeholder="Buscar pedido"
                value={visiblefilter}
                onChange={(e) => {
                  formatFilter(e.target.value);
                }}
              />
              <FiX color="#CDCDCD" size={22} onClick={() => setFilter('')} />
            </SearchInput>
          </div>
        )}
        {activeTab === 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <SquareButton color="#2EC9B7">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={handleUpdate}
              >
                <MdUpdate size={22} />
                <span>Atualizar</span>
              </div>
            </SquareButton>
            <SquareButton color="#6C6C6C">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={() => history.push('/delivery/scheduling')}
              >
                <FaGear size={20} />
                <span>Config. Agend.</span>
              </div>
            </SquareButton>
            <SearchInput>
              <FaMagnifyingGlass color="#666" size={16} />
              <input
                type="text"
                placeholder="Buscar pedido"
                value={visiblefilter}
                onChange={(e) => formatFilter(e.target.value)}
              />
              <FiX color="#CDCDCD" size={22} onClick={() => setFilter('')} />
            </SearchInput>
          </div>
        )}

        {activeTab === 2 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <SquareButton color="#2EC9B7">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={handleUpdate}
              >
                <MdUpdate size={22} />
                <span>Atualizar</span>
              </div>
            </SquareButton>
            <SearchInput>
              <FaMagnifyingGlass color="#666" size={16} />
              <input
                type="text"
                placeholder="Buscar pedido"
                value={visiblefilter}
                onChange={(e) => formatFilter(e.target.value)}
              />
              <FiX color="#CDCDCD" size={22} onClick={() => setFilter('')} />
            </SearchInput>
          </div>
        )}

        {activeTab === 3 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <SquareButton color="#2EC9B7">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={handleUpdate}
              >
                <MdUpdate size={22} />
                <span>Atualizar</span>
              </div>
            </SquareButton>
            <SearchInput>
              <FaMagnifyingGlass color="#666" size={16} />
              <input
                type="text"
                placeholder="Buscar pedido"
                value={visiblefilter}
                onChange={(e) => formatFilter(e.target.value)}
              />
              <FiX color="#CDCDCD" size={22} onClick={() => setFilter('')} />
            </SearchInput>
          </div>
        )}
      </Header>

      <Tabs
        onChangeTab={(tab) => setActiveTab(tab)}
        selected={activeTab}
        tabs={[
          'Pedidos Novos e Em Andamento',
          'Pedidos Agendados',
          'Pedidos Entregues',
          'Pedidos Cancelados',
        ]}
        counter={[
          null,
          pendingBaskets.filter((order) => order.scheduled_to).length,
          null,
          null,
        ]}
        mediaQueries={[
          {
            maxWidth: 1150,
            styles: `font-size: 12px; padding: 5px 15px;`,
          },
        ]}
      />

      <TabContent activeTab={activeTab}>
        <TabPane tabId={0} style={{ height: '100%' }}>
          {user.use_foody_delivery ? (
            <LugarPedidos first>
              {(!user.delivery_info.auto_accept || pendingBasketsCount > 0) && (
                <CardOrdersNew
                  handlePrintItem={handlePrintItem}
                  handleCancelItem={handleCancelItem}
                  baskets={pendingBasketsFiltered}
                  loading={loading}
                  setItemIfoodId={setItemIfoodId}
                  hasOrderActive={hasOrder}
                  user={user}
                  handleUpdate={handleUpdate}
                  countOrders={pendingBasketsCount}
                />
              )}
              <CardOrdersAndamento
                baskets={acceptedBasketsFiltered}
                handleItemFinished={handleItemReady}
                handleCancelItem={handleCancelItem}
                loading={loading}
                handleItemReady={handleItemReady}
                handleUpdate={handleUpdate}
                hasOrderActive={hasOrder}
                countOrders={acceptedBasketsCount}
              />
              <CardOrdersDelivery
                baskets={readyBasketsFiltered}
                handleItemFinished={handleItemFinished}
                handleCancelItem={handleCancelItem}
                loading={loading}
                getAllOrders={getAllOrders}
                hasOrderActive={hasOrder}
                handleUpdate={handleUpdate}
                countOrders={readyBasketsFiltered.length}
                headerTitle="PRONTO"
                headerColor="#FF7D24"
                paymentMethods={paymentMethods}
                allPaymentMethods={allPaymentMethods}
                favoritePaymentMethods={favoritePaymentMethods}
                showFinishBills={false}
                hasOrderBy
                hasFilterBy={false}
                handleItemReady={handleItemReady}
              />
              <CardOrdersDelivery
                baskets={ongoingBasketsFiltered}
                handleItemFinished={handleItemFinished}
                handleCancelItem={handleCancelItem}
                loading={loading}
                getAllOrders={getAllOrders}
                hasOrderActive={hasOrder}
                handleUpdate={handleUpdate}
                countOrders={ongoingBasketsFiltered.length}
                headerTitle="EM ROTA"
                headerColor="#01AFFF"
                paymentMethods={paymentMethods}
                allPaymentMethods={allPaymentMethods}
                favoritePaymentMethods={favoritePaymentMethods}
                showFinishBills={false}
                hasOrderBy={false}
                hasFilterBy={false}
                handleItemReady={handleItemReady}
              />
              <CardOrdersDelivery
                baskets={deliveredBasketsFiltered}
                handleItemFinished={handleItemFinished}
                handleCancelItem={handleCancelItem}
                loading={loading}
                getAllOrders={getAllOrders}
                hasOrderActive={hasOrder}
                handleUpdate={handleUpdate}
                countOrders={deliveredBasketsFiltered.length}
                headerTitle="ENTREGUE"
                headerColor="#2EC9B7"
                paymentMethods={paymentMethods}
                allPaymentMethods={allPaymentMethods}
                favoritePaymentMethods={favoritePaymentMethods}
                showFinishBills
                hasOrderBy={false}
                hasFilterBy
                handleItemReady={handleItemReady}
              />
            </LugarPedidos>
          ) : (
            <LugarPedidos first>
              <CardOrdersNew
                handlePrintItem={handlePrintItem}
                handleCancelItem={handleCancelItem}
                baskets={pendingBasketsFiltered}
                loading={loading}
                setItemIfoodId={setItemIfoodId}
                hasOrderActive={hasOrder}
                user={user}
                handleUpdate={handleUpdate}
                countOrders={pendingBasketsCount}
              />
              <CardOrdersAndamento
                baskets={acceptedBasketsFiltered}
                handleItemFinished={handleItemReady}
                handleCancelItem={handleCancelItem}
                loading={loading}
                handleItemReady={handleItemReady}
                handleUpdate={handleUpdate}
                hasOrderActive={hasOrder}
                countOrders={acceptedBasketsCount}
              />
              <CardOrdersReady
                baskets={readyBasketsFiltered}
                handleItemFinished={handleItemFinished}
                handleCancelItem={handleCancelItem}
                loading={loading}
                getAllOrders={getAllOrders}
                hasOrderActive={hasOrder}
                handleUpdate={handleUpdate}
                countOrders={readyBasketsCount}
                paymentMethods={paymentMethods}
                allPaymentMethods={allPaymentMethods}
                favoritePaymentMethods={favoritePaymentMethods}
              />
            </LugarPedidos>
          )}
        </TabPane>

        <TabPane tabId={1} style={{ height: '100%' }}>
          <LugarPedidos>
            <CardOrdersSchedulingNew
              handlePrintItem={handlePrintItem}
              handleCancelItem={handleCancelItem}
              baskets={pendingBasketsSchedulingFiltered}
              loading={loading}
              setItemIfoodId={setItemIfoodId}
              handleUpdate={handleUpdate}
              countOrders={pendingBasketSchedulingCount}
            />
            <CardOrdersSchedulingAndamento
              baskets={acceptedBasketsSchedulingFiltered}
              handleItemFinished={handleItemReady}
              handleCancelItem={handleCancelItem}
              loading={loading}
              handleItemReady={handleItemReady}
              handleUpdate={handleUpdate}
              countOrders={acceptedBasketSchedulingCount}
            />
            <CardOrdersSchedulingReady
              baskets={readyBasketsSchedulingFiltered}
              handleItemFinished={handleItemFinished}
              handleCancelItem={handleCancelItem}
              loading={loading}
              getAllOrders={getAllOrders}
              handleUpdate={handleUpdate}
              countOrders={readyBasketSchedulingCount}
              paymentMethods={paymentMethods}
              allPaymentMethods={allPaymentMethods}
              favoritePaymentMethods={favoritePaymentMethods}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId={2} style={{ height: '100%' }}>
          <LugarPedidos>
            <CardOrdersFinishedDelivery
              baskets={finishedBaskets}
              loading={loading}
              handleCancelItem={handleCancelItem}
              getAllOrders={getAllOrders}
              countOrders={readyBasketSchedulingCount}
              setFinishedBasketsSession={setFinishedBasketsSession}
              finishedBasketsFiltered={finishedBasketsFiltered}
              finishedBasketsSessionCounter={finishedBasketsSessionCounter}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId={3} style={{ height: '100%' }}>
          <LugarPedidos>
            <CardOrdersCanceled loading={loading} activeTab={activeTab} />
          </LugarPedidos>
        </TabPane>
      </TabContent>
    </Wrapper>
  );
}

export default OrdersPage;
