import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { FaMapMarkedAlt } from 'react-icons/fa';
import { DropdownMenu } from 'reactstrap';

import Switch from 'react-switch';

import {
  Container,
  Header,
  ConfigButton,
  MenuItem,
  ConfigDropdownToggle,
} from './styles';

import Neighborhoods from './Neighborhoods';
import MapDistances from './MapDistances';
import MapEditor from './MapEditor';

import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';

import api2 from '~/services/api2';
import { useAuth } from '~/context/AuthContext';
import { PageTitle } from '~/components/PageTitle';

function DeliveryAddress() {
  const { user, getProfileData } = useAuth();

  const [permission, setPermission] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const validateUser = useCallback(async () => {
    try {
      const response = await api2.get(
        '/restaurants/users/role-permission/DeliveryAddresses'
      );

      const { can_read } = response.data.DeliveryAddresses;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const [deliveryTypeSwitch, setDeliveryTypeSwitch] = useState(() => {
    const addresses_allowed = user.delivery_info.allow_delivery_addresses;

    let use_address = false;
    let use_distance = false;
    let use_map = false;

    if (user.delivery_info.delimit_by_area) {
      use_map = true;
    } else if (user.delivery_info.is_delivery_by_distance) {
      use_distance = true;
    } else {
      use_address = true;
    }

    return {
      use_address,
      use_distance,
      use_map,
      addresses_allowed,
    };
  });

  async function deliveryTypeChange(value, event, id) {
    const data = { ...deliveryTypeSwitch };

    switch (id) {
      case 'address':
        data.use_distance = false;
        data.use_address = true;
        data.use_map = false;
        break;
      case 'distance':
        data.use_distance = true;
        data.use_address = false;
        data.use_map = false;
        break;
      case 'map':
        data.use_distance = false;
        data.use_address = false;
        data.use_map = true;
        break;
      default:
        break;
    }

    try {
      await api2.put('/restaurants/delivery-info', {
        is_delivery_by_distance: data.use_distance,
        delimit_by_area: data.use_map,
      });

      await getProfileData();
      toast.success('Atualizado!');

      setDeliveryTypeSwitch(data);
    } catch (e) {
      toast.success('Erro ao atualizar!');
    }
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PageTitle>
              Delivery
              {deliveryTypeSwitch.use_address && ' > Endereços'}
              {deliveryTypeSwitch.use_distance && ' > Taxa por alcance'}
              {deliveryTypeSwitch.use_map && ' > Mapa'}
            </PageTitle>

            <div style={{ marginBottom: '10px' }}>
              <VideoPlayer id={68} />
            </div>
          </div>
          <p>Configure a modalidade de delivery do seu estabelecimento!</p>
        </div>

        <ConfigButton
          disabled={false}
          isOpen={tooltipOpen}
          toggle={() => setTooltipOpen(!tooltipOpen)}
        >
          <ConfigDropdownToggle caret tag="a">
            <FaMapMarkedAlt size={25} />
            <div
              style={{ display: 'flex', alignSelf: 'end', marginLeft: '5px' }}
            >
              Configurar Delivery
            </div>
          </ConfigDropdownToggle>

          <DropdownMenu
            style={{
              borderRadius: '6px',
              padding: '15px 10px',
              width: '250px',
            }}
          >
            <div style={{ padding: '6px' }}>
              Escolha como deseja limitar a taxa de entrega:
            </div>

            {deliveryTypeSwitch.addresses_allowed && (
              <MenuItem>
                <Switch
                  id="address"
                  draggable
                  onChange={deliveryTypeChange}
                  checked={deliveryTypeSwitch.use_address}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={18}
                  width={30}
                  handleDiameter={12}
                  activeBoxShadow="0 0 2px 3px #111"
                  boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  onColor="#0CCAB4"
                  offColor="#666666"
                />

                <div style={{ margin: '0 5px' }}> Limitar por endereço </div>
              </MenuItem>
            )}

            <MenuItem>
              <Switch
                id="distance"
                draggable
                onChange={deliveryTypeChange}
                checked={deliveryTypeSwitch.use_distance}
                checkedIcon={false}
                uncheckedIcon={false}
                height={18}
                width={30}
                handleDiameter={12}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                onColor="#0CCAB4"
                offColor="#666666"
              />

              <div style={{ margin: '0 5px' }}> Limitar por distância </div>
            </MenuItem>

            <MenuItem>
              <Switch
                id="map"
                draggable
                onChange={deliveryTypeChange}
                checked={deliveryTypeSwitch.use_map}
                checkedIcon={false}
                uncheckedIcon={false}
                height={18}
                width={30}
                handleDiameter={12}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                onColor="#0CCAB4"
                offColor="#666666"
              />

              <div style={{ margin: '0 5px' }}> Limitar no mapa </div>
            </MenuItem>
          </DropdownMenu>
        </ConfigButton>
      </Header>

      {deliveryTypeSwitch.use_address && <Neighborhoods />}
      {deliveryTypeSwitch.use_distance && <MapDistances />}
      {deliveryTypeSwitch.use_map && <MapEditor />}
    </Container>
  );
}

export default DeliveryAddress;
