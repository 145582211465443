import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { css } from 'styled-components';
import Select from '~/components/Form/SelectInput';
import { Tooltip } from 'reactstrap';


export const CustomTooltip = styled(Tooltip)`
  & .tooltip-inner {
    background-color: #222222 !important; 
    color: white !important; 
  }

  & .tooltip-arrow::before {
    border-bottom-color: #222222 !important; 
  }
`;

export const Container = styled.div`
  padding: ${(props) => (props.page !== '/operation' ? '0 20px 20px' : '0px')};
  height: calc(100vh - 136px);
  overflow-y: auto;
`;

export const LinkStyled = styled(Link)`
  color: #333;
  font-weight: bold;

  &:hover {
    color: #333;    
  }
`

export const SelectStyled = styled(Select)`
  margin-top: -20px;
`;
export const ScrollArea = styled.div`
  overflow-y: scroll;
  max-height: 70vh;

  @media (max-width: 1281px) {
    max-height: 50vh;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    max-height: 60vh;
  }
`;
export const Card = styled.div`
  width: 100%;

  padding: ${(props) =>
    props.page !== '/operation' ? '15px' : '15px 15px 130px 0'};
  border-radius: 7px;
  background-color: #fff;
  margin-top: 16px;
  overflow-x: hidden;
`;

export const ButtonArea = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.canAccessAllCashiers ? '16px' : '0px')};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: ${(props) => (props.canAccessAllCashiers ? '48px' : '36px')};
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;

  height: 40%;
  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const TotalArea = styled.div`
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 50px;
  width: calc(50% - 5px);

  @media (min-width: 1000px) and (max-width: 1441px) {
    strong {
      font-size: ${(props) => (props.page !== '/operation' ? '14px' : '12px')};
    }

    span {
      font-size: ${(props) => (props.page !== '/operation' ? '14px' : '12px')};
    }
  }
`;
export const Button = styled.button`
  margin-right: 8px;
  border: none;
  padding: 8px;
  border-radius: 7px;
  color: #fff;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }

  ${(props) =>
    props.operationType === 'deposit' &&
    css`
      background-color: #2ec9b7;
    `}

  ${(props) =>
    props.operationType === 'withdrawal' &&
    css`
      background-color: #ff2c3a;
    `}

    ${(props) =>
    props.operationType === 'report' &&
    css`
      background-color: #33333370;
    `}

      &:hover {
    filter: opacity(0.9);
  }
`;

export const DisplayNoneImprimir = styled.div`
  display: none;
`;

export const OpenBoxButton = styled.button`
  border: none;
  padding: 8px;
  border-radius: 7px;
  color: #fff;
  background-color: #2ec9b7;
  margin-right: 8px;
  font-size: 14px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const StyledTable = styled.table`
  width: 32%;

  margin-bottom: 30px;
  border-collapse: separate;
  border-spacing: 0 20px;
  tr {
  }
  tbody {
  }

  ${(props) =>
    props.operation &&
    css`
      width: 100%;
    `}

  @media (max-width: 1601px) {
    width: 70%;

    ${(props) =>
    props.operation &&
    css`
        width: 100%;
      `}
  }
`;

export const TotalValueArea = styled.div`
  border-top: 1px solid #33333320;
  padding-top: 20px;
`;

export const Deposits = styled.div`
  display: flex;
  margin: 8px;

  p {
    margin-right: 16px;
  }
`;
