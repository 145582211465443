import { Modal, Tooltip } from 'reactstrap';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 60px);
  padding: 20px;
  overflow-y: scroll;
  padding-bottom: 100px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.div`
  display: flex;
  padding-right: 68px;

  @media (max-width: 1029px) {
    padding-right: 55px;
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 28px;
  font-weight: 700;
  color: #222;
  font-size: 14px;
  margin-left: 8px;

  @media (max-width: 1025px) {
    width: 104px;
    margin-left: 0px;
  }
`;

export const HeaderItemOptmize = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 28px;
  font-weight: 700;
  color: #222;
  font-size: 14px;
  margin-left: 8px;

  @media (max-width: 1445px) {
    width: ${(props) =>
    props.onlyDelivery
      ? '118px'
      : !props.hasDelivery || !props.onlyDelivery
        ? '86px'
        : '87px'};
    margin-left: ${(props) => (props.onlyDelivery ? '8px' : '0px')};
    font-size: 12px;
  }

  ${(props) =>
    props.browserZoomLevel > 110 &&
    !props.onlyDelivery &&
    css`
      width: 80px;
      font-size: 12px;
    `}
`;

export const HeaderItemTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;

  @media (max-width: 1445px) {
    width: 64px;
  }
`;

export const ActionArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const SearchArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  margin-top: 10px;
  border-radius: 8px;
  background: #fff;
  padding: 12px;
`;

export const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px; /* Exemplo: Personaliza o conteúdo */
    width: 110%;
  }
`;

export const NotifyModal = styled(Modal)`
  .modal-content {
    border-radius: 12px; /* Exemplo: Personaliza o conteúdo */
    width: 339px;
    height: 156px;
  }
`;

export const InputsArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const CategoryArea = styled.div`
  border-radius: 8px;
  width: 100%;
  border: 1px solid #ededed;
  margin-bottom: 8px;
`;

export const CategoryHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ededed;
  height: 40px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;

  @media (max-width: 1025px) {
    padding-right: 8px;
  }
`;

export const CategoryBody = styled.div`
  padding: 8px;
  padding-bottom: 0px;
`;

// export const HeaderItem = styled.span`
//   margin-left: 8px;
//   font-weight: 700;
//   color: #222;
//   font-size: 14px;

//   ${(props) =>
//     props.type === 'ideal' &&
//     css`
//       margin-left: 32px;
//     `}

//   ${(props) =>
//     props.type === 'atual' &&
//     css`
//       margin-left: 46px;
//     `}

//   ${(props) =>
//     props.type === 'cmv' &&
//     css`
//       margin-left: 60px;
//     `}

//     @media (max-width: 1025px) {
//     ${(props) =>
//     props.type === 'markup' &&
//     css`
//         margin-left: -8px;
//       `}

//     ${(props) =>
//     props.type === 'ideal' &&
//     css`
//         margin-left: 8px;
//       `}

//     ${(props) =>
//     props.type === 'atual' &&
//     css`
//         margin-left: 8px;
//       `}

//   ${(props) =>
//     props.type === 'cmv' &&
//     css`
//         margin-left: 24px;
//       `}
//   }
// `;

export const CustomTooltip = styled(Tooltip)`
  & .tooltip-inner {
    background-color: #222222 !important;
    color: #fff !important;
    width: 341px !important;
    max-width: none !important; /* Remove limite do Bootstrap */
  }

  & .tooltip-arrow::before {
    border-bottom-color: #222222 !important;
  }
`;

export const TableColorsStyled = styled.table`
  width: 100%;
  border-spacing: 2px 2px; /* Espaço entre linhas */
  border-collapse: separate; /* Permite usar border-spacing */
  border-radius: 8px;
  overflow: hidden; /* Garante que os cantos arredondados sejam visíveis */
  color: #000;
  margin-top: 8px;

  tbody {
    tr {
      td {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        width: 141px;
        height: 32px;
      }
    }
  }
`;
// #BCE3DE
// #FFEBC8
// #E5A1A4
