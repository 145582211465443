import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Form } from '@unform/web';

import { Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import { Button } from 'ui-kit-takeat';
import Select from '~/components/Form/SelectInput';
import { FiTrash } from 'react-icons/fi';

import { FaPen, FaTrash } from 'react-icons/fa';

import getValidationErrors from '~/services/getValidationErrors';
import * as Yup from 'yup';

import nfeInfo from '~/services/NFeInfo';
import InputCurrency from '~/components/Form/InputCurrency';
import { Container } from './styles';

export default function NfeAvulsaItem({
  item,
  itens,
  setItens,
  key,
  number,
  nextId,
  setNextId,
  decimalFormatter,
  percentFormatter,
  currencyFormatter,
  icms_s_t_devolucao,
  icms_origem_options,
  user,
  icms_st,
}) {
  const [itemOptions, setItemOptions] = useState([
    { label: 'Código Produto', value: 'codigo_produto' },
    { label: 'Descrição', value: 'descricao' },
    { label: 'CFOP', value: 'cfop' },
    { label: 'NCM', value: 'codigo_ncm' },
    { label: 'Unidade Comercial', value: 'unidade_comercial' },
    { label: 'Unidade Tributavel', value: 'unidade_tributavel' },
    { label: 'Quantidade Comercial', value: 'quantidade_comercial' },
    { label: 'Quantidade Tributavel', value: 'quantidade_tributavel' },
    { label: 'Valor Unitário Comercial', value: 'valor_unitario_comercial' },
    { label: 'Valor Unitario Tributavel', value: 'valor_unitario_tributavel' },
    { label: 'Valor Bruto', value: 'valor_bruto' },
    { label: 'PIS Situação Tributaria', value: 'pis_situacao_tributaria' },
    {
      label: 'Cofins Situação Tributaria',
      value: 'cofins_situacao_tributaria',
    },
    { label: 'ICMS Situação Tributaria', value: 'icms_situacao_tributaria' },
    { label: 'ICMS Origem', value: 'icms_origem' },
    { label: 'ICMS Valor', value: 'icms_valor' },
    { label: 'ICMS Aliquota', value: 'icms_aliquota' },
    { label: 'ICMS Base Calculo', value: 'icms_base_calculo' },
    {
      label: 'ICMS Modalidade Base Calculo',
      value: 'icms_modalidade_base_calculo',
    },
    { label: 'CEST', value: 'cest' },
  ]);

  const [thisItens, setThisItens] = useState([]);
  const [icmsST, setIcmsSt] = useState(() => {
    return nfeInfo.ICMS_ST.filter((icms) => icms === icms_st);
  });
  const [selectValue, setSelectValue] = useState({
    label: 'Código Produto',
    value: 'codigo_produto',
  });
  const [inputValue, setInputValue] = useState('');

  const formRefEdit = useRef(null);

  const [modalEdit, setModalEdit] = useState(false);
  function toggleEdit() {
    setModalEdit(!modalEdit);
  }

  const [modalDelete, setModalDelete] = useState(false);
  function toggleDelete() {
    setModalDelete(!modalDelete);
  }
  const dataItensEdit = {
    valor_bruto: item.valor_bruto || '',
    valor_frete: item.valor_frete || '',
    valor_desconto: item.valor_desconto || '',
    valor_unitario_comercial: item.valor_unitario_comercial || '',
    quantidade_comercial: item.quantidade_comercial || '',
    valor_unitario_tributavel: item.valor_unitario_tributavel || '',
    quantidade_tributavel: item.quantidade_tributavel || '',
    icms_base_calculo: item.icms_base_calculo || '',
    icms_aliquota: item.icms_aliquota || '',
    icms_valor: item.icms_valor || '',
    pis_aliquota_porcentual: item.pis_aliquota_porcentual || '',
    pis_base_calculo: item.pis_base_calculo || '',
    pis_valor: item.pis_valor || '',
    cofins_aliquota_porcentual: item.cofins_aliquota_porcentual || '',
    cofins_base_calculo: item.cofins_base_calculo || '',
    cofins_valor: item.cofins_valor || '',
    icms_base_calculo_retido_st: item.icms_base_calculo_retido_st || '',
    icms_aliquota_final: item.icms_aliquota_final || '',
    icms_valor_substituto: item.icms_valor_substituto || '',
    icms_valor_retido_st: item.icms_valor_retido_st || '',
    icms_st: icmsST.value || '',
  };

  const [icmsSituacaoTributaria, setIcmsSituacaoTributaria] = useState(
    item.icms_situacao_tributaria || null
  );
  const [icmsOrigem, setIcmsOrigem] = useState(item.icms_origem || null);
  const [icmsModalidadeBaseCalculo, setIcmsModalidadeBaseCalculo] = useState(
    item.icms_modalidade_base_calculo || null
  );
  const [pisSituacaoTributaria, setPisSituacaoTributaria] = useState(
    item.pis_situacao_tributaria || null
  );
  const [cofinsSituacaoTributaria, setCofinsSituacaoTributaria] = useState(
    item.cofins_situacao_tributaria || null
  );
  const [quantityData, setQuantityData] = useState({
    comercial_quantity: item.quantidade_comercial || 0.0,
    unity_value_comercial: item.valor_unitario_comercial * 100 || 0.0,
    comercial_unity: item.unidade_comercial || '',
    tributable_quantity: item.quantidade_tributavel || 0.0,
    tributable_unity_value: item.valor_unitario_tributavel * 100 || 0.0,
    tributy_unity: item.unidade_tributavel || '',
  });
  const [totalValue, setTotalValue] = useState(item.valor_bruto || 0.0);
  const [total_icms_base_Calculo, setTotal_ICMS_base_calculo] = useState(
    dataItensEdit.icms_base_calculo * 100 || 0.0
  );
  const wasAlreadyRequested = useRef(false);

  const [icmsValor, setIcmsValor] = useState(item.icms_valor || 0.0);

  useEffect(() => {
    const calculatedValue =
      Number(total_icms_base_Calculo) * Number(item?.icms_aliquota) || 0;
    setIcmsValor(calculatedValue);
  }, [total_icms_base_Calculo]);

  useEffect(() => {
    if (
      quantityData.comercial_quantity !== 0.0 &&
      quantityData.unity_value_comercial !== 0.0
    ) {
      const total =
        quantityData.comercial_quantity * quantityData.unity_value_comercial;
      setTotalValue(parseFloat(total.toFixed(0)));
      setTotal_ICMS_base_calculo(parseFloat(total.toFixed(0)));
    } else if (
      quantityData.tributable_quantity !== 0.0 &&
      quantityData.tributable_unity_value !== 0.0
    ) {
      const total =
        quantityData.tributable_quantity * quantityData.tributable_unity_value;
      setTotalValue(parseFloat(total.toFixed(0)));
      setTotal_ICMS_base_calculo(parseFloat(total.toFixed(0)));
    }
  }, [quantityData]);

  useEffect(() => {
    setTotal_ICMS_base_calculo(item.icms_base_calculo * 100 || 0.0);
  }, [wasAlreadyRequested, item]);

  const validateItemEdit = useCallback(async () => {
    try {
      if (formRefEdit.current) {
        formRefEdit.current.setErrors({});
      }

      const data = {
        numero_item: item.numero_item,
        codigo_produto: formRefEdit.current.getFieldValue('codigo_produto'),
        descricao: formRefEdit.current.getFieldValue('descricao'),
        codigo_ncm: formRefEdit.current.getFieldValue('codigo_ncm'),
        cfop: formRefEdit.current.getFieldValue('cfop'),
        unidade_comercial:
          formRefEdit.current.getFieldValue('unidade_comercial'),
        unidade_tributavel:
          formRefEdit.current.getFieldValue('unidade_tributavel'),
        cest: formRefEdit.current.getFieldValue('cest'),
        codigo_beneficio_fiscal: formRefEdit.current.getFieldValue(
          'codigo_beneficio_fiscal'
        ),
      };

      data.icms_situacao_tributaria = icmsSituacaoTributaria;
      data.icms_origem = icmsOrigem;
      data.icms_modalidade_base_calculo = icmsModalidadeBaseCalculo;
      data.pis_situacao_tributaria = pisSituacaoTributaria;
      data.cofins_situacao_tributaria = cofinsSituacaoTributaria;

      // o dataItensEdit sao os itens que usa o InputCurrency com formato
      // o que ta no "data" sao os outros valores que usam o Input
      data.valor_bruto = totalValue / 100; // dataItensEdit.valor_bruto || null;
      data.valor_frete = dataItensEdit.valor_frete || null;
      data.valor_desconto = dataItensEdit.valor_desconto || null;
      data.valor_unitario_comercial =
        quantityData.unity_value_comercial / 100 || null; // dataItensEdit.valor_unitario_comercial || null;
      data.quantidade_comercial = quantityData.comercial_quantity || null;
      data.valor_unitario_tributavel =
        quantityData.tributable_unity_value / 100 || null;
      data.quantidade_tributavel = quantityData.tributable_quantity || null;
      data.icms_base_calculo = total_icms_base_Calculo / 100; // dataItensEdit.icms_base_calculo || null;
      data.icms_aliquota = dataItensEdit.icms_aliquota || null;
      data.icms_valor = icmsValor / 100 || null;
      data.pis_aliquota_porcentual =
        dataItensEdit.pis_aliquota_porcentual || null;
      data.pis_base_calculo = dataItensEdit.pis_base_calculo || null;
      data.pis_valor = dataItensEdit.pis_valor || null;
      data.cofins_aliquota_porcentual =
        dataItensEdit.cofins_aliquota_porcentual || null;
      data.cofins_base_calculo = dataItensEdit.cofins_base_calculo || null;
      data.cofins_valor = dataItensEdit.cofins_valor || null;

      data.icms_base_calculo_retido_st =
        dataItensEdit.icms_base_calculo_retido_st || null;
      data.icms_aliquota_final = dataItensEdit.icms_aliquota_final || null;
      data.icms_valor_substituto = dataItensEdit.icms_valor_substituto || null;
      data.icms_valor_retido_st = dataItensEdit.icms_valor_retido_st || null;
      data.icms_st = dataItensEdit.icms_st || null;

      const schemaValidator = {
        descricao: Yup.string().required('Obrigatório'),
        codigo_ncm: Yup.string().required('Obrigatório'),
        cfop: Yup.string().required('Obrigatório'),
        codigo_produto: Yup.string().required('Obrigatório'),
        quantidade_comercial: Yup.string().required('Obrigatório'),
        quantidade_tributavel: Yup.string().required('Obrigatório'),
        icms_situacao_tributaria: Yup.string().required('Obrigatório'),
        valor_unitario_comercial: Yup.string().required('Obrigatório'),
        unidade_comercial: Yup.string().required('Obrigatório'),
        valor_unitario_tributavel: Yup.string().required('Obrigatório'),
        unidade_tributavel: Yup.string().required('Obrigatório'),
        valor_bruto: Yup.string().required('Obrigatório'),
        icms_origem: Yup.string().required('Obrigatório'),
        icms_situacao_tributaria: Yup.string().required('Obrigatório'),
        pis_situacao_tributaria: Yup.string().required('Obrigatório'),
        cofins_situacao_tributaria: Yup.string().required('Obrigatório'),
      };

      if (data.icms_situacao_tributaria === '00') {
        schemaValidator.icms_aliquota = Yup.string().required('Obrigatório');
        schemaValidator.icms_base_calculo =
          Yup.string().required('Obrigatório');
        schemaValidator.icms_modalidade_base_calculo =
          Yup.string().required('Obrigatório');
      }

      const schema = Yup.object().shape(schemaValidator);

      await schema.validate(data, {
        abortEarly: false,
      });
      const newItens = [
        ...itens.filter((it) => it.numero_item !== item.numero_item),
        data,
      ];

      setItens(newItens.sort((a, b) => a.numero_item - b.numero_item));
      localStorage.setItem(
        '@gddashboard:itensNotaAvulsa',
        JSON.stringify(newItens)
      );
      toggleEdit();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRefEdit.current) {
          formRefEdit.current.setErrors(errors);
        }
      }
    }
  }, [
    item,
    itens,
    setItens,
    setNextId,
    nextId,
    toggleEdit,
    dataItensEdit,
    icmsValor,
  ]);

  const deleteItem = useCallback(() => {
    const newItens = itens.filter((it) => it.numero_item !== item.numero_item);
    let i = 1;

    newItens.forEach((it) => {
      it.numero_item = i;
      i++;
    });
    newItens.map((item) => {
      setTotalValue(item.valor_bruto);
      setTotal_ICMS_base_calculo(item.icms_base_calculo);
      setQuantityData((prev) => ({
        ...prev,
        comercial_quantity: item.quantidade_comercial,
        comercial_unity: item.unidade_comercial,
        unity_value_comercial: item.valor_unitario_comercial * 100,
        tributable_quantity: item.quantidade_tributavel,
        tributable_unity_value: item.valor_unitario_tributavel * 100,
        tributy_unity: item.unidade_tributavel,
      }));
    });
    setItens(newItens);
    localStorage.setItem(
      '@gddashboard:itensNotaAvulsa',
      JSON.stringify(newItens)
    );

    setNextId(nextId - 1);
  }, [item, itens, setItens, nextId, setNextId]);

  return (
    <Container>
      <Row>
        <Col md="1">
          <strong>Item {Number(number) + 1}</strong>
        </Col>
        <Col md="8">{item.descricao}</Col>
        <Col md="1" style={{ textAlign: 'right' }}>
          R$
          {item.valor_bruto?.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          })}
        </Col>
        <Col md="2" style={{ textAlign: 'right' }}>
          <FaPen
            onClick={() => toggleEdit()}
            title="Editar item"
            style={{ cursor: 'pointer', marginLeft: 8 }}
            color="#666666"
          />
          <FaTrash
            color="#ff403d"
            title="Deletar item"
            style={{ cursor: 'pointer', marginLeft: 8 }}
            onClick={() => {
              toggleDelete();
            }}
          />
        </Col>
      </Row>

      <Modal isOpen={modalEdit} toggle={toggleEdit} size="xl">
        <Form onSubmit={validateItemEdit} ref={formRefEdit} initialData={item}>
          <ModalBody>
            Editar Item
            <Row>
              <Col md="2">
                <Input
                  name="codigo_produto"
                  label="Código do Produto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
              <Col md="4">
                <Input
                  name="descricao"
                  label="Descrição / Nome do Produto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="codigo_ncm"
                  label="NCM"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="cfop"
                  label="CFOP"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="cest"
                  label="CEST"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
            </Row>
            {/* QTD comercial | Valor unit. Comercial | UN comercial | QTD tributável | Valor unit. Tributável | UN tributável */}
            <Row>
              <Col md="2">
                <Input
                  name="quantidade_comercial"
                  label="Quantidade Comercial"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => {
                    const value =
                      e.target.value === null || e.target.value === undefined
                        ? 0
                        : e.target.value.replace(',', '.');
                    dataItensEdit.quantidade_comercial = value;
                    dataItensEdit.quantidade_tributavel = value;
                    setQuantityData((prevData) => ({
                      ...prevData,
                      comercial_quantity: value,
                      tributable_quantity: value,
                    }));
                  }}
                  value={
                    quantityData.comercial_quantity === 0.0
                      ? null
                      : quantityData.comercial_quantity
                  }
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_unitario_comercial"
                  label="Valor Unit. Comercial"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.valor_unitario_comercial =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                    dataItensEdit.valor_unitario_tributavel =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                    setQuantityData((prevData) => ({
                      ...prevData,
                      unity_value_comercial: e.floatValue,
                      tributable_unity_value: e.floatValue,
                    }));
                  }}
                  value={quantityData.unity_value_comercial}
                />
              </Col>

              <Col md="2">
                <Input
                  name="unidade_comercial"
                  label="Unidade Comercial"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => {
                    const { value } = e.target;
                    setQuantityData((prevData) => ({
                      ...prevData,
                      comercial_unity: value,
                      tributy_unity: value,
                    }));
                  }}
                  value={quantityData.comercial_unity}
                />
              </Col>

              <Col md="2">
                <Input
                  name="quantidade_tributavel"
                  label="Quantidade Tributável"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => {
                    const value =
                      e.target.value === null || e.target.value === undefined
                        ? 0
                        : e.target.value.replace(',', '.');
                    dataItensEdit.quantidade_comercial = value;
                    dataItensEdit.quantidade_tributavel = value;
                    setQuantityData((prevData) => ({
                      ...prevData,
                      comercial_quantity: value,
                      tributable_quantity: value,
                    }));
                  }}
                  value={
                    quantityData.tributable_quantity === 0.0
                      ? null
                      : quantityData.tributable_quantity
                  }
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_unitario_tributavel"
                  label="Valor Unit. Tributável"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.valor_unitario_tributavel =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                    dataItensEdit.valor_unitario_comercial =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                    setQuantityData((prevData) => ({
                      ...prevData,
                      tributable_unity_value: e.floatValue,
                      unity_value_comercial: e.floatValue,
                    }));
                  }}
                  value={quantityData.tributable_unity_value}
                />
              </Col>

              <Col md="2">
                <Input
                  name="unidade_tributavel"
                  label="Unidade Tributável"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => {
                    const { value } = e.target;
                    setQuantityData((prevData) => ({
                      ...prevData,
                      tributy_unity: value,
                      comercial_unity: value,
                    }));
                  }}
                  value={quantityData.tributy_unity}
                />
              </Col>
            </Row>
            {/* Valor bruto | Valor Desconto | Valor Frete |Origem Mercadoria | CEST */}
            <Row>
              <Col md="2">
                <InputCurrency
                  prefix="R$"
                  label="Valor Bruto"
                  placeholder="R$"
                  format={currencyFormatter}
                  name="valor_bruto"
                  fontSize={{ fontSize: 12 }}
                  disabled
                  value={totalValue}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_desconto"
                  label="Valor Desconto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  prefix="R$"
                  placeholder="R$"
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.valor_desconto =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_frete"
                  label="Valor Frete"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  prefix="R$"
                  placeholder="R$"
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.valor_frete =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              {/* <Col md="2">
                <Input
                  name="cest"
                  label="CEST"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col> */}
              <Col md="2">
                <Input
                  name="codigo_beneficio_fiscal"
                  label="cBenef"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="4">
                <Select
                  name="icms_situacao_tributaria"
                  options={icms_s_t_devolucao}
                  label={`ICMS Situação Tributária (${user.regime_tributario === '1' ? 'CSOSN' : 'CST'
                    })`}
                  value={icms_s_t_devolucao.filter(
                    (icms) => icms.value === icmsSituacaoTributaria
                  )}
                  onChange={(e) => setIcmsSituacaoTributaria(e.value)}
                />
              </Col>
            </Row>
            {/* ICMS */}
            <Row>
              <Col md="3">
                <Select
                  name="icms_origem"
                  options={icms_origem_options}
                  label="ICMS de Origem"
                  value={icms_origem_options.filter(
                    (icms) => icms.value === icmsOrigem
                  )}
                  onChange={(e) => setIcmsOrigem(e.value)}
                />
              </Col>

              <Col md="3">
                <Select
                  name="icms_modalidade_base_calculo"
                  options={nfeInfo.icms_modalidade_base_calculo}
                  label="ICMS Modalidade Base Cálc."
                  value={nfeInfo.icms_modalidade_base_calculo.filter(
                    (icms) => icms.value === icmsModalidadeBaseCalculo
                  )}
                  onChange={(e) => setIcmsModalidadeBaseCalculo(e.value)}
                />
              </Col>

              <Col md="3">
                <Select
                  name="icms_modalidade_base_calculo_st"
                  options={nfeInfo.ICMS_ST}
                  label="ICMS Modal. Base Cálc. ST"
                  value={icmsST}
                  onChange={(e) => setIcmsSt(e.value)}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_base_calculo"
                  label="ICMS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_base_calculo =
                      e.floatValue > 0 ? e.floatValue : null;
                    setTotal_ICMS_base_calculo(e.floatValue);
                  }}
                  value={total_icms_base_Calculo}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_aliquota"
                  label="ICMS Alíquota"
                  type="text"
                  placeholder="%"
                  suffix="%"
                  fontSize={{ fontSize: 12 }}
                  onValueChange={(e) => {
                    dataItensEdit.icms_aliquota = e.floatValue;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_valor"
                  label="ICMS Valor"
                  value={icmsValor}
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    setIcmsValor(e.floatValue);
                  }}
                />
              </Col>
            </Row>
            {/* PIS */}
            <Row>
              <Col md="3">
                <Select
                  name="pis_situacao_tributaria"
                  options={nfeInfo.pis_situacao_tributaria}
                  label="PIS Situação Trib."
                  value={nfeInfo.pis_situacao_tributaria.filter(
                    (icms) => icms.value === pisSituacaoTributaria
                  )}
                  onChange={(e) => setPisSituacaoTributaria(e.value)}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="pis_base_calculo"
                  label="PIS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.pis_base_calculo =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="pis_aliquota_porcentual"
                  label="PIS Alíquota"
                  type="text"
                  placeholder="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.pis_aliquota_porcentual =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="pis_valor"
                  label="PIS Valor"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.pis_valor =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
            </Row>
            {/* COFINS */}
            <Row>
              <Col md="3">
                <Select
                  name="cofins_situacao_tributaria"
                  options={nfeInfo.pis_situacao_tributaria}
                  label="COFINS Situação Trib."
                  value={nfeInfo.pis_situacao_tributaria.filter(
                    (icms) => icms.value === cofinsSituacaoTributaria
                  )}
                  onChange={(e) => setCofinsSituacaoTributaria(e.value)}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="cofins_base_calculo"
                  label="COFINS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.cofins_base_calculo =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="cofins_aliquota_porcentual"
                  label="COFINS Alíquota"
                  type="text"
                  placeholder="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.cofins_aliquota_porcentual =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="cofins_valor"
                  label="COFINS Valor"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.cofins_valor =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
            </Row>
            {/* Mais ICMS */}
            <Row>
              <Col md="3">
                <InputCurrency
                  name="icms_base_calculo_retido_st"
                  label="ICMS Base Calc. Retido ST"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_base_calculo_retido_st =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="icms_aliquota_final"
                  label="ICMS Alíquota Final"
                  type="text"
                  prefix="%"
                  placeholder="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_aliquota_final =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="icms_valor_substituto"
                  label="ICMS Valor Substituto"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_valor_substituto =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="icms_valor_retido_st"
                  label="ICMS Valor Retido ST"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItensEdit.icms_valor_retido_st =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggleEdit}
              title="Cancelar"
              buttonColor="#dc3545"
            />
            <Button
              type="button"
              onClick={validateItemEdit}
              buttonColor="#2ec9b7"
              title="Salvar"
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          Tem certeza que deseja remover o item{' '}
          <strong>{item.descricao}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={toggleDelete}
            title="Continuar com item"
            buttonColor="#2ec9b7"
          />
          <Button
            type="button"
            onClick={() => {
              deleteItem();
              toggleDelete();
            }}
            buttonColor="#dc3545"
            title="Remover"
          />
        </ModalFooter>
      </Modal>
    </Container>
  );
}
