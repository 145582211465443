import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import Warning from '~/components/Notify';
import { ReactComponent as WarningIcon } from '~/assets/img/warning.svg';
import { FaX } from 'react-icons/fa6';
import { LinkStyled } from './styles';

const NotifyContext = createContext();

const NotifyProvider = ({ children }) => {
  const [isNotifyOpened, setIsNotifyOpened] = useState(false);

  const toggleNotify = useCallback(() => {
    setIsNotifyOpened((prev) => !prev);
  }, []);

  const value = useMemo(
    () => ({ isNotifyOpened, setIsNotifyOpened, toggleNotify }),
    [isNotifyOpened, toggleNotify]
  );

  return (
    <NotifyContext.Provider value={value}>
      <Warning type="warning" isOpen={isNotifyOpened}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon style={{ width: 52 }} />
        </div>

        <div style={{ marginLeft: 16 }}>
          <p
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: '#222',
            }}
          >
            Um dos seus produtos teve alteração no preço de venda.
          </p>
          <span style={{ color: '#545454' }}>
            Verifique sua{' '}
            <LinkStyled onClick={toggleNotify} to="/register/markup">
              precificação!
            </LinkStyled>
          </span>
        </div>
        <div style={{ width: 28 }}>
          <FaX onClick={() => setIsNotifyOpened(false)} cursor="pointer" />
        </div>
      </Warning>
      {children}
    </NotifyContext.Provider>
  );
};

function useNotify() {
  const context = useContext(NotifyContext);

  if (!context) {
    throw new Error('useNotify must be used within a NotifyProvider');
  }

  return context;
}

export { NotifyProvider, useNotify };
