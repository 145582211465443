/* eslint-disable no-console */
import React, { useEffect, useState, useCallback } from 'react';

import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';

import { Col, Row } from 'reactstrap';

import { FaPlus, FaPen, FaCheck } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './TipCircle';

import {
  Card,
  Map,
  PlusButton,
  NumberInput,
  AddressesTable,
  Checkbox,
  PenButton,
} from './styles';

import api2 from '~/services/api2';

import { useAuth } from '~/context/AuthContext';
import Input from '~/components/Form/Input';

function MapDistances() {
  const { user, getProfileData } = useAuth();

  const [formatedAddress] = useState(() => {
    if (user.adress && user.adress.state) {
      const { number, street, neighborhood, city, state } = user.adress;

      const n = number < 0 ? 'N/A' : `Nº ${number}`;

      return `${street} ${n}, ${neighborhood}, ${city} - ${state}`;
    }

    return 'Não cadastrado.';
  });

  const [isDistanceByPath, setIsDistanceByPath] = useState(
    user.delivery_info?.is_distance_by_path || false
  );

  const [deliveryDistances, setDeliveryDistances] = useState([]);

  const [defaultDistance, setDefaultDistance] = useState(() => {
    return {
      key: v4(),
      distance: null,
      price: null,
      time: null,
      changed: false,
    };
  });

  const [center, setCenter] = useState([-20.3196699, -40.338479]);

  const changeDeliveryConfig = useCallback(async () => {
    try {
      await api2.put('/restaurants/delivery-info', {
        is_distance_by_path: !isDistanceByPath,
      });

      setIsDistanceByPath(!isDistanceByPath);
      getProfileData();

      toast.success('Atualizado');
    } catch (error) {
      toast.error('Erro ao atualizar perfil do restaurante');
    }
  }, [getProfileData, isDistanceByPath]);

  const fitZoom = useCallback(() => {
    const allLayers = [];

    L.mainMap.eachLayer((l) => {
      if (l instanceof L.Circle) {
        allLayers.push(l);
      }
    });

    if (allLayers.length) {
      const group = new L.FeatureGroup(allLayers);
      L.mainMap.fitBounds(group.getBounds());
    }
  }, []);

  const updateMap = useCallback(async () => {
    const translation = {
      zoom: {
        zoomInTitle: 'Aumentar o zoom do mapa',
        zoomOutTitle: 'Diminuir o zoom do mapa',
      },
    };

    let distances = [];
    let address = null;

    try {
      const { data } = await api2.get('/restaurants/delivery-areas');

      distances = data.distances;
      address = data.address;
    } catch (err) {
      console.error('Failed to load distances', err);
    }

    // TODO: Adicionar tratamento para restaurantes sem endereço
    const vitoria_centro = [-20.3196699, -40.338479];

    const map_center = address?.latitude
      ? [address.latitude, address.longitude]
      : vitoria_centro;

    const map = L.map('leaflet-map', {
      zoomControl: false,
    }).setView(map_center, 13);

    L.control.zoom(translation.zoom).addTo(map);

    L.mainMap = map;

    // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   maxZoom: 19,
    //   attribution:
    //     '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    // }).addTo(map);

    L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
      maxZoom: 20,
      subdomains:['mt0','mt1','mt2','mt3'],
      attribution:
        '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
    }).addTo(map);

    const pos = map_center;

    const icon = L.icon({
      iconUrl: '/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [-3, -76],
    });

    L.marker(pos, { icon, pmIgnore: true }).addTo(map);

    distances.forEach((d) => {
      d.key = v4();

      d.layer = new L.TipCircle(pos, {
        color: '#ff000099',
        fillColor: '#f5e4e7',
        fillOpacity: 0,
        radius: d.distance * 1000,
        dashArray: '30 10',
        pmIgnore: true,
      }).addTo(map);
    });

    fitZoom();
    setDeliveryDistances(distances);
    setCenter(pos);
  }, [fitZoom]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const handleValueChange = useCallback(
    (e, field) => {
      switch (field) {
        case 'distance':
          defaultDistance.distance = e.floatValue;
          break;
        case 'price':
          defaultDistance.price = e.floatValue / 100;
          break;
        case 'time':
          defaultDistance.time = e.floatValue;
          break;
        default:
          break;
      }

      if (!defaultDistance.changed) {
        defaultDistance.changed = true;
        setDefaultDistance({ ...defaultDistance });
      }
    },
    [defaultDistance]
  );

  const handleAddDistance = useCallback(async () => {
    const data = {
      ...defaultDistance,
      price: defaultDistance.price.toFixed(2),
      key: v4(),
    };

    console.log('@@ handleAddDistance', data);

    try {
      const response = await api2.post('/restaurants/delivery-distance', data);

      data.id = response.data.id;

      // Adicionando ao mapa
      if (L.mainMap) {
        data.layer = new L.TipCircle(center, {
          color: '#ff000099',
          fillColor: '#f03',
          fillOpacity: 0,
          dashArray: '30 10',
          radius: data.distance * 1000,
          pmIgnore: true,
        }).addTo(L.mainMap);

        fitZoom();
      }

      deliveryDistances.push(data);
      deliveryDistances.sort((a, b) => Number(a.distance) - Number(b.distance));

      setDeliveryDistances([...deliveryDistances]);

      setDefaultDistance({
        key: v4(),
        distance: null,
        price: null,
        type: null,
      });

      toast.success('Distância cadastrada');
    } catch (err) {
      console.log('@@ err', err);
      toast.error('Erro ao salvar distância');
    }
  }, [defaultDistance, deliveryDistances, center, fitZoom]);

  const handleDeleteDistance = useCallback(
    async (i) => {
      const dist = deliveryDistances[i];

      console.log('@@ handleDeleteDistance', dist);

      try {
        await api2.delete(`/restaurants/delivery-distance/${dist.id}`);

        // Removendo do mapa
        if (deliveryDistances[i].layer) {
          deliveryDistances[i].layer.remove();
        }

        deliveryDistances.splice(i, 1);
        setDeliveryDistances([...deliveryDistances]);

        toast.success('Distância apagada');
      } catch (err) {
        toast.error('Erro ao apagada distância');
      }
    },
    [deliveryDistances]


  );

  // Indica se algum dado foi alterado em caso positivo o botão é habilitado
  const [changed, setChanged] = useState([]);

  const saveEditedArea = useCallback(
    async (i) => {
      const { id, distance, time, price } = deliveryDistances[i];

      const data = { price, distance, time };

      try {
        await api2.put(`/restaurants/delivery-distance/${id}`, data);

        changed[i] = false;
        setChanged([...changed]);

        toast.success('Alterações salvas com sucesso!');
      } catch (e) {
        toast.error('Não foi possível salvar a alteração!');
      }
    },
    [deliveryDistances, changed]
  );

  const handleDistanceChange = useCallback(
    (e, i) => {
      deliveryDistances[i].distance = e.target.value;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }
    },
    [deliveryDistances, changed]
  );

  const handlePriceChange = useCallback(
    (e, i) => {
      deliveryDistances[i].price = e.floatValue / 100;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }
    },
    [deliveryDistances, changed]
  );


  const handleTimeChange = useCallback(
    (e, i) => {
      deliveryDistances[i].time = e.target.value;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }
    },
    [deliveryDistances, changed]
  );



  return (
    <Card>
      <Row style={{ height: '100%' }}>
        <Col md="6">
          <Map id="leaflet-map" style={{ height: '100%', zIndex: 500 }}>
            {' '}
          </Map>
        </Col>

        <Col md="6" style={{ height: '100%' }}>
          <Row>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', marginBottom: '5px' }}>
                <b> Endereço: </b>
                <div style={{ marginLeft: '6px' }}> {formatedAddress} </div>
              </div>

              <PenButton to={{ pathname: '/profile/info' }}>
                <FaPen size={16} />
              </PenButton>
            </div>
          </Row>

          <Row>
            <Checkbox>
              <input
                type="checkbox"
                id="delivery-path"
                name="is_distance_by_path"
                checked={isDistanceByPath}
                onChange={() => changeDeliveryConfig()}
              />

              <div style={{ margin: '5px' }}>
                Considerar trajeto no cálculo da distância
              </div>
            </Checkbox>
          </Row>

          <Row style={{ margin: '0px' }}>
            <AddressesTable borderless>
              <thead>
                <tr>
                  <th> Alcance (km) </th>
                  <th> Taxa (R$) </th>
                  <th> Tempo (minutos) </th>
                  <th style={{ width: '60px' }}> </th>
                </tr>
              </thead>
              <tbody>
                <tr key={defaultDistance.key}>
                  <td>
                    <NumberInput
                      placeholder="Km..."
                      name="distance"
                      value={defaultDistance.distance}
                      onValueChange={(e) => handleValueChange(e, 'distance')}
                    />
                  </td>
                  <td>
                    <NumberInput
                      placeholder="R$..."
                      prefix="R$"
                      format={currencyFormatter}
                      name="price"
                      defaultValue={0}
                      value={parseFloat(defaultDistance.price) * 100}
                      onValueChange={(e) => handleValueChange(e, 'price')}
                    />
                  </td>
                  <td>
                    <NumberInput
                      placeholder="Minutos..."
                      name="time"
                      value={defaultDistance.time}
                      onValueChange={(e) => handleValueChange(e, 'time')}
                    />
                  </td>
                  <td>
                    <PlusButton
                      disabled={!defaultDistance.changed}
                      onClick={() => handleAddDistance()}
                    >
                      <FaPlus />
                    </PlusButton>
                  </td>
                </tr>

                {deliveryDistances.map((item, i) => (
                  <tr key={item.key}>
                    <td>
                      <input
                        defaultValue={item.distance}
                        style={{
                          height: '44px',
                          border: '1px solid #33333320',
                          borderRadius: '4px',
                          width: '100%',
                          padding: '0 10px',

                        }}
                        onChange={(e) => handleDistanceChange(e, i)}
                      />
                    </td>
                    <td>
                      <NumberInput
                        prefix="R$"
                        format={currencyFormatter}
                        defaultValue={item.price}
                        onValueChange={(e) => handlePriceChange(e, i)}
                        style={{
                          height: '44px',
                          border: '1px solid #33333320',
                          borderRadius: '4px',
                          width: '100%',
                          padding: '0 10px',

                        }}
                      />
                    </td>
                    <td>
                      <input
                        defaultValue={item.time}
                        onChange={(e) => handleTimeChange(e, i)}
                        style={{
                          height: '44px',
                          border: '1px solid #33333320',
                          borderRadius: '4px',
                          width: '100%',
                          padding: '0 10px',

                        }}
                      /></td>
                    <td style={{ display: "flex", alignItems: "center" }}>
                      <PlusButton
                        disabled={!changed[i]}
                        onClick={() => saveEditedArea(i)}
                      >
                        <FaCheck />
                      </PlusButton>
                      <MdDelete
                        size={20}
                        color="#ff0000"
                        title="Deletar alcance"
                        onClick={() => handleDeleteDistance(i)}
                        style={{ marginLeft: 8 }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </AddressesTable>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default MapDistances;
