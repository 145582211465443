import React from 'react';
import { Button } from 'takeat-design-system-ui-kit';
import { NavBar } from './styles';
import {
  FaCalendarCheck,
  FaClock,
  FaLocationArrow,
  FaMotorcycle,
  FaPercentage,
} from 'react-icons/fa';
import { GiFullMotorcycleHelmet } from 'react-icons/gi';
import { BsFillGearFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const DeliveryNavBar = ({}) => {
  const history = useHistory();
  const thisPath = window.location.pathname.split('/delivery')[1];
  const windowWidth = window.innerWidth;

  return (
    <NavBar>
      <Button
        customColor="#ff2c3a"
        icon={<FaMotorcycle />}
        variant={thisPath === '/orders' ? 'primary' : 'outlined'}
        onClick={() => history.push('/delivery/orders')}
      >
        {' '}
        Delivery
      </Button>

      <Button
        customColor="#ff2c3a"
        icon={<GiFullMotorcycleHelmet />}
        variant={thisPath === '/motoboys' ? 'primary' : 'outlined'}
        onClick={() => history.push('/delivery/motoboys')}
      >
        Motoboys
      </Button>
      <Button
        customColor="#ff2c3a"
        icon={<FaLocationArrow />}
        variant={thisPath === '/addresses' ? 'primary' : 'outlined'}
        onClick={() => history.push('/delivery/addresses')}
      >
        Endereços
      </Button>

      <Button
        customColor="#ff2c3a"
        icon={<FaClock />}
        variant={thisPath === '/opening-hours' ? 'primary' : 'outlined'}
        onClick={() => history.push('/delivery/opening-hours')}
      >
        {windowWidth >= 1105 && 'Funcionamento'}
      </Button>

      <Button
        customColor="#ff2c3a"
        icon={<FaCalendarCheck />}
        variant={thisPath === '/scheduling' ? 'primary' : 'outlined'}
        onClick={() => history.push('/delivery/scheduling')}
      >
        {windowWidth >= 1230 && 'Agendamento'}
      </Button>

      <Button
        customColor="#ff2c3a"
        icon={<FaPercentage />}
        variant={thisPath === '/coupons' ? 'primary' : 'outlined'}
        onClick={() => history.push('/delivery/coupons')}
      >
        {windowWidth >= 1305 && 'Cupons'}
      </Button>

      <Button
        customColor="#ff2c3a"
        icon={<BsFillGearFill />}
        variant={thisPath === '/config' ? 'primary' : 'outlined'}
        onClick={() => history.push('/delivery/config')}
      >
        {windowWidth >= 1375 && 'Config.'}
      </Button>
    </NavBar>
  );
};
