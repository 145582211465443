import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

import { Spinner } from 'reactstrap';
import {
  format,
  startOfDay,
  endOfDay,
  addHours,
} from 'date-fns';
import { toast } from 'react-hot-toast';
import {
  Header,
  Bloco,
  Container,
} from './styles';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import OrderFinishedDelivery from '../../Items/OrderFinishedDelivery';
import api2 from '~/services/api2';
import { useOrder } from '~/context/orderContext';
import { useAuth } from '~/context/AuthContext';
import { FullCalendar } from 'ui-kit-takeat';

export default function CardOrdersFinishedDelivery({
  handleCancelItem,
  setFinishedBasketsSession,
  finishedBasketsSessionCounter,
  finishedBasketsFiltered,
}) {
  const { user } = useAuth();
  const { finishedBaskets } = useOrder();
  const [loadingFinished, setLoadingFinished] = useState(false);

  const [filterDate, setFilterDate] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  });

  const [hourPhrase, setHourPhrase] = useState('');

  const getSessions = useCallback(async () => {
    try {
      setLoadingFinished(true);
      const response = await api2.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(filterDate.start), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(filterDate.end), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          filter_all: true,
          is_delivery: true,
          status: 'completed',
        },
      });

      const sessionsMinimalFinished = response.data.filter(
        (session) => session.status === 'completed' && session.is_delivery
      );

      setFinishedBasketsSession(sessionsMinimalFinished);
      setLoadingFinished(false);
    } catch (err) {
      toast.error('Erro ao carregar informações');
      setLoadingFinished(false);
    }
  }, [filterDate]);

  useEffect(() => {
    setHourPhrase(
      `De ${format(
        new Date(filterDate.start),
        'dd/MM/yy - HH:mm'
      )} até ${format(new Date(filterDate.end), 'dd/MM/yy - HH:mm')}`
    );
  }, [filterDate]);

  useEffect(() => {
    getSessions();
  }, [getSessions, finishedBaskets]);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <span>{hourPhrase}</span>
        <FullCalendar value={filterDate} onDateSelected={setFilterDate} />
      </div>

      <Bloco>
        <Header>
          FINALIZADOS
          <ItemsCount style={{ color: '#28a745' }}>
            {finishedBasketsSessionCounter}
          </ItemsCount>
        </Header>
        <Content>
          {loadingFinished ? (
            <LoadingDiv>
              <Spinner color="success" />
            </LoadingDiv>
          ) : (
            finishedBasketsFiltered.length > 0 &&
            finishedBasketsFiltered.map((session) => (
              <OrderFinishedDelivery
                handleCancelItem={handleCancelItem}
                session={session}
                user={user}
                getSessions={getSessions}
                key={session?.id}
              />
            ))
          )}
        </Content>
      </Bloco>
    </Container>
  );
}