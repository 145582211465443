import React, { useState, useRef, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { Row, Col, ModalBody } from 'reactstrap';
import { format, startOfDay, subHours } from 'date-fns';
import { FiX } from 'react-icons/fi';

import { Header, StyledForm, Container, Title } from './styles';

import RadioInput from '~/components/Form/RadioInput';
import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import PersonalButton from '~/components/Buttons/PersonalButton';

import api2 from '~/services/api2';
import getValidationErrors from '~/services/getValidationErrors';

function EditTicket({ coupom, toggleModalEditTicket, getTickets }) {
  const formRef = useRef(null);
  const [discountType, setDiscountType] = useState(coupom.discount_type);
  const [type, setType] = useState(
    coupom.discount_type === 'absolute' ? 'Desconto (R$)' : 'Desconto (%)'
  );
  const today = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), 'yyyy-MM-dd');
  }, []);

  function changeType(new_discount_type) {
    switch (new_discount_type) {
      case 'percentage':
        setType('Desconto (%)');
        break;
      case 'absolute':
        setType('Desconto (R$)');
        break;
      default:
        break;
    }

    setDiscountType(new_discount_type);
  }

  const [expirationDate, setExpirationDate] = useState(
    coupom.limit_date === null
      ? null
      : format(startOfDay(new Date(coupom.limit_date)), 'yyyy-MM-dd')
  );

  const [active, setActive] = useState(coupom.is_active);
  const [isPublic, setIsPublic] = useState(coupom.is_public);
  const [limited, setLimited] = useState(coupom.buyer_limit_buy !== null);
  const [expires, setExpires] = useState(coupom.limit_date !== null);

  const [coupomLimit, setcoupomLimit] = useState(coupom.buyer_limit_buy);

  function changeActive() {
    setActive(!active);
  }

  function changeIsPublic() {
    setIsPublic(!isPublic);
  }

  function changeLimited() {
    setLimited(!limited);
  }

  function changeExpires() {
    setExpires(!expires);
    !expires ? setExpirationDate(`${today}T03:00:00`) : setExpirationDate(null);
  }

  let discount_initial_data = coupom.discount;

  if (discountType !== 'absolute') {
    discount_initial_data = parseFloat(discount_initial_data) * 100;
    discount_initial_data = discount_initial_data.toString();
  }

  const initialData = {
    name: coupom.name,
    title: coupom.title,
    description: coupom.description,
    code: coupom.code,
    discount: parseFloat(discount_initial_data).toFixed(2).toString(),
    minimum_price: coupom.minimum_price || '0.00',
    maximum_discount: coupom.maximum_discount,
    buyer_limit_buy: coupomLimit,
    limit_date: expirationDate,
    limit_amount: coupom.limit_amount,
    active: coupom.is_active,
    expiration: false,
    isPublic: coupom.is_public,
    distance_limit: coupom.distance_limit || '',
  };

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        code: Yup.string().required('Código obrigatório'),
        discount: discountType !== 'free-shipping'
          ? Yup.string().required('O desconto é obrigatório')
          : Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let formattedPrice = 0;
      let distance_limit = null;

      if (discountType === 'free-shipping') {
        data.distance_limit = data.distance_limit.trim();

        if (data.distance_limit) {
          distance_limit = parseFloat(data.distance_limit.replace(',', '.'));
        }
      } else if (discountType === 'percentage') {
        formattedPrice = parseFloat(data.discount.replace(',', '.')) / 100;
      } else {
        formattedPrice = parseFloat(data.discount.replace(',', '.'));
      }

      const dataToSend = {
        name: data.name,
        title: '',
        description: '',
        code: data.code.toUpperCase(),
        discount_type: discountType,
        discount: formattedPrice,
        limit_date: expirationDate,
        is_active: active,
        is_public: isPublic,
        distance_limit,
      };

      if (data.minimum_price !== '') {
        dataToSend.minimum_price = parseFloat(
          data.minimum_price.replace(',', '.')
        ).toFixed(2);
      }

      if (data.maximum_discount !== '') {
        dataToSend.maximum_discount = parseFloat(
          data.maximum_discount.replace(',', '.')
        ).toFixed(2);
      }

      if (data.limit_amount !== '') {
        dataToSend.limit_amount = data.limit_amount;
      }

      if (coupomLimit !== null && limited) {
        dataToSend.buyer_limit_buy = coupomLimit;
      }

      await api2.put(`/restaurants/discount-coupons/${coupom.id}`, dataToSend);
      toast.success('Cupom atualizado com sucesso!');
      getTickets();
      toggleModalEditTicket();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao atualizar cupom');
      }
    }
  }

  return (
    <ModalBody>
      <Header>
        <Title>Editar cupom</Title>

        <FiX
          size={22}
          color="#FF2129"
          cursor="pointer"
          strokeWidth={3}
          onClick={() => toggleModalEditTicket()}
        />
      </Header>

      <Container>
        <Row>
          <Col md="12 ml-auto mr-auto">
            <StyledForm
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={initialData}
            >
              <Row>
                <Col md="6">
                  <RadioInput
                    label="Cupom em Porcentagem"
                    name="discount_type"
                    value="percentage"
                    labelCss={{ fontWeight: 'normal' }}
                    defaultChecked={discountType === 'percentage'}
                    onChange={() => changeType('percentage')}
                  />
                  <RadioInput
                    label="Cupom em Reais"
                    name="discount_type"
                    value="absolute"
                    labelCss={{ fontWeight: 'normal' }}
                    defaultChecked={discountType === 'absolute'}
                    onChange={() => changeType('absolute')}
                  />
                  <RadioInput
                    label="Frete Grátis"
                    name="discount_type"
                    value="free-shipping"
                    labelCss={{ fontWeight: 'normal' }}
                    defaultChecked={discountType === 'free-shipping'}
                    onChange={() => changeType('free-shipping')}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  {discountType !== 'free-shipping'
                    ? <Input label={type} name="discount" type="text" />
                    : <Input label="Limite de Distância (km)" name="distance_limit" type="text" />
                  }
                  <Input
                    label="Nome"
                    name="name"
                    type="text"
                    placeholder="60% OFF"
                  />

                  <Input
                    label="Código"
                    name="code"
                    type="text"
                    placeholder="60OFF"
                  />
                </Col>
                <Col md="6">
                  <Input
                    label="Valor Mínimo do Pedido"
                    name="minimum_price"
                    type="text"
                    placeholder=""
                  />

                  <Input
                    name="limit_amount"
                    type="text"
                    label="Limite de vezes que pode ser usado"
                  />

                  <Input
                    label="Desconto Máximo"
                    name="maximum_discount"
                    type="text"
                    placeholder=""
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <CheckboxInput
                    label="Limitar vezes que o mesmo cliente pode usar o cupom"
                    name="userLimitTime"
                    onChange={changeLimited}
                    defaultChecked={coupomLimit}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  {' '}
                  {limited && (
                    <Input
                      name="buyer_limit_buy"
                      type="text"
                      placeholder="Limite de vezes que o mesmo cliente pode usar o cupom"
                      onChange={(e) => setcoupomLimit(e.target.value)}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Definir data de expiração"
                    onChange={changeExpires}
                    name="expiration"
                    defaultChecked={expires}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  {expires && (
                    <Input
                      id="date"
                      label="Expira em"
                      type="date"
                      name="finalDate"
                      onChange={(e) =>
                        setExpirationDate(`${e.target.value}T03:00:00`)
                      }
                      defaultValue={expirationDate}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Ativo"
                    name="active"
                    defaultChecked={active}
                    onChange={changeActive}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Aparecer na lista de cupons disponíveis"
                    name="isPublic"
                    defaultValue={isPublic}
                    onChange={changeIsPublic}
                  />
                </Col>
              </Row>
              <div style={{ float: 'right' }}>
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                />
              </div>
            </StyledForm>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  );
}

export default EditTicket;
