import React, { useState, useCallback } from 'react';
import {IconTrashFilled,IconPencilFilled,IconPdfFilled, IconEyeFilled} from 'takeat-design-system-ui-kit'
import { Form } from '@unform/web';
import ReactModal from 'react-modal';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import api from '~/services/api';
import formatDate from '~/services/formatDate';
import formatValue from '~/utils/formatValue';
import Edit from '../Edit';
import EditEarning from '../EditEarning';
import CheckboxInput from '~/components/Form/Checkbox';
import { toast } from 'react-hot-toast';
import { ToggleButton } from 'ui-kit-takeat';
import EditTransactionCashFlowRow from '~/components/Rows/EditTransactionCashFlowRow';
import { useAuth } from '~/context/AuthContext';

function Transaction({
  transaction,
  handleDeleteTransaction,
  getTransactions,
  banks,
  getBanks,
  categories,
  freqOptions,
  suppliers,
  paymentMethods,
  getPaymentMethods,
  getSuppliers,
  getCategories,
  handleAddTransactionToUnify,
  isEarningTransaction,
}) {
  const [isModalEditOpen, setIsModalEditopen] = useState();
  const [isModalEditEarningOpen, setIsModalEditEarningOpen] = useState();
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState();
  const [isPaid, setIsPaid] = useState(transaction.paid);
  const {user} = useAuth()
  async function handleSubmit(e) {
    try {
      const response = await api.put(
        `/restaurants/cash-flows/${transaction.id}`,
        {
          paid: e,
          paid_at: new Date(),
        }
      );

      transaction.paid = e;
      transaction.paid_at = new Date();

      if (response.data.paid) {
        toast.success(
          'Lançamento considerado pago no dia de HOJE! Caso queira mudar, vá em editar e informe a data',
          { duration: 5000 }
        );
      } else {
        toast.success('Lançamento marcado como NÃO pago!');
      }
      // getTransactions();
    } catch (error) {
      toast.error('Ocorreu um erro ao fazer essa alteração');
      console.log(error);
    }
  }


  const getPdf = useCallback(async (CHAVE) => {
    try {
      const response = await api.post('/restaurants/nfe-received/manifest-note',
        {
          nfce_token:user.nfce_token,
          chave:CHAVE
        },
        {responseType: 'arraybuffer'}
      );
  
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
  
      window.open(pdfUrl, '_blank');
  
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 10000);
    } catch (error) {
      toast.error('erro ao obter pdf')
    }

  },[user.nfce_token]) 

  const toggleModalEdit = useCallback(() => {
    setIsModalEditopen(!isModalEditOpen);
  }, [isModalEditOpen]);

  const toggleModalEditEarning = useCallback(() => {
    setIsModalEditEarningOpen(!isModalEditEarningOpen);
  }, [isModalEditEarningOpen]);

  const toggleModalDetails = useCallback(() => {
    setIsModalDetailsOpen(!isModalDetailsOpen);
  }, [isModalDetailsOpen]);

  function openModal(is_earning) {
    if (is_earning) {
      toggleModalEditEarning();
    } else {
      toggleModalEdit();
    }
  }

  const [isChecked, setIsChecked] = useState(false);

  return (
    <tr>
      {isEarningTransaction && (
        <td>
          <Form onSubmit={() => { }}>
            <CheckboxInput
              onChange={() => {
                handleAddTransactionToUnify(transaction);
                setIsChecked(!isChecked);
              }}
              name="unify"
              defaultChecked={isChecked}
            />
          </Form>
        </td>
      )}
      <td>
        <span>{formatDate(transaction?.due_date)}</span>
      </td>
      {isEarningTransaction ? (
        <td>{transaction?.cash_flow_payment_method?.name || '-'}</td>
      ) : (
        <td>{transaction?.description || '-'}</td>
      )}
      {isEarningTransaction ? (
        <td>{transaction?.description || '-'}</td>
      ) : (
        <td>{transaction?.provider?.name || '-'}</td>
      )}
      {isEarningTransaction ? (
        <td>{transaction?.bank_account?.name || '-'}</td>
      ) : (
        <td>{transaction?.cash_flow_category_subcategory?.cash_flow_category?.name || '-'}</td>
      )}
      <td>
        <ToggleButton
          value={isPaid}
          onClick={(e) => {
            handleSubmit(e);
            setIsPaid(e);
          }}
        />
      </td>

      <td>
        <span
          style={{
            color: transaction.is_earning ? 'green' : '#f24f00',
            fontWeight: 'bold',
          }}
        >
          {formatValue(transaction.value)}
        </span>
      </td>
      <td style={{ width: 100 }}>
        <div style={{display:'flex',gap:5,justifyContent:'flex-end'}}>
          {transaction?.cash_flows.length > 0 && (
            <IconEyeFilled
              fill="#17a2b8"
              fontSize={20}
              onClick={() => {
                toggleModalDetails();
              }}
              title="Detalhes"
              style={{ cursor: 'pointer' }}
            />
          )}

          {transaction?.nfe_received?.chave_nfe && (
            <IconPdfFilled
            fontSize={20} 
            fill='#2ec9b7'
            onClick={()=> {
              getPdf(transaction.nfe_received.chave_nfe);
            }}
            style={{ cursor: 'pointer' }}
          />
          )}
          <IconPencilFilled
            onClick={() => {
              openModal(transaction.is_earning);
            }}
            fontSize={20}
            style={{ cursor: 'pointer' }}
          />

          <IconTrashFilled
            onClick={() => {
              handleDeleteTransaction(transaction.id);
            }}
            fill="#c8131b"
            fontSize={20}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </td>

      <Modal isOpen={isModalDetailsOpen} toggle={toggleModalDetails} size="xl">
        <ModalBody>
          <Row>
            <Col>
              <h4>Detalhes</h4>
            </Col>
          </Row>
          {transaction?.cash_flows.map((cash) => (
            <EditTransactionCashFlowRow cash={cash} categories={categories} getTransactions={getTransactions} />
          ))}
        </ModalBody>
      </Modal>


      <ReactModal
        onRequestClose={toggleModalEdit}
        isOpen={isModalEditOpen}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <Edit
          transaction={transaction}
          getTransactions={getTransactions}
          toggleModalEditTransaction={toggleModalEdit}
          paymentMethods={paymentMethods}
          banks={banks}
          suppliers={suppliers}
          freqOptions={freqOptions}
          categories={categories}
          setIsPaid={setIsPaid}
          getBanks={getBanks}
          getPaymentMethods={getPaymentMethods}
          getSuppliers={getSuppliers}
          getCategories={getCategories}

        />
      </ReactModal>


      <ReactModal
        onRequestClose={toggleModalEditEarning}
        isOpen={isModalEditEarningOpen}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <EditEarning
          transaction={transaction}
          getTransactions={getTransactions}
          toggleModalEditTransaction={toggleModalEditEarning}
          paymentMethods={paymentMethods}
          banks={banks}
          suppliers={suppliers}
          freqOptions={freqOptions}
          categories={categories}
          setIsPaid={setIsPaid}
          getBanks={getBanks}
        />
      </ReactModal>
    </tr>
  );
}

export default Transaction;
