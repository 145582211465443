/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

function getData(products, total_discount) {
  const sheet_data = [
    [
      'PRODUTOS',
      'QUANTIDADE',
      'VALOR UNITÁRIO',
      'VALOR TOTAL',
      'VALOR TOTAL COM TAXA',
    ],
  ];

  let total_price = 0;
  let total_service_price = 0;

  for (const p of products) {
    total_price += parseFloat(p.total_price);
    total_service_price += parseFloat(p.total_service_price);

    sheet_data.push([
      p.name,
      p.amount,
      parseFloat(p.price) + parseFloat(p.complements_price),
      parseFloat(p.total_price),
      parseFloat(p.total_service_price),
    ]);
  }

  sheet_data.push(
    ['-', '', '', total_price, total_service_price],
    ['DESCONTO', '', '', '', parseFloat(total_discount)]
  );

  return sheet_data;
}

// /restaurants/credit-register/summary/${id}
export function generateProductsSheet({ products, total_discount }) {
  const rows = getData(products, total_discount);

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  const num_cols = rows[0].length;
  const num_rows = rows.length;

  worksheet['!cols'] = new Array(num_cols);

  for (let i = 0; i < num_cols; i += 1) {
    if (i === 0 || i === num_cols - 1) {
      worksheet['!cols'][i] = { wch: 25 };
    } else {
      worksheet['!cols'][i] = { wch: 15 };
    }
  }

  const black = '000000';
  const white = 'FFFFFF';
  const gray1 = 'CCCCCC'
  const gray2 = 'DDDDDD'
  const blue = '729fcf';
  const light_blue = 'b4c7dc';

  for (let i = 0; i < rows.length; i += 1) {
    for (let j = 0; j < num_cols; j += 1) {
      const cell = worksheet[columns[j] + (i + 1)];

      if (i === 0 || i >= num_rows - 2) {
        // Aplicar estilo ao cabeçalho (primeira linha)
        cell.s = style()
          .Bg(gray1)
          .Font(black, '', 'center')
          .Bold()
          .Borders('tblr', gray2);

        if (i !== 0 && j >= num_cols - 2) {
          cell.s.Number();
        }

        cell.s = cell.s.Get();
      } else {
        cell.s = style()
          .Bg(white)
          .Font(black, '', 'center')
          .Borders('tblr', gray2)
          .Number()
          .Get();
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Contas a Prazo');

  return XLSX.write(workbook, { type: 'buffer' });
}
