import styled from 'styled-components';

export const ComplementAddedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    flex-grow: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  svg {
    color: #ff2c3a;
    cursor: pointer;
  }

  svg.disabled {
    color: #ccc;
    cursor: pointer;
    pointer-events: none;
  }
`;
