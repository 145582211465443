import React, { useState, useEffect, useCallback } from 'react';

import { format, startOfDay, endOfDay, addHours } from 'date-fns';

import { Spinner } from 'reactstrap';
import { FullCalendar } from 'ui-kit-takeat';
import api2 from '~/services/api2';
import { toast } from 'react-hot-toast';
import { Content, Header, Bloco } from './styles';
import { ItemsCount, LoadingDiv } from '../styles';
import OrderCanceledDelivery from '../../Items/OrderCanceledDelivery';

export default function CardOrdersCanceled({ loading, activeTab }) {
  const [hourPhrase, setHourPhrase] = useState('');
  const [loadingFinished, setLoadingFinished] = useState(false);
  const [canceledOrders, setCanceledOrders] = useState([]);
  const [countCanceledOrders, setCountCanceledOrders] = useState(0);
  const [filterDate, setFilterDate] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  });

  useEffect(() => {
    setHourPhrase(
      `De ${format(
        new Date(filterDate.start),
        'dd/MM/yy - HH:mm'
      )} até ${format(new Date(filterDate.end), 'dd/MM/yy - HH:mm')}`
    );
  }, [filterDate]);

  const getSessions = useCallback(async () => {
    try {
      setLoadingFinished(true);
      const response = await api2.get(
        '/restaurants/baskets/delivery/canceled',
        {
          params: {
            start_date: `${format(
              addHours(new Date(filterDate.start), 3),
              "yyyy-MM-dd'T'HH:mm:ss"
            )}`,
            end_date: `${format(
              addHours(new Date(filterDate.end), 3),
              "yyyy-MM-dd'T'HH:mm:ss"
            )}`,
          },
        }
      );
      const sessionsCanceled = response.data.orders.filter(
        (session) => session.table.table_type === 'delivery'
      );

      setCanceledOrders(sessionsCanceled);
      setCountCanceledOrders(sessionsCanceled.length);
      setLoadingFinished(false);
    } catch (err) {
      toast.error('Erro ao carregar informações');
      setLoadingFinished(false);
    }
  }, [filterDate]);

  useEffect(() => {
    if (activeTab === 3) {
      getSessions();
    }
  }, [getSessions, activeTab]);

  return (
    <Bloco>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <span>{hourPhrase}</span>
        <FullCalendar value={filterDate} onDateSelected={setFilterDate} />
      </div>
      <Header>
        CANCELADOS
        <ItemsCount style={{ color: '#ff2c3a' }}>
          {countCanceledOrders}
        </ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="danger" />
          </LoadingDiv>
        ) : (
          canceledOrders.map((item) => (
            <OrderCanceledDelivery key={item.basket.id} item={item} />
          ))
        )}
      </Content>
    </Bloco>
  );
}
