import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Table } from 'reactstrap';

export const Container = styled.div`
  padding: 0 20px 20px;
  height: calc(100vh - 136px);
  overflow-y: auto;
`;

export const Header = styled.div`
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
`;

export const FilterArea = styled.div`
  width: 160px;
  padding: 0px;
  background-color: #fff;
  position: absolute;
  font-size: 16px;
  top: 43px;
  right: 34px;
  border-radius: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  transform: translate(-110%, 135%);
  z-index: 99;

  p {
    padding-left: 16px;
    margin: 0;
  }
`;

export const CouponState = styled.div`
  display: flex;
  gap: 16px;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 14px;

  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
export const CategoriesTable = styled(Table)`
  width: 100%;
  min-width: 400px;
  max-height: calc(100% - 84px);
  overflow-y: scroll;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 0px 8px 8px 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        //letter-spacing: 0.10000000149011612px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #6c6c6c;

        text-align: center;
      }
      th:first-child {
        text-align: left;
      }
      th:last-child {
        text-align: right;
      }
      th:not(:first-child):not(:last-child) {
        padding-inline: 4px;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      text-align: center;
      max-width: 150px;
    }

    td:first-child {
      text-align: left;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;
