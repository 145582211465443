import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as Yup from 'yup';
import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import ReactToPrint from 'react-to-print';
import InputMask from 'react-input-mask';
import { Button, DefaultInput } from 'ui-kit-takeat';

import {
  Col,
  Collapse,
  Modal,
  ModalBody,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import {
  FaAngleDown,
  FaDownload,
  FaPen,
  FaPrint,
  FaTrash,
  FaEllipsisV,
} from 'react-icons/fa';
import { FiX } from 'react-icons/fi';

import {
  Container,
  TdStyled,
  DisplayNoneImprimir,
  ClosedButton,
  DropdownItemStyled,
} from './styles';

import { DatePickerStyledUnify } from '../styles';

import Input from '~/components/Form/Input';
import PersonalButton from '~/components/Buttons/PersonalButton';
import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import PrintTermAccountDetails from '~/components/Prints/PrintTermAccountDetails';

import formatCompleteDate from '~/services/formatDate';
import { generateProductsSheet } from '~/services/SheetGenerate/generateCreditRegisterProductsSheet';
import { generateSheet } from '~/services/SheetGenerate/generateCreditRegisterSheet';
import getValidationErrors from '~/services/getValidationErrors';
import api from '~/services/api';

import { useAuth } from '~/context/AuthContext';

function CreditRegisterItem({
  item,
  accountSelected,
  handleSelectAccount,
  registerCredits,
  setRegisterCredits,
  setTotalRegisterToReceive,
  getCreditRegisters,
  paymentMethodsOptions,
  banks,
}) {
  const { user } = useAuth()
  const [isModalEditAccountOpened, setIsModalEditAccountOpened] = useState(false)
  const [nfceArea, setNfceArea] = useState(false)

  function toggleModalEditAccount() {
    setIsModalEditAccountOpened(!isModalEditAccountOpened)
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [isPayoffModalOpened, setIsPayoffModalOpened] = useState(false)
  const togglePayoffModal = () => {
    setIsPayoffModalOpened(!isPayoffModalOpened)
    setVerifyDueDate(true)
    if (nfceArea === true) {
      setNfceArea(false)
    }
  }
  const [expiresAt, setExpiresAt] = useState(item.expires_at ? new Date(item.expires_at) : null)
  const [dueDate, setDueDate] = useState()
  const [valueToPayOff, setValueToPayOff] = useState(0)
  const initialData = {
    name: item.name,
    cnpj: item.cnpj,
    phone: item.phone,
    total_limit: item.total_limit,
    expires_at: expiresAt
  }

  const formRef = useRef(null)
  const payoffRef = useRef(null)

  const [verifyDueDate, setVerifyDueDate] = useState(true);

  const [responseNfce, setResponseNfce] = useState([]);

  async function handleSubmit(data) {
    if (data.total_limit && data.total_limit < Math.abs(item.balance)) {
      toast.error("O valor do limite não pode ser menor do que o saldo devido")
    } else {
      try {

        if (formRef?.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put(`/restaurants/credit-register/${item.id}`, {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
          expires_at: expiresAt,
          total_limit: data.total_limit || null
        })
        toast.success("Conta editada com sucesso")
        toggleModalEditAccount()

        const registers = [...registerCredits]

        const registerItemIndex = registers.findIndex(reg => reg.id === item.id)

        if (registerItemIndex !== -1) {
          const newUser = {
            ...response.data,
            credit_register_items: item.credit_register_items
          }
          registers[registerItemIndex] = newUser
        }

        setRegisterCredits(registers)

      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error("Erro ao editar conta")
        }

      }
    }

  }

  const [isSubmiting, setIsSubmiting] = useState(false)

  async function handleSubmitPayoff(data) {

    setIsSubmiting(true)
    if (valueToPayOff > Math.abs(item.balance)) {
      toast.error("Favor inserir valor igual ao menor que o devedor")
      setIsSubmiting(false)
    } else if (valueToPayOff < Math.abs(item.balance)) {

      try {
        if (payoffRef?.current) {
          payoffRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          value: Yup.string().required('Valor obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        if (!dueDate) {
          setVerifyDueDate(false)
        } else {
          const response = await api.post('/restaurants/credit-register/item', {
            value: parseFloat(data.value),
            credit_register_id: item.id,
            due_date: dueDate,
            bank_account_id: data.bank_account_id || null,
            cash_flow_payment_method_id: data.cash_flow_payment_method_id || null,

          })
          setIsPayoffModalOpened(false)
          setNfceArea(false)
          toast.success("Valor inserido com sucesso!")

          setIsSubmiting(false)

          const registers = [...registerCredits]

          const registerItemIndex = registers.findIndex(reg => reg.id === item.id)

          if (registerItemIndex !== -1) {
            registers[registerItemIndex] = response.data
          }

          setRegisterCredits(registers)
          setTotalRegisterToReceive(oldValue => { return oldValue - Number(data.value) })

          console.log(data.value)
        }

      } catch (error) {
        setIsSubmiting(false);

        if (error?.response?.data?.errorType === 'emit_nfce_issues') {
          setResponseNfce(error.response.data.payload);
        }

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (payoffRef?.current) {
            payoffRef.current.setErrors(errors);
          }
        } else if (error.response.data.errorType === 'cashier_opening_not_found') {
          toast.error("Não foi encontrado caixa em aberto. Favor abrir caixa para quitar a conta.")
        } else {
          toast.error("Erro ao quitar valor")
          console.log(error.message)
        }

      }
    } else {

      if (nfceArea) {
        if (typeSelected === 'nfce') {
          // const dataSent = { key: data.session.key || session?.session_key };
          const dataSent = {};

          if (data.email !== null && data.email !== '') {
            dataSent.email = data.email;
          }

          if (data.cpf !== '' && data.cpf !== null) {
            dataSent.cpf = data.cpf;
          }

          if (data.cnpj !== '' && data.cnpj !== null) {
            dataSent.cnpj = data.cnpj;
          }

          if (data.informacoes_adicionais_contribuinte) {
            dataSent.informacoes_adicionais_contribuinte =
              data.informacoes_adicionais_contribuinte;
          }

          dataSent.value = parseFloat(data.value)
          dataSent.credit_register_id = item.id
          dataSent.due_date = dueDate
          dataSent.bank_account_id = data.bank_account_id || null
          dataSent.cash_flow_payment_method_id = data.cash_flow_payment_method_id || null
          dataSent.nfce_type = typeSelected
          try {
            if (payoffRef?.current) {
              payoffRef.current.setErrors({});
            }

            const schema = Yup.object().shape({
              value: Yup.string().required('Valor obrigatório'),


            });

            await schema.validate(data, {
              abortEarly: false,
            });

            if (!dueDate) {
              setVerifyDueDate(false)
            } else {
              const response = await api.post(`/restaurants/credit-register/finish`, dataSent)
              toast.success("Conta a prazo quitada")
              setNfceArea(false)
              setIsPayoffModalOpened(false)
              setTotalRegisterToReceive(oldValue => { return oldValue - Number(data.value) })

              const registers = [...registerCredits]

              const registerItemIndex = registers.findIndex(reg => reg.id === item.id)

              if (registerItemIndex !== -1) {
                registers[registerItemIndex].balance = 0
                registers[registerItemIndex].credit_register_items = []
              }

              setRegisterCredits(registers)


              if (response.data.nfce.status_sefaz === '100') {
                window.open(
                  `https://api.focusnfe.com.br${response.data.nfce.caminho_danfe}`,
                  'janela',
                  'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
                );
              }
            }
            setIsSubmiting(false)
          } catch (error) {
            setIsSubmiting(false);

            if (error?.response?.data?.errorType === 'emit_nfce_issues') {
              setResponseNfce(error.response.data.payload);
            }

            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              if (payoffRef?.current) {
                payoffRef.current.setErrors(errors);
              }
            } else if (error.response.data.errorType === 'cashier_opening_not_found') {
              toast.error("Não foi encontrado caixa em aberto. Favor abrir caixa para quitar a conta.")
            } else {
              toast.error("Erro ao quitar valor")
              console.log(error.message)
            }

          }
        } else {
          const parsedData = {
            // key: data.session.key || session.session_key,
            cnpj: data.cnpj,
            cpf: data.cpf,
            nome_destinatario: data.nome_destinatario,
            logradouro_destinatario: data.logradouro_destinatario,
            numero_destinatario: data.numero_destinatario,
            bairro_destinatario: data.bairro_destinatario,
            municipio_destinatario: data.municipio_destinatario,
            uf_destinatario: data.uf_destinatario,
            indicador_inscricao_estadual_destinatario: ieSelected,
            local_destino: localDestinoSelected,
            informacoes_adicionais_contribuinte:
              data.informacoes_adicionais_contribuinte,
          };

          if (data.inscricao_estadual_destinatario !== '') {
            parsedData.inscricao_estadual_destinatario =
              data.inscricao_estadual_destinatario;
          }

          if (data.email !== null && data.email !== '') {
            parsedData.email = data.email;
          }

          parsedData.value = parseFloat(data.value)
          parsedData.credit_register_id = item.id
          parsedData.due_date = dueDate
          parsedData.bank_account_id = data.bank_account_id || null
          parsedData.cash_flow_payment_method_id = data.cash_flow_payment_method_id || null
          parsedData.nfce_type = typeSelected


          try {
            if (payoffRef?.current) {
              payoffRef.current.setErrors({});
            }

            const schema = Yup.object().shape({
              value: Yup.string().required('Valor obrigatório'),


            });

            await schema.validate(data, {
              abortEarly: false,
            });

            if (!dueDate) {
              setVerifyDueDate(false)
            } else {
              const response = await api.post(`/restaurants/credit-register/finish`, parsedData)
              toast.success("Conta a prazo quitada")
              setNfceArea(false)
              setTotalRegisterToReceive(oldValue => { return oldValue - Number(data.value) })
              setIsPayoffModalOpened(false)

              const registers = [...registerCredits]

              const registerItemIndex = registers.findIndex(reg => reg.id === item.id)

              if (registerItemIndex !== -1) {
                registers[registerItemIndex].balance = 0
                registers[registerItemIndex].credit_register_items = []
              }

              setRegisterCredits(registers)

              if (response.data.nfce.status_sefaz === '100') {
                window.open(
                  `https://api.focusnfe.com.br${response.data.nfce.caminho_danfe}`,
                  'janela',
                  'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
                );
              }
            }
            setIsSubmiting(false)
          } catch (error) {
            setIsSubmiting(false)
            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              if (payoffRef?.current) {
                payoffRef.current.setErrors(errors);
              }
            } else if (error.response.data.errorType === 'cashier_opening_not_found') {
              toast.error("Não foi encontrado caixa em aberto. Favor abrir caixa para quitar a conta.")
            } else {
              toast.error("Erro ao quitar conta")
              console.log(error.message)
            }
          }

        }
      } else {
        try {
          if (payoffRef?.current) {
            payoffRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (!dueDate) {
            setVerifyDueDate(false)
          } else {
            const response = await api.post(`/restaurants/credit-register/finish`, {
              value: parseFloat(data.value),
              credit_register_id: item.id,
              due_date: dueDate,
              bank_account_id: data.bank_account_id || null,
              cash_flow_payment_method_id: data.cash_flow_payment_method_id || null,
              cpf: item.cnpj,
              cnpj: item.cnpj,
              informacoes_adicionais_contribuinte: "",
            })
            toast.success("Conta a prazo quitada")
            setNfceArea(false)
            setTotalRegisterToReceive(oldValue => { return oldValue - Number(data.value) })
            setIsPayoffModalOpened(false)

            const registers = [...registerCredits]

            const registerItemIndex = registers.findIndex(reg => reg.id === item.id)

            if (registerItemIndex !== -1) {
              registers[registerItemIndex].balance = 0
              registers[registerItemIndex].credit_register_items = []
            }

            setRegisterCredits(registers)
          }
          setIsSubmiting(false)
        } catch (error) {
          setIsSubmiting(false);

          if (error?.response?.data?.errorType === 'emit_nfce_issues') {
            setResponseNfce(error.response.data.payload);
          }

          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (payoffRef?.current) {
              payoffRef.current.setErrors(errors);
            }
          } else if (error.response.data.errorType === 'cashier_opening_not_found') {
            toast.error("Não foi encontrado caixa em aberto. Favor abrir caixa para quitar a conta.")
          } else {
            toast.error("Erro ao quitar conta")
            console.log(error.message)
          }
        }
      }
    }
  }

  //fiscal
  const [cnpj, setCnpj] = useState(null);
  const [loadingNfce, setLoadingNfce] = useState(false);
  const [formData, setFormData] = useState({
    type: 'nfce',
    doc: 'cpf',
  });
  const [typeSelected, setTypeSelected] = useState('nfce');
  const [docSelected, setDocSelected] = useState('cpf');
  const [ieSelected, setIeSelected] = useState('9');
  const [localDestinoSelected, setLocalDestinoSelected] = useState(1);
  const [ufSelected, setUfSelected] = useState(null);
  const [initialDataNfce, setInitialDataNfce] = useState({
    local_destino: 1,
    indicador_inscricao_estadual_destinatario: '9',
  });
  const uf_options = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ];
  const type_options = [
    { value: 'nfce', label: 'NFCe (modelo 65)' },
    { value: 'nfe', label: 'NFe (modelo 55)' },
  ];

  const doc_options = [
    { value: 'cpf', label: 'CPF' },
    { value: 'cnpj', label: 'CNPJ' },
  ];

  const local_options = [
    { value: 1, label: 'Operação Interna' },
    { value: 2, label: 'Operação Interestadual' },
  ];

  const ie_options = [
    {
      value: '1',
      label: '1 – Contribuinte ICMS (informar a IE do destinatário);',
    },
    {
      value: '2',
      label:
        '2 – Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS;',
    },
    {
      value: '9',
      label:
        '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.',
    },
  ];

  const componentRef = useRef(null)

  const handleDeleteCreditRegister = async () => {
    const confirm = window.confirm("Deseja realmente excluir essa conta?")
    if (confirm) {
      await api.delete(`/restaurants/credit-register/${item.id}`)
        .then((response) => {
          console.log(response)
          getCreditRegisters()
          toast.success("Conta excluída com sucesso")
        })
        .catch((error) => {
          toast.error("Erro ao excluir conta")
          console.log(error)
        })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [adminPassword, setAdminPassword] = useState('')
  const [creditItemId, setCreditItemId] = useState(null)
  const handleOpenCreditRegisterItemDeleteModal = (creditItemId, cashFlowId) => {
    setDeleteModal(true)
    setCreditItemId(creditItemId)
  }
  const handleCloseCreditRegisterItemDeleteModal = () => {
    setDeleteModal(false)
    setCreditItemId(null)
    setAdminPassword('')
  }
  const handleDeleteCreditRegisterItem = async () => {
    if (adminPassword !== user.order_cancel_password) {
      toast.error('Senha administrativa incorreta')
      return
    }
    await api.delete(`/restaurants/credit-register/item/${creditItemId}`)
      .then((response) => {
        console.log('response', response.data)
        toast.success('Pagamento excluído com sucesso')
        setDeleteModal(false)
        setCreditItemId(null)
        setAdminPassword('')
      })
      .catch((err) => {
        console.log('err', err)
        toast.error('Erro ao excluir pagamento')
      })
  }

  // baixar planilha detalhada 
  const handleRegisterDownload = async (id) => {
    try {
      const response = await api.get(`/restaurants/credit-register/summary/${id}`)
      const sheet = await generateSheet(response.data);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(blob, `Contas-a-prazo-${item.name}.xlsx`);
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const handleProductsDownload = async (id) => {
    try {
      const response = await api.get(`/restaurants/credit-register/products/${id}`)
      const sheet = await generateProductsSheet(response.data);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(blob, `Produtos-Contas-a-prazo-${item.name}.xlsx`);
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  return (
    <>
      <Modal isOpen={deleteModal} toggle={handleCloseCreditRegisterItemDeleteModal} size='sm'>
        <div style={{
          width: "100%",
          padding: 20,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: 12,
        }}>
          <span>Digite a senha administrativa para excluir o pagamento. (O lancamento referente a este pagamento tambem sera excluido)</span>
          <DefaultInput
            title="Senha administrativa:"
            id="cancel_password"
            name="testing"
            type="password"
            autocomplete="new-password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
          />
          <div style={{ display: 'flex', gap: 12 }}>
            <Button
              title="Fechar"
              onClick={handleCloseCreditRegisterItemDeleteModal}
            />
            <Button
              title="Excluir"
              buttonColor="#2ec9b7"
              onClick={handleDeleteCreditRegisterItem}
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={isModalEditAccountOpened} toggle={toggleModalEditAccount}>
        <div style={{ width: "100%", padding: 20, display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold" }}>Editar conta</span>
          <FiX color='#FF2C3A' onClick={toggleModalEditAccount} cursor="pointer" />
        </div>
        <Form onSubmit={handleSubmit} initialData={initialData} ref={formRef}>
          <ModalBody>

            <Row>
              <Col md="7">
                <Input name="name" label="Nome da conta" />
              </Col>
              <Col Col md="5">
                <InputMask mask="(99) 99999-9999">
                  <Input name="phone" label="Telefone"></Input>
                </InputMask>
              </Col>
            </Row>
            <Row>
              <Input name="cnpj" label="CPF/ CNPJ" />
            </Row>
            <Row>
              <Col md="6">
                <p style={{ fontWeight: "bold" }}>Limite de gasto</p>
                <span>Se esse campo estiver em branco, o saldo nao será limitado</span>
                <Input name="total_limit" placeHolder="- R$" type="number" step="0.01" />
              </Col>
              <Col md="6">
                <p style={{ fontWeight: "bold" }}>Validade do saldo</p>
                <span>Se esse campo estiver em branco, não haverá data limite</span>
                <DatePickerStyledUnify
                  locale="pt"
                  name="expires_at"
                  selected={expiresAt}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setExpiresAt(date)}
                />
              </Col>
            </Row>

          </ModalBody >

          <div style={{ width: "100%", padding: 20, display: "flex", justifyContent: "flex-end" }}>
            <PersonalButton message="Salvar" fontColor="#fff" color="#2ec9b7" type="submit" />
          </div>
        </Form>
      </Modal >

      <Modal isOpen={isPayoffModalOpened} toggle={togglePayoffModal} size='lg'>
        <div style={{ width: "100%", padding: 20, display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold" }}>Quitar conta</span>
          <FiX color='#FF2C3A' onClick={() => { togglePayoffModal(); setNfceArea(false) }} cursor="pointer" />
        </div>
        <Form onSubmit={handleSubmitPayoff} ref={payoffRef}>
          <ModalBody>

            <Row>
              <Col md="6">
                <Input name="value" label="Valor" type="number" step="0.01" onChange={(e) => { setValueToPayOff(e.target.value) }} />
              </Col>
              <Col Col md="6">
                <p style={{ fontWeight: "bold" }}>Vencimento:</p>

                <DatePickerStyledUnify
                  locale="pt"
                  name="due_date"
                  selected={dueDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="__/__/____"
                  onChange={(date) => setDueDate(date)}
                />
                {!verifyDueDate && <p style={{ color: "#FF2C3A" }}>Informar o vencimento</p>}
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Select name="bank_account_id" label="Conta" options={banks} />
              </Col>
              <Col md="6">
                <Select name="cash_flow_payment_method_id" label="Forma de pagamento" options={paymentMethodsOptions} />
              </Col>

            </Row>
            {Math.abs(parseFloat(valueToPayOff)).toString() === Math.abs(parseFloat(item.balance)).toString() && user.has_nfce && user.credit_register_with_nfce && (
              <Row>
                <CheckboxInput name="is_nfce" label="Emitir nota fiscal" onChange={() => setNfceArea(!nfceArea)} />
              </Row>
            )}

            {
              user.has_nfce && nfceArea && (
                <div>
                  <Row>
                    <Col lg="12">

                      <>
                        <Row>
                          <Col md="12">
                            <Select
                              label="Tipo:"
                              name="type"
                              options={type_options}
                              defaultValue={{ value: 'nfce', label: 'NFCe (modelo 65)' }}
                              onChange={(e) => {
                                setFormData({ ...formData, type: e.value });
                                if (e.value === 'nfe') {
                                  setDocSelected('cnpj');
                                } else {
                                  setDocSelected('cpf');
                                }
                                setTypeSelected(e.value);
                              }}
                            />
                          </Col>
                        </Row>
                        {typeSelected === 'nfce' && (
                          <>
                            <Row>
                              <Col md="3">
                                <div style={{ marginTop: 13 }}>
                                  <Select
                                    label="Documento:"
                                    name="doc"
                                    options={doc_options}
                                    defaultValue={{ value: 'cpf', label: 'CPF' }}
                                    onChange={(e) => {
                                      setFormData({ ...formData, doc: e.value });
                                      setDocSelected(e.value);
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="9">
                                {docSelected === 'cpf' ? (
                                  <InputMask
                                    mask="999.999.999-99"
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        cpf: e.target.value,
                                      })
                                    }
                                  >
                                    <Input
                                      label="CPF: (opcional)"
                                      name="cpf"
                                      type="text"
                                      placeholder="xxx.xxx.xxx-xx"
                                    />
                                  </InputMask>
                                ) : (
                                  <InputMask
                                    mask="99.999.999/9999-99"
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        cnpj: e.target.value,
                                      })
                                    }
                                  >
                                    <Input
                                      label="CNPJ: (opcional)"
                                      name="cnpj"
                                      type="text"
                                      placeholder="xx.xxx.xxx/xxxx-xx"
                                    />
                                  </InputMask>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <Input
                                  label="Informações Adicionais"
                                  name="informacoes_adicionais_contribuinte"
                                  type="text"
                                  placeholder=""
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      informacoes_adicionais_contribuinte:
                                        e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </>
                        )}

                        {typeSelected === 'nfe' && (
                          <>
                            <Row>
                              <Col md="3">
                                <div style={{ marginTop: 13 }}>
                                  <Select
                                    label="Documento:"
                                    name="doc"
                                    options={doc_options}
                                    defaultValue={
                                      docSelected === 'cpf'
                                        ? { value: 'cpf', label: 'CPF' }
                                        : { value: 'cnpj', label: 'CNPJ' }
                                    }
                                    onChange={(e) => {
                                      setDocSelected(e.value);
                                      if (e.value === 'cpf') {
                                        setIeSelected('2');
                                      } else {
                                        setIeSelected('9');
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                              {docSelected === 'cpf' ? (
                                <Col md="9">
                                  <InputMask
                                    mask="999.999.999-99"
                                    onChange={(e) => setCnpj(e.target.value)}
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        cpf: e.target.value,
                                      })
                                    }
                                  >
                                    <Input
                                      label="CPF:"
                                      name="cpf"
                                      type="text"
                                      placeholder="xxx.xxx.xxx-xx"
                                    />
                                  </InputMask>
                                </Col>
                              ) : (
                                <Col md="9">
                                  <InputMask
                                    mask="99.999.999/9999-99"
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        cnpj: e.target.value,
                                      })
                                    }
                                    onChange={(e) => setCnpj(e.target.value)}
                                  >
                                    <Input
                                      label="CNPJ:"
                                      name="cnpj"
                                      type="text"
                                      placeholder="xx.xxx.xxx/xxxx-xx"
                                    />
                                  </InputMask>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              <Col>
                                <Input
                                  label="Nome:"
                                  name="nome_destinatario"
                                  type="text"
                                  placeholder=""
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      nome_destinatario: e.target.value,
                                    })
                                  }
                                />
                              </Col>

                              <Col>
                                <Input
                                  label="Logradouro:"
                                  name="logradouro_destinatario"
                                  type="text"
                                  placeholder=""
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      logradouro_destinatario: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="3">
                                <Input
                                  label="Número:"
                                  name="numero_destinatario"
                                  type="text"
                                  placeholder=""
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      numero_destinatario: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col md="6">
                                <Input
                                  label="Bairro:"
                                  name="bairro_destinatario"
                                  type="text"
                                  placeholder=""
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      bairro_destinatario: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col md="3">
                                {/* <Input
                                    label="UF:"
                                    name="uf_destinatario"
                                    type="text"
                                    placeholder=""
                                    
                                    // value={initialData?.uf_destinatario && uf_options.filter(local => local.value === initialData?.uf_destinatario)}
                                    // onChange={(e) => setUfSelected(e.value)}
                                  /> */}
                                <Select
                                  label="UF:"
                                  name="uf_destinatario"
                                  placeholder="Estado"
                                  options={uf_options}
                                  onChange={(e) => setUfSelected(e.value)}
                                  value={
                                    uf_options.filter(
                                      (local) => local.value === ufSelected
                                    ) || null
                                  }
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      uf_destinatario: e.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col md="6">
                                <Input
                                  label="Município:"
                                  name="municipio_destinatario"
                                  type="text"
                                  placeholder=""
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      municipio_destinatario: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col>
                                <div style={{ marginTop: 13 }}>
                                  <Select
                                    label="Estado de origem:"
                                    name="local_destino"
                                    options={local_options}
                                    // defaultValue={{
                                    //   value: 1,
                                    //   label: 'Operação Interna',
                                    // }}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        local_destino: e.value,
                                      });
                                      setLocalDestinoSelected(e.value);
                                    }}
                                    value={local_options.filter(
                                      (local) =>
                                        local.value === localDestinoSelected
                                    )}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div style={{ marginTop: 15 }}>
                                  <Select
                                    label="Indicador da Inscrição Estadual:"
                                    name="indicador_inscricao_estadual_destinatario"
                                    options={ie_options}
                                    // defaultValue={{
                                    //   value: '9',
                                    //   label:
                                    //     '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.',
                                    // }}
                                    onChange={(e) => {
                                      setIeSelected(e.value);
                                      setFormData({
                                        ...formData,
                                        indicador_inscricao_estadual_destinatario:
                                          e.value,
                                      });
                                    }}
                                    value={ie_options.filter(
                                      (local) => local.value === ieSelected
                                    )}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Input
                                  label={
                                    ieSelected === '1'
                                      ? 'Inscrição Estadual: (obrigatório)'
                                      : 'Inscrição Estadual: (opcional)'
                                  }
                                  name="inscricao_estadual_destinatario"
                                  type="text"
                                  placeholder=""
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      inscricao_estadual_destinatario:
                                        e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <Input
                                  label="Informações Adicionais"
                                  name="informacoes_adicionais_contribuinte"
                                  type="text"
                                  placeholder=""
                                  onBlur={(e) =>
                                    setFormData({
                                      ...formData,
                                      informacoes_adicionais_contribuinte:
                                        e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row>
                          <Col md="12">
                            <Input
                              label="E-mail para enviar cópia da Nota Fiscal: (opcional)"
                              name="email"
                              type="email"
                              placeholder="exemplo@gmail.com"
                              onBlur={(e) =>
                                setFormData({ ...formData, email: e.target.value })
                              }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            {responseNfce.mensagem_sefaz && (
                              <div style={{ marginTop: 10 }}>
                                {(responseNfce.status_sefaz === '100' || responseNfce.status === 'processando_autorizacao') ? (
                                  <p>
                                    <strong style={{ color: 'green' }}>
                                      {responseNfce.mensagem_sefaz}
                                    </strong>
                                  </p>
                                ) : (
                                  <div>
                                    <p>
                                      <strong style={{ color: 'red' }}>
                                        Mensagem SEFAZ:{' '}
                                        {responseNfce.response
                                          ? responseNfce.response.mensagem
                                          : responseNfce.mensagem_sefaz}
                                      </strong>
                                    </p>
                                    {typeSelected === 'nfe' &&
                                      responseNfce.response &&
                                      responseNfce.response.codigo ===
                                      'erro_validacao_schema' &&
                                      responseNfce.response.erros &&
                                      responseNfce.response.erros.map((erro) => (
                                        <p> - {erro.mensagem}</p>
                                      ))}
                                    {responseNfce.response &&
                                      responseNfce.response.codigo ===
                                      'erro_validacao_schema' &&
                                      responseNfce.erros_schema &&
                                      responseNfce.erros_schema.map((e) => (
                                        <p>
                                          {' '}
                                          - <strong>{e.name}:</strong>{' '}
                                          {e.erro}
                                        </p>
                                      ))}
                                  </div>
                                )}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </>

                    </Col>
                  </Row>
                </div>
              )
            }
          </ModalBody >

          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20
          }}>
            <span style={{ fontSize: 12 }}>Essa ação gera um "contas à receber".</span>
            <PersonalButton
              message={isSubmiting ? "Aguarde" : "Salvar"}
              fontColor="#fff"
              color="#2ec9b7"
              type="submit"
              disabled={isSubmiting}
            />
          </div>
        </Form>
      </Modal >

      <Container>
        <TdStyled onClick={() => handleSelectAccount(item.id)} style={{ cursor: "pointer" }}>
          <FaAngleDown cursor="pointer" style={{
            transform: accountSelected.includes(item.id) ? "rotate(-90deg)" : "rotate(0deg)"
          }} /> {item.name}
        </TdStyled>
        <TdStyled>
          {item.phone}
        </TdStyled>
        <TdStyled>
          R$ {item.balance}
        </TdStyled>
        <TdStyled>
          {item.total_limit > 0 ? `R$ ${item.total_limit}` : "-"}

        </TdStyled>
        <TdStyled style={{ textAlign: "right" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {item.balance >= 0
              ? <ClosedButton>Fechado</ClosedButton>
              : <PersonalOutlineButton
                  message="Quitar"
                  color="transparent"
                  fontColor="#2EC9B7"
                  borderColor="#2EC9B7" onClick={togglePayoffModal}
                />
            }

            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen((open) => !open)}
              direction="up"
            >
              <DropdownToggle tag="span">
                {' '}
                <FaEllipsisV
                  style={{ marginLeft: 8, marginRight: 5 }}
                  size={14}
                  id={`ellipsis2-${item?.id}`}
                />
              </DropdownToggle>

              <DropdownMenu>
                <DropdownItemStyled
                  onClick={() => toggleModalEditAccount()}
                >
                 <FaPen
                   color='#666666'
                   size={16}
                   style={{ marginRight: 8 }}
                 />
                  Editar
                </DropdownItemStyled>

                {item.balance >= 0 && (
                  <DropdownItemStyled
                    onClick={() => handleDeleteCreditRegister()}
                  >
                   <FaTrash
                     color='#666666'
                     size={16}
                     style={{ marginRight: 8 }}
                   />
                    Excluir
                  </DropdownItemStyled>
                )}

                <ReactToPrint
                  trigger={() => (
                    <DropdownItemStyled >
                      <FaPrint
                        color='#666666'
                        size={16}
                        style={{ marginRight: 8 }}
                      />
                      Imprimir
                    </DropdownItemStyled>
                  )}
                  content={() => componentRef.current}
                />

                <DropdownItemStyled
                  onClick={() => handleRegisterDownload(item?.id)}
                >
                  <FaDownload
                    color='#666666'
                    size={16}
                    style={{ marginRight: 8 }}
                  />
                  Baixar Histórico
                </DropdownItemStyled>

                <DropdownItemStyled
                  onClick={() => handleProductsDownload(item?.id)}
                >
                  <FaDownload
                    color='#666666'
                    size={16}
                   style={{ marginRight: 8 }}
                  />
                  Baixar Relatório de Produtos
                </DropdownItemStyled>
              </DropdownMenu>
            </Dropdown>
          </div>

        </TdStyled>

      </Container>
      <Collapse isOpen={accountSelected.includes(item.id)} tag="tr">
        <td style={{ border: "none", fontWeight: "bold" }}>
          Visitas: {item?.credit_register_items?.length || 0}
        </td>
        <td style={{ border: "none", fontWeight: "bold" }}>
          Comanda
        </td>
        <td style={{ border: "none", fontWeight: "bold" }}>
          Valor consumido
        </td>
        <td colSpan={2} style={{ border: "none", fontWeight: "bold", textAlign: "left" }}>
          Usuário
        </td>
      </Collapse>

      {item && item.credit_register_items?.length > 0 && item.credit_register_items?.map(session => (
        <Collapse isOpen={accountSelected.includes(item.id)} key={session.id} tag="tr">
          <td style={{ border: "none" }}>
            {formatCompleteDate(session.createdAt)}
          </td>
          <td style={{ border: "none" }}>
            {session.value > 0 ? `Pagamento` : `# ${session.session_id}`}

          </td>
          <td style={{ border: "none", color: session.value > 0 ? "#2EC9B7" : '#FF2C3A' }}>
            R$ {session.value}
          </td>
          <td colSpan={1} style={{ border: "none", textAlign: "left" }}>{user.name || ""}</td>
          {session.cash_flow_id && (
            <td style={{ border: "none" }}>
              <FaTrash
                color='#FF2C3A'
                size={18}
                style={{ marginLeft: 8 }}
                cursor="pointer"
                onClick={() => handleOpenCreditRegisterItemDeleteModal(session.id, session.cash_flow_id)}
              />
            </td>
          )}
        </Collapse >
      ))}

      <DisplayNoneImprimir>
        <PrintTermAccountDetails
          item={item}
          restaurant={user}
          ref={componentRef}
        // dates={{ initialDate, endDate }}
        />
      </DisplayNoneImprimir>

    </>);
}

export default CreditRegisterItem;