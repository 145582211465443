import React from 'react';
import { Container } from './styles';

export default function Warning({ type, isOpen, children }) {

  return (
    <Container type={type} isOpen={isOpen}>
      {children}
    </Container>
  );
}
