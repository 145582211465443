/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useContext, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FiMenu, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import ReactModal from 'react-modal';
import * as Yup from 'yup';

import { toast } from 'react-hot-toast';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Col,
  Row,
  Tooltip,
} from 'reactstrap';
import { FaAngleDown, FaCheck, FaEllipsisV, FaImage } from 'react-icons/fa';
import ImageInput from '~/components/Form/ImageInput';
import RadioGroup from '~/components/RadioGroup';

import EditProduct from '~/pages/Products/Edit';

import api from '~/services/api';
import Checkbox from '~/components/Form/Checkbox';
import { useAuth } from '~/context/AuthContext';
import ProductsComplementsCategory from '~/pages/Products/ProductsComplementsCategory';
import PersonalButton from '~/components/Buttons/PersonalButton';
import Select from '~/components/Form/SelectInput';
import Input from '~/components/Form/Input';
import RegisterButton from '~/components/Buttons/RegisterButton';
import getValidationErrors from '~/services/getValidationErrors';
import { differenceInMinutes, format, set } from 'date-fns';
import { Button, DefaultInput } from 'ui-kit-takeat';
import { PersonalizedHoursContainer } from '~/pages/Products/Edit/styles';
import { useNotify } from '~/context/NotifyContext';
import formatValue from '../../../utils/formatValue';
import {
  ProductTableRow,
  ProductImage,
  MenuItemPromotion,
  NoImage,
  Description,
  ProductName,
  ProductPrice,
  ProductDescription,
  ProductAvailable,
  ButtonsTd,
  ItemTd,
  ProductAvatar,
  NumberFormatStyled,
  SubmitStyledButton,
  ButtonLinnk,
  Content,
  LegendStyled,
  ButtonsArea,
  TdDisplayNone,
  AreaSwitcher,
  SwitchStyled,
  StyledDropdownItem,
} from './styles';
import OrderProductContext from '../../../pages/Products/ProductsTables/context';

ReactModal.setAppElement('#root');

const duplicateOptions = [
  {
    value: 1,
    label: 'Duplicar produto e manter os vínculos com os complementos.',
    description:
      'Cria uma cópia do produto e mantém os complementos originais.',
  },
  {
    value: 2,
    label: 'Duplicar produto e ignorar os complementos.',
    description:
      'Cria uma cópia do produto sem "Grupo de complementos" associado.',
  },
  {
    value: 3,
    label: 'Duplicar produto e seus complementos.',
    description:
      'Cria uma cópia do produto e também cria uma cópia dos "Grupos de complementos" associados.',
  },
];

export default function ItemProduct({
  product,
  category,
  handleDelete,
  getCategories,
  index,
  menewProducts,
  setProducts,
  products,
  categories,
  setCategoriesFiltered,
  setCategories,
  sliceSize,
  setComplementsCategories,
  complementCategories,
  fiscalGroups,
}) {
  const [modalImage, setModalImage] = useState(false);
  const [modalEditProduct, setModalEditProduct] = useState(false);
  const [
    complementCategoriesInsideProduct,
    setComplementCategoriesInsideProduct,
  ] = useState([]);
  const { user, combinedGroup } = useAuth();

  const canCreateProduct =
    !user?.price_table || user?.price_table?.create_products;
  const canEditProduct =
    !user?.price_table ||
    user?.price_table?.edit_products ||
    !user?.price_table?.is_customizable;
  const canCreateComplementsCategory =
    !user?.price_table || user?.price_table?.create_complement_categories;
  // drag and drop
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownHidden, setDropDownHidden] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const { move, onDrop } = useContext(OrderProductContext);

  const complementCategoriesOptions = complementCategories.map((cat) => {
    return {
      label: cat.name,
      value: cat.id,
    };
  });

  const [enableTimes, setEnableTimes] = useState(false);
  const [daysSelected, setDaysSelected] = useState([
    't',
    't',
    't',
    't',
    't',
    't',
    't',
  ]);
  const [hourSelected, setHourSelected] = useState({
    start: '00:00',
    end: '23:59',
  });

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
    });

    return validTime;
  };

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'PRODUCTCARD', index, product_id: product.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        const draggedIndex = item.index;
        const targetIndex = index;

        onDrop(item.product_id, product.id, draggedIndex, targetIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: 'PRODUCTCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.product_id, product.id, draggedIndex, targetIndex);

      item.index = targetIndex;
    },
    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      onDrop(item.product_id, product.id, draggedIndex, targetIndex);

      setDropDownHidden(false);
    },
  });

  dragRef(dropRef(ref));

  function toggleImage() {
    setModalImage(!modalImage);
  }

  function toggleModalEditProduct() {
    setModalEditProduct(!modalEditProduct);
  }

  const initialData = {
    image_id: {
      id: product.image ? product.image.id : null,
      url: product.image ? product.image.url_thumb || product.image.url : null,
    },
  };

  const checkPersonalizedHoursActive = () => {
    if (!product?.enable_times) {
      return true;
    }
    const daysActive = product?.active_days.split('');
    const today = new Date().getDay();

    if (daysActive[today] === 't') {
      const diff1 = differenceInMinutes(
        new Date(`2023/01/01 ${format(new Date(), 'HH:mm')}`),
        new Date(`2023/01/01 ${format(new Date(product?.start_time), 'HH:mm')}`)
      );

      const diff2 = differenceInMinutes(
        new Date(`2023/01/01 ${format(new Date(), 'HH:mm')}`),
        new Date(`2023/01/01 ${format(new Date(product?.end_time), 'HH:mm')}`)
      );

      if (diff1 >= 0 && diff2 <= 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  async function handleSubmit(data) {
    try {
      const parsedData = {};

      if (data.image_id) {
        parsedData.image_id = Number(data.image_id);
      }

      await api.put(`restaurants/products/${product.id}`, parsedData);

      toast.success('Produto atualizado com sucesso!');
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
      toggleImage();
      // getMenu(userScreenTop);

      // getCategories();
    } catch (error) {
      toast.error('Falha ao atualizar produto');
      console.log(error);
    }
  }

  async function handleChangeProduct(value, event, idx) {
    const [type, prodId] = idx.split('-');
    const id = Number(prodId);

    const data = {};

    if (type === 'pdv') {
      data.available = value;
    } else {
      data.available_in_delivery = value;
    }

    if (parseFloat(product.input_master?.quantidade) <= 0) {
      data.available = false;
      toast.error('Coloque uma quantidade acima de 0');
      return false;
    }

    try {
      await api.put(`restaurants/products/${id}`, data);

      const productFind = products?.findIndex((prod) => prod.id === id);

      const newProductsArray = [...products];

      newProductsArray[productFind] = {
        ...product,
        ...data,
      };

      const newProducts = [...newProductsArray];

      setProducts(newProducts);

      const catFind = categories?.findIndex((cat) => cat.id === category.id);

      categories[catFind].products = newProducts;
      localStorage.setItem('@gddashboard:isMenuUpdated', true);

      toast.success('Produto alterado com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar/ desativar produto!');
      console.log(err);
    }
  }

  // const [complementsCategoryInsideProductState, getComplementsCategoryInsideProductState ] = useState()

  const getComplementsCategoryInsideProduct = useCallback(async () => {
    const response = await api.get(
      `/restaurants/product-complement-categories/${product.id}`
    );

    const complementCatFiltered = response.data.filter(
      (item) => item.available_multistore
    );

    setComplementCategoriesInsideProduct(complementCatFiltered);
  }, [product]);

  // const getComplementsCategoryInsideProductNew = useCallback(async (id) => {
  //   const response = await api.get(
  //     `/restaurants/product-complement-categories/${id}`
  //   );

  //   setComplementCategoriesInsideProduct(response.data);
  // }, [product]);

  // useEffect(()=>{
  //   getComplementsCategoryInsideProductNew(complementsCategoryInsideProductState);
  // }, [complementsCategoryInsideProductState, getComplementsCategoryInsideProductNew])
  const [collapsed, setCollapsed] = useState([]);

  const handleCollapse = (id) => {
    getComplementsCategoryInsideProduct();
    if (collapsed.includes(id)) {
      const newCollapse = collapsed.filter((c) => c !== id);
      setCollapsed(newCollapse);
    } else {
      setCollapsed((state) => [...state, id]);
    }
  };

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const [isProductDeliveryPricePromotion, setIsProductDeliveryPricePromotion] =
    useState(false);
  const [isProductPricePromotion, setIsProductPricePromotion] = useState(false);

  // edit price at input
  // const [buttonsSubmitDesbloqued, setButtonsSubmitDesbloqued] = useState([]);
  // const [
  //   buttonsSubmitDeliveryPriceDesbloqued,
  //   setButtonsSubmitDeliveryPriceDesbloqued,
  // ] = useState([]);

  // function handleDesbloquedSubmitButton(id) {
  //   if (product.id === id) {
  //     setButtonsSubmitDesbloqued([id]);
  //   } else {
  //     const idsFiltered = buttonsSubmitDesbloqued.filter((item) => item !== id);
  //     setButtonsSubmitDesbloqued(idsFiltered);
  //   }
  // }

  const [buttonsSubmitChangesDesbloqued, setButtonsSubmitChangesDesbloqued] =
    useState([]);

  function handleDesbloquedSubmitChangesButton(id) {
    if (product.id === id) {
      setButtonsSubmitChangesDesbloqued([id]);
    } else {
      const idsFiltered = buttonsSubmitChangesDesbloqued.filter(
        (item) => item !== id
      );
      setButtonsSubmitChangesDesbloqued(idsFiltered);
    }
  }

  const { toggleNotify } = useNotify();

  const [price, setPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const handleSubmitChanges = useCallback(async () => {
    const readyValue = price.floatValue
      ? (price.floatValue / 100).toFixed(2)
      : price;

    const formatPrice = (price) => {
      return readyValue > 0 ? price.replace(',', '.') : '0.00';
    };

    const formattedPrice = formatPrice(readyValue);

    if (isProductPricePromotion && price.floatValue >= 0) {
      if (Number(formattedPrice) >= product?.price) {
        toast.error(
          'O preço do produto promocional deve ser menor que o preço do produto.'
        );
        return;
      }
      await api.put(`restaurants/products/${product.id}`, {
        price_promotion: formattedPrice,
      });
    }

    if (!isProductPricePromotion && price.floatValue >= 0) {
      await api.put(`restaurants/products/${product.id}`, {
        price: formattedPrice,
      });
    }

    const readyDeliveryValue = deliveryPrice.floatValue
      ? (deliveryPrice.floatValue / 100).toFixed(2)
      : deliveryPrice;

    const formatDeliveryPrice = (price) => {
      return readyDeliveryValue > 0 ? price.replace(',', '.') : '0.00';
    };

    const formattedDeliveryPrice = formatDeliveryPrice(readyDeliveryValue);

    if (isProductDeliveryPricePromotion && deliveryPrice.floatValue >= 0) {
      if (Number(formattedDeliveryPrice) >= product?.delivery_price) {
        toast.error(
          'O preço promocional de delivery deve ser menor que o preço do produto no delivery.'
        );
        return;
      }
      await api.put(`restaurants/products/${product.id}`, {
        delivery_price_promotion: formattedDeliveryPrice,
      });

      const newCategories = [...categories];

      newCategories.map((cat, idix) =>
        cat.products.map((prod, indx) => {
          if (prod.id === product.id) {
            newCategories[idix].products[indx].delivery_price_promotion =
              formattedDeliveryPrice;
          }
        })
      );

      setCategories(newCategories);
    }

    if (!isProductDeliveryPricePromotion && deliveryPrice.floatValue >= 0) {
      await api.put(`restaurants/products/${product.id}`, {
        delivery_price: formattedDeliveryPrice,
      });

      const newCategories = [...categories];

      newCategories.map((cat, idix) =>
        cat.products.map((prod, indx) => {
          if (prod.id === product.id) {
            newCategories[idix].products[indx].delivery_price =
              formattedDeliveryPrice;
          }
        })
      );

      setCategories(newCategories);
    }

    const newCategories = [...categories];

    newCategories.map((cat, idix) =>
      cat.products.map((prod, indx) => {
        if (prod.id === product.id) {
          isProductPricePromotion
            ? (newCategories[idix].products[indx].price_promotion =
              formattedPrice)
            : (newCategories[idix].products[indx].price = formattedPrice);
        }
      })
    );

    setCategories(newCategories);
    localStorage.setItem('@gddashboard:isMenuUpdated', true);

    toast.success('Preço alterado com sucesso.');
    if (combinedGroup.includes(user.id)) {
      toggleNotify();
    }
    setButtonsSubmitChangesDesbloqued([]);
  }, [price, deliveryPrice, categories, setCategories, product]);

  function handleValueChange(e) {
    setPrice(e);
  }

  function handleValueDeliveryChange(e) {
    setDeliveryPrice(e);
  }

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [dupOptionSelected, setDupOptionSelected] = useState(1);

  const handleDuplicateProduct = useCallback(
    async (product_id) => {
      try {
        let complements_action = 'keep';

        if (dupOptionSelected === 2) {
          complements_action = 'remove';
        } else if (dupOptionSelected === 3) {
          complements_action = 'duplicate';
        }

        const response = await api.put(
          `/restaurants/products/duplicate/${product_id}`,
          {},
          {
            params: {
              complements_action,
            },
          }
        );

        const newCategories = [...categories];

        const duplicate_item = response.data;

        const categoryFind = categories.findIndex(
          (item) => item.id === category.id
        );

        newCategories[categoryFind].products.splice(
          [index + 1],
          0,
          duplicate_item
        );

        setProducts(newCategories[categoryFind].products);
        setCategories(newCategories);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);

        toast.success('Produto duplicado com sucesso');
      } catch (error) {
        toast.error('Erro ao duplicar produto');
      }
    },
    [categories, category, index, setCategories, dupOptionSelected, setProducts]
  );

  const [productIdState, setProductIdState] = useState();
  const [createNewComplementCategoryArea, setCreateNewComplementCategoryArea] =
    useState(false);

  const [modalCreateComplementGroup, setModalCreateComplamentGroup] =
    useState(false);

  function toggleModalCrerateComplementGroup() {
    setModalCreateComplamentGroup(!modalCreateComplementGroup);
    setCreateNewComplementCategoryArea(false);
  }

  const [productState, setProductState] = useState();
  const formRef = useRef(null);

  const [availableCategory, setAvailableCategory] = useState(true);

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  const [availableDeliveryCategory, setAvailableDeliveryCategory] =
    useState(true);

  function handleChangeAvailableDeliveryCategory() {
    setAvailableDeliveryCategory(!availableDeliveryCategory);
  }

  async function handleSubmitCategoriesGroup(data) {
    if (createNewComplementCategoryArea) {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          limit: Yup.string().required('Máximo obrigatório'),
          minimum: Yup.string().required('Mínimo obrigatório'),
          question: Yup.string().required('Pergunta obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post(
          'restaurants/product/complement-category',
          {
            name: data.name,
            question: data.question,
            limit: data.limit,
            minimum: Number(data.minimum),
            optional: data.optional,
            available: availableCategory,
            available_in_delivery: availableDeliveryCategory,
            additional: data.additional,
            complements: data.complements,
            more_expensive_only: data.more_expensive_only,
            single_choice: false,
            enable_times: enableTimes,
            active_days: daysSelected.join(''),
            start_time: new Date(`2023/01/01 ${hourSelected.start}`),
            end_time: new Date(`2023/01/01 ${hourSelected.end}`),
          }
        );

        const complementsAlreadyFind = productState?.complement_categories.map(
          (item) => item.id
        );

        if (response.data) {
          await api.put('restaurants/product-complements', {
            product_id: productIdState,
            complement_categories: [
              ...complementsAlreadyFind,
              response.data.id,
            ],
          });
        }

        setComplementsCategories((state) => [...state, response.data]);

        const productFind = products?.findIndex(
          (prod) => prod.id === productIdState
        );

        const newProductsArray = [...products];

        newProductsArray[productFind].has_complement_categories = true;

        const newProducts = [...newProductsArray];

        setProducts(newProducts);

        const catFind = categories?.findIndex((cat) => cat.id === category.id);

        categories[catFind].products = newProducts;

        toast.success('Grupo cadastrado com sucesso!');

        getComplementsCategoryInsideProduct(productIdState);
        toggleModalCrerateComplementGroup();
        setProductState(null);
        setProductIdState(null);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error('Falha ao salvar categoria');
          console.log(error.message);
        }
      }
    } else {
      try {
        await api.put('restaurants/product-complements', {
          product_id: productIdState,
          complement_categories: data.complement_categories,
        });

        const productFind = products?.findIndex(
          (prod) => prod.id === productIdState
        );

        const newProductsArray = [...products];

        newProductsArray[productFind].has_complement_categories = true;

        const newProducts = [...newProductsArray];

        setProducts(newProducts);

        const catFind = categories?.findIndex((cat) => cat.id === category.id);

        categories[catFind].products = newProducts;

        toast.success('Grupo cadastrado com sucesso!');

        toast.success('Produto atualizado com sucesso!');

        getComplementsCategoryInsideProduct(productIdState);
        toggleModalCrerateComplementGroup();
        setProductState(null);
        setProductIdState(null);

        localStorage.setItem('@gddashboard:isMenuUpdated', true);
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  const getProduct = useCallback(async (productId) => {
    const response = await api.get(`/restaurants/products/${productId}`);

    setProductState(response.data);
  }, []);

  const [complementsOptions, setComplementsOptions] = useState([]);

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: complement.name,
    }));

    setComplementsOptions(data);
  }, []);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  function toggleTip() {
    setTooltipOpen(!tooltipOpen);
  }

  const [tooltipEllipsisOpen, setTooltipEllipsisOpen] = useState(false);

  function toggleTip() {
    setTooltipOpen(!tooltipOpen);
  }

  function toggleEllipsisTip() {
    setTooltipEllipsisOpen(!tooltipEllipsisOpen);
  }

  return (
    <>
      <ReactModal
        onRequestClose={toggleModalCrerateComplementGroup}
        isOpen={modalCreateComplementGroup}
        className="modal-content-takeat"
        closeTimeoutMS={500}
      >
        <Content>
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
            onSubmit={handleSubmitCategoriesGroup}
            ref={formRef}
          >
            <div>
              <h3>Grupo de Complementos</h3>

              {!createNewComplementCategoryArea && (
                <>
                  {productState && (
                    <Select
                      label="Associe um grupo de complementos já existente"
                      name="complement_categories"
                      isMulti
                      options={complementCategoriesOptions}
                      disabled={product.is_multistore_child}
                      defaultValue={
                        productState
                          ? productState?.complement_categories?.map(
                            (categ) => ({
                              value: categ.id,
                              label: categ.name,
                            })
                          )
                          : null
                      }
                    />
                  )}

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <RegisterButton
                      text="... ou crie um novo Grupo de Complementos"
                      disabled={createNewComplementCategoryArea}
                      onClick={() => setCreateNewComplementCategoryArea(true)}
                    />
                  </div>
                </>
              )}

              {createNewComplementCategoryArea && (
                <div style={{ marginTop: 20 }}>
                  <Row>
                    <Col sm="12" lg="6">
                      <Input
                        label="Nome"
                        name="name"
                        type="text"
                        placeholder="Nome do grupo"
                      />
                    </Col>
                    <Col sm="12" lg="3">
                      <Input
                        label="Mínimo"
                        name="minimum"
                        type="number"
                        placeholder="1"
                      />
                    </Col>
                    <Col sm="12" lg="3">
                      <Input
                        label="Máximo"
                        name="limit"
                        type="number"
                        placeholder="1"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Input
                        label="Pergunta"
                        name="question"
                        type="text"
                        placeholder="Escolha uma opção de carne, Qual o ponto da carne..."
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Select
                      name="complements"
                      isMulti
                      label="Associe complementos existentes ao grupo"
                      options={complementsOptions}
                    />
                  </Row>
                  <Row>
                    {!user.only_delivery && (
                      <Col md="3">
                        {/* <CheckboxInput
                defaultChecked
                label="Disponível no presencial"
                name="available"
              /> */}

                        <AreaSwitcher title="Disponível no presencial">
                          <SwitchStyled
                            available={
                              product?.available ||
                              product?.available_in_delivery
                            }
                            sold_off={product?.sold_off}
                            onChange={handleChangeAvailableCategory}
                            draggable
                            checked={availableCategory}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={27}
                            width={44}
                            handleDiameter={16}
                            activeBoxShadow="0 0 2px 3px #111"
                            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                            onColor="#0CCAB4"
                            offColor="#666666"
                          />
                          <span>PDV</span>
                        </AreaSwitcher>
                      </Col>
                    )}

                    {user?.delivery_info?.is_delivery_allowed && (
                      <Col md="3">
                        {/* <CheckboxInput
                defaultChecked
                label="Disponível no delivery"
                name="available"
              /> */}
                        <AreaSwitcher title="Disponível no delivery">
                          <SwitchStyled
                            available={
                              product?.available ||
                              product?.available_in_delivery
                            }
                            sold_off={product?.sold_off}
                            onChange={handleChangeAvailableDeliveryCategory}
                            draggable
                            checked={availableDeliveryCategory}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={27}
                            width={44}
                            handleDiameter={16}
                            activeBoxShadow="0 0 2px 3px #111"
                            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                            onColor="#0CCAB4"
                            offColor="#666666"
                          />
                          <span>Delivery</span>
                        </AreaSwitcher>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        defaultChecked
                        label="Opcional"
                        name="optional"
                      />
                      <LegendStyled>
                        O cliente poderá escolher ou não algo neste grupo
                      </LegendStyled>
                    </Col>

                    {/* <Col>
                      <Checkbox
                        defaultChecked
                        label="Escolha única"
                        name="single_choice"
                      />
                      <LegendStyled>
                        O cliente poderá escolher somente uma opção nesta
                        categoria
                      </LegendStyled>
                    </Col> */}
                    <Col>
                      <Checkbox
                        defaultChecked
                        label="Adicional"
                        name="additional"
                      />
                      <LegendStyled>
                        Se marcado, os complementos terão preço adicional. Caso
                        contrário, serão gratúitos
                      </LegendStyled>
                    </Col>
                    <Col>
                      <Checkbox
                        label="Cobra apenas complemento mais caro"
                        name="more_expensive_only"
                      />

                      <LegendStyled>
                        Se marcado, apenas o complemento mais caro da categoria
                        será cobrado.
                      </LegendStyled>
                    </Col>
                  </Row>
                  <Row>
                    <Checkbox
                      label="Horário personalizado"
                      name="enable_times"
                      value={enableTimes}
                      onChange={() => {
                        setEnableTimes(!enableTimes);
                      }}
                    />
                  </Row>
                </div>
              )}
            </div>

            {enableTimes && (
              <PersonalizedHoursContainer>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Dias:</span>
                  <div style={{ display: 'flex', gap: 5 }}>
                    <Button
                      type="button"
                      title="Dom"
                      buttonColor="#2ec9b7"
                      inverted={daysSelected[0] === 'f'}
                      containerStyles={{ width: 60, height: 30 }}
                      onClick={() => {
                        handleChangeDay(0);
                      }}
                    />
                    <Button
                      type="button"
                      title="Seg"
                      containerStyles={{ width: 60, height: 30 }}
                      inverted={daysSelected[1] === 'f'}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(1);
                      }}
                    />
                    <Button
                      type="button"
                      title="Ter"
                      containerStyles={{ width: 60, height: 30 }}
                      inverted={daysSelected[2] === 'f'}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(2);
                      }}
                    />
                    <Button
                      type="button"
                      title="Qua"
                      containerStyles={{ width: 60, height: 30 }}
                      inverted={daysSelected[3] === 'f'}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(3);
                      }}
                    />
                    <Button
                      type="button"
                      title="Qui"
                      containerStyles={{ width: 60, height: 30 }}
                      inverted={daysSelected[4] === 'f'}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(4);
                      }}
                    />
                    <Button
                      type="button"
                      title="Sex"
                      containerStyles={{ width: 60, height: 30 }}
                      inverted={daysSelected[5] === 'f'}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(5);
                      }}
                    />
                    <Button
                      type="button"
                      title="Sáb"
                      containerStyles={{ width: 60, height: 30 }}
                      inverted={daysSelected[6] === 'f'}
                      buttonColor="#2ec9b7"
                      onClick={() => {
                        handleChangeDay(6);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Horários:</span>
                  <div
                    style={{
                      display: 'flex',
                      gap: 5,
                      alignItems: 'center',
                    }}
                  >
                    <span>das</span>
                    <DefaultInput
                      placeholder="__:__"
                      mask="99:99"
                      maskChar="0"
                      style={{
                        width: '70px',
                        padding: '0px 10px',
                        height: '30px',
                      }}
                      inputStyle={{ textAlign: 'center' }}
                      value={hourSelected.start}
                      onChange={(e) => {
                        const { value } = e.target;
                        setHourSelected((state) => {
                          return {
                            start: handleTimeInput(value),
                            end: state.end,
                          };
                        });
                      }}
                    />
                    <span>às</span>
                    <DefaultInput
                      placeholder="__:__"
                      mask="99:99"
                      maskChar="0"
                      style={{
                        width: '70px',
                        padding: '0px 10px',
                        height: '30px',
                      }}
                      inputStyle={{ textAlign: 'center' }}
                      value={hourSelected.end}
                      onChange={(e) => {
                        const { value } = e.target;
                        setHourSelected((state) => {
                          return {
                            start: state.start,
                            end: handleTimeInput(value),
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </PersonalizedHoursContainer>
            )}

            <ButtonsArea>
              <PersonalButton
                color="#FF2C3A"
                message="Cancelar"
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggleModalCrerateComplementGroup();
                }}
              />
              <PersonalButton color="#0CCAB4" message="Salvar" type="submit" />
            </ButtonsArea>
          </Form>
        </Content>
      </ReactModal>
      <ProductTableRow isDragging={isDragging} ref={ref}>
        <ItemTd>
          <FiMenu
            title="Clique e arraste para ordenar"
            size={18}
            onMouseDown={() => setDropDownHidden(true)}
            onMouseUp={() => setDropDownHidden(false)}
          />
          <ProductAvatar
            available={product?.available || product?.available_in_delivery}
            sold_off={product?.sold_off}
          >
            {product?.image ? (
              <ProductImage
                src={product?.image?.url_thumb || product?.image?.url}
                alt="Foto do produto"
                onClick={toggleImage}
                title="Clique para atualizar a foto"
              />
            ) : (
              <NoImage
                onClick={toggleImage}
                title="Clique para atualizar a foto"
              />
            )}
          </ProductAvatar>
          <ProductDescription
            available={product?.available || product?.available_in_delivery}
            sold_off={product?.sold_off}
          >
            <ProductName>{product?.name}</ProductName>
            <Description>{product?.description}</Description>
          </ProductDescription>
        </ItemTd>
        {!user.only_delivery && (
          <ProductAvailable
            onClick={(e) => {
              if (
                product.input_master
                  ? parseFloat(product.input_master?.quantidade) <= 0
                  : false
              ) {
                toast.error(
                  'O produto precisa ter uma quantidade acima de 0. Clique em editar produto e atualize.'
                );
              }

              if (!checkPersonalizedHoursActive()) {
                toast.error(
                  'Para reativar esse item, clique nos 3 pontinhos > Editar item e desmarque a opção “Horário personalizado”',
                  {
                    duration: 5000,
                  }
                );
                return false;
              }
            }}
          >
            <SwitchStyled
              available={product?.available || product?.available_in_delivery}
              sold_off={product?.sold_off}
              id={`pdv-${product?.id}`}
              onChange={handleChangeProduct}
              draggable={false}
              checked={product?.available && checkPersonalizedHoursActive()}
              checkedIcon={false}
              uncheckedIcon={false}
              height={27}
              width={44}
              handleDiameter={16}
              onColor="#0CCAB4"
              offColor="#666666"
              activeBoxShadow="0 0 2px 3px #111"
              boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
              disabled={
                !checkPersonalizedHoursActive() ||
                !product?.available_multistore ||
                (product.input_master
                  ? parseFloat(product.input_master?.quantidade) <= 0
                  : false) ||
                false
              }
            />
          </ProductAvailable>
        )}

        {user?.delivery_info?.is_delivery_allowed && (
          <ProductAvailable
            onClick={(e) => {
              if (
                product.input_master
                  ? parseFloat(product.input_master?.quantidade) <= 0
                  : false
              ) {
                toast.error(
                  'O produto precisa ter uma quantidade acima de 0. Clique em editar produto e atualize.'
                );
              }

              if (!checkPersonalizedHoursActive()) {
                toast.error(
                  'Para reativar esse item, clique nos 3 pontinhos > Editar item e desmarque a opção “Horário personalizado”',
                  {
                    duration: 5000,
                  }
                );
                return false;
              }
            }}
          >
            <SwitchStyled
              available={product?.available || product?.available_in_delivery}
              sold_off={product?.sold_off}
              id={`delivery-${product?.id}`}
              onChange={handleChangeProduct}
              draggable
              checked={
                product?.available_in_delivery && checkPersonalizedHoursActive()
              }
              checkedIcon={false}
              uncheckedIcon={false}
              height={27}
              width={44}
              handleDiameter={16}
              onColor="#0CCAB4"
              offColor="#666666"
              activeBoxShadow="0 0 2px 3px #111"
              boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
              disabled={
                !checkPersonalizedHoursActive() ||
                !product?.available_multistore ||
                (product.input_master
                  ? parseFloat(product.input_master?.quantidade) <= 0
                  : false) ||
                false
              }
            />
          </ProductAvailable>
        )}
        {!user.only_delivery && (
          <ProductPrice>
            {product?.price_promotion ? (
              <>
                <MenuItemPromotion>
                  {formatValue(product?.price)}
                </MenuItemPromotion>

                <NumberFormatStyled
                  available={
                    product?.available || product?.available_in_delivery
                  }
                  sold_off={product?.sold_off}
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={formatValue(product?.price_promotion)}
                  key={product?.price_promotion}
                  onValueChange={(e) => handleValueChange(e)}
                  onClick={() => {
                    setPrice(product?.price_promotion);
                    setIsProductPricePromotion(true);
                    handleDesbloquedSubmitChangesButton(product?.id);
                  }}
                  disabled={!canEditProduct}
                />
                {/* <SubmitStyledButton
                  onClick={() => handleSubmitPrice(true)}
                  type="button"
                  disabled={
                    !buttonsSubmitDesbloqued.includes(product?.id) ||
                    product.is_multistore_child
                  }
                >
                  <FaCheck />
                </SubmitStyledButton> */}
              </>
            ) : (
              <>
                <NumberFormatStyled
                  available={
                    product?.available || product?.available_in_delivery
                  }
                  prefix="R$"
                  format={currencyFormatter}
                  allowLeadingZeros
                  name="value"
                  defaultValue={product?.price}
                  key={product?.price}
                  onValueChange={(e) => handleValueChange(e)}
                  onClick={() => {
                    setPrice(product?.price);
                    handleDesbloquedSubmitChangesButton(product?.id);
                    setIsProductPricePromotion(false);
                  }}
                  disabled={!canEditProduct}
                />
                {/* <SubmitStyledButton
                  onClick={() => handleSubmitPrice(false)}
                  type="button"
                  disabled={
                    !buttonsSubmitDesbloqued.includes(product?.id) ||
                    product.is_multistore_child
                  }
                >
                  <FaCheck />
                </SubmitStyledButton> */}
              </>
            )}
          </ProductPrice>
        )}
        {user?.delivery_info?.is_delivery_allowed && (
          <ProductPrice>
            {product?.delivery_price_promotion ? (
              <>
                <MenuItemPromotion>
                  {formatValue(product?.delivery_price || product?.price)}
                </MenuItemPromotion>

                <NumberFormatStyled
                  available={
                    product?.available || product?.available_in_delivery
                  }
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={
                    product?.delivery_price_promotion || product?.price
                  }
                  key={product?.delivery_price_promotion || product?.price}
                  onValueChange={(e) => handleValueDeliveryChange(e)}
                  onClick={() => {
                    setDeliveryPrice(
                      product?.delivery_price_promotion || product?.price
                    );
                    handleDesbloquedSubmitChangesButton(product?.id);
                    setIsProductDeliveryPricePromotion(true);
                  }}
                  disabled={!canEditProduct}
                />
                {/* <SubmitStyledButton
                  onClick={() => handleSubmitDeliveryPrice(true)}
                  type="button"
                  disabled={
                    !buttonsSubmitDeliveryPriceDesbloqued.includes(
                      product?.id
                    ) || product.is_multistore_child
                  }
                >
                  <FaCheck />
                </SubmitStyledButton> */}
              </>
            ) : (
              <>
                {' '}
                <NumberFormatStyled
                  available={
                    product?.available || product?.available_in_delivery
                  }
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={product?.delivery_price || product?.price}
                  key={product?.delivery_price || product?.price}
                  onValueChange={(e) => handleValueDeliveryChange(e)}
                  onClick={() => {
                    setDeliveryPrice(product?.delivery_price || product?.price);
                    handleDesbloquedSubmitChangesButton(product?.id);
                    setIsProductDeliveryPricePromotion(false);
                  }}
                  disabled={!canEditProduct}
                />
              </>
            )}
          </ProductPrice>
        )}

        <td />

        <ProductPrice>
          <SubmitStyledButton
            onClick={handleSubmitChanges}
            type="button"
            disabled={
              !buttonsSubmitChangesDesbloqued.includes(product?.id) ||
              product.is_multistore_child
            }
          >
            <FaCheck />
          </SubmitStyledButton>
        </ProductPrice>

        <TdDisplayNone />

        <ButtonsTd>
          <div
            style={{
              display: 'flex',
              height: 'auto',
              justifyContent: 'end',
            }}
          >
            {product?.has_complement_categories ? (
              <button
                style={{
                  width: 40,
                  background: 'transparent',
                  border: 'none',
                  marginLeft: 22,
                }}
                id={`arrow-${index}`}
                onClick={() => {
                  handleCollapse(product?.id);
                }}
                type="button"
                href="#"
              >
                <FaAngleDown
                  size={28}
                  cursor="pointer"
                  color="#000"
                  style={{
                    transition: 'all 0.2s',
                    transform: collapsed.includes(product?.id)
                      ? 'rotate(-180deg)'
                      : 'rotate(0deg)',
                  }}
                />
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  autohide={false}
                  target={`arrow-${index}`}
                  toggle={toggleTip}
                >
                  Mostrar complementos
                </Tooltip>
              </button>
            ) : (
              ''
            )}

            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              direction={
                index === 0
                  ? 'left'
                  : index + 1 === products?.length || index + 1 === sliceSize
                    ? 'up'
                    : 'down'
              }
            >
              <DropdownToggle tag="span">
                <button
                  style={{
                    width: 40,
                    height: 40,
                    background: 'transparent',
                    border: 'none',
                    marginLeft: 8,
                  }}
                  type="button"
                  id={`ellipsis-${index}`}
                >
                  <FaEllipsisV cursor="pointer" color="#000" size={20} />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipEllipsisOpen}
                    autohide={false}
                    target={`ellipsis-${index}`}
                    toggle={toggleEllipsisTip}
                  >
                    Mostrar Opções
                  </Tooltip>
                </button>
              </DropdownToggle>
              <DropdownMenu hidden={dropDownHidden} flip>
                <StyledDropdownItem
                  disabled={!canEditProduct}
                  onClick={toggleModalEditProduct}
                >
                  Editar Produto
                </StyledDropdownItem>
                {!product.is_multistore_child && (
                  <StyledDropdownItem
                    disabled={!canCreateProduct}
                    onClick={() => handleDelete(product?.id)}
                  >
                    Excluir Item
                  </StyledDropdownItem>
                )}
                {!product.is_multistore_child && (
                  <StyledDropdownItem
                    disabled={!canCreateProduct}
                    onClick={() => setShowDuplicateModal(true)}
                  >
                    Duplicar Item
                  </StyledDropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
          {/* <DeleteAndEditActions>
              <EditButton onClick={toggleModalEditProduct}>
                <div>
                  <MdEdit size={17} />
                  Editar
                </div>
              </EditButton>
              {!product?.is_multistore_child && (
                <DeleteButton onClick={() => handleDelete(product?.id)}>
                  <div>
                    <MdDelete size={17} />
                    Excluir
                  </div>
                </DeleteButton>
              )}
            </DeleteAndEditActions> */}
        </ButtonsTd>
      </ProductTableRow>
      <ButtonLinnk
        group
        onClick={() => {
          toggleModalCrerateComplementGroup();
          setProductIdState(product.id);
          getProduct(product.id);
          getComplements();
        }}
        disabled={!canCreateComplementsCategory}
      >
        + Adicionar grupo de complementos
      </ButtonLinnk>

      {complementCategoriesInsideProduct &&
        complementCategoriesInsideProduct.length > 0 &&
        complementCategoriesInsideProduct.map((comp_category, idx) => (
          <ProductsComplementsCategory
            complementCategory={comp_category}
            complementCategoriesProduct={complementCategoriesInsideProduct}
            setComplementCategoriesInsideProduct={
              setComplementCategoriesInsideProduct
            }
            index={idx}
            isOpen={!collapsed.includes(product?.id)}
            getCategories={getCategories}
            menewProducts={menewProducts}
            product={product}
            categories={categories}
            setCategories={setCategories}
            category={category}
            setComplementsCategories={setComplementsCategories}
            complementCategories={complementCategories}
            fiscalGroups={fiscalGroups}
          />
        ))}

      <Modal isOpen={modalImage} toggle={toggleImage} size="md">
        <ModalBody>
          <h3 className="text-center" style={{ marginBottom: 32 }}>
            Atualizar foto do produto
          </h3>
          <p className="text-center">
            Clique sobre a imagem e faça upload da nova foto do produto.
          </p>
          <Form initialData={initialData} onSubmit={handleSubmit}>
            <ImageInput
              name="image_id"
              alt="Foto do produto"
              product={product}
              products={products}
              setProducts={setProducts}
              setProduct={() => { }}
            // disabled={product?.is_multistore_child || false}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <a
                href="https://drive.google.com/file/d/12vkubd07X2JfOe_V0J6zDwIJgef9L23u/view?usp=share_link"
                target="blank"
                style={{
                  fontWeight: 'bold',
                  color: '#58CEB1',
                  textDecoration: 'underline',
                }}
              >
                <FaImage color="#58CEB1" style={{ marginRight: 10 }} /> Acessar
                manual da foto
              </a>
              <PersonalButton type="submit" color="#58CEB1" message="Salvar" />
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showDuplicateModal}
        toggle={() => setShowDuplicateModal(false)}
        size="lg"
        style={{ maxWidth: '534px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
            padding: '24px 24px 0px 24px',
          }}
        >
          <strong style={{ fontSize: 18 }}>
            Como deseja duplicar o produto "{product?.name || '??'}"?
          </strong>

          <FiX
            size={22}
            color="#FF2129"
            cursor="pointer"
            onClick={() => setShowDuplicateModal(false)}
          />
        </div>

        <ModalBody style={{ lineHeight: 1.5 }}>
          <RadioGroup
            selected={dupOptionSelected}
            labelStyle={{ marginTop: -2, fontWeight: 500 }}
            options={duplicateOptions}
            onClick={(v) => setDupOptionSelected(v)}
          />
        </ModalBody>

        <ModalFooter style={{ justifyContent: 'flex-end' }}>
          <PersonalButton
            type="submit"
            color="#58CEB1"
            message="Confirmar"
            onClick={() => {
              setDupOptionSelected(1);
              setShowDuplicateModal(false);
              handleDuplicateProduct(product?.id);
            }}
          />
        </ModalFooter>
      </Modal>

      <ReactModal
        onRequestClose={toggleModalEditProduct}
        isOpen={modalEditProduct}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <EditProduct
          productId={product?.id}
          category={category}
          getCategory={getCategories}
          productFather={product}
          toggle={toggleModalEditProduct}
          menewProducts={menewProducts}
          setProducts={setProducts}
          products={products}
          // getProducts={getProducts}
          categoriesComplete={categories}
          setCategoriesFiltered={setCategoriesFiltered}
          categories={categories}
          fiscalGroups={fiscalGroups}
          setCategories={setCategories}
          getComplementsCategoryInsideProduct={
            getComplementsCategoryInsideProduct
          }
          setCollapsed={setCollapsed}
        />
      </ReactModal>
    </>
  );
}
