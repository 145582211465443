import React,{useCallback, useState,useEffect} from 'react';
import { Modal } from 'reactstrap';
import {Container,Header,InformationArea,ButtonSection} from './styles'
import {DefaultInput,Button} from 'ui-kit-takeat'
import api2 from '~/services/api2';
import { toast } from 'react-hot-toast';


export const AddClients = ({
  addClientModal,
  setAddClientModal,
  clientPhone,
  setHasClientParent,
  }) => {
    const [name,setName] = useState('');
    const [phone,setPhone] = useState(clientPhone || '');
    const [birthday,setBirthday] = useState('');
    const [email,setEmail] = useState('');
    const [number,setNumber] = useState('');
    const [cep,setCep] = useState('');
    const [cpf,setCpf] = useState('');
    const [complement,setComplement] = useState('');
    const [street,setStreet] = useState('');
    const [clientId,setClientId] = useState(0);
    const [hasClient,setHasClient] = useState(false);
    const handleChangeInput = (event, setFunction ) => {
        setFunction(event.target.value);
      };


    const GetClientInformations = useCallback( async () => {
        try{
            const res = await api2.post('/restaurants/get-clients', {
                phone: phone
              });
            const {name,birthday,email,complement,number,cpf,cep,street,id} = res.data.client
            setName(name);
            setBirthday(birthday);
            setEmail(email);
            setComplement(complement);
            setNumber(number);
            setCpf(cpf);
            setCep(cep);
            setStreet(street);
            setHasClient(true);
            setClientId(id)
            setHasClientParent(res.data.hasClient)
        }
        catch(erro){
            console.log(erro);
            setHasClient(false)
            setHasClientParent(false)
        }
    })

    async function editClient(){
        try{
            if(hasClient){
                await api2.put(`restaurants/clients/${clientId}`,{
                    birthday:birthday,
                    email:email,
                    complement:complement,
                    number:number,
                    cpf:cpf,
                    cep:cep,
                    street:street,
                    id:clientId
                })
                toast.success('Cliente atualizado com sucesso!');
            }
    
            else{
                await api2.post('restaurants/clients',{
                    birthday:birthday,
                    email:email,
                    complement:complement,
                    number:number,
                    cpf:cpf,
                    cep:cep,
                    street:street,
                    id:clientId,
                    phone: phone
                })
                toast.success('Cliente criado com sucesso!');
            }
            setAddClientModal(false)
        }
        catch(erro){
            console.log(erro);
            toast.error('Não foi possível atualizar o cadastro! Tente novamente');
        }
    }

    useEffect(()=>{
        GetClientInformations()
    },[phone])
    return(
        <Modal isOpen={addClientModal}  >
            <Container>
            <div>
                <Header>Informações do cliente</Header>
            </div>
            <InformationArea>
                <DefaultInput
                    type='text'
                    mask="(99) 99999-9999"
                    containerStyles={{
                        gridArea: "client-phone",
                        gap:10
                    }}
                    title='Celular do cliente *'
                    value={phone}
                    onChange={(e) => handleChangeInput(e,setPhone)}
                    placeholder='Obrigatório'
                />

                <DefaultInput
                    type='text'
                    value={birthday}
                    mask="99/99/9999"
                    title='Data de nascimento'
                    containerStyles={{
                        gridArea: "birthday",
                        gap:10
                    }}
                    onChange={(e) => handleChangeInput(e,setBirthday)}
                    placeholder='Opcional'

                />
                <DefaultInput
                    type='text'
                    value={name}
                    title='Nome do cliente *'
                    containerStyles={{
                        gridArea: "name",
                        gap:10
                    }}
                    onChange={(e) => handleChangeInput(e,setName)}
                    placeholder='Obrigatório'

                />

                <DefaultInput
                    type='email'
                    value={email}
                    title='E-mail'
                    containerStyles={{
                        gridArea: "email",
                        gap:10
                    }}
                    onChange={(e) => handleChangeInput(e,setEmail)}
                    placeholder='Opcional'

                />
                <DefaultInput
                    title='CPF'
                    type='text'
                    value={cpf}
                    mask="999.999.999.-99"
                    containerStyles={{
                        gridArea: "cpf",
                        gap:10
                    }}
                    onChange={(e) => handleChangeInput(e,setCpf)}
                    placeholder='Opcional'

                    />

                <DefaultInput
                    title='CEP '
                    type='text'
                    value={cep}
                    mask="99.999-999"
                    containerStyles={{
                        gridArea: "cep",
                        gap:10
                    }}
                    onChange={(e) => handleChangeInput(e,setCep)}
                    placeholder='Opcional'

                    />
                <DefaultInput
                    title='Rua '
                    type='text'
                    value={street}
                    containerStyles={{
                        gridArea: "street",
                        gap:10
                    }}
                    onChange={(e) => handleChangeInput(e,setStreet)}
                    placeholder='Opcional'

                    />
                <DefaultInput
                    title='Número '
                    type='text'
                    value={number}
                    containerStyles={{
                        gridArea: "number",
                        gap:10
                    }}
                    onChange={(e) => handleChangeInput(e,setNumber)}
                    placeholder='Opcional'

                    />
                <DefaultInput
                    title='Complemento '
                    type='text'
                    value={complement}
                    containerStyles={{
                        gridArea: "complement",
                        gap:10
                    }}
                    onChange={(e) => handleChangeInput(e,setComplement)}
                    placeholder='Opcional'

                    />
            </InformationArea>
            <ButtonSection>
                <Button
                    title='Cancelar'
                    type='button'
                    textStyle={{color:'white'}}
                    onClick={() => {
                        setAddClientModal(false)}}
                />
                <Button
                    title='Salvar informações'
                    type='button'
                    buttonColor='#2EC9B7'
                    textStyle={{color:'white'}}
                    onClick={() => editClient()}
                />
            </ButtonSection>
        </Container>
        </Modal>
    )
}