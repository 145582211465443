import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

export const LinkStyled = styled(Link)`
    color: #FFB32F;
    font-weight: 700;
    text-decoration: underline;

    &:hover{
      color: #FFB32F;
      text-decoration: underline;
      filter: opacity(2);
    }

    &:active{
      color: #FFB32F;
      text-decoration: underline;
      filter: opacity(2);
    }
`;
