import React, { useState, useCallback } from 'react';

import { toast } from 'react-hot-toast';

import {
  format,
  startOfDay,
  endOfDay,
} from 'date-fns';

import api from '~/services/api';
import { DownloadButton, DayCalendar, DefaultInput, OrderStatusTag } from 'ui-kit-takeat';
import FileSaver from 'file-saver';
import { generateInventoryReport } from '~/services/SheetGenerate/generateInventoryReport';
import { useAuth } from '~/context/AuthContext';
import { Button, Divider, Input, Select } from 'takeat-design-system-ui-kit';
import Push from '~/services/Push';
import { Modal, ModalBody } from 'reactstrap';

export default function InputSmartQuote({ type }) {
  const [filterDate, setFilterDate] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  });

  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleDownloadSheet = async (history) => {
    try {
      const sheet = await generateInventoryReport(history, user, format(new Date(filterDate.end), 'dd/MM/yyyy'));

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Inventario (${format(new Date(filterDate.end), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const updateHistory = useCallback(
    async () => {
      setLoading(true);
      try {
        const response = await api.get('/restaurants/inputs/inventory/day', {
          params: {
            end_date: filterDate.end.toISOString()
          },
        });

        handleDownloadSheet(response.data);
      } catch (error) {
        toast.error('Falha ao carregar dados');
      }
      setLoading(false);
    },
    [filterDate]
  );

  const cityOptions = [
    { "label": "VITÓRIA / ES", "value": "VITÓRIA", "uf": "ES" },
    { "label": "VILA VELHA / ES", "value": "VILA VELHA", "uf": "ES" },
    { "label": "COLATINA / ES", "value": "COLATINA", "uf": "ES" },
    { "label": "CARIACICA / ES", "value": "CARIACICA", "uf": "ES" },
    { "label": "SERRA / ES", "value": "SERRA", "uf": "ES" },
    { "label": "VIANA / ES", "value": "VIANA", "uf": "ES" },
    { "label": "BRASÍLIA / DF", "value": "BRASÍLIA", "uf": "DF" },
    { "label": "GOIÂNIA / GO", "value": "GOIÂNIA", "uf": "GO" },
    { "label": "PORTO ALEGRE / RS", "value": "PORTO ALEGRE", "uf": "RS" },
    { "label": "UBERLÂNDIA / MG", "value": "UBERLÂNDIA", "uf": "MG" },
    { "label": "SÃO PAULO / SP", "value": "SÃO PAULO", "uf": "SP" },
    { "label": "RIO DE JANEIRO / RJ", "value": "RIO DE JANEIRO", "uf": "RJ" },
    { "label": "BELO HORIZONTE / MG", "value": "BELO HORIZONTE", "uf": "MG" },
    { "label": "PORTO ALEGRE / RS", "value": "PORTO ALEGRE", "uf": "RS" },
  ];

  function formatarCNPJ(numero) {
    // Remover qualquer caractere não numérico
    const numLimpo = numero.replace(/\D/g, '');
  
    // Verificar se o número tem 14 dígitos
    if (numLimpo.length !== 14) {
      throw new Error('O número precisa ter 14 dígitos.');
    }
  
    // Aplicar a máscara de CNPJ
    return numLimpo.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }

  const [cities, setCities] = useState([cityOptions[0]]);
  const [itemName, setItemName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [portionValue, setPortionValue] = useState(9);

  function normalizarCidade(cidade) {
    return cidade.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().trim();
  }

  const [smartQuotes, setSmartQuotes] = useState(null);
  
  const getSmartQuote = async (history) => {
    setIsLoading(true);
    setPortionValue(9);
    // setModalSmartQuote(true);
    try {
      const thisCities = [];
      
      cities.forEach(city => {
        if(city.value !== normalizarCidade(city.value)){
          thisCities.push(city.value);
          thisCities.push(normalizarCidade(city.value));
        }else{
          thisCities.push(city.value);
        }
      });

      const response = await api.post('/restaurants/inputs/smart-quote', {
        name: normalizarCidade(itemName),
        cities: thisCities
      });

      setSmartQuotes(response.data);
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
    setIsLoading(false);
  };

  const [modalSmartQuote, setModalSmartQuote] = useState(false);
  function toggle(){
    setModalSmartQuote(!modalSmartQuote);
  }

  return (
    <div
      style={{
        background: '#fff',
        borderRadius: 8,
        padding: '20px',
        boxShadow: '1px 0 5px 1px rgba(0, 0, 0, 0.1)',
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignItems: 'center'
      }}
    >
      <div>
        <p>Selecione a cidade e digite o nome do item que você quer pesquisar a cotação</p>
      </div>

      <div style={{

        display: 'flex',
        gap: 8,
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Input
          name="name"
          type="text"
          label="Nome do item"
          style={{ width: '100%', marginRight: 10 }}
          value={itemName}
          onChange={e => setItemName(e.target.value)}
        />

        <Select
          options={cityOptions}
          value={cities}
          label="Cidade"
          placeholder="Selecione a cidade"
          isMulti
          onChange={e => setCities(e)}
          style={{ width: '30%', marginLeft: 10 }}
        />

        <Button 
          type="submit" 
          label="Pesquisar"
          variant='outlined'
          style={{marginTop: 25}}
          onClick={getSmartQuote}
          disabled={itemName === ''}
        >
          Pesquisar
        </Button>
      </div>

      <div style={{marginTop: 20}}>
        {
            isLoading ? <div>
              Carregando...
            </div> :
            <div>
              {smartQuotes !== null && (smartQuotes.itens?.length > 0 ? 
                <div>
                  <h4>Foi encontrado <b>{smartQuotes.itens?.length} itens</b> parecidos com <b>{smartQuotes.name}</b> nos últimos 14 dias.</h4> 
                  <p>A variação de preço foi entre <b>R${smartQuotes.itens[0].unitary_price.toFixed(2)} e R${smartQuotes.itens[smartQuotes.itens.length - 1].unitary_price.toFixed(2)}</b>; Confira a relação de fornecedores:</p>
                  {
                    smartQuotes.itens.slice(0, portionValue).map((item, i) => (
                    <div style={{border: '1px solid #c8131b', borderRadius: 10, padding: 7, marginBottom: 5}}>
                      {i === 0 && <div style={{marginBottom: 5}}>
                        <OrderStatusTag
                        color="#FFA814"
                        text="Melhor opção!"
                      /></div>}
                      <p><b>{item.item}</b> - {item.quantidade > 1 && <>com <b>{item.quantidade}</b> unidades</>} com custo unitário de <b>R${item.unitary_price.toFixed(2)}</b></p>
                      <p><i>Unidade original: {item.unidade_comercial} com custo R${parseFloat(item.valor_unitario_comercial).toFixed(2)}</i></p>
                      <p>Cotação realizada dia {format(new Date(item.data_emissao), 'dd/MM/yyyy')} </p>
                      <p>Fornecedor <b>{item.nome_emitente}</b>; CNPJ: {formatarCNPJ(item.documento_emitente)}</p>
                      <p>Rua São Paulo, 2058, bairro {item.bairro_emitente} em {item.municipio_emitente}</p>
                    </div>
                    ))
                  }

                  {portionValue < smartQuotes.itens?.length &&
                    <Button onClick={() => setPortionValue(portionValue + 10)} type="button" name="addPortion" style={{width: '100%', marginTop: 5}}>
                      Carregar mais
                    </Button>
                  }
                </div>
                : 
                <div><p style={{color: 'red'}}> Não foram encontrados itens parecido com <b>{smartQuotes.name}</b> nos últimos 14 dias :(</p></div>)
              }
            </div>
          }
      </div>
{/* 
      <Modal isOpen={modalSmartQuote} toggle={toggle} size="lg">
        <ModalBody>
          {
            isLoading ? <div>
              Carregando...
            </div> :
            <div>
              {smartQuotes.length > 0 ? 
                <div>
                  <h4>Foi encontrado <b>{smartQuotes.length} itens</b> parecidos com <b>{itemName}</b> nos últimos 14 dias.</h4> 
                  A variação de preço foi entre <b>R${smartQuotes[0].unitary_price} e R${smartQuotes[smartQuotes.length - 1].unitary_price}</b>


                </div>
                : 
                <div> Não foram encontrados itens parecido com <b>{itemName}</b> nos últimos 14 dias :(</div>
              }
            </div>
          }
        </ModalBody>
      </Modal> */}
    </div>
  );
}
