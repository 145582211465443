/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { format } from 'date-fns';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import { FiTrash } from 'react-icons/fi';
import {
  FaArrowLeft,
  FaCheck,
  FaInfoCircle,
  FaPlus,
  FaQuestionCircle,
  FaTrash,
} from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import ReactModal from 'react-modal';
import StockImg from '~/assets/img/stock_banner.png';
import englishFlag from '~/assets/img/englishFlag.png';

import nfeInfo from '~/services/NFeInfo';
import api from '~/services/api';

import ImageInput from '~/components/Form/ImageInput';
import Input from '~/components/Form/Input';
import Textarea from '~/components/Form/Textarea';
import Select from '~/components/Form/SelectInput';
import Checkbox from '~/components/Form/Checkbox';

import { useAuth } from '~/context/AuthContext';

import CheckboxInput from '~/components/Form/Checkbox';
import { useIfood } from '~/context/iFoodContext';
import NFeInfo from '~/services/NFeInfo';

import formatValue from '~/utils/formatValue';
import PersonalButton from '~/components/Buttons/PersonalButton';
import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
import { DefaultInput, Button, Tooltip, DefaultSelect } from 'ui-kit-takeat';
import EnglishMenuButton from '~/components/Buttons/EnglishMenuButton';
import NumberFormat from 'react-number-format';
import { useNotify } from '~/context/NotifyContext';
import {
  Card,
  InputsTable,
  NavDiv,
  NavDivTeste,
  NavLinkSpan,
  IfoodTable,
  NumberFormatStyled,
  CmvButtons,
  SubmitStyledButton,
  InnerArea,
  Container,
  ButtonsArea,
  StockBanner,
  SwitcherArea,
  SelectStyled,
  InputStyled,
  ButtonAdd,
  Delete,
  StyledCheckbox,
  FiscalMessage,
  PersonalizedHoursContainer,
  StyledForm,
} from './styles';
import getValidationErrors from '../../../services/getValidationErrors';

function EditProduct({
  category,
  toggle,
  setProducts,
  products,
  categoriesComplete,
  productId,
  categories,
  fiscalGroups,
  getCategory,
  productFather,
  setCollapsed,
}) {
  const { user, menewProducts, combinedGroup } = useAuth();
  const formRef = useRef(null);
  const { ifoodMenus, ifoodRestaurants, ifoodProducts } = useIfood();
  // const [ifoodProducts, setIfoodProducts] = useState(() => {
  //   if(ifoodMenus)
  // });
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  const { toggleNotify } = useNotify();
  const canAssociateComplements =
    !user?.price_table ||
    user?.price_table?.change_product_complements ||
    !user?.price_table?.is_customizable;

  const [selectIfoodProducts, setSelectIfoodProducts] = useState(() => {
    const toreturn = ifoodRestaurants.map((rest) => {
      return {
        ifood_restaurant_id: rest.ifood_restaurant_id,
        value: null,
      };
    });
    return toreturn;
  });

  const [isCategoryChanged, setIsCategoryChanged] = useState(false);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [product, setProduct] = useState(false);
  const [isMultistoreChild, setIsMultistoreChild] = useState(false);
  const [complementCategories, setComplementCategories] = useState([]);
  const [complementCategoriesData, setComplementCategoriesData] = useState([]);
  const [isCombo, setIsCombo] = useState(false);
  const [comboTooltip, setComboTooltip] = useState(false);
  const initialIfoodData = ifoodProducts.find(
    (p) => p.value === product.ifood_product_id
  );
  const canEditProduct =
    !user?.price_table ||
    user?.price_table?.edit_products ||
    user?.price_table?.is_customizable;
  const allProducts = categoriesComplete.map((cat) => cat.products).flat();
  const allProductsIfoodIds = allProducts
    .map((prod) => prod.ifood_product_id)
    .filter((i) => i != null);

  ifoodProducts.forEach((p) => {
    p.isDisabled =
      allProductsIfoodIds.includes(p.value) &&
      p.value !== product.ifood_product_id;
  });

  const initialComplementCategories = product?.complement_categories?.map(
    (categ) => ({
      value: categ.id,
      label: categ.name,
    })
  );

  const getCategories = useCallback(async () => {
    const response = await api.get('restaurants/product-category');

    const data = response.data.map((cat) => ({
      value: cat.id,
      label: cat.name,
    }));

    setCategoriesOptions(data);
  }, []);

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    setInputSelected('');
  };

  const [modalPI, setModalPI] = useState(false);
  const toggleModalPI = () => {
    setModalPI(!modalPI);
    setIntermediarySelected('');
  };

  const [activeTab, setActiveTab] = useState('1');
  const [inputs, setInputs] = useState([]);
  const [intermediaries, setIntermediaries] = useState([]);
  const [restaurantInputs, setRestaurantInputs] = useState([]);
  const [restaurantInputsSelect, setRestaurantInputsSelect] = useState([]);
  const [inputSelectedId, setInputSelectedId] = useState(null);
  const [, setInputSelected] = useState('');

  const [paidCheck, setPaidCheck] = useState(false);
  const [activeIntermediaryTab, setActiveIntermediaryTab] = useState('4');
  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);
  }

  const [interInter, setInterInter] = useState([]);
  const [defaultInterInterValue, setDefaultInterInterValue] = useState(0);
  const [selectInter, setSelectInter] = useState([]);
  const [, setUnidadeSelected] = useState('Kg');
  const [, setNameIntermediary] = useState('');
  const [yeldIntermediary, setYeldIntermediary] = useState(1);
  const formIntermediaryRef = useRef(null);
  const formInputRef = useRef(null);
  const [restaurantIntermediaries, setRestaurantIntermediaries] = useState([]);
  const [restaurantIntermediarySelect, setRestaurantIntermediarySelect] =
    useState([]);
  const [intermediarySelectedId, setIntermediarySelectedId] = useState(null);
  const [, setIntermediarySelected] = useState('');
  const [interInputs, setInterInputs] = useState([]);
  const [defaultInterValue, setDefaultInterValue] = useState(0);
  const [selectInput, setSelectInput] = useState([]);
  const [suppliers, setSuppliers] = useState();
  const [banks, setBanks] = useState();

  const [enableTimes, setEnableTimes] = useState(product?.enable_times);
  const disable_enable_times =
    product?.is_multistore_child && product?.multistore_enable_times;
  const [daysSelected, setDaysSelected] = useState([
    'f',
    'f',
    'f',
    'f',
    'f',
    'f',
    'f',
  ]);
  const [hourSelected, setHourSelected] = useState({ start: '', end: '' });

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
    });

    return validTime;
  };

  const arrayInputs = [];

  function insertInterInter(selectInter, defaultInterValue) {
    const exists = interInter.filter((int) => int.id === selectInter.value);

    if (exists.length > 0) {
      toast.error('Produto Intermediário já cadastrado');
    } else {
      setInterInter([
        ...interInter,
        {
          name: selectInter.label,
          id: selectInter.value,
          quantidade_inter_value:
            defaultInterValue > 0 ? defaultInterValue.replace(',', '.') : 0,
          unidade: selectInter.unidade,
          unitary_price: selectInter.unitary_price,
          quantidade: selectInter.quantidade,
        },
      ]);
    }
  }

  function deleteInputInter(id) {
    const updated = interInputs.filter((int) => int.id !== id);
    setInterInputs(updated);
  }

  function deleteInterInter(id) {
    const updated = interInter.filter((int) => int.id !== id);
    setInterInter(updated);
  }

  function insertInputInter(selectInput, defaultInterValue) {
    const exists = interInputs.filter((int) => int.id === selectInput.value);

    if (exists.length > 0) {
      toast.error('Insumo já cadastrado');
    } else {
      setInterInputs((state) => {
        return [
          ...state,
          {
            name: selectInput.label,
            id: selectInput.value,
            quantidade_inter_value:
              defaultInterValue > 0 ? defaultInterValue.replace(',', '.') : 0,
            unidade: selectInput.unidade,
            unitary_price: selectInput.unitary_price,
            quantidade: selectInput.quantidade,
          },
        ];
      });
    }
  }

  function handleCreateCashFlowOnNewInput() {
    if (createCashFlowOnNewInput === true) {
      setCreateCashFlowOnNewInput(false);
    } else {
      setCreateCashFlowOnNewInput(true);
    }
  }
  const [categoriesDetails, setCategoriesDetails] = useState();

  const getCategoriesDetails = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const categoriesSelect = response?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
        };
      });

      setCategoriesDetails(categoriesSelect);
    } catch (error) {
      toast.error('Erro ao baixar métodos de pagamento');
    }
  }, []);

  useEffect(() => {
    getCategoriesDetails();
  }, [getCategoriesDetails]);

  const unidade_options = [
    {
      value: 'UN',
      label: 'UNIDADE',
    },
    {
      value: 'KG',
      label: 'QUILOGRAMA',
    },
    {
      value: 'L',
      label: 'LITRO',
    },
    {
      value: 'ML',
      label: 'MILILITRO',
    },
    {
      value: 'PC',
      label: 'PACOTE',
    },
    {
      value: 'BD',
      label: 'BALDE',
    },
    {
      value: 'CX',
      label: 'CAIXA',
    },
    {
      value: 'FD',
      label: 'FARDO',
    },
    {
      value: 'GF',
      label: 'GARRAFA',
    },
    {
      value: 'G',
      label: 'GRAMA',
    },
    {
      value: 'GL',
      label: 'GALAO',
    },
    {
      value: 'PT',
      label: 'POTE',
    },
  ];

  const [totalInputs, setTotalInputs] = useState();
  const [currentCmv, setCurrentCmv] = useState();

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('restaurants/inputs-minimal');
      const inputs_id = inputs?.map((input) => input.id);

      const new_inputs = response.data.filter(
        (input) => !inputs_id?.includes(input.id)
      );
      const select_inputs = new_inputs
        .filter((it) => !it.is_master)
        .map((input) => ({
          value: input.id,
          label: input.name,
          unidade: input.unidade,
          unitary_price: input.unitary_price,
          quantidade: input.quantidade,
          name: input.name,
          id: input.id,
        }));
      setRestaurantInputs(response.data);
      setRestaurantInputsSelect(select_inputs);
    } catch (err) {
      toast.error('Erro ao carregar insumos.');
      console.log(err);
    }
  }, [inputs]);

  const getIntermediaries = useCallback(async () => {
    try {
      const response = await api.get('restaurants/intermediary-minimal');
      const intermediaries_id = intermediaries?.map((inter) => inter.id);

      const new_inter = response.data.filter(
        (inter) => !intermediaries_id?.includes(inter.id)
      );
      const select_inter = new_inter.map((inter) => ({
        value: inter.id,
        label: inter.name,
        unidade: inter.unidade,
        unitary_price: inter.unitary_price,
        quantidade: inter.quantidade,
        name: inter.name,
        id: inter.id,
      }));
      setRestaurantIntermediaries(response.data);
      setRestaurantIntermediarySelect(select_inter);
    } catch (err) {
      toast.error('Erro ao carregar produtos intermediarios.');
      console.log(err);
    }
  }, [intermediaries]);

  const handleInput = useCallback(
    async (data) => {
      try {
        if (formInputRef.current) {
          formInputRef.current.setErrors({});
        }

        if (data.total_to_send && parseFloat(data.total_to_send) > 0) {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            unidade: Yup.string().required('Unidade Obrigatória'),
            quantidade: Yup.string().required('Quantidade Obrigatória'),
            total_to_send: Yup.number().min(0, 'Não pode ser negativo'),
            category: Yup.string().required('Centro de custo obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        } else {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            unidade: Yup.string().required('Unidade Obrigatória'),
            quantidade: Yup.string().required('Quantidade Obrigatória'),
            total_to_send: Yup.number().min(0, 'Não pode ser negativo'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        }

        const new_date = `${data.due_date}T03:00:00.000Z`;
        const new_paid = `${data.paid_at}T03:00:00.000Z`;

        const handleData = {
          name: `${data.name}(teste1)`,
          quantidade: data.quantidade.replace(',', '.'),
          unidade: data.unidade,
          total: parseFloat(data.total_to_send.replace(',', '.')),
          cash_flow_category_subcategory_id: data.category || null,
          minimum_stock: data.minimum_stock,
          ideal_stock: data.ideal_stock,
          provider_id: data.provider_id || null,
          bank_account_id: data.bank_account_id || null,
          due_date: data.due_date ? new_date : null,
          paid: data.paid,
          paid_at: data.paid ? new_paid : null,
          create_cash_flow: data.create_cashflow,
        };

        const response = await api.post(`/restaurants/inputs`, handleData);
        setInputs((state) => {
          return [response.data, ...state];
        });

        toast.success('Insumo adiconado com sucesso!');
        setModalInput(false);
        setCreateCashFlowOnNewInput(true);

        setPaidCheck(!paidCheck);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formInputRef.current) {
            formInputRef.current.setErrors(errors);
          }
        } else {
          toast.error('Erro ao adicionar insumo.');
        }
      }
    },
    [paidCheck]
  );

  const [filterString] = useState('');

  const [quantInter, setQuantInter] = useState(0);
  async function handleIntermediary(data) {
    try {
      if (formIntermediaryRef.current) {
        formIntermediaryRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        unidade: Yup.string().required('Unidade Obrigatória'),
        quantidade_inter: Yup.string().required('Quantidade Obrigatória'),
        yield: Yup.string().required('Valor Obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const inputs = interInputs.map((inter) => ({
        id: inter.id,
        quantidade: inter.quantidade_inter_value,
      }));
      const intermediaries = interInter.map((inter) => ({
        id: inter.id,
        quantidade: inter.quantidade_inter_value,
      }));

      const handleData = {
        name: data.name,
        quantidade: quantInter.toString().replace(',', '.'),
        unidade: data.unidade,
        recipe: data.recipe,
        yield: data.yield,
        minimum_stock: data.minimum_stock,
        ideal_stock: data.ideal_stock,
        inputs,
        intermediaries,
      };

      const response = await api.post(`/restaurants/intermediary`, handleData);
      setIntermediaries((state) => {
        return [response.data, ...state];
      });

      setInterInputs([]);
      setInterInter([]);
      getInputs(filterString);
      toggleIntermediary();

      toast.success('Produto intermediario adiconado com sucesso!');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formIntermediaryRef.current) {
          formIntermediaryRef.current.setErrors(errors);
        }
      } else {
        toast.error('Erro ao adicionar produto intermediário.');
        console.log(error);
      }
    }
  }

  function handleChangeCategory() {
    setIsCategoryChanged(true);
  }

  useEffect(() => {
    const handle = restaurantInputs.filter(
      (input) => input.id === inputSelectedId
    );
    if (handle.length > 0) {
      setInputSelected(handle[0].unidade);
      const uni = unidade_options.find(
        (opt) => opt.value.toLocaleLowerCase() === handle[0].unidade
      );
      if (uni) {
        setInputUnidade(uni.label);
      } else {
        setInputUnidade('-');
      }
    }
  }, [inputSelectedId]);

  useEffect(() => {
    const handle = restaurantIntermediaries.filter(
      (inter) => inter.id === intermediarySelectedId
    );
    if (handle.length > 0) {
      const uni = unidade_options.find(
        (opt) => opt.value.toLocaleLowerCase() === handle[0].unidade
      );
      if (uni) {
        setInterUnidade(uni.label);
      } else {
        setInterUnidade('-');
      }
    }
  }, [intermediarySelectedId]);

  useEffect(() => {
    const handle = restaurantIntermediaries.filter(
      (intermediary) => intermediary.id === intermediarySelectedId
    );
    if (handle.length > 0) {
      setIntermediarySelected(handle[0].unidade);
    }
  }, [intermediarySelectedId]);

  useEffect(() => {
    const inter_id = intermediaries?.map((inter) => inter.id);

    const new_inter = restaurantIntermediaries.filter(
      (inter) => !inter_id.includes(inter.id)
    );
    const select_inter = new_inter.map((inter) => ({
      value: inter.id,
      label: inter.name,
    }));

    setRestaurantIntermediarySelect(select_inter);
  }, [intermediaries]);

  useEffect(() => {
    const inputs_id = inputs?.map((input) => input.id);

    const new_inputs = restaurantInputs.filter(
      (input) => !inputs_id.includes(input.id)
    );
    const select_inputs = new_inputs.map((input) => ({
      value: input.id,
      label: input.name,
    }));

    setRestaurantInputsSelect(select_inputs);
  }, [inputs]);

  const getComplementCategories = useCallback(async () => {
    const response = await api.get('restaurants/product/complement-category');

    const data = response.data.map((category) => ({
      value: category.id,
      label: category.name,
    }));

    setComplementCategories(data);
    setComplementCategoriesData(response.data);
  }, []);

  let menew_options;
  let menew_default;

  if (Array.isArray(menewProducts)) {
    menew_default = menewProducts.filter(
      (menew) => menew.cod === product.menew_cod
    );
    menew_default = {
      value: menew_default[0] ? menew_default[0].cod : null,
      label: `${menew_default[0] ? menew_default[0].cod : 'Carregando Menew'
        } - ${menew_default[0]
          ? menew_default[0].name
          : 'Saia dessa janela e clique de novo em editar'
        }`,
    };
    if (!product.menew_cod) {
      menew_default = null;
    }
    menew_options = menewProducts.map((product) => ({
      value: product.cod,
      label: `${product.cod} - ${product.name}`,
    }));
  }

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  let icms_default;
  if (product.icms_origem) {
    icms_default = nfeInfo.icms_origem.filter(
      (c) => c.value === product.icms_origem
    );
    if (icms_default[0]) {
      icms_default = {
        value: icms_default[0].value,
        label: icms_default[0].description,
      };
    }
  }

  const csosn_options = nfeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  let csosn_default;
  if (product.csosn) {
    csosn_default = nfeInfo.csosn.filter((c) => c.csosn === product.csosn);
    if (csosn_default[0]) {
      csosn_default = {
        value: csosn_default[0].csosn,
        label: `${csosn_default[0].csosn} - ${csosn_default[0].description}`,
      };
    }
  }

  const base_calculo_options = nfeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  let base_default;
  if (product.base_calculo) {
    const base_filtered = nfeInfo.base_calculo.filter(
      (c) => c.value === product.base_calculo
    );
    base_default = {
      value: base_filtered[0].value,
      label: base_filtered[0].description,
    };
  }

  let cfop_default;
  if (product.cfop) {
    cfop_default = nfeInfo.cfopDescription.filter(
      (c) => c.cfop === product.cfop
    );
    if (cfop_default[0]) {
      cfop_default = {
        value: cfop_default[0].cfop,
        label: `${cfop_default[0].cfop} - ${cfop_default[0].description}`,
      };
    }
  }

  let cofins_default;
  if (product.cofins) {
    cofins_default = nfeInfo.cofins_options.filter(
      (c) => c.value === product.cofins
    );
  }

  let pis_default;
  if (product.pis) {
    pis_default = nfeInfo.cofins_options.filter((c) => c.value === product.pis);
  }

  const [csosnSelected, setCsosnSelected] = useState(product.csosn);
  const [cfopOptions, setCfopOptions] = useState(nfeInfo.cfop_options);

  useEffect(() => {
    if (csosnSelected && user.regime_tributario === '1') {
      const cfopSelect = nfeInfo.csosn.filter(
        (cs) => cs.csosn === csosnSelected
      );
      const cfop = [];

      cfopSelect.map((selected) =>
        selected.cfop?.forEach((cf) => {
          const cfopDesc = nfeInfo.cfopDescription.filter(
            (cfop) => cfop.cfop === cf
          );

          cfop.push({
            value: cfopDesc[0].cfop,
            label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
          });
        })
      );

      setCfopOptions(cfop);
    }
  }, [csosnSelected]);

  useEffect(() => {
    try {
      getComplementCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplementCategories]);

  useEffect(() => {
    setCsosnSelected(product.csosn);
    setIsCombo(product.is_combo);
    formRef.current.setFieldValue('is_combo', product.is_combo);
  }, [product]);

  useEffect(() => {
    try {
      getCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCategories]);
  const [cmvCheck, setCmvCheck] = useState(false);

  useEffect(() => {
    try {
      getInputs();
      getIntermediaries();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getInputs, getIntermediaries]);

  useEffect(() => {
    setCmvCheck(product.is_cmv_manual);
    if (cmvCheck) {
      setCurrentCmv(product.current_cmv);

      console.log(product.current_cmv);
    } else {
      setCurrentCmv(totalInputs);
    }

    if (product) {
      setDaysSelected(product.active_days?.split(''));
      setEnableTimes(product.enable_times);
      setHourSelected({
        start: product.start_time
          ? format(new Date(product.start_time), 'HH:mm')
          : '',
        end: product.end_time
          ? format(new Date(product.end_time), 'HH:mm')
          : '',
      });
    }
  }, [product, totalInputs]);

  const image_input = useRef(null);

  const [fiscalGroup, setFiscalGroup] = useState(null);
  const [fiscalGroupDefault, setFiscalGroupDefault] = useState(null);

  const [hasInput, setHasInput] = useState(false);

  // regime simples
  const [icmsOrigemValue, setIcmsOrigemValue] = useState(null);
  const [csosnValue, setCsosnValue] = useState(null);
  const [cfopValue, setCfopValue] = useState(null);
  const [cofinsValue, setCofinsValue] = useState(null);
  const [pisValue, setPisValue] = useState(null);
  const [cestValue, setCestValue] = useState(null);
  const [icmsValorValue, setIcmsValorValue] = useState(null);
  const [baseCalculoValue, setBaseCalculoValue] = useState(null);
  const [aliquotaValue, setAliquotaValue] = useState(null);
  // regime normal
  const [cstValue, setCstValue] = useState(null);
  const [pisAliquotaValue, setPisAliquotaValue] = useState(null);
  const [cofinsAliquotaValue, setCofinsAliquotaValue] = useState(null);
  const [icmsModalidadeBCValue, setIcmsModalidadeBCValue] = useState(null);
  const [cbenefValue, setCbenefValue] = useState(null);
  const [inputQuantidade, setInputQuantidade] = useState(null);
  const [inputUnidade, setInputUnidade] = useState('-');
  const [interUnidade, setInterUnidade] = useState('-');
  const [icmsValorDesoneradoValue, setIcmsValorDesoneradoValue] =
    useState(null);
  const [icmsBaseCalculoRetidoSt, setIcmsBaseCalculoRetidoSt] = useState(null);
  const [icmsValorSubstituto, setIcmsValorSubstituto] = useState(null);
  const [icmsValorRetidoSt, setIcmsValorRetidoSt] = useState(null);
  const [icmsAliquotaFinal, setIcmsAliquotaFinal] = useState(null);
  const [icmsReducaoBaseCalculo, setIcmsReducaoBaseCalculo] = useState(null);
  const [icmsMotivoDesoneracao, setIcmsMotivoDesoneracao] = useState(null);
  const [icmsAliquotaCreditoSimples, setIcmsAliquotaCreditoSimples] =
    useState(null);
  const [icmsValorCreditoSimples, setIcmsValorCreditoSimples] = useState(null);
  const [fcpPercentual, setFcpPercentual] = useState(null);

  const getProduct = useCallback(async () => {
    const response = await api.get(`/restaurants/products/${productId}`);
    setCurrentCmv(response.data.current_cmv);
    setCmvCheck(response.data.is_cmv_manual);
    setFcpPercentual(response.data.fcp_percentual);
    setProductPriceState(response.data.price);
    setProduct(response.data);
    setIsMultistoreChild(response.data.is_multistore_child);
    setInputs(response.data.inputs);
    setInputArray(
      response.data.inputs.map((item) => {
        return {
          ...item,
          quantidade: item.ProductInput.quantidade,
          input_id: item.id,
          is_master: item.is_master,
        };
      })
    );

    setIntermediaries(response.data.intermediaries);
    setIntermediaryArray(
      response.data.intermediaries.map((item) => {
        return {
          ...item,
          quantidade: item.ProductIntermediary.quantidade,
          intermediary_id: item.id,
        };
      })
    );
    setInputQuantidade(
      response.data?.input_master?.quantidade
        ? parseFloat(response.data?.input_master?.quantidade).toFixed(4)
        : null
    );
    setHasInput(!!response.data.input_master);

    response.data.inputs &&
      response.data.inputs.forEach((input) => {
        const total = parseFloat(
          (
            parseFloat(input.unitary_price) *
            parseFloat(input.ProductInput.quantidade)
          ).toFixed(2)
        );
        arrayInputs.push(total);
      });

    response.data.intermediaries &&
      response.data.intermediaries.forEach((inter) => {
        const total = parseFloat(
          (
            parseFloat(inter.unitary_price) *
            parseFloat(inter.ProductIntermediary.quantidade)
          ).toFixed(2)
        );
        arrayInputs.push(total);
      });

    const inputsTotal = arrayInputs.reduce((Acc, input) => Acc + input, 0);

    setTotalInputs(inputsTotal.toFixed(2));

    const product = response.data;
    setFiscalGroup(product.fiscal_group_id);
    setIcmsOrigemValue(
      icms_origem_options.find((x) => x.value === product.icms_origem)
    );
    setCsosnValue(csosn_options.find((x) => x.value === product.csosn));
    setCfopValue(NFeInfo.cfop_options.find((x) => x.value === product.cfop));
    setCofinsValue(
      NFeInfo.cofins_options.find((x) => x.value === product.cofins)
    );
    setPisValue(NFeInfo.cofins_options.find((x) => x.value === product.pis));
    setCestValue(product.cest);
    setIcmsValorValue(product.icms_valor);
    setAliquotaValue(product.aliquota || product.icms_aliquota);
    setBaseCalculoValue(
      base_calculo_options.find((x) => x.value === product.base_calculo)
    );
    // regime normal
    setCstValue(
      NFeInfo.icms_situacao_tributaria.find((x) => x.value === product.cst)
    );
    setPisAliquotaValue(product.pis_aliquota);
    setCofinsAliquotaValue(product.cofins_aliquota);
    setIcmsModalidadeBCValue(
      NFeInfo.icms_modalidade_base_calculo.find(
        (x) => x.value === product.icms_modalidade_base_calculo
      )
    );
    setCbenefValue(product.cbenef);

    setIcmsBaseCalculoRetidoSt(product.icms_base_calculo_retido_st);
    setIcmsValorSubstituto(product.icms_valor_substituto);
    setIcmsValorRetidoSt(product.icms_valor_retido_st);
    setIcmsAliquotaFinal(product.icms_aliquota_final);
    setIcmsMotivoDesoneracao(product.icms_motivo_desoneracao);
    setIcmsValorDesoneradoValue(product.icms_valor_desonerado);
    setIcmsAliquotaCreditoSimples(product.icms_aliquota_credito_simples);
    setIcmsValorCreditoSimples(product.icms_valor_credito_simples);
    setIcmsReducaoBaseCalculo(product.icms_reducao_base_calculo);

    const group = fiscalGroups.find((x) => x.id === product.fiscal_group_id);

    if (group) {
      setFiscalGroupDefault({
        label: group.title,
        value: group.id,
      });
    }
  }, [productId]);

  async function handleHasInput() {
    if (hasInput) {
      if (
        window.confirm(
          'Tem certeza que deseja parar de controlar a quantidade?'
        )
      ) {
        try {
          await api.delete(`/restaurants/input-master/${product.id}`);
          productFather.input_master = null;
          setProduct((old) => {
            return { ...old, input_master: null };
          });
          toast.success('Quantidade atualizada com sucesso!');
          setHasInput(!hasInput);
        } catch (err) {
          toast.error(
            err?.response?.data?.error
              ? `${err.response.data?.error}`
              : 'Erro ao atualizar.'
          );
          console.log('input master error', err.response);
        }
      }
    } else {
      setHasInput(!hasInput);
    }
  }

  const insertProductIfood = useCallback(
    async ({ product_id, ifood_restaurant_id, product }) => {
      let ifood_id = selectIfoodProducts.find(
        (prod) => prod.ifood_restaurant_id === ifood_restaurant_id
      );
      if (ifood_id) {
        ifood_id = ifood_id.value;
        const data = {
          ifood_id,
          product_id,
          ifood_restaurant_id,
        };
        try {
          const response = await api.post(`/restaurants/ifood/products`, data);
          // let newProduct = product;
          // console.log('newProduct', newProduct);
          // newProduct.ifood_products.push(response.data);
          // setProduct(newProduct);
          toast.success('Produto associado com sucesso');
          getProduct();
        } catch (err) {
          toast.error(
            err?.response?.data?.error
              ? `${err.response.data?.error}`
              : 'Erro ao associar produto'
          );
          console.log('ifood error', err.response);
        }
      } else {
        toast.error('Erro ao associar produto');
      }
    },
    []
  );

  const removeProductIfood = useCallback(
    async ({ product_id, ifood_id, ifood_restaurant_id }) => {
      if (window.confirm('Tem certeza que deseja deletar essa relação?')) {
        if (ifood_id) {
          const data = {
            ifood_id,
            product_id,
            ifood_restaurant_id,
          };
          try {
            await api.delete(`/restaurants/ifood/products`, { data });
            // let newProduct = product;
            // console.log('newProduct', newProduct);
            // newProduct.ifood_products.push(response.data);
            // setProduct(newProduct);
            toast.success('Produto desassociado com sucesso');
            getProduct();
          } catch (err) {
            toast.error(
              err?.response?.data?.error
                ? `${err.response.data?.error}`
                : 'Erro ao desassociar produto'
            );
            console.log('ifood error', err.response);
          }
        } else {
          toast.error('Erro ao desassociar produto');
        }
      }
    },
    []
  );

  async function handleSubmitFiscal(data) {
    try {
      const parsedData = {
        fiscal_group_id: fiscalGroup,
      };

      if (user.regime_tributario === '1') {
        parsedData.ncm = data.ncm;
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;

        if (csosnSelected === '00') {
          parsedData.aliquota = aliquotaValue || data.aliquota;
          parsedData.base_calculo =
            baseCalculoValue?.value || data.base_calculo;
        }

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }

        if (csosnSelected === '500') {
          parsedData.icms_base_calculo_retido_st =
            data?.icms_base_calculo_retido_st?.replace(',', '.') || null;
          parsedData.icms_aliquota_final =
            data?.icms_aliquota_final?.replace(',', '.') || null;
          parsedData.icms_valor_substituto =
            data?.icms_valor_substituto?.replace(',', '.') || null;
          parsedData.icms_valor_retido_st =
            data?.icms_valor_retido_st?.replace(',', '.') || null;
        }

        if (csosnSelected === '101') {
          parsedData.icms_aliquota_credito_simples =
            data?.icms_aliquota_credito_simples?.replace(',', '.') || null;
          parsedData.icms_valor_credito_simples =
            data?.icms_valor_credito_simples?.replace(',', '.') || null;
        }
      } else {
        parsedData.ncm = data.ncm;
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
        parsedData.icms_aliquota = aliquotaValue || data.icms_aliquota;
        parsedData.icms_valor = icmsValorValue || data.icms_valor;

        parsedData.cst = cstValue?.value || data.cst;
        parsedData.pis_aliquota = pisAliquotaValue || data.pis_aliquota;
        parsedData.cofins_aliquota =
          cofinsAliquotaValue || data.cofins_aliquota;
        parsedData.icms_modalidade_base_calculo =
          icmsModalidadeBCValue?.value || data.icms_modalidade_base_calculo;
        parsedData.cbenef = cbenefValue || data.cbenef;

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }

        if (cstValue?.value === '20' || cstValue?.value === '40') {
          parsedData.icms_reducao_base_calculo =
            data?.icms_reducao_base_calculo?.replace(',', '.') || null;
          parsedData.icms_valor_desonerado =
            data?.icms_valor_desonerado?.replace(',', '.') || null;
          parsedData.icms_motivo_desoneracao =
            data?.icms_motivo_desoneracao || null;
        }

        if (cstValue?.value === '60') {
          parsedData.icms_base_calculo_retido_st =
            data?.icms_base_calculo_retido_st?.replace(',', '.') || null;
          parsedData.icms_aliquota_final =
            data?.icms_aliquota_final?.replace(',', '.') || null;
          parsedData.icms_valor_substituto =
            data?.icms_valor_substituto?.replace(',', '.') || null;
          parsedData.icms_valor_retido_st =
            data?.icms_valor_retido_st?.replace(',', '.') || null;
        }
      }

      parsedData.fcp_percentual = data?.fcp_percentual
        ? data?.fcp_percentual?.replace(',', '.')
        : null;

      const response = await api.put(
        `restaurants/products/fiscal/${product.id}`,
        parsedData
      );

      const productNew = response.data;

      const productFind = products.findIndex((prod) => prod.id === product.id);

      const complements_array = [];

      complementCategoriesData.map((comp, idx) =>
        data.complement_categories.map((item) => {
          if (comp.id === item) {
            complements_array.push(comp);
          }
        })
      );

      const newProductsState = [...products];

      newProductsState[productFind] = {
        ...product,
        ...productNew,
        has_complement_categories: complements_array.length > 0,
        complement_categories: complements_array,
      };

      const newProducts = [...newProductsState];

      const categoryFind = categories.find((cat) => cat.id === category.id);

      categoryFind.products[productFind] = {
        ...product,
        ...productNew,
        has_complement_categories: complements_array.length > 0,
        complement_categories: complements_array,
      };

      setProducts(newProducts);

      setCollapsed([]);
    } catch (error) {
      toast.error('Falha ao salvar dados fiscais');
      console.log(error);
    }
  }

  // create banks
  const formRefBank = useRef(null);
  const formRefSupplier = useRef(null);

  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksSelect = response?.data?.map((bank) => {
        return {
          value: bank.id,
          label: bank.name,
        };
      });

      setBanks(banksSelect);
    } catch (error) {
      toast.error('Erro ao baixar contas de banco');
    }
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  const handleBankSubmit = useCallback(
    async (data) => {
      try {
        if (formRefBank?.current) {
          formRefBank.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefBank?.current) {
            formRefBank.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  // create suplliers

  const getSuppliers = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/providers');

      const providersSelect = response?.data?.map((provider) => {
        return {
          value: provider.id,
          label: provider.name,
        };
      });

      setSuppliers(providersSelect);
    } catch (error) {
      toast.error('Erro ao baixar fornecedores');
    }
  }, []);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  const [isModalCreateSuppliersOpen, setIsModalCreatesuppliersOpen] =
    useState(false);

  const toggleModalCreateSuplliers = useCallback(() => {
    setIsModalCreatesuppliersOpen(!isModalCreateSuppliersOpen);
  }, [isModalCreateSuppliersOpen]);

  const handleSupplierSubmit = useCallback(
    async (data) => {
      try {
        if (formRefSupplier?.current) {
          formRefSupplier.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/providers', {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor cadastrada com sucesso!');

        getSuppliers();
        toggleModalCreateSuplliers();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefSupplier?.current) {
            formRefSupplier.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalCreateSuplliers]
  );

  let initialData = {
    name: product.name,
    description: product.description,
    price: product.price,
    price_promotion: product.price_promotion,
    promotion: product.promotion,
    sold_off: product.sold_off,
    use_weight: product.use_weight,
    cfop: product.cfop,
    ncm: product.ncm,
    csosn: product.csosn,
    icms_origem: product.icms_origem,
    icms_valor: product.icms_valor,
    aliquota: product.aliquota,
    base_calculo: product.base_calculo,
    cofins: product.cofins,
    cest: product.cest,
    pis: product.pis,
    charge_service_tax: product.charge_service_tax,
    has_starting_price: product.has_starting_price,
    // is_combo: product.is_combo,
    product_category: {
      value: category.id,
      label: category.name,
    },
    image_id: {
      id: product.image ? product.image.id : null,
      url: product.image
        ? product?.image?.url_thumb || product?.image?.url
        : null,
    },
    delivery_price: product.delivery_price || product.price,
    delivery_price_promotion: product.delivery_price_promotion,
    available: product.available,
    available_in_delivery: product.available_in_delivery,
    ifood_product: initialIfoodData || null,
    icms_aliquota: product.icms_aliquota,
    cst: product.cst, // cst
    pis_aliquota: product.pis_aliquota,
    cofins_aliquota: product.cofins_aliquota,
    cbenef: product.cbenef,
    icms_modalidade_base_calculo: product.icms_modalidade_base_calculo,
    input_quantidade: product.input_master?.quantidade || null,
    icms_base_calculo_retido_st: product.icms_base_calculo_retido_st || null,
    icms_aliquota_final: product.icms_aliquota_final || null,
    icms_valor_substituto: product.icms_valor_substituto || null,
    icms_valor_retido_st: product.icms_valor_retido_st || null,
    icms_reducao_base_calculo: product.icms_reducao_base_calculo || null,
    icms_motivo_desoneracao: product.icms_motivo_desoneracao || null,
    icms_valor_desonerado: product.icms_valor_desonerado || null,
    fcp_percentual: product.fcp_percentual || null,
    icms_aliquota_credito_simples:
      product.icms_aliquota_credito_simples || null,
    icms_valor_credito_simples: product.icms_valor_credito_simples || null,
    enable_times: product.enable_times,
  };

  const englishInitialData = {
    en_name: product?.translations?.en?.name,
    en_description: product?.translations?.en?.description,
    en_promotion: product?.translations?.en?.description,
  };
  async function updateFiscalValues(id) {
    const group = fiscalGroups.find((group) => group.id === id);

    console.log(group);

    setFiscalGroup(id);
    setFiscalGroupDefault({
      value: group.id,
      label: group.title,
    });
    setIcmsOrigemValue(
      icms_origem_options.find((x) => x.value === group.icms_origem)
    );

    setFcpPercentual(group.fcp_percentual);
    setCsosnValue(csosn_options.find((x) => x.value === group.csosn));
    setCfopValue(NFeInfo.cfop_options.find((x) => x.value === group.cfop));
    setCofinsValue(
      NFeInfo.cofins_options.find((x) => x.value === group.cofins)
    );
    setPisValue(NFeInfo.cofins_options.find((x) => x.value === group.pis));
    setCestValue(group.cest);
    setIcmsValorValue(group.icms_valor);
    setAliquotaValue(group.icms_aliquota);
    setBaseCalculoValue(
      base_calculo_options.find((x) => x.value === group.base_calculo)
    );
    // regime normal
    setCstValue(
      NFeInfo.icms_situacao_tributaria.find((x) => x.value === group.cst)
    );
    setPisAliquotaValue(group.pis_aliquota);
    setCofinsAliquotaValue(group.cofins_aliquota);
    setIcmsModalidadeBCValue(
      NFeInfo.icms_modalidade_base_calculo.find(
        (x) => x.value === group.icms_modalidade_base_calculo
      )
    );
    setCbenefValue(group.cbenef);

    setIcmsBaseCalculoRetidoSt(group.icms_base_calculo_retido_st);
    setIcmsValorSubstituto(group.icms_valor_substituto);
    setIcmsValorRetidoSt(group.icms_valor_retido_st);
    setIcmsAliquotaFinal(group.icms_aliquota_final);
    setIcmsMotivoDesoneracao(group.icms_motivo_desoneracao);
    setIcmsValorDesoneradoValue(group.icms_valor_desonerado);
    setIcmsAliquotaCreditoSimples(group.icms_aliquota_credito_simples);
    setIcmsValorCreditoSimples(group.icms_valor_credito_simples);
    setIcmsReducaoBaseCalculo(group.icms_reducao_base_calculo);

    initialData = {
      ...initialData,
      icms_base_calculo_retido_st: group.icms_base_calculo_retido_st,
      icms_valor_substituto: group.icms_valor_substituto,
      icms_valor_retido_st: group.icms_valor_retido_st,
      icms_aliquota_final: group.icms_aliquota_final,
      icms_reducao_base_calculo: group.icms_reducao_base_calculo,
      icms_motivo_desoneracao: group.icms_motivo_desoneracao,
      icms_valor_desonerado: group.icms_valor_desonerado,
      icms_aliquota_credito_simples: group.icms_aliquota_credito_simples,
      icms_valor_credito_simples: group.icms_valor_credito_simples,
    };
  }

  const [inputArray, setInputArray] = useState([]);
  const [intermediaryArray, setIntermediaryArray] = useState([]);
  const [productPriceState, setProductPriceState] = useState(0);
  const [modalInput, setModalInput] = useState(false);
  const [intermediaryQuantidade, setIntermediaryQuantidade] = useState();
  const [intermediaryState, setIntermediaryState] = useState();
  const [createCashFlowOnNewInput, setCreateCashFlowOnNewInput] =
    useState(true);

  function handleAddIntermediaryAtArray() {
    if (intermediaryQuantidade) {
      const intermediaryAlreadyInserted = intermediaryArray.find(
        (item) => item.intermediary_id === intermediarySelectedId
      );
      if (!intermediaryAlreadyInserted) {
        setIntermediaryArray((state) => {
          return [
            {
              id: v4(),
              quantidade: intermediaryQuantidade.replace(',', '.'),
              intermediary_id: intermediarySelectedId,
              name: intermediaryState.name,
              unitary_price: intermediaryState.unitary_price,
            },
            ...state,
          ];
        });
        setIntermediaryQuantidade('');
      } else {
        toast.error('Inserir valor do consumo');
      }
    } else {
      toast.error('Inserir valor do consumo');
    }
  }

  const [modalIntermediary, setModalIntermediary] = useState(false);
  const toggleIntermediary = () => setModalIntermediary(!modalIntermediary);

  const toggleModalInput = () => {
    setModalInput(!modalInput);
    setCreateCashFlowOnNewInput(true);
  };

  const updateInputMaster = useCallback(async () => {
    if (parseFloat(inputQuantidade) < 0) {
      toast.error('A quantidade tem que ser igual ou maior a zero.');
      return false;
    }

    if (product.input_master) {
      try {
        setButtonInputDisabled(true);
        const response = await api.put(
          `/restaurants/input-master/${product.input_master.id}/${product.id}`,
          {
            quantidade: inputQuantidade,
          }
        );

        toast.success('Quantidade atualizada com sucesso!');
        productFather.input_master = response.data;
        productFather.available = response.data.available;
        productFather.available_in_delivery =
          response.data.available_in_delivery;
      } catch (err) {
        setButtonInputDisabled(false);
        toast.error(
          err?.response?.data?.error
            ? `${err.response.data?.error}`
            : 'Erro ao atualizar quantidade.'
        );
        console.log('input master error', err.response);
      }
    } else {
      try {
        setButtonInputDisabled(true);
        const response = await api.post(
          `/restaurants/input-master/${product.id}`,
          {
            quantidade: inputQuantidade,
          }
        );
        setProduct((old) => {
          return { ...old, input_master: response.data };
        });
        toast.success('Quantidade atualizada com sucesso!');
        productFather.input_master = response.data;
        productFather.available = response.data.available;
        productFather.available_in_delivery =
          response.data.available_in_delivery;
      } catch (err) {
        setButtonInputDisabled(false);
        toast.error(
          err?.response?.data?.error
            ? `${err.response.data?.error}`
            : 'Erro ao atualizar quantidade.'
        );
        console.log('input master error', err.response);
      }
    }
  }, [product, inputQuantidade, productFather]);

  const toggleCmvCheck = () => {
    if (!cmvCheck) {
      setCurrentCmv(product?.current_cmv || 0);
    } else {
      setCurrentCmv(null);
    }
    setCmvCheck((state) => !state);
  };

  useEffect(() => {
    if (!cmvCheck && product && currentCmv === null) {
      handleUpdateCmv();
    }
  }, [cmvCheck]);

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const handleUpdateCmv = useCallback(async () => {
    try {
      const response = await api.put(
        `/restaurants/products/cmv/${product.id}`,
        { current_cmv: currentCmv, is_cmv_manual: cmvCheck }
      );

      toast.success(
        `CMV de ${product.name} atualizado para usar ${cmvCheck
          ? `valor inserido! (R$ ${currentCmv})`
          : `valor dos insumos! (R$ ${totalInputs})`
        }`
      );
      setProduct({ ...product, ...response.data });
    } catch (err) {
      toast.error('Não foi possível atualizar o CMV deste produto');
    }
  }, [product, currentCmv]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  useEffect(() => {
    if (product) {
      setEnableTimes(product.enable_times);
    }
  }, [product]);

  const [inputState, setInputState] = useState();

  const [applyFiscalArea, setApplyFiscalArea] = useState(user.use_stock);
  function handleChangeApplyFiscalArea() {
    setApplyFiscalArea(!applyFiscalArea);
  }

  const handleAddInputAtArray = useCallback(() => {
    if (inputQuantidade) {
      const inputsAlreadyInserted = inputArray.find(
        (item) => item.input_id === inputSelectedId
      );

      if (!inputsAlreadyInserted) {
        setInputArray((state) => {
          return [
            {
              id: v4(),
              quantidade: inputQuantidade.replace(',', '.'),
              input_id: inputSelectedId,
              name: inputState.name,
              unitary_price: inputState.unitary_price,
              is_master: inputState.is_master,
            },
            ...state,
          ];
        });
        setInputQuantidade('');
      } else {
        toast.error('Item já inserido');
      }
    } else {
      toast.error('Inserir valor do consumo');
    }
  }, [inputQuantidade, inputSelectedId, inputState, inputArray]);

  function handleDeleteIntermediaryArray(id) {
    const itensFiltered = intermediaryArray.filter(
      (item) => item.intermediary_id !== id
    );

    setIntermediaryArray(itensFiltered);
  }

  function handleDeleteInputArray(id) {
    const itensFiltered = inputArray.filter((item) => item.input_id !== id);

    setInputArray(itensFiltered);
  }

  const [activeButtonsSubmitQuantidade, setActiveButtonsSubmitQuantidade] =
    useState([]);

  function handleActiveButton(id) {
    setActiveButtonsSubmitQuantidade([id]);
  }

  const [inputQuantidadeAtList, setInputQuantidadeAtList] = useState();
  // const [inputQuantidadeState, setInputQuantidadeState] = useState({});

  // useEffect(() => {
  //   const inputFind = document.getElementById(`${inputQuantidadeState?.id}`);

  //   if (inputFind) {
  //     inputFind.value =
  //       inputQuantidadeState &&
  //       formatValue(
  //         parseFloat(inputQuantidadeState?.quantidade) *
  //         parseFloat(inputQuantidadeState?.unitary_price).toFixed(2) || '0.00'
  //       );
  //   }
  // }, [inputQuantidadeState]);

  const [intermediaryQuantidadeAtList, setIntermediaryQuantidadeAtList] =
    useState();
  // const [intermediaryQuantidadeState, setIntermediaryQuantidadeState] =
  //   useState({});

  // useEffect(() => {
  //   const intermediaryFind = document.getElementById(
  //     `${intermediaryQuantidadeState?.id}`
  //   );

  //   if (intermediaryFind) {
  //     intermediaryFind.value =
  //       intermediaryQuantidadeState &&
  //       formatValue(
  //         parseFloat(intermediaryQuantidadeState?.quantidade) *
  //         parseFloat(intermediaryQuantidadeState?.unitary_price).toFixed(2) ||
  //         '0.00'
  //       );
  //   }
  // }, [intermediaryQuantidadeState]);

  function handleChangeInputQuantidadeAtArray(id) {
    const inputFind = inputArray.find((item) => item.input_id === id);
    if (inputFind) {
      inputFind.quantidade = inputQuantidadeAtList;

      toast.success('Quantidade alterada.');

      console.log(inputQuantidadeAtList);
    }
  }

  function handleChangeIntermediaryQuantidadeAtArray(id) {
    const intermediaryFind = intermediaryArray.find(
      (item) => item.intermediary_id === id
    );
    if (intermediaryFind) {
      intermediaryFind.quantidade = intermediaryQuantidadeAtList;

      toast.success('Quantidade alterada.');
    }
  }

  const [isPriceChanged, setIsPriceChanged] = useState(false);

  async function handleSubmit(data, enableTimes, daysSelected, hourSelected) {
    try {
      if (
        data?.price_promotion &&
        Number(data?.price_promotion) >= Number(data?.price)
      ) {
        toast.error(
          'O preço promocional deve ser menor que o preço do produto.'
        );
        return;
      }

      if (
        data?.delivery_price_promotion &&
        data?.delivery_price &&
        Number(data?.delivery_price_promotion) >= Number(data?.delivery_price)
      ) {
        toast.error(
          'O preço promocional no delivery deve ser menor que o preço do produto no delivery.'
        );
        return;
      }

      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      if (!user.only_delivery) {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          price: Yup.string().required('Defina o preço'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      if (user.only_delivery) {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          delivery_price: Yup.string().required('Defina o preço'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      const formatPrice = (price) => {
        return parseFloat(price.replace(',', '.')).toFixed(2);
      };

      const formattedPrice = user.only_delivery
        ? formatPrice(data.delivery_price)
        : formatPrice(data.price);

      const formattedPromotionPrice = data.price_promotion
        ? formatPrice(data.price_promotion)
        : null;

      const formattedDeliveryPrice = data.delivery_price
        ? formatPrice(data.delivery_price)
        : null;

      const formattedDeliveryPromotionPrice = data.delivery_price_promotion
        ? formatPrice(data.delivery_price_promotion)
        : null;

      const parsedData = {
        name: data.name,
        description: data.description,
        price: formattedPrice,
        price_promotion: formattedPromotionPrice,
        product_category_id: data.product_category,
        promotion: data.promotion,
        sold_off: data.sold_off,
        use_weight: data.use_weight,
        charge_service_tax: data.charge_service_tax,
        has_starting_price: data.has_starting_price,
        is_combo: data.is_combo,
        available: data.available,
        available_in_delivery: data.available_in_delivery,
        delivery_price: formattedDeliveryPrice,
        delivery_price_promotion: formattedDeliveryPromotionPrice,
        ifood_product_id: data.ifood_product,
        is_unitary: data.is_unitary,
        start_time: new Date(`2023/01/01 ${hourSelected.start}`),
        end_time: new Date(`2023/01/01 ${hourSelected.end}`),
        active_days: daysSelected.join(''),
        enable_times: enableTimes,
      };

      if (user.has_menew) {
        parsedData.menew_cod = data.menew_cod === '' ? null : data.menew_cod;
      }

      if (data.image_id) {
        parsedData.image_id = Number(data.image_id);
      }
      if (data.thumbnail_id) {
        parsedData.thumbnail_id = Number(data.thumbnail_id);
      }
      console.clear();
      console.log(inputArray);
      if (user.use_stock && applyFiscalArea) {
        parsedData.intermediaries = intermediaryArray || [];
        parsedData.inputs = inputArray || [];
      }

      if (user.use_stock && !applyFiscalArea) {
        parsedData.intermediaries = [];
        parsedData.inputs = [];
      }

      if (hasInput && data.input_quantidade) {
        parsedData.has_input = hasInput;
        parsedData.input_quantidade = data.input_quantidade?.replace(',', '.');
      }
      console.log(parsedData);
      const response = await api.put(
        `restaurants/products/${product.id}`,
        parsedData
      );

      await api.put('restaurants/product-complements', {
        product_id: product.id,
        complement_categories: data.complement_categories,
      });

      if (user.has_nfce) {
        handleSubmitFiscal(data);
      }

      toast.success('Produto atualizado com sucesso!');

      const productFind = products.findIndex((prod) => prod.id === product.id);

      const complements_array = [];

      complementCategoriesData.map((comp, idx) =>
        data.complement_categories.map((item) => {
          if (comp.id === item) {
            complements_array.push(comp);
          }
        })
      );

      const newProductsState = [...products];

      newProductsState[productFind] = {
        ...product,
        ...parsedData,
        has_complement_categories: complements_array.length > 0,
        complement_categories: complements_array,
      };

      const newProducts = [...newProductsState];

      const categoryFind = categories.find((cat) => cat.id === category.id);

      categoryFind.products[productFind] = {
        ...product,
        ...parsedData,
        has_complement_categories: complements_array.length > 0,
        complement_categories: complements_array,
      };

      setCollapsed([]);
      setProducts(newProducts);

      // getIfoodProducts();

      if (isCategoryChanged) {
        // const newCategories = [...categories];
        // const categoryFinded = newCategories.findIndex(
        //   (cat) => cat.id === category.id
        // );
        // const filteredsProducts = newCategories[categoryFinded].products.filter(
        //   (item) => item.id !== product.id
        // );
        // newCategories[categoryFinded].products = [...filteredsProducts];
        // setCategories(newCategories);
        // setProducts(filteredsProducts);
        // const newCategories2 = [...categories];
        // const categoryFinded2 = newCategories.findIndex(
        //   (cat) => cat.id === data.product_category
        // );
        // newCategories2[categoryFinded2].products = [
        //   ...newCategories2[categoryFinded2].products,
        //   response.data,
        // ];
        // setCategories(newCategories2);
        // setIsCategoryUpdate(!isCategoryUpdated);
        // getCategory();

        getCategory();
      }

      localStorage.setItem('@gddashboard:isMenuUpdated', true);
      if (combinedGroup.includes(user.id) && isPriceChanged) {
        toggleNotify();
      }

      toggle();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        if (
          error.response.data.error &&
          error.response.data.error.message ===
          'value too long for type character varying(800)'
        ) {
          console.log(error.response.data);
          toast.error('O campo descrição deve ter no máximo 800 caracteres.');
          return;
        }
        toast.error('Falha ao atualizar produto');
        console.log(error);
      }
    }
  }

  async function handleSubmitEnglishMenu(data) {
    try {
      let translations = null;

      if (data.en_name || data.en_description || data.en_promotion) {
        translations = {
          en: {
            name: data.en_name,
            description: data.en_description,
            promotion: data.en_promotion,
          },
        };
      }

      const response = await api.put(`restaurants/products/${product.id}`, {
        translations,
      });

      setProduct((state) => {
        return { ...state, translations: response.data.translations };
      });

      toggleEnglishMenuModal();
      toast.success('Produto atualizado com sucesso!');
    } catch (error) {
      toast.error('Falha ao atualizar produto');
      console.log(error);
    }
  }

  useEffect(() => {
    if (!cmvCheck) {
      const inpTotal = inputArray.reduce(
        (acc, cur) =>
          acc +
          parseFloat(cur?.unitary_price) *
          parseFloat(cur?.ComplementInput?.quantidade || cur?.quantidade),
        0
      );
      const intTotal = intermediaryArray.reduce(
        (acc, cur) =>
          acc +
          parseFloat(cur.unitary_price) *
          parseFloat(
            cur?.ComplementIntermediary?.quantidade || cur?.quantidade
          ),
        0
      );
      setTotalInputs((inpTotal + intTotal).toFixed(2));
      setCurrentCmv((inpTotal + intTotal).toFixed(2));
    }
  }, [inputArray, intermediaryArray]);

  const [buttonInputDisabled, setButtonInputDisabled] = useState(true);

  const motivoDesoneracaoOptions = [
    { value: null, label: 'Nenhum' },
    { value: '1', label: '1 - Táxi' },
    { value: '3', label: '2 - Produtor Agropecuário' },
    { value: '4', label: '4 - Frotista/Locadora' },
    { value: '5', label: '5 - Diplomático/Consular' },
    {
      value: '6',
      label:
        '6 - Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (Resolução 714/88 e 790/94 - CONTRAN e suas alterações)',
    },
    { value: '7', label: '7 - SUFRAMA' },
    { value: '8', label: '8 - Venda a Órgão Público' },
    { value: '9', label: '9 - Outros' },
    { value: '10', label: '10 - Deficiente Condutor (Convênio ICMS 38/12)' },
    {
      value: '11',
      label: '11 - Deficiente Não Condutor (Convênio ICMS 38/12)',
    },
  ];

  // modalEnglishMenu
  const [isEnglishMenuModalOpened, setIsEnglishMenuModalOpened] =
    useState(false);
  function toggleEnglishMenuModal() {
    setIsEnglishMenuModalOpened(!isEnglishMenuModalOpened);
  }

  const [stateQuantity, setStateQuantity] = useState([]);

  const handleChange = (e, item) => {
    setStateQuantity((oldState) => {
      const itemIndex = oldState.findIndex((it) => it.id === `input${item.id}`);

      if (itemIndex !== -1) {
        const newState = [...oldState];
        newState[itemIndex].quantidade = e.value;
        return newState;
      }
      return [...oldState, { id: `input${item.id}`, quantidade: e.value }];
    });

    setInputQuantidadeAtList(e.value);
  };

  const handleChangeIntermediary = (e, item) => {
    setIntermediaryQuantidadeAtList(e.value);

    setStateQuantity((oldState) => {
      const itemIndex = oldState.findIndex((it) => it.id === `inter${item.id}`);

      if (itemIndex !== -1) {
        const newState = [...oldState];
        newState[itemIndex].quantidade = e.value;
        return newState;
      }
      return [...oldState, { id: `inter${item.id}`, quantidade: e.value }];
    });
  };

  const getFormattedValue = (id, item) => {
    const foundItem = stateQuantity.find((it) => it.id === id);
    const quantidade = foundItem ? foundItem.quantidade : item.quantidade;
    return formatValue(quantidade * item.unitary_price);
  };

  function handleVerifyPrices() {
    const price = formRef.current.getFieldValue('price');
    const pricePromotion = formRef.current.getFieldValue('price_promotion');
    const deliveryPrice = formRef.current.getFieldValue('delivery_price');
    const deliveryPricePromotion = formRef.current.getFieldValue(
      'delivery_price_promotion'
    );

    if (price && pricePromotion && Number(pricePromotion) >= Number(price)) {
      toast.error('O preço promocional deve ser menor que o preço do produto.');
      return;
    }

    if (
      deliveryPrice &&
      deliveryPricePromotion &&
      Number(deliveryPricePromotion) >= Number(deliveryPrice)
    ) {
      toast.error(
        'O preço promocional no delivery deve ser menor que o preço do produto no delivery.'
      );
      return;
    }

    setActiveTab('2');
  }

  return (
    <Container>
      <ReactModal
        onRequestClose={toggleEnglishMenuModal}
        isOpen={isEnglishMenuModalOpened}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <StyledForm
            initialData={englishInitialData}
            ref={formRef}
            onSubmit={handleSubmitEnglishMenu}
          >
            <Row>
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                <img
                  src={englishFlag}
                  alt="Bandeira dos Estados unidos"
                  style={{ width: 20, height: 20 }}
                />{' '}
                Cardápio em inglês
              </span>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col size="md-6">
                <Input
                  label="Nome"
                  name="en_name"
                  type="text"
                  placeholder="Nome da categoria"
                  disabled={category?.is_multistore_child}
                />
              </Col>
              <Col md="6">
                <Input
                  label="Atributo"
                  name="en_promotion"
                  type="text"
                  placeholder="Dose dupla, sugestão..."
                  disabled={!canEditProduct}
                />
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Textarea
                  label="Descriçãoo"
                  name="en_description"
                  type="textarea"
                  placeholder="Descrição do Produto"
                  disabled={!canEditProduct}
                  maxlength={800}
                />
              </Col>
            </Row>

            <ButtonsArea>
              <PersonalOutlineButton
                color="transparent"
                fontColor="#FF2C3A"
                borderColor="#FF2C3A"
                message="Voltar"
                Icon={FaArrowLeft}
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggleEnglishMenuModal();
                }}
              />

              <PersonalButton
                color="#0CCAB4"
                message="Salvar"
                type="submit"
                style={{
                  marginRight: 10,
                }}
              />
            </ButtonsArea>
          </StyledForm>
        </InnerArea>
      </ReactModal>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <NavDiv>
          <NavDivTeste>
            <Nav tabs red borderless>
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => setActiveTab('1')}
                >
                  <NavLinkSpan>Produto</NavLinkSpan>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => setActiveTab('2')}
                >
                  <NavLinkSpan>Fiscal</NavLinkSpan>
                </NavLink>
              </NavItem>

              {user.has_ifood && (
                <NavItem>
                  <NavLink
                    className={activeTab === '3' ? 'active' : ''}
                    onClick={() => setActiveTab('3')}
                  >
                    <NavLinkSpan>iFood</NavLinkSpan>
                  </NavLink>
                </NavItem>
              )}
              {user.use_stock && (
                <NavItem>
                  <NavLink
                    className={activeTab === '4' ? 'active' : ''}
                    onClick={() => setActiveTab('4')}
                  >
                    <NavLinkSpan>Estoque</NavLinkSpan>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </NavDivTeste>
        </NavDiv>

        {user.enable_translations && (
          <EnglishMenuButton onClick={toggleEnglishMenuModal} />
        )}
      </div>

      <Card>
        <Form
          initialData={initialData}
          onSubmit={(data) =>
            handleSubmit(data, enableTimes, daysSelected, hourSelected)
          }
          ref={formRef}
        >
          <TabContent activeTab={activeTab}>
            <TabPane tabId="2">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                {user.has_nfce && (
                  <Row>
                    <Col sm="12">
                      <Select
                        name="fiscal_group_id"
                        options={fiscalGroups?.map((group) => {
                          return {
                            label: group.title,
                            value: group.id,
                          };
                        })}
                        label="Grupo Fiscal"
                        onChange={(e) => updateFiscalValues(e.value)}
                        value={fiscalGroupDefault}
                      />
                    </Col>
                  </Row>
                )}

                {!user.has_nfce && (
                  <FiscalMessage>
                    <p>Seu plano atual não contempla a função de fiscal!</p>
                    <span>
                      Entre em contato com nosso time de suporte e faça a
                      ativação.
                    </span>
                  </FiscalMessage>
                )}
                {user.has_nfce &&
                  product &&
                  (user.regime_tributario === '1' ? (
                    <div>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <InputMask mask="99999999">
                            <Input label="NCM" name="ncm" type="text" />
                          </InputMask>
                        </Col>

                        <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                          <Select
                            name="icms_origem"
                            options={icms_origem_options}
                            label="ICMS de Origem"
                            value={icmsOrigemValue}
                            onChange={(e) => {
                              setIcmsOrigemValue(
                                icms_origem_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="csosn"
                            options={csosn_options}
                            label="CSOSN"
                            onChange={(e) => {
                              setCsosnSelected(e.value);
                              setCsosnValue(
                                csosn_options.find((x) => x.value === e.value)
                              );
                            }}
                            value={csosnValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Select
                            name="cfop"
                            options={cfopOptions}
                            label="CFOP"
                            onChange={(e) => {
                              setCfopValue(
                                NFeInfo.cfop_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cfopValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cofins"
                            options={nfeInfo.cofins_options}
                            label="Cofins"
                            onChange={(e) => {
                              setCofinsValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cofinsValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Select
                            name="pis"
                            options={nfeInfo.cofins_options}
                            label="Pis"
                            onChange={(e) => {
                              setPisValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={pisValue}
                          />
                        </Col>
                      </Row>

                      {(csosnValue?.value === '00' ||
                        aliquotaValue ||
                        baseCalculoValue) && (
                          <div>
                            <Row style={{ paddingTop: 20 }}>
                              <Col sm="12" lg="6">
                                <Input
                                  label="Alíquota (Entre 0 e 100)"
                                  name="aliquota"
                                  type="text"
                                  value={aliquotaValue}
                                  onChange={(e) => {
                                    setAliquotaValue(e.target.value);
                                  }}
                                />
                              </Col>

                              <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                                <Select
                                  name="base_calculo"
                                  options={base_calculo_options}
                                  label="Base Cálculo"
                                  value={baseCalculoValue}
                                  onChange={(e) => {
                                    setBaseCalculoValue(
                                      base_calculo_options.find(
                                        (x) => x.value === e.value
                                      )
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        )}

                      {(csosnValue?.value === '500' || cestValue) && (
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="3">
                            <InputMask
                              mask="9999999"
                              onChange={(e) => {
                                setCestValue(e.target.value);
                              }}
                              value={cestValue}
                            >
                              <Input label="CEST" name="cest" type="text" />
                            </InputMask>
                          </Col>

                          {csosnValue?.value === '500' && (
                            <>
                              <Col sm="12" lg="3">
                                <Input
                                  label="Base de Cálculo ICMS Retido na operação anterior"
                                  name="icms_base_calculo_retido_st"
                                  type="text"
                                />
                              </Col>
                              <Col sm="12" lg="3">
                                <Input
                                  label="Alíquota suportada pelo Consumidor Final"
                                  name="icms_aliquota_final"
                                  type="text"
                                />
                              </Col>
                              <Col sm="12" lg="3">
                                <Input
                                  label="Valor do ICMS próprio do Substituto"
                                  name="icms_valor_substituto"
                                  type="text"
                                />
                              </Col>
                              <Col sm="12" lg="3">
                                <Input
                                  label="Valor do ICMS ST Retido na operação anterior"
                                  name="icms_valor_retido_st"
                                  type="text"
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      )}

                      {csosnValue?.value === '101' && (
                        <Row>
                          <Col sm="12" lg="6">
                            <Input
                              label="Alíquota crédito do ICMS"
                              name="icms_aliquota_credito_simples"
                              type="text"
                              placeholder="%"
                            />
                          </Col>
                          <Col sm="12" lg="6">
                            <Input
                              label="Valor crédito do ICMS"
                              name="icms_valor_credito_simples"
                              type="text"
                              placeholder="R$"
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col sm="12" lg="6">
                          <Input
                            label="Fundo de Comb. a Pobreza (%)"
                            name="fcp_percentual"
                            type="text"
                            value={fcpPercentual}
                            onChange={(e) => {
                              setFcpPercentual(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="4">
                          <InputMask mask="99999999">
                            <Input label="NCM" name="ncm" type="text" />
                          </InputMask>
                        </Col>
                        <Col sm="12" lg="5">
                          <Select
                            name="icms_origem"
                            options={icms_origem_options}
                            label="ICMS de Origem"
                            value={icmsOrigemValue}
                            onChange={(e) => {
                              setIcmsOrigemValue(
                                icms_origem_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                          />
                        </Col>

                        <Col sm="12" lg="3">
                          <Input
                            label="Alíquota ICMS (%)"
                            name="icms_aliquota"
                            type="text"
                            value={aliquotaValue}
                            onChange={(e) => {
                              setAliquotaValue(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cfop"
                            options={cfopOptions}
                            label="CFOP"
                            onChange={(e) => {
                              setCfopValue(
                                NFeInfo.cfop_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cfopValue}
                          />
                        </Col>
                        <Col sm="12" lg="6">
                          <Select
                            name="cst"
                            options={NFeInfo.icms_situacao_tributaria}
                            label="CST"
                            onChange={(e) => {
                              setCstValue(
                                NFeInfo.icms_situacao_tributaria.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cstValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="pis"
                            options={nfeInfo.pis_situacao_tributaria}
                            label="PIS"
                            onChange={(e) => {
                              setPisValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={pisValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota PIS (%)"
                            name="pis_aliquota"
                            type="text"
                            onChange={(e) => {
                              setPisAliquotaValue(e.target.value);
                            }}
                            value={pisAliquotaValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cofins"
                            options={nfeInfo.pis_situacao_tributaria}
                            label="COFINS"
                            onChange={(e) => {
                              setCofinsValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cofinsValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota COFINS (%)"
                            name="cofins_aliquota"
                            type="text"
                            onChange={(e) => {
                              setCofinsAliquotaValue(e.target.value);
                            }}
                            value={cofinsAliquotaValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="icms_modalidade_base_calculo"
                            options={nfeInfo.icms_modalidade_base_calculo}
                            label="Modalidade Base Cálculo"
                            onChange={(e) => {
                              setIcmsModalidadeBCValue(
                                NFeInfo.icms_modalidade_base_calculo.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={icmsModalidadeBCValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Código de Benefícios Fiscais (cBenef)"
                            name="cbenef"
                            type="text"
                            onChange={(e) => {
                              setCbenefValue(e.target.value);
                            }}
                            value={cbenefValue}
                          />
                        </Col>
                      </Row>

                      <Row>
                        {(['10', '30', '60', '70', '90'].includes(
                          cstValue?.value
                        ) ||
                          cestValue) && (
                            <Col sm="3" lg="3">
                              <InputMask
                                mask="9999999"
                                onChange={(e) => {
                                  setCestValue(e.target.value);
                                }}
                                value={cestValue}
                              >
                                <Input label="CEST" name="cest" type="text" />
                              </InputMask>
                            </Col>
                          )}

                        {cstValue?.value === '20' && (
                          <>
                            <Col sm="3" lg="3">
                              <Input
                                label="Valor Desonerado ICMS"
                                name="icms_valor_desonerado"
                                type="text"
                                placeholder="R$"
                                onChange={(e) => {
                                  setIcmsValorDesoneradoValue(e.target.value);
                                }}
                                value={icmsValorDesoneradoValue}
                              />
                            </Col>
                            <Col sm="4" lg="4">
                              <Input
                                label="Percentual de redução da base cálculo"
                                name="icms_reducao_base_calculo"
                                type="text"
                                placeholder="%"
                                onChange={(e) => {
                                  setIcmsReducaoBaseCalculo(e.target.value);
                                }}
                                value={icmsReducaoBaseCalculo}
                              />
                            </Col>
                            <Col sm="5" lg="5">
                              <Select
                                name="icms_motivo_desoneracao"
                                options={motivoDesoneracaoOptions}
                                label="Motivo da desoneração do ICMS"
                                defaultValue={
                                  initialData.icms_motivo_desoneracao
                                    ? motivoDesoneracaoOptions.find(
                                      (mot) =>
                                        mot.value ===
                                        initialData.icms_motivo_desoneracao
                                    )
                                    : null
                                }
                                value={
                                  motivoDesoneracaoOptions.find(
                                    (mot) => mot.value === icmsMotivoDesoneracao
                                  ) || null
                                }
                              />
                            </Col>
                          </>
                        )}

                        {cstValue?.value === '60' && (
                          <>
                            <Col sm="3" lg="3">
                              <Input
                                label="Base de Cálculo ICMS Retido na operação anterior"
                                name="icms_base_calculo_retido_st"
                                type="text"
                                onChange={(e) => {
                                  setIcmsBaseCalculoRetidoSt(e.target.value);
                                }}
                                value={icmsBaseCalculoRetidoSt}
                              />
                            </Col>
                            <Col sm="3" lg="3">
                              <Input
                                label="Alíquota suportada pelo Consumidor Final"
                                name="icms_aliquota_final"
                                type="text"
                                onChange={(e) => {
                                  setIcmsAliquotaFinal(e.target.value);
                                }}
                                value={icmsAliquotaFinal}
                              />
                            </Col>
                            <Col sm="3" lg="3">
                              <Input
                                label="Valor do ICMS próprio do Substituto"
                                name="icms_valor_substituto"
                                type="text"
                                onChange={(e) => {
                                  setIcmsValorSubstituto(e.target.value);
                                }}
                                value={icmsValorSubstituto}
                              />
                            </Col>
                            <Col sm="3" lg="3">
                              <Input
                                label="Valor do ICMS ST Retido na operação anterior"
                                name="icms_valor_retido_st"
                                type="text"
                                onChange={(e) => {
                                  setIcmsValorRetidoSt(e.target.value);
                                }}
                                value={icmsValorRetidoSt}
                              />
                            </Col>
                          </>
                        )}

                        {cstValue?.value === '40' && (
                          <>
                            <Col sm="4" lg="4">
                              <Input
                                label="Valor Desonerado ICMS"
                                name="icms_valor_desonerado"
                                type="text"
                                placeholder="R$"
                                onChange={(e) => {
                                  setIcmsValorDesoneradoValue(e.target.value);
                                }}
                                value={icmsValorDesoneradoValue}
                              />
                            </Col>
                            <Col sm="4" lg="4">
                              <Select
                                name="icms_motivo_desoneracao"
                                options={motivoDesoneracaoOptions}
                                label="Motivo da desoneração do ICMS"
                                defaultValue={
                                  initialData.icms_motivo_desoneracao
                                    ? motivoDesoneracaoOptions.find(
                                      (mot) =>
                                        mot.value ===
                                        initialData.icms_motivo_desoneracao
                                    )
                                    : null
                                }
                                value={
                                  motivoDesoneracaoOptions.find(
                                    (mot) => mot.value === icmsMotivoDesoneracao
                                  ) || null
                                }
                              />
                            </Col>
                          </>
                        )}
                      </Row>

                      <Row>
                        <Col sm="12" lg="6">
                          <Input
                            label="Fundo de Comb. a Pobreza (%)"
                            name="fcp_percentual"
                            type="text"
                            defaultValue={fcpPercentual}
                            onChange={(e) => {
                              setFcpPercentual(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
              </InnerArea>
            </TabPane>

            <TabPane tabId="3">
              {ifoodRestaurants.map((ifoodRestaurant) => {
                const thisOptions =
                  ifoodMenus.find(
                    (menu) =>
                      menu.ifood_restaurant_id ===
                      ifoodRestaurant.ifood_restaurant_id
                  )?.products || [];

                const productsRegistered =
                  product.ifood_products?.filter(
                    (ifood) =>
                      ifood.ifood_restaurant_id ===
                      ifoodRestaurant.ifood_restaurant_id
                  ) || [];
                productsRegistered.forEach((prod) => {
                  const name =
                    thisOptions.find((option) => option.value === prod.ifood_id)
                      ?.label || null;
                  prod.name = name;
                });

                return (
                  <Form>
                    <Row>
                      <Col sm="10">
                        {user.use_ifood_pdv_code ? (
                          <Input
                            name="ifood_product"
                            label={`Código PDV (${ifoodRestaurant.name})`}
                            onChange={(e) => {
                              const newData = selectIfoodProducts;
                              const select = newData.find(
                                (data) =>
                                  data.ifood_restaurant_id ===
                                  ifoodRestaurant.ifood_restaurant_id
                              );
                              if (select) {
                                select.value = e.target.value;
                              }

                              setSelectIfoodProducts(newData);
                            }}
                          />
                        ) : (
                          <Select
                            name="ifood_product"
                            options={thisOptions}
                            label={`${ifoodRestaurant.name}`}
                            onChange={(e) => {
                              const newData = selectIfoodProducts;
                              const select = newData.find(
                                (data) =>
                                  data.ifood_restaurant_id ===
                                  ifoodRestaurant.ifood_restaurant_id
                              );
                              if (select) {
                                select.value = e.value;
                              }

                              setSelectIfoodProducts(newData);
                            }}
                          />
                        )}
                      </Col>
                      <Col sm="2">
                        <SubmitStyledButton
                          id="limit"
                          type="button"
                          style={{
                            marginTop: 30,
                          }}
                          onClick={() =>
                            insertProductIfood({
                              product_id: product.id,
                              ifood_restaurant_id:
                                ifoodRestaurant.ifood_restaurant_id,
                              product,
                            })
                          }
                        >
                          <FaPlus />
                        </SubmitStyledButton>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        borderBottom: '1px solid #EBEBEB',
                        paddingBottom: 10,
                      }}
                    >
                      <IfoodTable borderless>
                        <tbody>
                          {productsRegistered.map((prod) => (
                            <tr>
                              <td>
                                <strong>{prod.name}</strong>
                              </td>
                              <td>{prod.ifood_id}</td>
                              <td>
                                <SubmitStyledButton
                                  id="limit"
                                  type="button"
                                  style={{
                                    background: '#ff2c3a',
                                  }}
                                  onClick={() =>
                                    removeProductIfood({
                                      product_id: product.id,
                                      ifood_id: prod.ifood_id,
                                      ifood_restaurant_id:
                                        ifoodRestaurant.ifood_restaurant_id,
                                    })
                                  }
                                >
                                  <FiTrash />
                                </SubmitStyledButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </IfoodTable>
                    </Row>
                  </Form>
                );
              })}
            </TabPane>

            <TabPane tabId="4">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                <p style={{ color: '#818181', marginTop: 10 }}>
                  Some seu talento e paixão pela gastronomia a uma boa
                  administração.
                </p>

                <SwitcherArea>
                  <Switch
                    onChange={() => handleHasInput()}
                    draggable
                    checked={hasInput}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                  // disabled={isMultistoreChild}
                  />
                  <div style={{ paddingLeft: 20 }}>
                    <p>Controlar quantidade de itens</p>
                    <span>
                      Tudo na sua mão: Você determina quantos ítens possui no
                      estoque, e a cada venda essa quantidade diminui. Ao chegar
                      em zero, o produto é pausado. Ideal para quem trabalha com
                      estoque limitado de produtos.
                    </span>
                  </div>
                </SwitcherArea>

                {hasInput && (
                  <div
                    style={{
                      display: 'flex',
                      width: 390,
                      alignItems: 'center',
                    }}
                  >
                    <Input
                      name="input_quantidade"
                      type="number"
                      value={inputQuantidade}
                      onChange={(e) => {
                        setInputQuantidade(e.target.value);
                        setButtonInputDisabled(false);
                      }}
                    />

                    <SubmitStyledButton
                      onClick={() => updateInputMaster()}
                      type="button"
                      disabled={buttonInputDisabled}
                    >
                      <FaCheck />
                    </SubmitStyledButton>
                    <span
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {product.use_weight ? 'kg' : 'unidades'}
                    </span>
                  </div>
                )}

                <SwitcherArea>
                  <Switch
                    onChange={handleChangeApplyFiscalArea}
                    draggable
                    checked={applyFiscalArea}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                    disabled={!user.use_stock || !canEditProduct}
                  />
                  <div style={{ paddingLeft: 20 }}>
                    <p>Controle de estoque</p>
                    <span>
                      Associe insumos ou produtos intermediários ao seu item e
                      tenha um controle total do seu restaurante. Ideal para
                      quem quer realizar gestão de produção/estoque. A
                      atualizaçõo do estoque ocorre uma vez ao dia às 03:00
                      (horário de Brasília).
                    </span>
                  </div>
                </SwitcherArea>

                {!user.use_stock && (
                  <StockBanner>
                    <div>
                      <img
                        src={StockImg}
                        alt="ilustração representando um estoque, com caixas e uma pessoa verificando no tablet"
                      />
                    </div>
                    <div>
                      <p>
                        Boas notícias! Seu plano atual já contempla a função de
                        controle de estoque!
                      </p>
                      <span>
                        Para ativar essa funcionalidade, entre em contato com o
                        time de suporte que sua ativação será realizada sem
                        custos extras.
                      </span>
                    </div>
                  </StockBanner>
                )}

                {applyFiscalArea && user.use_stock && (
                  <>
                    <InputsTable borderless style={{ width: '54%' }}>
                      <thead>
                        <tr>
                          <th>Custo do Item (CMV)</th>
                          <th>Valor de Venda</th>
                          <th>Lucro Bruto</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td style={{ color: '#A6803A' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {product && (
                                <CmvButtons>
                                  <NumberFormatStyled
                                    prefix="R$"
                                    name="value"
                                    format={currencyFormatter}
                                    defaultValue={formatValue(
                                      currentCmv || '0.00'
                                    )}
                                    disabled={!cmvCheck || !canEditProduct}
                                    value={formatValue(currentCmv || '0.00')}
                                    onChange={(e) =>
                                      setCurrentCmv(
                                        e.target.value
                                          .replace(/(R|\$|\s)/g, '')
                                          .replace(',', '.')
                                      )
                                    }
                                  />
                                  <SubmitStyledButton
                                    onClick={() => handleUpdateCmv()}
                                    disabled={!cmvCheck}
                                    type="button"
                                  >
                                    <FaCheck />
                                  </SubmitStyledButton>
                                </CmvButtons>
                              )}

                              <StyledCheckbox onClick={() => toggleCmvCheck()}>
                                <input
                                  type="checkbox"
                                  checked={cmvCheck}
                                  disabled={!canEditProduct}
                                />
                                <span>Inserir valor manualmente</span>
                              </StyledCheckbox>
                            </div>
                          </td>

                          <td>
                            R$
                            {parseFloat(productPriceState).toLocaleString(
                              'pt-BR',
                              {
                                minimumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td style={{ color: 'green' }}>
                            R$
                            {(
                              parseFloat(productPriceState) -
                              parseFloat(currentCmv || 0)
                            ).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </InputsTable>

                    <InputsTable borderless style={{ marginTop: -32 }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'left' }}>Insumo</th>
                          <th style={{ textAlign: 'left' }}>Consumo</th>
                          <th style={{ textAlign: 'center' }}>Unidade</th>
                          <th style={{ textAlign: 'center' }}>Valor</th>
                          <th style={{ textAlign: 'right' }}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: '60%' }}>
                            <SelectStyled
                              placeholder="Selecione o insumo"
                              name="input"
                              options={restaurantInputsSelect}
                              onChange={(e) => {
                                setInputSelectedId(e.value);
                                setInputState(e);
                              }}
                            />
                          </td>

                          <td style={{ width: '10%' }}>
                            <InputStyled
                              name="input_quantidade"
                              placeholder="0"
                              type="number"
                              step=".001"
                              onChange={(e) =>
                                setInputQuantidade(e.target.value)
                              }
                              value={inputQuantidade}
                              disabled={!canEditProduct}
                            />
                          </td>
                          <td style={{ width: '11%', textAlign: 'center' }}>
                            {inputUnidade}
                          </td>
                          <td style={{ textAlign: 'center' }}>-</td>

                          <td>
                            <PersonalButton
                              color="#0CCAB4"
                              message="Associar"
                              onClick={handleAddInputAtArray}
                              style={{ float: 'right' }}
                              disabled={!canEditProduct}
                            />
                          </td>
                        </tr>
                        <tr>
                          <ButtonAdd
                            onClick={toggleModalInput}
                            type="button"
                            disabled={!canEditProduct}
                          >
                            + Cadastrar novo
                          </ButtonAdd>
                        </tr>
                        {inputArray &&
                          inputArray.length > 0 &&
                          inputArray
                            .filter((it) => !it.is_master)
                            .map((item) => (
                              <tr>
                                <td>{item.name}</td>
                                <td style={{ display: 'flex' }}>
                                  <NumberFormat
                                    defaultValue={item.quantidade}
                                    key={item.quantidade}
                                    onClick={() => {
                                      handleActiveButton(item.input_id);
                                      setInputQuantidadeAtList(item.quantidade);
                                    }}
                                    onSelect={() => {
                                      handleActiveButton(item.input_id);
                                    }}
                                    onValueChange={(e) => {
                                      handleChange(e, item);
                                    }}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    allowedDecimalSeparators={[',', '.']}
                                    isNumericString
                                    disabled={!canEditProduct}
                                    customInput={InputStyled}
                                  />
                                  <SubmitStyledButton
                                    type="button"
                                    disabled={
                                      !activeButtonsSubmitQuantidade.includes(
                                        item.input_id
                                      ) || !canEditProduct
                                    }
                                    onClick={() =>
                                      handleChangeInputQuantidadeAtArray(
                                        item.input_id
                                      )
                                    }
                                  >
                                    <FaCheck />
                                  </SubmitStyledButton>
                                </td>
                                <td style={{ textAlign: 'center' }}>-</td>

                                <td style={{ textAlign: '-webkit-center' }}>
                                  <DefaultInput
                                    // id={`input${item?.id}`}
                                    value={getFormattedValue(
                                      `input${item?.id}`,
                                      item
                                    )}
                                    disabled
                                    containerStyles={{ width: '70%' }}
                                  />
                                </td>
                                <td>
                                  {canEditProduct && (
                                    <FaTrash
                                      color="#FF2C3A"
                                      onClick={() => {
                                        handleDeleteInputArray(item.input_id);
                                      }}
                                      cursor="pointer"
                                      disabled={!canEditProduct}
                                    />
                                  )}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </InputsTable>
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'left' }}>
                            Produto Intermediário
                          </th>
                          <th style={{ textAlign: 'left' }}>Consumo</th>
                          <th style={{ textAlign: 'center' }}>Unidade</th>
                          <th style={{ textAlign: 'center' }}>Valor</th>
                          <th style={{ textAlign: 'right' }}>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td style={{ width: '60%' }}>
                            <SelectStyled
                              placeholder="Selecione o produto intermediário"
                              name="intermediary"
                              options={restaurantIntermediarySelect}
                              onChange={(e) => {
                                setIntermediarySelectedId(e.value);
                                setIntermediaryState(e);
                              }}
                              disabled={!canEditProduct}
                            />
                          </td>
                          <td style={{ width: '10%' }}>
                            <InputStyled
                              name="intermediary_quantidade"
                              placeholder="0"
                              type="number"
                              step=".001"
                              onChange={(e) => {
                                setIntermediaryQuantidade(e.target.value);
                              }}
                              value={intermediaryQuantidade}
                              disabled={!canEditProduct}
                            />
                          </td>
                          <td style={{ width: '11%', textAlign: 'center' }}>
                            {interUnidade}
                          </td>
                          <td style={{ textAlign: 'center' }}>-</td>

                          <td>
                            <PersonalButton
                              color="#0CCAB4"
                              message="Associar"
                              onClick={handleAddIntermediaryAtArray}
                              style={{ float: 'right' }}
                              disabled={!canEditProduct}
                            />
                          </td>
                        </tr>
                        <tr>
                          <ButtonAdd
                            onClick={toggleIntermediary}
                            type="button"
                            disabled={!canEditProduct}
                          >
                            + Cadastrar novo
                          </ButtonAdd>
                        </tr>

                        {intermediaryArray &&
                          intermediaryArray.length > 0 &&
                          intermediaryArray.map((item) => (
                            <tr>
                              <td>{item.name}</td>
                              <td style={{ display: 'flex' }}>
                                <NumberFormat
                                  defaultValue={item.quantidade}
                                  key={item.quantidade}
                                  onClick={() => {
                                    handleActiveButton(item.intermediary_id);
                                    setIntermediaryQuantidadeAtList(
                                      item.quantidade
                                    );
                                  }}
                                  onSelect={() => {
                                    handleActiveButton(item.intermediary_id);
                                  }}
                                  onValueChange={(e) => {
                                    handleChangeIntermediary(e, item);
                                  }}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  allowedDecimalSeparators={[',', '.']}
                                  isNumericString
                                  disabled={!canEditProduct}
                                  customInput={InputStyled}
                                />

                                <SubmitStyledButton
                                  type="button"
                                  disabled={
                                    !activeButtonsSubmitQuantidade.includes(
                                      item.intermediary_id
                                    ) || !canEditProduct
                                  }
                                  onClick={() =>
                                    handleChangeIntermediaryQuantidadeAtArray(
                                      item.intermediary_id
                                    )
                                  }
                                >
                                  <FaCheck />
                                </SubmitStyledButton>
                              </td>
                              <td />
                              <td style={{ textAlign: 'center' }}>
                                <NumberFormat
                                  id={`inter${item?.id}`}
                                  value={getFormattedValue(
                                    `inter${item?.id}`,
                                    item
                                  )}
                                  disabled
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  isNumericString
                                  key={getFormattedValue(
                                    `inter${item?.id}`,
                                    item
                                  )}
                                  customInput={InputStyled}
                                />
                              </td>

                              <td>
                                {canEditProduct && (
                                  <FaTrash
                                    color="#FF2C3A"
                                    onClick={() => {
                                      handleDeleteIntermediaryArray(
                                        item.intermediary_id
                                      );
                                    }}
                                    cursor="pointer"
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                  </>
                )}
              </InnerArea>
            </TabPane>

            <TabPane tabId="1">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                <Row>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <Input
                          label="Nome"
                          name="name"
                          type="text"
                          placeholder="Nome do Produto"
                          disabled={!canEditProduct}
                        />
                      </Col>
                      <Col md="6">
                        <Input
                          label="Atributo"
                          name="promotion"
                          type="text"
                          placeholder="Dose dupla, sugestão..."
                          disabled={!canEditProduct}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Textarea
                          label="Descrição"
                          name="description"
                          type="textarea"
                          placeholder="Descrição do Produto"
                          disabled={!canEditProduct}
                          maxlength={800}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col md="3" style={{ marginTop: 40 }}>
                    <ImageInput
                      name="image_id"
                      product={product}
                      products={products}
                      setProducts={setProducts}
                      setProduct={setProduct}
                      disabled={!canEditProduct}
                    />
                  </Col>
                </Row>

                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                  }}
                >
                  <CheckboxInput
                    name="has_starting_price"
                    label={`Mostrar "a partir de R$ x,xx"`}
                    disabled={isCombo}
                  />

                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                    }}
                    onMouseEnter={() => setComboTooltip(true)}
                    onMouseLeave={() => setComboTooltip(false)}
                  >
                    <CheckboxInput
                      name="is_combo"
                      label="Produto combo"
                      onChange={(e) => {
                        setIsCombo(e.target.checked);
                        if (e.target.checked) {
                          formRef.current.setFieldValue(
                            'has_starting_price',
                            true
                          );
                        }
                      }}
                    />
                    <FaInfoCircle />
                    <Tooltip
                      content={
                        <div>
                          Ao marcar essa opção, o valor dos complementos
                          obrigatórios não serão exibidos ao seu cliente.{' '}
                          <a
                            style={{
                              color: '#2ec9b7',
                              fontWeight: 500,
                              textDecoration: 'underline',
                            }}
                            target="_blank"
                            href="https://produtotakeat.notion.site/Produto-do-tipo-Combo-2483248e2c1a4c64bf6f09e5a15b43af?pvs=4"
                            rel="noreferrer"
                          >
                            Entender melhor
                          </a>
                        </div>
                      }
                      show={comboTooltip}
                      containerStyles={{
                        top: 27,
                        padding: 10,
                      }}
                    />
                  </div>

                  <Checkbox label="Produto Esgotado" name="sold_off" />

                  <Checkbox label="Vendido por peso" name="use_weight" />

                  <Checkbox
                    label="Horário personalizado"
                    name="enable_times"
                    value={enableTimes}
                    onChange={() => {
                      setEnableTimes(!enableTimes);
                    }}
                    disabled={disable_enable_times}
                  />

                  {!user.only_delivery && (
                    <Checkbox
                      defaultChecked={product.available}
                      label="Disponível no Presencial"
                      name="available"
                    />
                  )}

                  <Checkbox
                    defaultChecked={product.available_in_delivery}
                    label="Disponível no Delivery"
                    name="available_in_delivery"
                  />

                  {!user.only_delivery && user?.has_service_tax && (
                    <CheckboxInput
                      name="charge_service_tax"
                      label={`Cobrar ${user.service_tax}% de taxa.`}
                      defaultChecked={product?.charge_service_tax}
                    />
                  )}

                  <CheckboxInput name="is_unitary" label="Escolha única" />
                </div>
                {/* 
                <Row>
                  <Col md="4">
                    <CheckboxInput
                      name="has_starting_price"
                      label={`Mostrar "a partir de R$ x,xx"`}
                      disabled={isCombo}
                    />
                  </Col>

                  <Col md="4">
                    <CheckboxInput
                      name="is_combo"
                      label="Produto combo"
                      onChange={(e) => {
                        setIsCombo(e.target.checked);
                        if (e.target.checked) {
                          formRef.current.setFieldValue(
                            'has_starting_price',
                            true
                          );
                        }
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <Checkbox label="Produto Esgotado" name="sold_off" />
                  </Col>

                  <Col md="4">
                    <Checkbox label="Vendido por peso" name="use_weight" />
                  </Col>

                  <Col md="4">
                    <Checkbox
                      label="Horário personalizado"
                      name="enable_times"
                      value={enableTimes}
                      onChange={() => {
                        setEnableTimes(!enableTimes);
                      }}
                      disabled={disable_enable_times}
                    />
                  </Col>
                </Row>

                <Row>
                  {!user.only_delivery && (
                    <Col md="4">
                      <Checkbox
                        defaultChecked={product.available}
                        label="Disponível no Presencial"
                        name="available"
                      />
                    </Col>
                  )}
                  <Col md="4">
                    <Checkbox
                      defaultChecked={product.available_in_delivery}
                      label="Disponível no Delivery"
                      name="available_in_delivery"
                    />
                  </Col>

                  {!user.only_delivery && user?.has_service_tax && (
                    <>
                      <Col md="4">
                        <CheckboxInput
                          name="charge_service_tax"
                          label={`Cobrar ${user.service_tax}% de taxa.`}
                          defaultChecked={product?.charge_service_tax}
                        />
                      </Col>
                    </>
                  )}
                </Row> */}

                {enableTimes && (
                  <PersonalizedHoursContainer>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Dias:</span>
                      <div style={{ display: 'flex', gap: 5 }}>
                        <Button
                          type="button"
                          title="Dom"
                          buttonColor="#2ec9b7"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[0] === 'f' ? 0.5 : 1,
                          }}
                          onClick={() => {
                            handleChangeDay(0);
                          }}
                          disabled={disable_enable_times}
                        />
                        <Button
                          type="button"
                          title="Seg"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[1] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(1);
                          }}
                          disabled={disable_enable_times}
                        />
                        <Button
                          type="button"
                          title="Ter"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[2] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(2);
                          }}
                          disabled={disable_enable_times}
                        />
                        <Button
                          type="button"
                          title="Qua"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[3] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(3);
                          }}
                          disabled={disable_enable_times}
                        />
                        <Button
                          type="button"
                          title="Qui"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[4] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(4);
                          }}
                          disabled={disable_enable_times}
                        />
                        <Button
                          type="button"
                          title="Sex"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[5] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(5);
                          }}
                          disabled={disable_enable_times}
                        />
                        <Button
                          type="button"
                          title="Sáb"
                          containerStyles={{
                            width: 60,
                            height: 30,
                            opacity: daysSelected[6] === 'f' ? 0.5 : 1,
                          }}
                          buttonColor="#2ec9b7"
                          onClick={() => {
                            handleChangeDay(6);
                          }}
                          disabled={disable_enable_times}
                        />
                      </div>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Horários:</span>
                      <div
                        style={{
                          display: 'flex',
                          gap: 5,
                          alignItems: 'center',
                        }}
                        disabled={disable_enable_times}
                      >
                        <span>das</span>
                        <DefaultInput
                          placeholder="__:__"
                          mask="99:99"
                          maskChar="0"
                          style={{
                            width: '74px',
                            padding: '0px 10px',
                            height: '30px',
                          }}
                          value={hourSelected.start}
                          disabled={disable_enable_times}
                          onChange={(e) => {
                            const { value } = e.target;
                            setHourSelected((state) => {
                              return {
                                start: handleTimeInput(value),
                                end: state.end,
                              };
                            });
                          }}
                        />
                        <span>às</span>
                        <DefaultInput
                          placeholder="__:__"
                          mask="99:99"
                          maskChar="0"
                          style={{
                            width: '74px',
                            padding: '0px 10px',
                            height: '30px',
                          }}
                          disabled={disable_enable_times}
                          value={hourSelected.end}
                          onChange={(e) => {
                            const { value } = e.target;
                            setHourSelected((state) => {
                              return {
                                start: state.start,
                                end: handleTimeInput(value),
                              };
                            });
                          }}
                        />
                      </div>
                    </div>
                  </PersonalizedHoursContainer>
                )}

                {!user.only_delivery && (
                  <Row>
                    <Col md="3">
                      <Input
                        label="Preço no Presencial"
                        name="price"
                        type="number"
                        step="0.01"
                        min="0"
                        placeholder="R$"
                        disabled={!canEditProduct}
                        onClick={() => {
                          setIsPriceChanged(true);
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <Input
                        label="Preço Promo. Presenc."
                        name="price_promotion"
                        type="number"
                        step="0.01"
                        min="0"
                        placeholder="R$"
                        disabled={!canEditProduct}
                      />
                    </Col>

                    {user?.delivery_info?.is_delivery_allowed && (
                      <>
                        <Col md="3">
                          <Input
                            label="Preço no Delivery"
                            name="delivery_price"
                            type="number"
                            step="0.01"
                            min="0"
                            placeholder="R$"
                            disabled={!canEditProduct}
                            onClick={() => {
                              setIsPriceChanged(true);
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <Input
                            label="Preço Promo. Delivery"
                            name="delivery_price_promotion"
                            type="number"
                            step="0.01"
                            min="0"
                            placeholder="R$"
                            disabled={!canEditProduct}
                          />
                        </Col>{' '}
                      </>
                    )}
                  </Row>
                )}

                {user?.delivery_info?.is_delivery_allowed &&
                  user.only_delivery && (
                    <Row>
                      <Col md="3">
                        <Input
                          label="Preço no Delivery"
                          name="delivery_price"
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="R$"
                          onClick={() => {
                            setIsPriceChanged(true);
                          }}
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          label="Preço Promo. Delivery"
                          name="delivery_price_promotion"
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="R$"
                          disabled={!canEditProduct}
                        />
                      </Col>
                    </Row>
                  )}

                {/* {user?.delivery_info?.is_delivery_allowed &&
                      !user.only_delivery && (
                        <>
                          <Col md="3">
                            <Input
                              label="Preço no delivery"
                              name="delivery_price"
                              type="number"
                              step="0.01"
                              min="0"
                              placeholder="R$12,00"
                              disabled={isMultistoreChild}
                            />
                          </Col>
                          <Col md="3">
                            <Input
                              label="Preço Promo. Delivery"
                              name="delivery_price_promotion"
                              type="number"
                              step="0.01"
                              min="0"
                              placeholder="R$10,00"
                              disabled={isMultistoreChild}
                            />
                          </Col>
                        </>
                    )} */}

                <Row>
                  <Col sm="12">
                    <Select
                      label="Categoria"
                      name="product_category"
                      options={categoriesOptions}
                      onChange={handleChangeCategory}
                      isDisabled={!canEditProduct}
                    />
                  </Col>
                </Row>

                <Row
                  style={
                    !canAssociateComplements
                      ? {
                        pointerEvents: 'none',
                        opacity: 0.5,
                      }
                      : {}
                  }
                >
                  <Select
                    name="complement_categories"
                    isMulti
                    defaultValue={initialComplementCategories}
                    key={initialComplementCategories}
                    options={complementCategories}
                    label="Associar categorias de complementos ao seu produto"
                    isDisabled={!canEditProduct}
                  />
                </Row>

                {user.has_menew && product && (
                  <Row>
                    <Select
                      name="menew_cod"
                      options={menew_options}
                      defaultValue={menew_default}
                      label="Produto Menew"
                    />
                  </Row>
                )}
              </InnerArea>
            </TabPane>
          </TabContent>

          <ButtonsArea>
            {activeTab === '1' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    toggle();
                  }}
                />
                <div style={{ display: 'flex' }}>
                  <PersonalOutlineButton
                    color="#fff"
                    fontColor="#0CCAB4"
                    borderColor="#0CCAB4"
                    message="Salvar e sair"
                    type="submit"
                  />
                  <PersonalButton
                    color="#0CCAB4"
                    message="Próximo"
                    type="button"
                    onClick={() => {
                      handleVerifyPrices();
                    }}
                  />
                </div>
              </>
            )}
            {activeTab === '2' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Voltar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    setActiveTab('1');
                  }}
                />
                <div style={{ display: 'flex' }}>
                  <PersonalOutlineButton
                    color="#fff"
                    fontColor="#0CCAB4"
                    borderColor="#0CCAB4"
                    message="Salvar e sair"
                    type="submit"
                  />
                  <PersonalButton
                    color="#0CCAB4"
                    message="Próximo"
                    type="button"
                    onClick={() => {
                      user.has_ifood ? setActiveTab('3') : setActiveTab('4');
                    }}
                  />
                </div>
              </>
            )}
            {activeTab === '3' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Voltar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    setActiveTab('2');
                  }}
                />
                <div style={{ display: 'flex' }}>
                  <PersonalOutlineButton
                    color="#fff"
                    fontColor="#0CCAB4"
                    borderColor="#0CCAB4"
                    message="Salvar e sair"
                    type="submit"
                  />
                  <PersonalButton
                    color="#0CCAB4"
                    message="Próximo"
                    type="button"
                    onClick={() => {
                      setActiveTab('4');
                    }}
                  />
                </div>
              </>
            )}

            {activeTab === '4' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Voltar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    user.has_ifood ? setActiveTab('3') : setActiveTab('2');
                  }}
                />
                <div style={{ display: 'flex' }}>
                  <PersonalButton
                    color="#0CCAB4"
                    message="Salvar"
                    type="submit"
                  />
                </div>
              </>
            )}
          </ButtonsArea>
        </Form>
      </Card>

      <ReactModal
        isOpen={modalInput}
        toggle={toggleModalInput}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
        onRequestClose={toggleModalInput}
      >
        <Form onSubmit={handleInput} ref={formInputRef}>
          <Row>
            <Col md="12">
              <p>Cadastrar Insumos</p>
            </Col>
          </Row>

          <InnerArea browserZoomLevel={browserZoomLevel}>
            <Row>
              <Col md="12">
                <Input label="Nome do Insumo:" name="name" type="text" />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Input
                  label="Estoque atual:"
                  name="quantidade"
                  type="number"
                  step="0.001"
                  icon={
                    <FaQuestionCircle
                      title="Quantidade de insumo adiciondo ao estoque"
                      color="orange"
                      style={{ marginLeft: 5 }}
                    />
                  }
                />
              </Col>
              <Col md="4">
                <Select
                  label="Unidade:"
                  name="unidade"
                  options={unidade_options}
                  defaultValue={unidade_options[1]}
                // onChange={(e) => setInputSelectedId(e.value)}
                />
              </Col>
              <Col md="4">
                <Input
                  label="Valor Gasto"
                  name="total_to_send"
                  type="number"
                  step="0.01"
                  defaultValue="1"
                  icon={
                    <FaQuestionCircle
                      title="Custo referente à quantidade de insumo adicionado."
                      color="orange"
                      style={{ marginLeft: 5 }}
                    />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Input
                  label="Estoque Mínimo"
                  name="minimum_stock"
                  type="number"
                  step="0.001"
                  icon={
                    <FaQuestionCircle
                      title="Caso o estoque do Insumo esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas."
                      color="orange"
                      style={{ marginLeft: 5 }}
                    />
                  }
                />
              </Col>
              <Col md="4">
                <Input
                  label="Estoque Ideal"
                  name="ideal_stock"
                  type="number"
                  step="0.001"
                />
              </Col>

              <Col md="4">
                <Select
                  label="Associar centro de custo"
                  name="category"
                  options={categoriesDetails}
                  placeholder="Selecione a categoria"
                // onChange={(e) => setInputSelectedId(e.value)}
                />
              </Col>
            </Row>

            <Row>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <label>
                  <CheckboxInput
                    name="create_cashflow"
                    label="Vincular esta movimentação ao financeiro"
                    defaultChecked
                    onChange={() => {
                      handleCreateCashFlowOnNewInput();
                    }}
                  />
                </label>
              </div>
            </Row>

            {createCashFlowOnNewInput && (
              <>
                <p style={{ marginTop: 10 }}>
                  Informações Adicionais{' '}
                  <FaQuestionCircle
                    color="orange"
                    title="Complemente as informações do seu lançamento para uma melhor gestão de estoque."
                  />
                </p>
                <Row>
                  <Col md="4">
                    <Row>
                      <Col md="9" style={{ marginLeft: 5 }}>
                        <Select
                          label="Fornecedor"
                          name="provider_id"
                          options={suppliers}
                          placeholder="Fornecedor"
                        // onChange={(e) => setInputSelectedId(e.value)}
                        />
                      </Col>
                      <Col md="1">
                        <button
                          type="button"
                          style={{
                            width: 32,
                            height: 32,
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            background: '#3BD2C1',
                            borderRadius: '5px',
                            border: 'none',
                            marginBottom: 15,
                          }}
                          title="Cadastrar Fornecedor"
                          onClick={toggleModalCreateSuplliers}
                        >
                          <FaPlus />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Row>
                      <Col md="9" style={{ marginLeft: 5 }}>
                        <Select
                          label="Conta"
                          name="bank_account_id"
                          options={banks}
                          placeholder="Conta"
                        // onChange={(e) => setInputSelectedId(e.value)}
                        />
                      </Col>
                      <Col md="1">
                        <button
                          type="button"
                          style={{
                            width: 32,
                            height: 32,
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            background: '#3BD2C1',
                            borderRadius: '5px',
                            border: 'none',
                            marginBottom: 15,
                          }}
                          onClick={toggleModalCreateBank}
                          title="Cadastrar Conta Bancária"
                        >
                          <FaPlus />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Input
                      id="date-local"
                      label="Vencimento"
                      type="date"
                      name="due_date"
                      defaultValue={format(new Date(), 'yyyy-MM-dd')}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <CheckboxInput
                      name="paid"
                      label="Pago"
                      onChange={handleSetPaidCheck}
                    />
                  </Col>{' '}
                </Row>

                {paidCheck && (
                  <Row>
                    <Col>
                      <Input
                        id="date-local"
                        label="Pago em:"
                        type="date"
                        name="paid_at"
                        defaultValue={format(new Date(), 'yyyy-MM-dd')}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </InnerArea>

          <ButtonsArea>
            <PersonalButton
              color="#FF2C3A"
              message="Cancelar"
              onClick={toggleModalInput}
            />
            <PersonalButton color="#0CCAB4" message="Salvar" type="submit" />
          </ButtonsArea>
        </Form>
      </ReactModal>

      <ReactModal
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
        isOpen={modalIntermediary}
        toggle={toggleIntermediary}
        onRequestClose={toggleIntermediary}
      >
        <Form onSubmit={handleIntermediary} ref={formIntermediaryRef}>
          <TabContent activeTab={activeIntermediaryTab}>
            <TabPane tabId="4">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                <Row>
                  <Col md="12">
                    <p>Adicionar produto intermediário</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div style={{ lineHeight: '18px', display: 'flex' }}>
                      <p style={{ fontWeight: 'bold' }}>Nome:</p>
                      <span style={{ fontSize: 14, color: '#FF2C3A' }}>*</span>
                    </div>
                    <Input
                      name="name"
                      id="name_intermediary"
                      type="text"
                      onChange={(e) => setNameIntermediary(e.target.value)}
                    />
                  </Col>

                  <Col md="6" style={{ marginTop: 0 }}>
                    <div style={{ lineHeight: '18px', display: 'flex' }}>
                      <p style={{ fontWeight: 'bold' }}>Unidade:</p>
                      <span style={{ fontSize: 14, color: '#FF2C3A' }}>*</span>
                    </div>
                    <Select
                      name="unidade"
                      options={unidade_options}
                      defaultValue={unidade_options[1]}
                      onChange={(e) => setUnidadeSelected(e.label)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div style={{ lineHeight: '18px' }}>
                      <div style={{ lineHeight: '18px', display: 'flex' }}>
                        <p style={{ fontWeight: 'bold' }}>Rendimento</p>
                        <span style={{ fontSize: 14, color: '#FF2C3A' }}>
                          *
                        </span>
                      </div>
                      <span style={{ fontSize: 14 }}>
                        Inerir o rendimento da ficha técnica (receita)
                      </span>
                    </div>

                    <Input
                      name="yield"
                      defaultValue={1}
                      type="number"
                      step="0.001"
                      onChange={(e) => setYeldIntermediary(e.target.value)}
                    />

                    {/* <Col md="8">
                    <p style={{ marginTop: 15 }}>
                      {unidadeSelected} de{' '}
                      {nameIntermediary === '' ? '-' : nameIntermediary}
                    </p>
                  </Col> */}
                  </Col>
                  <Col md="6">
                    <div style={{ lineHeight: '18px' }}>
                      <p style={{ fontWeight: 'bold' }}>
                        Quantidade em Estoque
                      </p>
                      <span style={{ fontSize: 14 }}>
                        Quantidade atual do produto intermediário
                      </span>
                    </div>
                    <Input
                      name="quantidade_inter"
                      onChange={(e) => setQuantInter(e.target.value)}
                      type="number"
                      step="0.001"
                      icon={
                        <FaQuestionCircle
                          title="Quantidade criada do Produto Intermediário nessa receita."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div style={{ lineHeight: '18px' }}>
                      <p style={{ fontWeight: 'bold' }}>Estoque Mínimo</p>
                      <span style={{ fontSize: 14 }}>
                        Você receberá uma notificação caso o estoque esteja
                        abaixo do mínimo
                      </span>
                    </div>
                    <Input
                      name="minimum_stock"
                      icon={
                        <FaQuestionCircle
                          title="Caso o estoque do Produto Intermediário esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas.."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="6">
                    <div style={{ lineHeight: '18px' }}>
                      <p style={{ fontWeight: 'bold' }}>Estoque ideal</p>
                      <span style={{ fontSize: 14 }}>
                        Você receberá uma notificação caso o seu estoque esteja
                        abaixo do ideal
                      </span>
                    </div>
                    <Input name="ideal_stock" />
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <div style={{ lineHeight: '18px' }}>
                      <p style={{ fontWeight: 'bold' }}>Receita</p>
                      <span style={{ fontSize: 14 }}>
                        Informe a receita desse produto intermediário
                      </span>
                    </div>
                    <Textarea name="recipe" type="text" maxlength={500} />
                  </Col>
                </Row>
              </InnerArea>
            </TabPane>
          </TabContent>

          <TabContent activeTab={activeIntermediaryTab}>
            <TabPane tabId="5">
              <InnerArea browserZoomLevel={browserZoomLevel}>
                <Row>
                  <Col md="12">
                    <p>Adicionar produto intermediário &gt; Ficha Técnica</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Select
                      label="Insumos"
                      name="inputs_select"
                      options={restaurantInputsSelect}
                      placeholder="Selecione"
                      onChange={(e) => setSelectInput(e)}
                      icon={
                        <FaQuestionCircle
                          title="Vincule os insumos usados na receita para produzir seu produto intermediário"
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      label="Quantidade"
                      name="quantidade"
                      type="text"
                      defaultValue={0}
                      onChange={(e) => {
                        setDefaultInterValue(e.target.value);
                        document.getElementById('add').disabled = false;
                      }}
                      icon={
                        <FaQuestionCircle
                          title="Insira a quantidade necessária utilizada na receita para produzir seu produto intermediário."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                    <button
                      type="button"
                      id="add"
                      style={{
                        background: '#3BD2C1',
                        width: 40,
                        height: 40,
                        borderRadius: '5px',
                        border: 'none',
                        fontSize: 16,
                      }}
                      onClick={() =>
                        insertInputInter(selectInput, defaultInterValue)
                      }
                    >
                      <FaPlus />
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th>Insumo</th>
                          <th>
                            Consumo unitário{' '}
                            <FaQuestionCircle
                              title="Quantidade necessária para
                            se produzir uma unidade do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Custo do insumo{' '}
                            <FaQuestionCircle
                              title="Custo de uma unidade do insumo."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Consumo total{' '}
                            <FaQuestionCircle
                              title="Quantidade necessária para
                                se produzir uma receita do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>
                            Custo da receita{' '}
                            <FaQuestionCircle
                              title="Custo para se produzir uma receita do Produto Intermediário."
                              color="orange"
                              style={{ marginLeft: 2 }}
                            />
                          </th>
                          <th>Disponível</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {interInputs.length > 0 &&
                          interInputs.map((input) => (
                            <tr>
                              <td>{input.name}</td>
                              <td>
                                {parseFloat(
                                  input.quantidade_inter_value /
                                  yeldIntermediary
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(input.unitary_price).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                {parseFloat(input.quantidade_inter_value)
                                  .toFixed(3)
                                  .replace('.', ',')}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(
                                  input.quantidade_inter_value *
                                  // yeldIntermediary *
                                  input.unitary_price
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                {' '}
                                {input.quantidade.replace('.', ',')}{' '}
                                {input.unidade}
                              </td>

                              <td>
                                <Delete
                                  type="button"
                                  onClick={() => deleteInputInter(input.id)}
                                >
                                  <MdDelete size={20} />
                                </Delete>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ margin: 5 }} />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Select
                      label="Prod. Interm."
                      name="inter_select"
                      options={restaurantIntermediarySelect}
                      placeholder="Selecione"
                      onChange={(e) => setSelectInter(e)}
                      icon={
                        <FaQuestionCircle
                          title="Vincule os produtos intermediários usados na receita para produzir seu novo produto intermediário."
                          color="orange"
                          style={{ marginLeft: 5 }}
                        />
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      label="Quantidade"
                      name="quantidade"
                      type="text"
                      defaultValue={0}
                      onChange={(e) => {
                        setDefaultInterInterValue(
                          e.target.value.replace(',', '.')
                        );
                        document.getElementById('add1').disabled = false;
                      }}
                    />
                  </Col>
                  <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                    <button
                      type="button"
                      id="add1"
                      style={{
                        background: '#3BD2C1',
                        width: 40,
                        height: 40,
                        borderRadius: '5px',
                        border: 'none',
                        fontSize: 16,
                      }}
                      onClick={() =>
                        insertInterInter(selectInter, defaultInterInterValue)
                      }
                    >
                      <FaPlus />
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <InputsTable borderless>
                      <thead>
                        <tr>
                          <th>Prod. interm.</th>
                          <th>Consumo nitário</th>
                          <th>Custo do Prod. Int.</th>
                          <th>Consumo total</th>
                          <th>Custo da receita</th>
                          <th>Disponível</th>
                          <th>Ações</th>
                        </tr>
                      </thead>

                      <tbody>
                        {interInter.length > 0 &&
                          interInter.map((inter) => (
                            <tr>
                              <td>{inter.name}</td>
                              <td>
                                {parseFloat(
                                  inter.quantidade_inter_value /
                                  yeldIntermediary
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {inter.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(inter.unitary_price).toLocaleString(
                                  'pt-BR',
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>
                                {parseFloat(inter.quantidade_inter_value)
                                  .toFixed(3)
                                  .replace('.', ',')}
                                {inter.unidade}
                              </td>

                              <td>
                                R$
                                {parseFloat(
                                  inter.quantidade_inter_value *
                                  // yeldIntermediary *
                                  inter.unitary_price
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}
                              </td>

                              <td>
                                {' '}
                                {inter.quantidade.replace('.', ',')}{' '}
                                {inter.unidade}
                              </td>

                              <td>
                                <Delete
                                  type="button"
                                  onClick={() => deleteInterInter(inter.id)}
                                >
                                  <MdDelete size={20} />
                                </Delete>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </InputsTable>
                  </Col>
                </Row>
              </InnerArea>
            </TabPane>
          </TabContent>

          <ButtonsArea>
            {activeIntermediaryTab === '4' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  onClick={toggleIntermediary}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Próximo"
                  type="button"
                  onClick={() => setActiveIntermediaryTab('5')}
                />
              </>
            )}

            {activeIntermediaryTab === '5' && (
              <>
                <PersonalButton
                  color="#FF2C3A"
                  message="Voltar"
                  onClick={() => setActiveIntermediaryTab('4')}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                />
              </>
            )}
          </ButtonsArea>
        </Form>
      </ReactModal>

      <Modal
        isOpen={isModalCreateSuppliersOpen}
        toggle={toggleModalCreateSuplliers}
      >
        <ModalHeader> Criar Fornecedor</ModalHeader>
        <Form onSubmit={handleSupplierSubmit} ref={formRefSupplier}>
          <ModalBody>
            <Input label="Nome do fornecedor" name="name" />
            <Input label="CNPJ" name="cnpj" />
            <InputMask mask="(99) 99999-9999">
              <Input
                label="Telefone"
                name="phone"
                maskChar=""
                placeholder="(xx) xxxxx-xxxx"
              />
            </InputMask>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggleModalCreateSuplliers}>
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
        <ModalHeader> Criar Conta</ModalHeader>
        <Form onSubmit={handleBankSubmit} ref={formRefBank}>
          <ModalBody>
            <Input label="Nome do banco" name="name" />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggleModalCreateBank}>
              Cancelar
            </Button>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}
export default EditProduct;
