import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaPercent } from 'react-icons/fa';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';
import { Button, DefaultInput, Hyperlink, IconInput } from 'ui-kit-takeat';
import { CurrencyInput } from '~/components/CurrencyInput';
import { useAuth } from '~/context/AuthContext';
import { useCart } from '~/context/OrderingSystem/Cart';
import { usePos } from '~/context/PosContext';
import apiClube from '~/services/apiClube';
import formatValue from '~/utils/formatValue';
import { Input, Button as NewButton } from 'takeat-design-system-ui-kit';
import { useTheme } from 'styled-components';
import { BANESTES_METHOD, CLUBE_METHOD } from '~/utils/consts';
import {
  ClientArea,
  ClubArea,
  Container,
  DiscountApplied,
  OptionButton,
  PaymentsMade,
  PaymentsOptions,
  Value,
  ValuesArea,
} from './styles';

import api2 from '~/services/api2';
import { PaymentRow } from './PaymentRow';
import { AddClients } from '../Cart/Clients/index';

export const Payments = ({
  clubInfo,
  setClubInfo,
  clientCashback,
  setClientCashback,
  banestesPayment,
  setBanestesPayment,
  paymentsSession,
  setPaymentsSession,
  setMetodoAndPaymentOption,
  left,
  setLeft,
  discount,
  discountPercent,
  value,
  setValue,
  paid,
  totalProduct,
  hasMoneyMethod,
  setModalDiscount,
  handleStoneTransactions,
  loadingPos,
  toggleCancelPos,
  modalCancelPos,
  setModalCancelPos,
  handleCancelStoneTransactions,
  allPaymentMethods,
  favoriteMethods,
  paymentMethods,
  checkStonePending,
  setBuyerName,
  buyerName,
}) => {
  const theme = useTheme();
  const { user, setData } = useAuth();
  const { cart } = useCart();
  const {
    stoneTransactions,
    cancelStoneTransactionsBalcony,
    createStoneTransactionsBalcony,
    cancelAllStoneTransactions,
  } = usePos();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const [metodo, setMetodo] = useState('Outros');
  const [clientRegister, setClientRegister] = useState(true);
  const [addClientModal, setAddClientModal] = useState(false);
  const [loadingCashback, setLoadingCashback] = useState(false);
  const totalToShow =
    totalProduct - (parseFloat(discount?.replace(',', '.')) || 0);

  const deletePayments = useCallback(
    async (id, skipConfirmation = false) => {
      if (
        skipConfirmation ||
        window.confirm('Tem certeza que deseja remover esse pagamento?')
      ) {
        try {
          const payments_bill = paymentsSession.filter(
            (payment) => payment.id !== id
          );

          const payments_bill_find = paymentsSession.filter(
            (payment) => payment.id === id
          );

          setValue(Number(left) + Number(payments_bill_find[0].payment_value));

          if (discount) {
            setLeft(
              Math.abs(
                Number(left) +
                Number(payments_bill_find[0].payment_value) -
                Number(discount)
              ).toFixed(2)
            );
          } else {
            setLeft(
              Math.abs(
                Number(left) + Number(payments_bill_find[0].payment_value)
              ).toFixed(2)
            );
          }

          setPaymentsSession(payments_bill);

          if (
            stoneTransactions.find(
              (trans) =>
                trans.payment_balcony_id === id && trans.status !== 'canceled'
            )
          ) {
            cancelStoneTransactionsBalcony({ payment_balcony_id: id });
          }

          toast.success('Pagamento removido com sucesso!');
        } catch (error) {
          if (
            error.response.data.errorType === 'credit_register_already_finished'
          ) {
            toast.error(
              'Essa conta a prazo já foi quitada, não pode ser removida.'
            );
          } else {
            toast.error('Erro ao deletar pagamento');
          }
        }
      }
    },
    [paymentsSession, setPaymentsSession, left, stoneTransactions]
  );

  const [hasClient, setHasClient] = useState(false);

  const getCashback = useCallback(async () => {
    try {
      let login_club;
      setLoadingCashback(true);

      if (!user.club_login) {
        login_club = await apiClube.post('/public/sessions/takeat', {
          token: user.token_clube,
        });

        localStorage.setItem(
          '@gddashboard:user',
          JSON.stringify({
            ...user,
            club_login: login_club.data.token,
            minimo: login_club.data.user?.settings[0]?.minimo,
          })
        );

        setData((state) => {
          return {
            ...state,
            user: {
              ...user,
              club_login: login_club.data.token,
              minimo: login_club.data.user?.settings[0]?.minimo,
            },
          };
        });
      }

      const response = await apiClube.get(`/store/cashback/${clubInfo.phone}`, {
        headers: {
          Authorization: `Bearer: ${user.club_login || login_club.data.token}`,
        },
      });
      setClientCashback(response.data);
    } catch (err) {
      setClientCashback({ date: null, value: 0 });
      if (err.response?.data?.errorType === 'default_error') {
        toast.error(err.response.data.message);
      } else {
        toast.error(
          'Não foi possível buscar o cashback referente a este telefone'
        );
      }
    } finally {
      setClientRegister(false);
    }
    setLoadingCashback(false);
  }, [user.club_login, clubInfo.phone]);

  function getLeftName() {
    if (discount) {
      if (left > 0 && left > discount) {
        return 'Restante';
      }
      if (left > 0 && left < discount && hasMoneyMethod) {
        return 'Troco';
      }
      if (left > 0 && left < discount && !hasMoneyMethod) {
        return 'Extra';
      }
      if (left < 0 && hasMoneyMethod) {
        return 'Troco';
      }
      if (left < 0 && !hasMoneyMethod) {
        return 'Extra';
      }
      return 'Restante';
    }
    if (left < 0 && !hasMoneyMethod) {
      return 'Extra';
    }
    if (left < 0) {
      return 'Troco';
    }
    return 'Restante';
  }

  useEffect(() => {
    if (user.has_clube && clubInfo.phone?.length === 15) {
      getCashback();
    }
    if (clubInfo.phone?.length < 15) {
      setClientRegister(true);
      setClientCashback({ date: null, value: 0 });
    }
  }, [clubInfo.phone]);

  useEffect(() => {
    if (
      clubInfo.date.length === 10 &&
      clientCashback.date &&
      format(new Date(clientCashback.date.slice(0, -1)), 'dd/MM/yyyy') !==
      clubInfo.date
    ) {
      toast.error('Data informada não coincide com a cadastrada');
    }
  }, [clubInfo.date]);

  const handleCancelPendingPos = () => {
    if (
      window.confirm('Tem certeza que deseja cancelar os pagamentos pendentes?')
    ) {
      cancelAllStoneTransactions()
        .then(() => {
          const stoneTransactionPendingId = stoneTransactions.find(
            (transaction) => transaction.status === 'pending'
          ).payment_balcony_id;
          deletePayments(stoneTransactionPendingId, true);
        })
        .catch(() => {
          console.log('Erro ao cancelar pagamentos pendentes');
        });
    }
  };

  const [methodsFiltered, setMethodsFiltered] = useState([]);

  const prohibitedPaymentMethods = [
    CLUBE_METHOD, //  Resgate Clube
    572, //  Pagamento Online iFood
    2548, // Cartão de Crédito Online
    301, //  Pagamento Online Takeat
    50629, // Cupom iFood
    BANESTES_METHOD, // Pagar com pontos Banestes
  ];

  function handleFilterMethods(method) {
    if (method.length === 0) {
      setMethodsFiltered([]);
      return;
    }

    const paymentsMethodsFiltered = paymentMethods.filter(
      (payment) =>
        payment.available && !prohibitedPaymentMethods.includes(payment.id)
    );

    if (method.length >= 2) {
      const productsFound = paymentsMethodsFiltered.filter((item) =>
        item.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            method
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );

      setMethodsFiltered(productsFound);
    }
  }

  // Métodos banestes
  const rescueBanestes = async () => {
    setBanestesPayment((state) => ({ ...state, loading: true }));
    try {
      const { data } = await api2.get(
        `/restaurants/banestes/validate-code/${banestesPayment.code}`
      );

      if (!data.exists) {
        toast.error(
          data.message || 'Algo deu errado. Tente novamente em breve.'
        );
        setBanestesPayment((state) => ({ ...state, loading: false }));
        return;
      }

      setMetodoAndPaymentOption({
        label: 'Resgate Banestes',
        id: BANESTES_METHOD,
        value: data.value,
        keyword: 'pontos_banestes',
        code: banestesPayment.code,
      });
      setBanestesPayment({ code: '', loading: false });
      toast.success('Resgate Banestes efetuado com sucesso!');
    } catch (err) {
      console.log('rescueBanestes error: ', err);
      toast.error(
        err?.response?.data?.message ||
        'Algo deu errado. Tente novamente em breve.'
      );
    }
    setBanestesPayment((state) => ({ ...state, loading: false }));
  };

  return (
    <Container>
      <h3>Pagamento</h3>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          title="Aplicar desconto"
          buttonColor="#2EC9B7"
          icon={<FaPercent />}
          onClick={() => {
            if (cart.length <= 0) {
              toast.error(
                'Adicione produtos ao carrinho para aplicar desconto'
              );
              return;
            }
            setModalDiscount(true);
          }}
        />
        {discount && (
          <DiscountApplied>
            <p>Desconto aplicado:</p>
            <p style={{ fontWeight: 500 }}>
              {formatValue(discount)} ({discountPercent}%)
            </p>
          </DiscountApplied>
        )}
      </div>

      <ClientArea>
        <Input
          label="Celular"
          mask="(99) 99999-9999"
          isLoading={loadingCashback}
          disabled={loadingCashback}
          value={clubInfo.phone}
          onChange={(e) => {
            const newPhone = e.target.value;
            if (e.target.value === '(') {
              setClientRegister(true);
            } else
              setClubInfo((state) => {
                return { ...state, phone: newPhone };
              });
          }}
        />

        <Input
          label="Nome"
          isLoading={loadingCashback}
          disabled={loadingCashback}
          value={buyerName || ''}
          onChange={(e) => {
            setBuyerName(e.target.value);
          }}
        />
        {!clientRegister && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ color: '#FF2C3A' }}>
              {!hasClient
                ? 'Cliente novo'
                : clientCashback?.date
                  ? ''
                  : 'Cliente sem cadastro no clube'}
            </p>

            <p
              style={{ color: '#2EC9B7', cursor: 'pointer', fontWeight: 600 }}
              onClick={() => setAddClientModal(true)}
            >
              {hasClient ? 'Editar informações' : 'Adicionar mais informações'}
            </p>
          </div>
        )}
        {clubInfo?.phone?.length === 15 && (
          <AddClients
            addClientModal={addClientModal}
            setAddClientModal={setAddClientModal}
            clientPhone={clubInfo.phone}
            setHasClientParent={setHasClient}
          />
        )}

        {user.has_clube && clientCashback.date !== null && (
          <ClubArea>
            <h3>Takeat Clube</h3>
            <div className="buttons-div">
              <Input
                label="Data de nascimento"
                mask="99/99/9999"
                placeholder="dd/mm/aaaa"
                value={clubInfo.date}
                onChange={(e) => {
                  const newDate = e.target.value;
                  setClubInfo((state) => {
                    return { ...state, date: newDate };
                  });
                }}
              />
              <NewButton
                disabled={
                  format(
                    new Date(clientCashback?.date.slice(0, -1) || null),
                    'dd/MM/yyyy'
                  ) !== clubInfo?.date || parseFloat(clientCashback?.value) <= 0
                }
                customColor={theme.colors.green.default}
                onClick={(e) => {
                  e.preventDefault();
                  if (cart.length <= 0) {
                    toast.error(
                      'Adicione produtos ao carrinho para adicionar pagamento'
                    );
                    return;
                  }
                  if (
                    paymentsSession.find(
                      (p) => p.payment_method_id === CLUBE_METHOD
                    )
                  ) {
                    toast.error('Este telefone já realizou o resgate');
                  } else if (parseFloat(clientCashback.value) > 0) {
                    setMetodoAndPaymentOption({
                      label: 'Resgate Clube',
                      id: CLUBE_METHOD,
                    });
                  }
                }}
              >
                Resgatar {formatValue(clientCashback.value)}
              </NewButton>
            </div>
          </ClubArea>
        )}

        {user.use_banestes && (
          <div style={{ display: 'flex', gap: 8, alignItems: 'flex-end' }}>
            <Input
              maxLength={6}
              style={{ width: '50%' }}
              label={
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    color: theme.colors.neutral.dark,
                  }}
                >
                  Pagar com pontos (Banestes)
                </span>
              }
              placeholder="XXXXXX"
              value={banestesPayment.code}
              onChange={(e) => {
                const newCode = e?.target?.value.toUpperCase();
                setBanestesPayment((state) => ({
                  ...state,
                  code: newCode,
                }));
              }}
            />
            <NewButton
              disabled={banestesPayment.code.length < 6}
              isLoading={banestesPayment.loading}
              style={{ flexGrow: 1 }}
              customColor={theme.colors.green.default}
              onClick={() => rescueBanestes()}
            >
              Resgatar
            </NewButton>
          </div>
        )}
      </ClientArea>

      <ValuesArea>
        <Value>
          <span>Total</span>
          <span>
            <b>
              {formatValue(
                totalProduct - (parseFloat(discount?.replace(',', '.')) || 0)
              )}
            </b>
          </span>
        </Value>
        <Value>
          <span>{getLeftName()}</span>
          <span>
            <b>
              {formatValue(
                left > 0
                  ? Math.abs(
                    left - (parseFloat(discount?.replace(',', '.')) || 0)
                  ).toFixed(2)
                  : (
                    Math.abs(Number(left)) +
                    (parseFloat(discount?.replace(',', '.')) || 0)
                  ).toFixed(2)
              )}
            </b>
          </span>
        </Value>
        <CurrencyInput
          id="value"
          value={value * 100}
          onChange={(e) => {
            setValue(e.floatValue ? e.floatValue / 100 : 0);
          }}
        />
      </ValuesArea>

      <PaymentsOptions>
        {favoriteMethods
          .filter(
            (method) =>
              method.available &&
              !prohibitedPaymentMethods.includes(method.keyword)
          )
          .map((method) => {
            return (
              <OptionButton
                disabled={
                  paymentsSession?.filter((pay) => pay?.keyword === 'prazo')
                    .length > 0 && user.credit_register_with_nfce
                }
                onClick={() => {
                  if (cart.length <= 0) {
                    toast.error(
                      'Adicione produtos ao carrinho para adicionar pagamento'
                    );
                    return;
                  }
                  if (
                    user.has_stone_pdv &&
                    ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(
                      allPaymentMethods.find((pay) => pay.id === method.id)
                        ?.method || false
                    ) &&
                    checkStonePending
                  ) {
                    toast.error(
                      'Pagamento pendente na maquininha, aguarde a confirmação ou cancele o pagamento'
                    );
                    return;
                  }
                  setMetodoAndPaymentOption(method);
                }}
              >
                <span>{method.label}</span>
              </OptionButton>
            );
          })}

        {paymentMethods.length > 0 && favoriteMethods.length >= 8 && (
          <Dropdown
            // disabled={checkStonePending}
            isOpen={dropdownOpen}
            size="sm"
            toggle={toggleDrop}
          >
            <DropdownToggle
              caret
              style={{
                height: '40px',
                margin: 0,
                background: '#ff2c3a',
                width: '100%',
              }}
            >
              {metodo}
            </DropdownToggle>
            <DropdownMenu style={{ overflowY: 'scroll', height: 300 }}>
              <IconInput
                color="#6c6c6c"
                placeholder="Buscar"
                isClearable
                deleteButton={() => setMethodsFiltered([])}
                containerStyles={{
                  height: 40,
                  border: 'none',
                  borderBottom: '1px solid #6c6c6c',
                  borderRadius: 0,
                }}
                onChange={(e) => {
                  handleFilterMethods(e.target.value);
                }}
              />

              {methodsFiltered && methodsFiltered.length > 0
                ? methodsFiltered.map((payment) => (
                  <DropdownItem
                    onClick={() => setMetodoAndPaymentOption(payment)}
                    disabled={
                      paymentsSession.filter(
                        (pay) => pay?.keyword === 'prazo'
                      ).length > 0 && user.credit_register_with_nfce
                    }
                  >
                    {payment.label}
                  </DropdownItem>
                ))
                : paymentMethods.map((payment) =>
                  !prohibitedPaymentMethods.includes(payment.id) ? (
                    <div key={payment.id}>
                      <DropdownItem
                        disabled={
                          paymentsSession.filter(
                            (pay) => pay?.keyword === 'prazo'
                          ).length > 0 && user.credit_register_with_nfce
                        }
                        onClick={() => {
                          if (cart.length <= 0) {
                            toast.error(
                              'Adicione produtos ao carrinho para adicionar pagamento'
                            );
                            return;
                          }
                          if (
                            user.has_stone_pdv &&
                            ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(
                              allPaymentMethods.find(
                                (pay) => pay.id === payment.id
                              )?.method || false
                            ) &&
                            checkStonePending
                          ) {
                            toast.error(
                              'Pagamento pendente na maquininha, aguarde a confirmação ou cancele o pagamento'
                            );
                            return;
                          }
                          setMetodoAndPaymentOption(payment);
                        }}
                      >
                        {payment.label}
                      </DropdownItem>
                    </div>
                  ) : (
                    <></>
                  )
                )}
            </DropdownMenu>
          </Dropdown>
        )}
      </PaymentsOptions>

      <PaymentsMade>
        <thead>
          <th>Método de pgto</th>
          <th>Valor</th>
          <th style={{ textAlign: 'right' }}>Ação</th>
        </thead>
        <tbody>
          {paymentsSession?.length > 0 ? (
            paymentsSession.map((payment) => {
              const method =
                payment.payment_method_id === CLUBE_METHOD
                  ? { label: 'Resgate Clube', id: CLUBE_METHOD }
                  : payment.payment_method_id === BANESTES_METHOD
                    ? { label: 'Resgate Banestes', id: BANESTES_METHOD }
                    : allPaymentMethods.find(
                      (method) =>
                        method.available &&
                        method.id === payment.payment_method_id
                    );

              return (
                <PaymentRow
                  key={payment.id}
                  deletePayments={deletePayments}
                  payment={payment}
                  method={method}
                  stoneTransactions={stoneTransactions}
                  allPaymentMethods={allPaymentMethods}
                  handleStoneTransactions={handleStoneTransactions}
                  loadingPos={loadingPos}
                  toggleCancelPos={toggleCancelPos}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>Nenhum pagamento adicionado</td>
            </tr>
          )}
        </tbody>
        {user.has_stone_pdv && user.stone_device_id && checkStonePending && (
          <Hyperlink
            color="#2EC9B7"
            fontSize={12}
            textDecoration="underline"
            onClick={handleCancelPendingPos}
          >
            Cancelar Pagamentos Pendentes POS
          </Hyperlink>
        )}
      </PaymentsMade>

      <ValuesArea>
        <Value>
          <span>
            <b>Total pago</b>
          </span>
          <span>{formatValue(paid)}</span>
        </Value>
        <Value style={{ alignItems: 'flex-end' }}>
          <span>
            <b>Pago</b>
          </span>
          <span>
            {(totalToShow <= 0
              ? '0'
              : paid / totalToShow > 1
                ? '100'
                : (paid / totalToShow) * 100
            ).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            %
          </span>
        </Value>
      </ValuesArea>

      <Modal isOpen={modalCancelPos} toggle={toggleCancelPos}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="12">Deseja cancelar esse pagamento na POS?</Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ marginLeft: 'auto' }}>
          <Button
            title="Sim, cancelar"
            buttonColor="#2ec9b7"
            disabled={loadingPos}
            onClick={(e) => handleCancelStoneTransactions()}
          />
        </ModalFooter>
      </Modal>
    </Container>
  );
};
