import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import {
  Container,
  Header,
  Card
} from './styles';

import api2 from '~/services/api2';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Button, Checkbox, DefaultSelect, RadioGroup } from 'ui-kit-takeat';
import { PageTitle } from '~/components/PageTitle';
import { Modal, ModalBody, CarouselItem } from 'reactstrap';
import { FiX } from 'react-icons/fi';
import PersonalCalendar from '~/components/Calendar';

import { FaTrash } from 'react-icons/fa';

import { useDeliveryOpeningHour } from '~/context/DeliveryOpeningHour';

function OpeningHours() {
  const {
    events,
    eventToEdit,
    eventToSubmit,
    handleDeleteEvent,
    handleSelectDayOfWeek,
    deleteHoursInfos,
    toggleEditEvent,
    isSetHourModalOpened,
    isModalDeleteOpened,
    toggleNewSlotHourWithData,
    handleEditOpeningHoursData,
    handleSaveOpeningHoursData,
    setIsSetHourModalOpened,
    isDeliveryActive,
    changeEventDeliveryActive,
    isWithdrawalActive,
    changeEventWithdrawalActive,
    initialHour,
    setInitialHour,
    finalHour,
    setFinalHour,
    timeSlots,
    toggleModalDelete,
    toogleSetHourModal,
    daysOfWeek,
    dayOfWeekSelected,
    hourOption,
    configHourOptions,
    setHourOption,
    setIsModalDeleteOpened
  } = useDeliveryOpeningHour()


  const [permission, setPermission] = useState(false)

  const validateUser = useCallback(async () => {
    try {
      const response = await api2.get(
        `/restaurants/users/role-permission/${'DeliverySchedules'}`
      );

      const { can_read } = response.data.DeliverySchedules;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, [setPermission]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>

      <Header>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <PageTitle>Horário de Funcionamento</PageTitle>
            <VideoPlayer id={73} />
          </div>
          <p>Configure o horário de abertura e fechamento do seu Delivery.</p>
        </div>
        <Button title='Adicionar horário' onClick={() => setIsSetHourModalOpened(true)} />

      </Header>

      <Modal isOpen={isSetHourModalOpened} toggle={toogleSetHourModal} onClosed={deleteHoursInfos}>
        <ModalBody>
          {eventToEdit ? (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontSize: 18, fontWeight: "bold" }}>
                  {`${eventToEdit?.day} - ${initialHour?.label} até ${finalHour?.label}`}
                </span>
                <FiX size={21} color="#767676" onClick={toogleSetHourModal} cursor="pointer" />
              </div>

              <hr />
              <div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span style={{ color: "#6c6c6c", fontSize: 16, marginRight: "8px" }}>Das</span>
                  <DefaultSelect options={timeSlots} onChange={(v) => setInitialHour(v)} value={initialHour} defaultValue={initialHour} controlStyles={{ width: 150 }} containerStyles={{ width: "33%" }} />
                  <span style={{ color: "#6c6c6c", fontSize: 16, margin: "0 8px" }}>até</span>
                  <DefaultSelect options={timeSlots} onChange={(v) => setFinalHour(v)} value={finalHour} defaultValue={finalHour} controlStyles={{ width: 150 }} containerStyles={{ width: "33%" }} />
                  <FaTrash color='#FF2C3A' size={23} cursor="pointer" style={{ marginLeft: 8 }} onClick={toggleModalDelete} />
                </div>
              </div>

              <hr />
              <span style={{ fontSize: 18, fontWeight: "bold" }}>
                Ativo em:
              </span>
              <Checkbox label='Delivery' customColor='#FF2C3A' checked={isDeliveryActive} onClick={changeEventDeliveryActive} />
              <Checkbox label='Retirada' customColor='#FF2C3A' checked={isWithdrawalActive} onClick={changeEventWithdrawalActive} />

              <Modal isOpen={isModalDeleteOpened} toggle={toggleModalDelete}>
                <ModalBody>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ fontSize: 18, fontWeight: "bold" }}>
                      {`${eventToEdit?.day} - ${initialHour?.label} até ${finalHour?.label}`}
                    </span>
                    <FiX size={21} color="#767676" onClick={toogleSetHourModal} cursor="pointer" />
                  </div>

                  <hr />
                  <p style={{ fontSize: 18, fontWeight: "bold", color: "#4d4d4c" }}>
                    Tem certeza que deseja excluir o horário cadastrado?
                  </p>
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                    <Button title='Cancelar' inverted buttonColor='#FF2C3A' onClick={() => setIsModalDeleteOpened(false)} />
                    <Button title='Excluir' buttonColor='#FF2C3A' onClick={handleDeleteEvent} />
                  </div>
                </ModalBody>
              </Modal>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontSize: 18, fontWeight: "bold" }}>
                  Escolha os dias e horários
                </span>
                <FiX size={21} color="#767676" onClick={toogleSetHourModal} cursor="pointer" />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                {daysOfWeek.map(item => (
                  <span key={item} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <p>{item}</p>
                    <Checkbox disabled={eventToSubmit || eventToEdit} customColor={(eventToSubmit || eventToEdit) ? `grey` : "#2ec9b7"} checked={dayOfWeekSelected.includes(item)} onClick={() => handleSelectDayOfWeek(item)} />
                  </span>
                ))}
              </div>
              <hr />
              <div>
                <RadioGroup
                  selected={hourOption}
                  labelStyle={{ marginTop: -2, fontWeight: 500, position: "relative" }}
                  options={configHourOptions}
                  onClick={(v) => { setHourOption(v) }}
                  customColor='#FF2C3A'
                />
                <div style={{ display: "flex", justifyContent: "center", position: "absolute", top: 138, left: 53, alignItems: "center" }}>
                  <span style={{ color: "#6c6c6c", fontSize: 16, marginRight: "8px" }}>Das</span>
                  <DefaultSelect options={timeSlots} onChange={(v) => setInitialHour(v)} value={initialHour} defaultValue={initialHour} controlStyles={{ width: 150 }} />
                  <span style={{ color: "#6c6c6c", fontSize: 16, margin: "0 8px" }}>até</span>
                  <DefaultSelect options={timeSlots} onChange={(v) => setFinalHour(v)} value={finalHour} defaultValue={finalHour} controlStyles={{ width: 150 }} />
                </div>
              </div>
              <hr />
              <span style={{ fontSize: 18, fontWeight: "bold" }}>
                Ativo em:
              </span>
              <Checkbox label='Delivery' customColor='#FF2C3A' checked={isDeliveryActive} onClick={changeEventDeliveryActive} />
              <Checkbox label='Retirada' customColor='#FF2C3A' checked={isWithdrawalActive} onClick={changeEventWithdrawalActive} />
            </>
          )}

          <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
            <Button title='Cancelar' inverted buttonColor='#FF2C3A' onClick={() => { toogleSetHourModal() }} />
            <Button title={eventToEdit ? 'Salvar' : 'Adicionar'} buttonColor='#2EC9B7' onClick={eventToEdit ? handleEditOpeningHoursData : handleSaveOpeningHoursData} />
          </div>
        </ModalBody>
      </Modal >


      <Card>
        <PersonalCalendar toggleNewSlotHourWithData={toggleNewSlotHourWithData} events={events} toggleEditEvent={toggleEditEvent} />
      </Card>
    </Container >
  );
}

export default OpeningHours;
