import React, { useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { FaMinus } from 'react-icons/fa6';
import { DefaultInput } from 'ui-kit-takeat';
import { ComplementAddedContainer } from './styles';
import { usePreCart } from '~/context/PreCartContext';

export const ComplementAdded = ({ category, complement }) => {
  const {
    incrementOnPreCart,
    decrementOnPreCart,
    changeComplementPreCart,
    getComplementCategoryAmount,
    getComplementAmount,
  } = usePreCart();

  const disableIncrement =
    getComplementAmount(complement.id, category.id) >= complement.limit ||
    getComplementCategoryAmount(category.id) >= category.limit;

  const handleChange = (e) => {
    if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

    let value = e.target.value.length < 1 ? 0 : parseInt(e.target.value);

    if (complement?.amount) {
      if (
        category.limit -
          getComplementCategoryAmount(category.id) +
          complement.amount <=
        value
      ) {
        value =
          category.limit -
          getComplementCategoryAmount(category.id) +
          complement.amount;
      }

      if (
        complement.limit -
          getComplementAmount(complement.id, category.id) +
          complement.amount <=
        value
      ) {
        value =
          complement.limit -
          getComplementAmount(complement.id, category.id) +
          complement.amount;
      }
    } else {
      if (category.limit - getComplementCategoryAmount(category.id) <= value) {
        value = category.limit - getComplementCategoryAmount(category.id);
      }

      if (
        complement.limit - getComplementAmount(complement.id, category.id) <=
        value
      ) {
        value =
          complement.limit - getComplementAmount(complement.id, category.id);
      }
    }

    changeComplementPreCart(category, complement, value);
  };

  return (
    <ComplementAddedContainer>
      <p>{complement.name}</p>
      <FaMinus onClick={() => decrementOnPreCart(category, complement)} />
      <DefaultInput
        value={complement.amount || 0}
        style={{ padding: 0, height: 30 }}
        containerStyles={{ width: 30, minWidth: 30 }}
        inputStyle={{
          fontWeight: 500,
          textAlign: 'center',
          fontSize: 12,
          height: 30,
        }}
        onChange={(e) => handleChange(e)}
      />
      <FaPlus
        className={disableIncrement ? 'disabled' : ''}
        onClick={() => incrementOnPreCart(category, complement)}
      />
    </ComplementAddedContainer>
  );
};
