import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { Select } from 'takeat-design-system-ui-kit';

export const Container = styled.div`
  margin: 0px;
`;
export const InputAreaStyled = styled.div`
  background: #f4f4f4;
  height: 60px;
  width: 130px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    background: transparent;
    border: none;
    width: 100%;
    height: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: -10px;
  }

  b {
    font-size: 11px;
    margin-top: 10px;
  }
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const HistoryTable = styled(Table)`
  width: 100%;
  margin: 0 auto;
  border-spacing: 0 10px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 30px;

  thead {
    border-bottom: 10px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }

      button {
        border: none;
        background-color: transparent;
        color: #222a42;
        opacity: 0.7;
      }
    }
  }
  tbody {
    td {
      top: 0;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;


export const StyledSelect = styled(Select)`
  .react-select__menu {
  min-width: 250px;
  text-align: left;
  }
  `;
export const Delete = styled.button`
  border: none;
  background: none;
  padding-left: 7px;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const StyledTooltip = styled.div`
  width: 300px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
  position: absolute;
  background-color: #ffffff;
  top: 297px;
  left: 238px;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

export const DataPickerArea = styled.div`
  display: inline-block;
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 40px;
  padding: 10px;

  margin-right: 15px;
  margin-left: 10px;

  border-radius: 20px;
  align-self: center;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 20px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: 1px solid transparent;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  &:focus {
    border: none;
  }
`;
