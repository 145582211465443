import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import { Form } from "@unform/web";
import ReactInputMask from "react-input-mask";
import {
	AddressField,
	InputField,
	StreetInputField,
} from "./styles";
import Input from "~/components/Form/Input";
import { toast } from "react-hot-toast";
import api2 from "~/services/api2";

const EditAddressModal = (props) => {
	const {
		isModalEditAddressOpened,
		toggleModalEditUserAddress,
		item,
		user,
		userEditInfo,
		getUserInfo,
	} = props;
	const [validCep, setValidCep] = useState(true);
	const editUseRef = useRef(null);

	async function handleEditUserAddresses(data) {
		if (user.delivery_info.cep_required && data.zip_code === "") {
			toast.error("CEP obrigatório");
			return;
		}
		try {
			await api2.put(
				`/restaurants/buyer/delivery-addresses/${userEditInfo.id}`,
				{
					buyer_id: item.buyer.id,
					city: data.city,
					complement: data.complement,
					neighborhood: data.neighborhood,
					number: data.number,
					reference: data.reference,
					street: data.street,
					zip_code: data.zip_code,
					state: data.state,
				}
			);

			getUserInfo();
			toggleModalEditUserAddress();
		} catch (error) {
			console.log(error);
		}
	}

	async function handleSearchCepEdit(e) {
		if (e.target.value.charAt(8) !== "_") {
			const cep = e.target.value.replace("-", "").replace(".", "");
			const response = await api2.get(`/public/delivery/cep/${cep}`);

			if (!response.data.erro) {
				const streetInput = editUseRef.current.getFieldRef("street");
				const neighboorhoodInput =
      editUseRef.current.getFieldRef("neighborhood");
				const cityInput = editUseRef.current.getFieldRef("city");
				const stateInput = editUseRef.current.getFieldRef("state");
				streetInput.value = response.data.logradouro;
				neighboorhoodInput.value = response.data.bairro;
				cityInput.value = response.data.localidade;
				stateInput.value = response.data.uf;
				setValidCep(true);
			} else {
				const streetInput = editUseRef.current.getFieldRef("street");
				const neighboorhoodInput =
      editUseRef.current.getFieldRef("neighborhood");
				const cityInput = editUseRef.current.getFieldRef("city");
				setValidCep(false);
				streetInput.value = "";
				neighboorhoodInput.value = "";
				cityInput.value = "";
			}
		}
	}

	return (
		<Modal
			isOpen={isModalEditAddressOpened}
			toggle={toggleModalEditUserAddress}
		>
			<Form
				initialData={userEditInfo}
				onSubmit={handleEditUserAddresses}
				ref={editUseRef}
			>
				<ModalHeader>Editar endereço</ModalHeader>
				<ModalBody>
					<AddressField>
						<ReactInputMask
							mask="99999-999"
							onChange={(e) => handleSearchCepEdit(e)}
						>
							<Input name="zip_code" placeHolder="CEP" />
						</ReactInputMask>
						{!validCep && (
							<Row>
								<p style={{ color: "red", textAlign: "right" }}>
                CEP inválido.
								</p>
							</Row>
						)}
						<StreetInputField>
							{" "}
							<InputField>
								<Input
									type="text"
									placeholder="Digite sua rua ..."
									name="street"
									id="street"
								/>
							</InputField>
							<InputField>
								<Input
									type="text"
									placeholder="Número"
									name="number"
									id="number"
								/>
							</InputField>
						</StreetInputField>

						<Input type="text" placeholder="Bairro" name="neighborhood" />

						<Input name="city" placeHolder="Cidade" />
						<Input name="state" placeHolder="Estado" />

						<Input
							type="text"
							name="complement"
							placeholder="Ex: Casa 3, fundos"
						/>

						<Input
							type="text"
							name="reference"
							placeholder="Ex: Prox. ao mercado x"
						/>
					</AddressField>
				</ModalBody>
				<ModalFooter>
					<button
						className="btn btn-danger"
						type="button"
						onClick={toggleModalEditUserAddress}
					>
          Cancelar
					</button>
					<button className="btn btn-success" type="submit">
          Salvar
					</button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

EditAddressModal.propTypes = {
	isModalEditAddressOpened: PropTypes.bool,
	toggleModalEditUserAddress: PropTypes.func,
	item: PropTypes.object,
	user: PropTypes.object,
	userEditInfo: PropTypes.object,
	getUserInfo: PropTypes.func,
};

export default EditAddressModal;