import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import api2 from '~/services/api2';
import { BsFillXCircleFill } from 'react-icons/bs';
import { compareAsc, isSameDay } from 'date-fns';
import { v4 } from 'uuid';
import { useAuth } from '../AuthContext';

const DeliveryOpeningHourContext = createContext();

const DeliveryOpeningHourProvider = ({ children }) => {
  const { token, setHasAScheduleBeenConfigured } = useAuth();

  const [events, setEvents] = useState([]);
  const [eventToSubmit, setEventToSubmit] = useState();
  const [eventToEdit, setEventToEdit] = useState(false);
  const [isSetHourModalOpened, setIsSetHourModalOpened] = useState(false);
  const [isSetHourModalOpenedAtHeader, setIsHourModalOpenedAtHeader] =
    useState(false);
  const [hasACurrentSchedule, setHasACurrentSchedule] = useState(false);

  function formatTime(hour, minute) {
    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(
      2,
      '0'
    )}`;
  }

  // verifica se houveram alterções nas datas
  const history = useHistory();
  const [nextLocation, setNextLocation] = useState(null);
  const [isModified, setIsModified] = useState(false);
  // const [modalConfirmation, setModalConfirmation] = useState(false)

  // function toggleModalConfimation() {
  //   setModalConfirmation(!modalConfirmation)
  // }

  // useEffect(() => {
  //   const unblock = history.block((location, action) => {
  //     if (isModified) {
  //       setModalConfirmation(true);
  //       setNextLocation(location);
  //       return false;
  //     }
  //     return true;
  //   });

  //   return () => {
  //     unblock();
  //   };
  // }, [isModified, history]);

  // const handleNavigate = useCallback(async () => {
  //   await setIsModified(false);
  //   setModalConfirmation(false);
  //   if (nextLocation) {
  //     history.push(nextLocation.pathname);
  //   }
  // }, [nextLocation, history]);

  // const handleCancelNavigation = () => {
  //   setModalConfirmation(false);
  //   setNextLocation(null);
  // };

  const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const toogleSetHourModalAtHeader = () => {
    setIsHourModalOpenedAtHeader(!isSetHourModalOpenedAtHeader);
  };

  const toogleSetHourModal = () => {
    setIsSetHourModalOpened(!isSetHourModalOpened);
  };

  const configHourOptions = [
    { label: '', value: 0 },
    {
      label:
        'Usar horário de pico durante o almoço, geralmente mais movimentado (11:00 - 15:00)',
      value: 1,
    },
    {
      label:
        'Usar horário de pico durante a janta, geralmente mais movimentado (18:00 - 23:00)',
      value: 2,
    },
  ];

  const [hourOption, setHourOption] = useState(0);
  const [initialHour, setInitialHour] = useState(null);
  const [finalHour, setFinalHour] = useState(null);

  const timeSlots = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const id = hour * 4 + minute / 15 + 1; // Cálculo para o ID
      const hourString = formatTime(hour, minute); // Formatar a hora no formato "hh:mm"
      timeSlots.push({ value: id, label: hourString }); // Adicionar o objeto ao array
    }
  }

  function getDateForDayOfWeek(dayOfWeek) {
    const today = new Date(); // Obter a data atual
    const currentDayOfWeek = today.getDay(); // Dia da semana atual (0 = domingo, 1 = segunda-feira, ..., 6 = sábado)

    // Calcular o deslocamento de dias para chegar ao dia da semana desejado
    const daysDiff = dayOfWeek - currentDayOfWeek;

    // Adicionar o deslocamento de dias à data atual
    today.setDate(today.getDate() + daysDiff);

    return today; // Retornar a data correspondente ao dia da semana desejado
  }

  const [isDeliveryActive, setIsDeliveryActive] = useState(true);
  const [isWithdrawalActive, setIsWithdrawalActive] = useState(true);

  function changeEventDeliveryActive() {
    // if (!isWithdrawalActive && isDeliveryActive && eventToSubmit) {
    //   toast.error("O Horário precisa estar ativo ou Delivery ou Retirada")
    // } else {
    //   setIsDeliveryActive(!isDeliveryActive)
    // }

    setIsDeliveryActive(!isDeliveryActive);
  }

  function changeEventWithdrawalActive() {
    // if (!isDeliveryActive && isWithdrawalActive && eventToSubmit) {
    //   toast.error("O Horário precisa estar ativo ou Delivery ou Retirada")
    // } else {
    //   setIsWithdrawalActive(!isWithdrawalActive)
    // }

    setIsWithdrawalActive(!isWithdrawalActive);
  }

  const [dayOfWeekSelected, setDayOfWeekSelected] = useState([]);
  const [isAllDateOk, setIsAllDateOk] = useState(true);

  const handleSubmitEventsWithData = useCallback(
    async (eventArray) => {
      const parsedData = [];
      eventArray.forEach((objeto) => {
        const { day, start, end, id, delivery_active, withdrawal_active } =
          objeto;
        const itemFind = parsedData.findIndex((item) => item.day === day);

        if (itemFind !== -1) {
          if (!parsedData[itemFind].open_time) {
            parsedData[itemFind] = {
              open_time: new Date(start).toISOString(),
              close_time: new Date(end).toISOString(),
              open_time_2: null,
              close_time_2: null,
              day,
              is_two_shifts: false,
              id,
              delivery_active,
              withdrawal_active,
            };
          } else {
            parsedData[itemFind] = {
              open_time: parsedData[itemFind].open_time,
              close_time: parsedData[itemFind].close_time,
              open_time_2: new Date(start).toISOString(),
              close_time_2: new Date(end).toISOString(),
              day,
              is_two_shifts: true,
              id,
              delivery_active2: delivery_active,
              withdrawal_active2: withdrawal_active,
              delivery_active: parsedData[itemFind].delivery_active,
              withdrawal_active: parsedData[itemFind].withdrawal_active,
            };
          }
        } else {
          parsedData.push({
            open_time: start ? new Date(start).toISOString() : null,
            close_time: end ? new Date(end).toISOString() : null,
            open_time_2: null,
            close_time_2: null,
            day,
            is_two_shifts: false,
            id,
            delivery_active,
            withdrawal_active,
          });
        }
      });

      try {
        const response = await api2.put('/restaurants/delivery-schedules', {
          is_two_shifts: true,
          schedules: parsedData,
          timezone_offset: 180,
        });
        toast.success('Horário alterado com sucesso!');

        if (response?.data?.success) {
          const someSheduleActive = eventArray.some(
            (item) => item.start || item.end
          );
          if (someSheduleActive) {
            setHasAScheduleBeenConfigured(true);
          } else {
            setHasAScheduleBeenConfigured(false);
          }
        }

        setIsModified(false);
      } catch (error) {
        toast.error('Não foi possível atualizar o horário de funcionamento');
      }
    },
    [setHasAScheduleBeenConfigured]
  );

  const handleSubmitEvents = useCallback(async () => {
    const parsedData = [];
    events.forEach((objeto) => {
      const { day, start, end, id, delivery_active, withdrawal_active } =
        objeto;
      const itemFind = parsedData.findIndex((item) => item.day === day);

      if (itemFind !== -1) {
        if (!parsedData[itemFind].open_time) {
          parsedData[itemFind] = {
            open_time: new Date(start).toISOString(),
            close_time: new Date(end).toISOString(),
            open_time_2: null,
            close_time_2: null,
            day,
            is_two_shifts: false,
            id,
            delivery_active,
            withdrawal_active,
          };
        } else {
          parsedData[itemFind] = {
            open_time: parsedData[itemFind].open_time,
            close_time: parsedData[itemFind].close_time,
            open_time_2: new Date(start).toISOString(),
            close_time_2: new Date(end).toISOString(),
            day,
            is_two_shifts: true,
            id,
            delivery_active2: delivery_active,
            withdrawal_active2: withdrawal_active,
            delivery_active: parsedData[itemFind].delivery_active,
            withdrawal_active: parsedData[itemFind].withdrawal_active,
          };
        }
      } else {
        parsedData.push({
          open_time: start ? new Date(start).toISOString() : null,
          close_time: end ? new Date(end).toISOString() : null,
          open_time_2: null,
          close_time_2: null,
          day,
          is_two_shifts: false,
          id,
          delivery_active,
          withdrawal_active,
        });
      }
    });

    try {
      const response = await api2.put('/restaurants/delivery-schedules', {
        is_two_shifts: true,
        schedules: parsedData,
        timezone_offset: 180,
      });
      toast.success('Horário alterado com sucesso!');

      if (response?.data?.success) {
        const someSheduleActive = events.some(
          (item) => item.withdrawal_active || item.delivery_active
        );

        if (someSheduleActive) {
          setHasAScheduleBeenConfigured(true);
        } else {
          setHasAScheduleBeenConfigured(false);
        }
      }

      setIsModified(false);
    } catch (error) {
      toast.error('Não foi possível atualizar o horário de funcionamento');
    }
  }, [events, setHasAScheduleBeenConfigured]);

  const handleSaveOpeningHoursData = useCallback(() => {
    if (!initialHour || !finalHour) {
      toast.error('Defina o horário.');
      return;
    }

    if (initialHour?.label === finalHour?.label) {
      toast.error('Defina horários diferentes');
      return;
    }

    const newTimeStrStart = initialHour.label;
    const newTimeStrEnd = finalHour.label;

    const [newHoursStr, newMinutesStr] = newTimeStrStart.split(':');
    const newHoursStart = parseInt(newHoursStr, 10);
    const newMinutesStart = parseInt(newMinutesStr, 10);

    const [newHoursStrEnd, newMinutesStrEnd] = newTimeStrEnd.split(':');
    const newHoursEnd = parseInt(newHoursStrEnd, 10);
    const newMinutesend = parseInt(newMinutesStrEnd, 10);

    if (dayOfWeekSelected.length > 1) {
      const newEventsAlan = [...events];

      dayOfWeekSelected.forEach((day) => {
        const weekDayId = daysOfWeek.findIndex((d) => d === day);
        const dateFind = getDateForDayOfWeek(weekDayId);

        const existingStartDate = new Date(dateFind);
        const existingEndDate = new Date(dateFind);

        existingStartDate.setHours(newHoursStart);
        existingStartDate.setMinutes(newMinutesStart);
        existingEndDate.setHours(newHoursEnd);
        existingEndDate.setMinutes(newMinutesend);

        if (newHoursEnd < newHoursStart) {
          existingEndDate.setDate(existingEndDate.getDate() + 1);
        }

        const eventFind = events.filter((e) => e.day === day);

        const isDateCollision = eventFind.find((ev) => {
          if (ev.start && ev.end) {
            const existingEventStartTime = new Date(ev.start).getHours();
            let existingEventEndTime = new Date(ev.end).getHours();
            const newEventStartTime = parseInt(initialHour.label, 10);
            let newEventEndTime = parseInt(finalHour.label, 10);

            if (!isSameDay(new Date(ev.start), new Date(ev.end))) {
              existingEventEndTime += 24;
            }

            if (newEventEndTime < newEventStartTime) {
              newEventEndTime += 24;
            }

            const isOverlapping =
              (newEventStartTime >= existingEventStartTime &&
                newEventStartTime < existingEventEndTime) ||
              (newEventEndTime >= existingEventStartTime &&
                newEventEndTime <= existingEventEndTime) ||
              (newEventStartTime <= existingEventStartTime &&
                newEventEndTime >= existingEventEndTime);

            return isOverlapping;
          }

          return false;
        });

        if (eventFind.length > 1) {
          if (eventFind.find((item) => item.day === day)) {
            toast(
              `O dia selecionado "${day}." já possui 2 horários cadastrados.`,
              {
                duration: 7000,
                position: 'top-center',
                icon: <BsFillXCircleFill color="#ff4b4b" size={30} />,
                style: {
                  border: '2px solid #ff4b4b',
                },
              }
            );
          }
          setIsAllDateOk(false);
          return false;
        }
        if (isDateCollision) {
          if (eventFind.find((item) => item.day === day)) {
            toast.error(
              `Existe uma colisão de horários no dia "${day}.". Ajuste os horários.`
            );
          }

          setIsAllDateOk(false);
          return false;
        }
        if (!eventFind[0]?.start) {
          const findId = newEventsAlan.findIndex(
            (item) => item.temp_id === eventFind[0]?.temp_id
          );
          if (findId !== -1) {
            newEventsAlan[findId] = {
              start: existingStartDate,
              end: existingEndDate,
              day: newEventsAlan[findId].day,
              temp_id: newEventsAlan[findId].temp_id,
              delivery_active: isDeliveryActive,
              withdrawal_active: isWithdrawalActive,
            };
          }
        } else {
          newEventsAlan.push({
            start: existingStartDate,
            end: existingEndDate,
            day,
            delivery_active: isDeliveryActive,
            withdrawal_active: isWithdrawalActive,
            temp_id: v4(),
          });
        }

        if (isAllDateOk) {
          setEvents(newEventsAlan);
          deleteHoursInfos();
          setIsSetHourModalOpened(false);
          setIsHourModalOpenedAtHeader(false);
          setIsModified(true);
          setIsAllDateOk(true);

          handleSubmitEventsWithData(newEventsAlan);
        } else {
          return false;
        }
      });
    } else {
      const weekDayId = daysOfWeek.findIndex((d) => d === dayOfWeekSelected[0]);
      const dateFind = getDateForDayOfWeek(weekDayId);

      const existingStartDate = new Date(dateFind);
      const existingEndDate = new Date(dateFind);

      existingStartDate.setHours(newHoursStart);
      existingStartDate.setMinutes(newMinutesStart);
      existingEndDate.setHours(newHoursEnd);
      existingEndDate.setMinutes(newMinutesend);

      if (newHoursEnd < newHoursStart) {
        existingEndDate.setDate(existingEndDate.getDate() + 1);
      }

      const eventFind = events.filter((e) => e.day === dayOfWeekSelected[0]);
      const isDateCollision = eventFind.find((ev) => {
        if (ev.start && ev.end) {
          const existingEventStartTime = new Date(ev.start).getHours();
          let existingEventEndTime = new Date(ev.end).getHours();
          const newEventStartTime = parseInt(initialHour.label, 10);
          let newEventEndTime = parseInt(finalHour.label, 10);

          if (!isSameDay(new Date(ev.start), new Date(ev.end))) {
            existingEventEndTime += 24;
          }

          if (newEventEndTime < newEventStartTime) {
            newEventEndTime += 24;
          }

          const isOverlapping =
            (newEventStartTime >= existingEventStartTime &&
              newEventStartTime < existingEventEndTime) ||
            (newEventEndTime >= existingEventStartTime &&
              newEventEndTime <= existingEventEndTime) ||
            (newEventStartTime <= existingEventStartTime &&
              newEventEndTime >= existingEventEndTime);

          return isOverlapping;
        }

        return false;
      });

      if (eventFind.length > 1) {
        toast.error('o dia selecionado já possui 2 horários cadastrados.');
      } else if (isDateCollision) {
        toast.error(
          `Existe uma colisão de horários no dia "${dayOfWeekSelected}.". Ajuste os horários.`
        );
      } else if (!eventFind[0]?.start) {
        const newEvents = [...events];
        const findId = newEvents.findIndex(
          (item) => item.temp_id === eventFind[0]?.temp_id
        );

        if (findId !== -1) {
          newEvents[findId] = {
            start: existingStartDate,
            end: existingEndDate,
            day: newEvents[findId].day,
            temp_id: newEvents[findId].temp_id,
            delivery_active: isDeliveryActive,
            withdrawal_active: isWithdrawalActive,
          };
          setEvents(newEvents);
          deleteHoursInfos();
          setIsSetHourModalOpened(false);
          setIsHourModalOpenedAtHeader(false);
          setIsModified(true);

          handleSubmitEventsWithData(newEvents);
        }
      } else {
        const newEvents = [
          {
            start: existingStartDate,
            end: existingEndDate,
            day: dayOfWeekSelected[0],
            temp_id: v4(),
            delivery_active: isDeliveryActive,
            withdrawal_active: isWithdrawalActive,
          },
        ];

        setEvents((oldState) => [...oldState, ...newEvents]);
        deleteHoursInfos();
        setIsSetHourModalOpened(false);
        setIsHourModalOpenedAtHeader(false);
        setIsModified(true);

        handleSubmitEventsWithData([...events, ...newEvents]);
      }
    }
  }, [
    handleSubmitEventsWithData,
    eventToSubmit,
    initialHour,
    finalHour,
    hourOption,
    dayOfWeekSelected,
    isDeliveryActive,
    isWithdrawalActive,
  ]);

  const handleEditOpeningHoursData = useCallback(() => {
    // if (!isDeliveryActive && !isWithdrawalActive) {
    //   toggleModalDelete()

    //   return false
    // }

    if (initialHour?.label === finalHour?.label) {
      toast.error('Defina horários diferentes');
      return;
    }

    const newTimeStrStart = initialHour.label;
    const newTimeStrEnd = finalHour.label;

    const [newHoursStr, newMinutesStr] = newTimeStrStart.split(':');
    const newHoursStart = parseInt(newHoursStr, 10);
    const newMinutesStart = parseInt(newMinutesStr, 10);

    const [newHoursStrEnd, newMinutesStrEnd] = newTimeStrEnd.split(':');
    const newHoursEnd = parseInt(newHoursStrEnd, 10);
    const newMinutesend = parseInt(newMinutesStrEnd, 10);

    const existingStartDate = new Date(eventToEdit.start);
    const existingEndDate = new Date(eventToEdit.end);

    existingStartDate.setHours(newHoursStart);
    existingStartDate.setMinutes(newMinutesStart);
    existingEndDate.setHours(newHoursEnd);
    existingEndDate.setMinutes(newMinutesend);

    if (
      isSameDay(existingEndDate, existingStartDate) ||
      compareAsc(existingEndDate, existingStartDate) === -1
    ) {
      if (newHoursEnd < newHoursStart) {
        existingEndDate.setDate(existingEndDate.getDate() + 1);
      }
    } else if (newHoursEnd > newHoursStart) {
      existingEndDate.setDate(existingEndDate.getDate() - 1);
    }

    const eventFind = events
      .filter((e) => e.day === eventToEdit.day)
      .filter((e) => e.temp_id !== eventToEdit.temp_id);
    const isDateCollision = eventFind.find((ev) => {
      if (ev.start && ev.end) {
        const existingEventStartTime = new Date(ev.start).getHours();
        let existingEventEndTime = new Date(ev.end).getHours();
        const newEventStartTime = parseInt(initialHour.label, 10);
        let newEventEndTime = parseInt(finalHour.label, 10);

        if (!isSameDay(new Date(ev.start), new Date(ev.end))) {
          existingEventEndTime += 24;
        }

        if (newEventEndTime < newEventStartTime) {
          newEventEndTime += 24;
        }

        const isOverlapping =
          (newEventStartTime >= existingEventStartTime &&
            newEventStartTime <= existingEventEndTime) ||
          (newEventEndTime >= existingEventStartTime &&
            newEventEndTime <= existingEventEndTime) ||
          (newEventStartTime <= existingEventStartTime &&
            newEventEndTime >= existingEventEndTime);

        return isOverlapping;
      }

      return false;
    });

    if (eventToEdit.temp_id) {
      if (isDateCollision) {
        toast.error(
          'Existe uma colisão de horários neste mesmo dia. Ajuste os horários.'
        );
      } else {
        const newEvents = events;
        const eventFind = newEvents.findIndex(
          (item) => item?.temp_id === eventToEdit.temp_id
        );

        if (eventFind !== -1) {
          newEvents[eventFind] = {
            ...eventToEdit,
            start: existingStartDate,
            end: existingEndDate,
            delivery_active: isDeliveryActive,
            withdrawal_active: isWithdrawalActive,
          };
          setEvents(newEvents);
        }
        deleteHoursInfos();
        setIsSetHourModalOpened(false);
        setIsHourModalOpenedAtHeader(false);
        setIsModified(true);

        handleSubmitEvents();
      }
    }
  }, [
    eventToSubmit,
    initialHour,
    finalHour,
    hourOption,
    dayOfWeekSelected,
    isDeliveryActive,
    isWithdrawalActive,
    handleSubmitEvents,
  ]);

  const toggleNewSlotHourWithData = useCallback(({ start, end }) => {
    const formatTime = (date) => {
      return `${String(date.getHours()).padStart(2, '0')}:${String(
        date.getMinutes()
      ).padStart(2, '0')}`;
    };
    const startDate = new Date(start);
    const endDate = new Date(end);

    setEventToSubmit({
      start: startDate,
      end: endDate,
      temp_id: v4(),
      delivery_active: true,
      withdrawal_active: true,
    });

    const initialHourFind = timeSlots.find(
      (item) => item.label === formatTime(startDate)
    );
    const finalHourFind = timeSlots.find(
      (item) => item.label === formatTime(endDate)
    );

    setInitialHour(initialHourFind);
    setFinalHour(finalHourFind);
    setDayOfWeekSelected([daysOfWeek[new Date(start).getDay()]]);
    setIsSetHourModalOpened(true);
    setIsDeliveryActive(true);
    setIsWithdrawalActive(true);
  }, []);

  const toggleEditEventHeader = useCallback(() => {
    const whichDay = daysOfWeek[new Date().getDay()];
    const bufferTime = 30 * 60 * 1000;

    const eventFindActualTime = events.find((item) => {
      const eventStartTime = new Date(item.start).getTime();
      const eventEndTime = new Date(item.end).getTime();
      const currentTime = new Date().getTime();

      return (
        item?.day === whichDay &&
        currentTime >= eventStartTime - bufferTime &&
        currentTime <= eventEndTime + bufferTime
      );
    });

    const eventFind = events.find((item) => item?.day === whichDay);
    const formatTime = (date) => {
      return `${String(date.getHours()).padStart(2, '0')}:${String(
        date.getMinutes()
      ).padStart(2, '0')}`;
    };

    let startDate;
    let endDate;

    if (eventFindActualTime) {
      startDate = eventFindActualTime?.start
        ? new Date(eventFindActualTime?.start)
        : new Date();
      endDate = eventFindActualTime?.end
        ? new Date(eventFindActualTime?.end)
        : new Date();
    } else {
      startDate = eventFind?.start ? new Date(eventFind?.start) : new Date();
      endDate = eventFind?.end ? new Date(eventFind?.end) : new Date();
    }
    if (eventFindActualTime) {
      setHasACurrentSchedule(true);

      setEventToEdit({
        start: startDate,
        end: endDate,
        temp_id: eventFindActualTime?.temp_id,
        day: eventFindActualTime?.day,
        delivery_active: eventFindActualTime.delivery_active,
        withdrawal_active: eventFindActualTime.withdrawal_active,
      });
      const initialHourFind = timeSlots.find(
        (item) => item.label === formatTime(startDate)
      ) || { value: formatTime(startDate), label: formatTime(startDate) };
      const finalHourFind = timeSlots.find(
        (item) => item.label === formatTime(endDate)
      ) || { value: formatTime(endDate), label: formatTime(endDate) };

      setInitialHour(initialHourFind);
      setFinalHour(finalHourFind);
      setDayOfWeekSelected([
        daysOfWeek[new Date(eventFindActualTime?.start).getDay()],
      ]);
      setIsHourModalOpenedAtHeader(true);
      setIsDeliveryActive(eventFindActualTime.delivery_active);
      setIsWithdrawalActive(eventFindActualTime.withdrawal_active);
    } else if (eventFind) {
      setHasACurrentSchedule(false);
      setEventToEdit({
        start: startDate,
        end: endDate,
        temp_id: eventFind?.temp_id,
        day: eventFind?.day,
        delivery_active: eventFind.delivery_active,
        withdrawal_active: eventFind.withdrawal_active,
      });
      const initialHourFind =
        eventFind?.start && eventFindActualTime
          ? timeSlots.find((item) => item.label === formatTime(startDate)) || {
            value: formatTime(startDate),
            label: formatTime(startDate),
          }
          : null;
      const finalHourFind =
        eventFind?.start && eventFindActualTime
          ? timeSlots.find((item) => item.label === formatTime(endDate)) || {
            value: formatTime(endDate),
            label: formatTime(endDate),
          }
          : null;

      setInitialHour(initialHourFind);
      setFinalHour(finalHourFind);
      setDayOfWeekSelected([daysOfWeek[new Date(eventFind?.start).getDay()]]);
      setIsHourModalOpenedAtHeader(true);
      setIsDeliveryActive(eventFind?.start ? eventFind.delivery_active : true);
      setIsWithdrawalActive(
        eventFind?.start ? eventFind.withdrawal_active : true
      );
    }
  }, [events]);

  const toggleEditEvent = useCallback(
    ({ start, end, temp_id, day, delivery_active, withdrawal_active }) => {
      const formatTime = (date) => {
        return `${String(date.getHours()).padStart(2, '0')}:${String(
          date.getMinutes()
        ).padStart(2, '0')}`;
      };

      const startDate = new Date(start);
      const endDate = new Date(end);

      setEventToEdit({
        start: startDate,
        end: endDate,
        temp_id,
        day,
        delivery_active,
        withdrawal_active,
      });
      const initialHourFind = timeSlots.find(
        (item) => item.label === formatTime(startDate)
      ) || { value: formatTime(startDate), label: formatTime(startDate) };
      const finalHourFind = timeSlots.find(
        (item) => item.label === formatTime(endDate)
      ) || { value: formatTime(endDate), label: formatTime(endDate) };

      setInitialHour(initialHourFind);
      setFinalHour(finalHourFind);
      setDayOfWeekSelected([daysOfWeek[new Date(start).getDay()]]);
      setIsSetHourModalOpened(true);

      setIsDeliveryActive(delivery_active);
      setIsWithdrawalActive(withdrawal_active);
    },
    []
  );

  function deleteHoursInfos() {
    setInitialHour(null);
    setFinalHour(null);
    setDayOfWeekSelected([]);
    setEventToEdit(null);
    setEventToSubmit(null);
    setHourOption(0);
  }

  useEffect(() => {
    if (hourOption === 1) {
      setInitialHour({ label: '11:00' });
      setFinalHour({ label: '15:00' });
    } else if (hourOption === 2) {
      setInitialHour({ label: '18:00' });
      setFinalHour({ label: '23:00' });
    }
  }, [hourOption]);

  const getSchedules = useCallback(async () => {
    const response = await api2.get('/restaurants/delivery-schedules');
    if (response.data) {
      const eventsData = response.data.map((e) => {
        const weekDayId = daysOfWeek.findIndex((d) => d === e.day);
        const dateFind = getDateForDayOfWeek(weekDayId);

        const openTime = new Date(e.open_time);
        const closeTime = new Date(e.close_time);

        const existingStartDate = new Date(dateFind);
        const existingEndDate = new Date(dateFind);

        existingStartDate.setHours(openTime.getHours());
        existingStartDate.setMinutes(openTime.getMinutes());
        existingEndDate.setHours(closeTime.getHours());
        existingEndDate.setMinutes(closeTime.getMinutes());

        if (
          isSameDay(closeTime, openTime) ||
          compareAsc(closeTime, openTime) === 1
        ) {
          if (
            closeTime.getHours() * 60 + closeTime.getMinutes() <
            openTime.getHours() * 60 + openTime.getMinutes()
          ) {
            existingEndDate.setDate(existingEndDate.getDate() + 1);
          }
        }

        if (e.is_two_shifts) {
          const openTime2 = new Date(e.open_time_2);
          const closeTime2 = new Date(e.close_time_2);

          const existingStartDate2 = new Date(dateFind);
          const existingEndDate2 = new Date(dateFind);

          existingStartDate2.setHours(openTime2.getHours());
          existingStartDate2.setMinutes(openTime2.getMinutes());
          existingEndDate2.setHours(closeTime2.getHours());
          existingEndDate2.setMinutes(closeTime2.getMinutes());

          if (
            isSameDay(closeTime2, openTime2) ||
            compareAsc(closeTime2, openTime2) === 1
          ) {
            if (
              closeTime2.getHours() * 60 + closeTime2.getMinutes() <
              openTime2.getHours() * 60 + openTime2.getMinutes()
            ) {
              existingEndDate2.setDate(existingEndDate2.getDate() + 1);
            }
          }

          const dates = [
            {
              temp_id: v4(),
              start: e.is_active ? existingStartDate : null,
              end: e.is_active ? existingEndDate : null,
              day: e.day,
              id: e.id,
              delivery_active: e.delivery_active && e.is_active,
              withdrawal_active: e.withdrawal_active && e.is_active,
            },
            {
              temp_id: v4(),
              start: e.is_active ? existingStartDate2 : null,
              end: e.is_active ? existingEndDate2 : null,
              day: e.day,
              id: e.id,
              delivery_active: !!e.delivery_active2,
              withdrawal_active: !!e.withdrawal_active2,
            },
          ];
          return dates.map((item) => item);
        }
        return {
          temp_id: v4(),
          start: e.is_active ? existingStartDate : null,
          end: e.is_active ? existingEndDate : null,
          day: e.day,
          id: e.id,
          delivery_active: e.delivery_active && e.is_active,
          withdrawal_active: e.withdrawal_active && e.is_active,
        };
      });
      const eventsArray = eventsData.flatMap((item) => {
        return Array.isArray(item) ? item : [item];
      });
      setEvents(eventsArray);

      const someSheduleActive = response?.data?.some((item) => item.is_active);
      if (someSheduleActive) {
        setHasAScheduleBeenConfigured(true);
      } else {
        setHasAScheduleBeenConfigured(false);
      }
    }
  }, []);

  const page = window.location.pathname;

  useEffect(() => {
    if (token && page !== '/balcony') {
      getSchedules();
    }
  }, [getSchedules, token]);

  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false);

  function toggleModalDelete() {
    setIsModalDeleteOpened(!isModalDeleteOpened);
  }

  function handleDeleteEvent() {
    const newEvents = [...events];

    const eventsFind = events.filter((ev) => ev.day === eventToEdit.day);

    if (eventsFind.length > 1) {
      if (eventToEdit.temp_id) {
        const eventsFilter = events.filter(
          (item) => item.temp_id !== eventToEdit.temp_id
        );
        setEvents(eventsFilter);
        handleSubmitEventsWithData(eventsFilter);
      }
    } else if (eventToEdit.temp_id) {
      const eventFind = events.findIndex(
        (item) => item.temp_id === eventToEdit.temp_id
      );

      if (eventFind !== -1) {
        newEvents[eventFind].start = null;
        newEvents[eventFind].end = null;
        setEvents(newEvents);

        handleSubmitEventsWithData(newEvents);
      }
    }

    toggleModalDelete();
    setIsSetHourModalOpened(false);
    setIsHourModalOpenedAtHeader(false);
  }

  function handleSelectDayOfWeek(item) {
    if (dayOfWeekSelected.includes(item)) {
      const items = dayOfWeekSelected.filter((it) => it !== item);
      setDayOfWeekSelected(items);
    } else {
      setDayOfWeekSelected((old) => [...old, item]);
    }
  }

  const value = useMemo(
    () => ({
      events,
      eventToEdit,
      eventToSubmit,
      handleDeleteEvent,
      handleSelectDayOfWeek,
      deleteHoursInfos,
      setEvents,
      toggleEditEvent,
      isSetHourModalOpened,
      isModalDeleteOpened,
      toggleNewSlotHourWithData,
      handleEditOpeningHoursData,
      handleSaveOpeningHoursData,
      setIsSetHourModalOpened,
      isDeliveryActive,
      changeEventDeliveryActive,
      isWithdrawalActive,
      changeEventWithdrawalActive,
      initialHour,
      setInitialHour,
      finalHour,
      setFinalHour,
      timeSlots,
      handleSubmitEvents,
      toggleModalDelete,
      toogleSetHourModal,
      daysOfWeek,
      dayOfWeekSelected,
      hourOption,
      configHourOptions,
      setHourOption,
      setIsModalDeleteOpened,
      toggleEditEventHeader,
      isSetHourModalOpenedAtHeader,
      toogleSetHourModalAtHeader,
      hasACurrentSchedule,
    }),
    [
      events,
      eventToEdit,
      eventToSubmit,
      handleDeleteEvent,
      handleSelectDayOfWeek,
      deleteHoursInfos,
      setEvents,
      toggleEditEvent,
      isSetHourModalOpened,
      isModalDeleteOpened,
      toggleNewSlotHourWithData,
      handleEditOpeningHoursData,
      handleSaveOpeningHoursData,
      setIsSetHourModalOpened,
      isDeliveryActive,
      changeEventDeliveryActive,
      isWithdrawalActive,
      changeEventWithdrawalActive,
      initialHour,
      setInitialHour,
      finalHour,
      setFinalHour,
      timeSlots,
      handleSubmitEvents,
      toggleModalDelete,
      toogleSetHourModal,
      daysOfWeek,
      dayOfWeekSelected,
      hourOption,
      configHourOptions,
      setHourOption,
      setIsModalDeleteOpened,
      toggleEditEventHeader,
      isSetHourModalOpenedAtHeader,
      toogleSetHourModalAtHeader,
      hasACurrentSchedule,
    ]
  );
  return (
    <DeliveryOpeningHourContext.Provider value={value}>
      {children}
    </DeliveryOpeningHourContext.Provider>
  );
};

function useDeliveryOpeningHour() {
  const context = useContext(DeliveryOpeningHourContext);

  if (!context) {
    throw new Error(
      'useDeliveryOpeningHour must be used within an PosProvider'
    );
  }

  return context;
}

export { useDeliveryOpeningHour, DeliveryOpeningHourProvider };
