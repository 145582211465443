import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import getValidationErrors from '../../../services/getValidationErrors';
import { useAuth } from '../../../context/AuthContext';
import api2 from '~/services/api2';
import { useMotoboys } from '~/context/MotoboysContext';
import { Container, ContainerHeader } from './styles';
import { Button, DefaultInput } from 'ui-kit-takeat';
import { FaTimes } from 'react-icons/fa';

function CreateMotoboy({ toggleModalRegisterMotoboy, motoboyCreated }) {
  const formRef = useRef(null);
  const { getProfileData } = useAuth();
  const { setDetailedMotoboy } = useMotoboys();
  const [createMotoboyData, setCreateMotoboyData] = useState({
    name: '',
    phone: '',
  });
  const input = document.getElementById('input_motoboy_name')?.focus();

  async function handleSubmit() {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        phone: Yup.string()
          .min(13, 'O número deve ter DDD + 9 dígitos')
          .max(13, 'O número deve ter DDD + 9 dígitos'),
      });

      const req = {
        name: createMotoboyData.name,
      };

      if (createMotoboyData.phone.length > 0) {
        createMotoboyData.phone = createMotoboyData.phone
          .split(/^\(|\)|-|\s/)
          .join('');
        createMotoboyData.phone = `(${createMotoboyData.phone.substring(
          0,
          2
        )})${createMotoboyData.phone.substring(2)}`;

        req.phone = createMotoboyData.phone;
      } else {
        delete createMotoboyData.phone;
      }

      await schema.validate(createMotoboyData, {
        abortEarly: false,
      });

      const createdMotoboy = await api2.post('restaurants/motoboys', req);

      toast.success('Motoboy criado com sucesso!');

      setDetailedMotoboy((state) => {
        if (state?.length > 0) {
          return [...state, createdMotoboy.data];
        } else {
          return [createdMotoboy.data];
        }
      });
      setCreateMotoboyData(createdMotoboy.data);

      toggleModalRegisterMotoboy();
      if (!motoboyCreated) {
        getProfileData();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (!error.response?.ok) {
        console.log('error motoboy: ', error);
        switch (error.response?.data?.errorType) {
          case 'phone_already_exists':
            toast.error('Telefone já usado por outro motoboy');
            break;
          case 'restaurant_not_found':
            toast.error('Restaurante não foi encontrado');
            break;
          default:
            console.log('motoboy err ', error);
            toast.error('Falha ao criar motoboy');
        }
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Container>
        <ContainerHeader>
          <h3>Cadastrar Motoboy</h3>
          <FaTimes
            size={20}
            color="red"
            onClick={() => {
              if (toggleModalRegisterMotoboy) {
                toggleModalRegisterMotoboy();
              }
            }}
          />
        </ContainerHeader>
        <DefaultInput
          title="Nome do Motoboy:"
          name="nome"
          placeholder="Nome"
          width="100%"
          value={createMotoboyData.name}
          onChange={(e) =>
            setCreateMotoboyData({ ...createMotoboyData, name: e.target.value })
          }
        />
        <DefaultInput
          title="Telefone do Motoboy:"
          name="phone"
          placeholder="Telefone"
          width="100%"
          mask="(99)99999-9999"
          value={createMotoboyData.phone}
          onChange={(e) =>
            setCreateMotoboyData({
              ...createMotoboyData,
              phone: e.target.value,
            })
          }
        />
        <Button
          title="Cadastrar"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          buttonColor="#2ec9b7"
          containerStyles={{ alignSelf: 'flex-end' }}
        />
      </Container>
    </Form>
  );
}

export default CreateMotoboy;
