import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button, DefaultSelect, InformationDiv } from 'ui-kit-takeat';
import { useIfood } from '~/context/iFoodContext';
import { useOrder } from '~/context/orderContext';
import api2 from '~/services/api2';
import { EntregaFacilInfo } from './styles';
import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';

function IfoodOnDemand({
  item,
  user,
  handleItemReady = () => {}, //só é passado quando o pedido esta "accepted", quando ta "ready" nao precisa da funcao
  toggleModal,
  orders = [] //preciso pra enviar pro wpp, mas só vai ser passado quando tiver "accepted"
}) {
  const { ifoodRestaurants, getIfoodMenus } = useIfood();
  const { updateIfoodOnDemandReady } = useOrder();

  const [onDemandData, setOnDemandData] = useState(null);
  const [onDemandError, setOnDemandError] = useState(null);

  const [ifoodMerchantIdOnDemand, setIfoodMerchantIdOnDemand] = useState(null);

  const [loadingOnDemand, setLoadingOnDemand] = useState(false);
  const [onDemandPaymentOptions, setOnDemandPaymentOptions] = useState([]);
  const [onDemandPaymentSelected, setOnDemandPaymentSelected] = useState([]);

  const getOnDemand = async (data) => {
    setLoadingOnDemand(true);
    onDemandError && setOnDemandError(null);
    try {
      const response = await api2.get(`/restaurants/ifood/on-demand-availabilities/${item.session_id}`, {
        params: {
          merchant_id: ifoodMerchantIdOnDemand.value
        }
      });

      setOnDemandData(response.data);

      function getMethod(type){
        switch(type){
          case 'CREDIT':
            return 'Crédito';
          case 'DEBIT':
            return 'Débito';
          case 'CASH':
            return 'Dinheiro';
          default:
            return type;
        }
      }

      const takeatPaymentMethod = response.data.takeatPaymentMethod;

      if(!takeatPaymentMethod){
        const options = response.data.paymentMethods.map(meth => {
          return {
            label: `${meth.brand} ${getMethod(meth.method)}`,
            value: meth.id,
            brand: meth.brand,
            method: meth.method
          }
        });
  
        setOnDemandPaymentOptions(options);
      }else{
        const selected = {
          label: `${takeatPaymentMethod.brand} ${getMethod(takeatPaymentMethod.method)}`,
          value: takeatPaymentMethod.id,
          brand: takeatPaymentMethod.brand,
          method: takeatPaymentMethod.method
        };
  
        setOnDemandPaymentOptions([selected]);
        setOnDemandPaymentSelected(selected);
      }
    } catch (err) {
      if(err?.response?.data?.message){
        setOnDemandError(err?.response?.data?.message);
      }else{
        toast.error('Erro ao consultar disponibilidade')
      }
    }
    setLoadingOnDemand(false);
  };

  const confirmOnDemand = async (data) => {
    setLoadingOnDemand(true);

    if(onDemandPaymentSelected.length === 0){
      toast.error('Selelecione um método de pagamento');
      setLoadingOnDemand(false);
      return;
    }

    try {
      const response = await api2.post(`/restaurants/ifood/on-demand/${item.session_id}`, {
        merchant_id: ifoodMerchantIdOnDemand.value,
        payment: onDemandPaymentSelected,
        ifood_value: onDemandData.quote.netValue
      });

      if(response.data?.response?.id){
        const ifood_on_demand_id = response.data?.response?.id || null;

        if(item.basket.order_status === 'ready'){
          updateIfoodOnDemandReady({
            order_basket_id: item.basket.id,
            ifood_on_demand_id: ifood_on_demand_id
          });

          handleNotifyWhatsapp(item, orders, user, 'ifood_on_demand', ifood_on_demand_id);
        }else{
          handleItemReady(
            item.basket.id,
            item.basket.ifood_id,
            item.with_withdrawal,
            ifood_on_demand_id
          );

          handleNotifyWhatsapp(item, orders, user, 'ready', ifood_on_demand_id);
        }

        toggleModal();
        //voltar nisso dps
      }

    } catch (err) {
      console.log(err);
      if(err?.response?.data?.response?.message){
        toast.error(err?.response?.data?.response?.message);
      }else{
        toast.error('Erro ao solicitar entrega fácil')
      }
    }
    setLoadingOnDemand(false);
  };

  useEffect(() => {
    if(user.has_ifood){
      if(ifoodRestaurants.length > 0){
        setIfoodMerchantIdOnDemand({ label: ifoodRestaurants[0].name, value: ifoodRestaurants[0].merchant_id });
      }else{
        getIfoodMenus();
      }
    }
  }, [ifoodRestaurants]);

  return (
    <>
      {onDemandData ? 
        <div style={{display: 'flex', padding: 20, flexDirection: 'column'}}>
          <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
            <EntregaFacilInfo>
              <div style={{alignItems: 'center', gap: '5px', textAlign: 'center'}}>
                  <strong>Valor do frete</strong>
                  <p>
                    R$ {onDemandData.quote.netValue.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
              </div>

              <div style={{alignItems: 'center', gap: '5px', textAlign: 'center'}}>
                <strong>Previsão de entrega</strong>
                <p>Entre {parseInt(onDemandData.deliveryTime.min / 60)} e {parseInt(onDemandData.deliveryTime.max / 60)} minutos</p>
              </div>
            </EntregaFacilInfo>

            <div style={{alignItems: 'center', gap: '5px', textAlign: 'left'}}>
              <strong>Endereço do cliente</strong>
              <p>Rua {item.buyer.delivery_address.street}, {item.buyer.delivery_address.number}, {item.buyer.delivery_address.neighborhood}, {item.buyer.delivery_address.city}</p>
            </div>

            {onDemandPaymentOptions.length > 1 &&
              <InformationDiv
                text={item.intended_payment_method 
                  ? <>A forma de pagamento escolhida (<strong>{item.intended_payment_method.name}</strong>) não é compatível com as oferecidas pelo iFood para pagamento na entrega. Selecione uma das opções disponíveis abaixo</> 
                  : <>Sem forma de pagamento escolhida. Selecione uma das opções disponíveis abaixo</>}
                type='warning'
              />
            }

            <div style={{alignItems: 'center', gap: '5px', textAlign: 'left'}}>
              <strong>{onDemandData.paid ? 'Pagamento' : (onDemandPaymentOptions.length > 1 ? 'Métodos de pagamento disponíveis na entrega' : 'Método de pagamento selecionado pelo cliente')}</strong>
              {onDemandData.paid ?
                <p style={{color: 'green'}}>Pago - {item.intended_payment_method.name}</p> :
                <>
                  <DefaultSelect
                    name="onDemandPaymentMethod"
                    width="-webkit-fill-available"
                    options={onDemandPaymentOptions}
                    value={onDemandPaymentSelected}
                    onChange={(e) => setOnDemandPaymentSelected(e)}
                    disabled={onDemandPaymentOptions.length === 1}
                  />
                </>
              }
            </div>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Button
                title='Fechar'
                onClick={toggleModal}
              />

              <Button
                title='Confirmar'
                buttonColor='#2EC9B7'
                disabled={loadingOnDemand}
                onClick={confirmOnDemand}
              />
            </div>
          </div>
        </div>
      :
        <div style={{display: 'flex', padding: 20, flexDirection: 'column', gap: 20}}>
          Com o Entrega Fácil, você pode solicitar entregadores parceiros do iFood para realizar suas entregas. Para obter mais informações, consulte o iFood.
          
          {ifoodRestaurants.length > 1 &&
            <div>
              <strong>Restaurante iFood</strong>
              <DefaultSelect
                name="ifoodRestaurantSelect"
                width="-webkit-fill-available"
                options={ifoodRestaurants.map(rest => {
                  return {
                    label: rest.name,
                    value: rest.merchant_id
                  }
                })}
                value={ifoodMerchantIdOnDemand}
                onChange={(e) => setIfoodMerchantIdOnDemand(e)}
              />
            </div>
          }


          {onDemandError && 
            <InformationDiv
              text={onDemandError}
              type="error"
            />
          }

          {ifoodMerchantIdOnDemand &&
            <Button 
              title='Consultar disponibilidade de entregador' 
              inverted 
              onClick={getOnDemand} 
              disabled={loadingOnDemand}
            />
          }
        </div>
      }
    </>
  );
}

export default IfoodOnDemand;
