import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Card, OrdersArea, OrdersTitle, OrdersItem, OrderItem, Footer } from './styles';
import { IconCheck } from 'takeat-design-system-ui-kit';
import Logo from '~/assets/img/logo_branco.png';
import toast from 'react-hot-toast';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';

export default function PasswordPickup() {
  const { websocketManager } = useAuth();
  const [preparingOrders, setPreparingOrders] = useState([])
  const [finishedOrders, setFinishedOrders] = useState([])

  const [maxPreparingItems, setMaxPreparingItems] = useState(0);
  const [maxFinishedItems, setMaxFinishedItems] = useState(0);

  const cardRef = useRef(null);
  const preparingItemRef = useRef(null);
  const finishedItemRef = useRef(null);

  // Atualiza o número máximo de itens visíveis
  const updateVisibleItems = () => {
    if (cardRef.current) {
      const cardHeight = cardRef.current.offsetHeight; // Altura do card
      const itemPreparingHeight = preparingItemRef.current?.offsetHeight || 1;
      const itemFinishedHeight = finishedItemRef.current?.offsetHeight || 1;

      // Quantidade total de linhas que cabem no card
      const totalPreparingRows = Math.floor(cardHeight / itemPreparingHeight);
      const totalFinishedRows = Math.floor(cardHeight / itemFinishedHeight);
      // Número total de itens visíveis (2 itens por linha)
      const maxPreparingItems = totalPreparingRows * 2;
      const maxFinishedItems = totalFinishedRows * 2;

      console.log(maxFinishedItems)

      setMaxPreparingItems(maxPreparingItems - 4);
      setMaxFinishedItems(maxFinishedItems - 2);
    }
  };

  useEffect(() => {
    const handleResize = () => updateVisibleItems();

    updateVisibleItems();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const priorities = {
    pending: 1,
    doing: 2,
    finished: 3,
    canceled: 4
  };

  const verifyOrdernation = useCallback((baskets_compare) => {
    if (baskets_compare) {
      baskets_compare.sort((a, b) => {
        if (a.status === b.status) {
          return (
            new Date(a.arrived_at).valueOf() - new Date(b.arrived_at).valueOf()
          );
        }

        return priorities[b.status] - priorities[a.status];
      });
    }
    return baskets_compare;
  }, []);

  const getKdsOrders = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/kds/unique-baskets')

      const basketOrdened = verifyOrdernation(response.data)

      const doingOrders = basketOrdened.filter(item => item.status === "doing")
      if (doingOrders.length > 0) {
        setPreparingOrders(doingOrders)
      } else {
        setPreparingOrders([])
      }

      const finishedOrders = basketOrdened.filter(item => item.status === "finished")
      if (finishedOrders.length > 0) {
        setFinishedOrders(finishedOrders)
      } else {
        setFinishedOrders([])
      }

      updateVisibleItems()

    } catch (error) {
      toast.error("Erro ao baixar pedidos do kds")
      console.log(error)
    }
  }, [])

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(8, (data) => {
        if (data.type === 'kds:new-orders' || data.type === 'new-transfer') {
          getKdsOrders();
        } else if (data.type === 'kds:basket-status') {
          getKdsOrders();
        } else if (data.type === 'kds:order-check') {
          getKdsOrders();
        } else if (data.type === 'kds:cancel-basket') {
          getKdsOrders();
        } else if (data.type === 'kds:cancel-order-item') {
          getKdsOrders();
        } else if (data.type === 'kds:cancel-order-amount') {
          getKdsOrders();
        } else if (data.type === 'kds:basket-alter') {
          getKdsOrders();
        }
      });

      return () => {
        websocketManager.addMessageCallback(8, (data) => { });
      };
    }
  }, [
    websocketManager,
    getKdsOrders,
  ]);

  useEffect(() => {
    getKdsOrders()
  }, [getKdsOrders])

  return (
    <Container>
      <Card ref={cardRef} windowHeight={windowHeight}>
        <OrdersArea>
          <OrdersTitle type="preparing">EM PREPARO</OrdersTitle>
          <OrdersItem>
            {preparingOrders
              .slice(Math.max(0, preparingOrders.length - maxPreparingItems))
              .map((item, index) => (
                <OrderItem
                  key={item.basket_key || item.attendance_password}
                  type="preparing"
                  ref={index === 0 ? preparingItemRef : null}
                >
                  #{item.basket_key || item.attendance_password}
                </OrderItem>
              ))}
          </OrdersItem>
        </OrdersArea>
        <OrdersArea>
          <OrdersTitle type="finished">
            PRONTOS
            <IconCheck fill="#fff" fontSize="3rem" css={{ marginLeft: 8 }} />
          </OrdersTitle>
          <OrdersItem>
            {finishedOrders
              .slice(Math.max(0, finishedOrders.length - maxFinishedItems))
              .map((item, index) => (
                <OrderItem
                  key={item.basket_key || item.attendance_password}
                  type="finished"
                  ref={index === 0 ? finishedItemRef : null}
                >
                  #{item.basket_key || item.attendance_password}
                </OrderItem>
              ))}
          </OrdersItem>
        </OrdersArea>
      </Card>
      <Footer>
        <div>
          <span>Desenvolvido por</span>
          <img src={Logo} alt='' />
        </div>
      </Footer>
    </Container>
  );
}
