import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

import formatCompleteDate from '../formatCompleteDate';
import formatValue from '~/utils/formatValue';

function getCreditRegisterData(registers) {
  const headers = [
    'DATA - HORA',
    'COMANDA',
    'CONSUMO',
    'VALOR EM ABERTO',
    'VALOR PAGO',
    'SITUAÇÃO DA CONTA',
  ];

  const data = [];

  const clientData = registers.map((s) => {
    return [
      formatCompleteDate(s.created_at),
      s.session_id ? `# ${s.session_id}` : '-',
      s.value > 0 ? formatValue("0") : formatValue(s.value),
      formatValue(s.total),
      s.value > 0 ? formatValue(s.value) : formatValue("0"),
      s.status,
    ];
  });

  data.push(headers);
  data.push(...clientData);

  return data;
}

// /restaurants/credit-register/summary/${id}
export function generateSheet(registers) {
  const rows = getCreditRegisterData(registers);

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const num_cols = 6;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });

  const black = '000000';
  const blue = '729fcf';
  const light_blue = 'b4c7dc';

  for (let i = 0; i < rows.length; i += 1) {
    for (let j = 0; j < num_cols; j += 1) {
      const cell = worksheet[columns[j] + (i + 1)];

      if (i === 0) {
        // Aplicar estilo ao cabeçalho (primeira linha)
        cell.s = style()
          .Bg("FFE599")
          .Font(black, 'bold', 'center')
          .Borders('blr', "000000")
          .Get();
      } else {
        if (rows[i][j] === "Consumo") {
          cell.s = style()
            .Bg("0000FF")
            .Font("FFFFFF", "bold", "center")
            .Borders('blr', "000000")
            .Get();
        } else if (rows[i][j] === "Pagamento Parcial") {
          cell.s = style()
            .Bg("FF9900")
            .Font("FFFFFF", "bold", "center")
            .Borders('blr', "000000")
            .Get();
        } else if (rows[i][j] === "Pagamento Total") {
          cell.s = style()
            .Bg("6AA84F")
            .Font("FFFFFF", "bold", "center")
            .Borders('blr', "000000")
            .Get();
        } else {
          cell.s = style()
            .Bg("FFFFFF")
            .Font("000000", "bold", "center")
            .Borders('blr', "000000")
            .Get();
        }
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Contas a Prazo');

  return XLSX.write(workbook, { type: 'buffer' });
}
