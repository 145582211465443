import styled from 'styled-components';

export const Container = styled.div`
  background-color: #222222;
  width: 100vw;
  height: 100vh;
  padding: 60px 40px;
  overflow: hidden;
`;

export const Card = styled.div`
  width: 100%;
  height: 97%;
  background-color: #545454;
  border-radius: 16px;
  display: flex;
  justify-content: space-evenly;
`;

export const OrdersArea = styled.div`
   width: 35rem;
`;

export const OrdersItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const OrderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  width: 17rem;
  height: ${props => props.type === "preparing" ? "4.5rem" : "7.28rem"};
  border-radius: 1rem;
  background: ${props => props.type === "preparing" ? "#222" : "#2EC9B7"};
  font-size: ${props => props.type === "preparing" ? "2rem" : "2.1rem"};
  color: ${props => props.type === "preparing" ? "#FFF" : "#000"};
  font-weight: 600;

  @media (max-width: 1025px){
    width: 14rem;
  }
`;

export const OrdersTitle = styled.div`
  max-width: 35rem;
  height: 5rem;
  color: #fff;
  font-size: 2rem;
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin-top: -2.5rem;
  margin-bottom: 1rem;
  align-items: center;
  border-radius: 1rem;
  background-color: ${props => props.type === "preparing" ? "#FFB32F" : "#2EC9B7"};
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  height: 3%;

  div {
    color: #fff;

    span{
      font-size: 10px; 
      color: #fff !important;
    }

    img {
      width: 91px;
    }
  }
`